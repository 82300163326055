@import 'assets/scss/variables';

.booking-client-item {
  display: flex;
  flex-direction: row;
  width: 100%;
	height: 60px;
  background-color: $color-athens-gray;
  border-radius: 6px;
  font-size: 12px;
  padding-top: 6px;
  padding-right: 12px;

  &--deleted {
    opacity: 0.5;

    .booking-status1 {
      color: $color-red;
    }
  }

  &__date {
    display: block;
		color: $color-bali-hai;
    margin-bottom: 1px;
    margin-top: 2px;
  }

  &__column {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 31%;
    text-align: left;
    padding-left: 15px;

    &--align-right {
      align-items: flex-end;
    }

    &:first-child {
      width: 38%;
    }
  }

  &__color-line {
    position: absolute;
    left: 0;
    top: -2px;
    width: 3px;
    height: 48px;
    border-radius: 2px;
  }

  &__confirmation-status {
    margin-bottom: 12px;
  }

  &__markers {
    gap: 10px;

    & div {
      scale: 0.85;
    }
  }

  &__details {
    font-size: 11px;
    line-height: 12px;
    height: 49px;
    overflow: hidden;

    & span:nth-child(odd) {
			min-height: 12px;
    }

    & .booking-details__item--requests {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__escort {
    & .lazy-load__container {
      width: 36px;
    }

    & img {
      border-radius: 7px;
    }
  }
}
