@import 'assets/scss/variables.scss';
@import 'assets/scss/functions.scss';

.mail-composer {
  &__mail-select-wrap {
    position: relative;
    display: flex;
    align-items: center;
    flex-grow: 1;
    .react-select {
      flex-grow: 1;
  
      &__control {
        padding-left: 0;
        border-radius: 6px;
      }
  
      &__value-container {
        height: 40px;
        outline: none;
        color: $color-tundora;
        padding: 8px;
        font-size: 14px;
        font-weight: 400;
        line-height: 1;
        letter-spacing: 1px;
      }
  
      &__placeholder {
        margin: 0;
        opacity: 0.54;
        color: $color-tundora;
        cursor: text;
      }
  
      &__input-container {
        margin: 0;
        padding: 0;
        cursor: text;
      }
  
      &__multi-value {
        display: flex;
        align-items: center;
        background-color: $color-mystic;
        border-radius: 6px;
        margin: 0 5px;
        padding: 1px 0;
        max-height: 18px;
  
        &__label {
          font-family: Roboto;
          font-size: 12px;
          font-weight: 300;
          letter-spacing: 1px;
          line-height: 16px;
          padding: 0;
          margin-left: 6px;
  
          .mail-contact-item {
            background-color: transparent;
            padding: 0;
          }
        }
  
        &__remove {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 12px;
          height: 16px;
          padding: 0;
          margin: 0 6px;
          cursor: pointer;
  
          svg {
            display: flex;
            width: 7px;
            height: 7px;
            color: $color-tundora;
            margin: 0;
          }
  
          &:hover {
            background: transparent;
  
            svg {
              color: $color-border-focus;
            }
          }
        }
      }
  
      &__menu {
        top: 90%;
        min-width: 370px;
        max-width: 370px;
      }
  
      &__menu-list {
        position: relative;
        overflow: hidden;
        padding: 13px 0;
        @include scrollBars;
  
        &::-webkit-scrollbar-button {
          display: none;
        }
  
        &:hover {
          overflow: overlay;
        }
      }
  
      &__option {
        padding: 1px 15px;
  
        &:nth-child(even) {
          background-color: transparent;
        }
  
        &:hover {
          background-color: transparent !important;
        }
  
        .mail-contact-item {
          padding: 2px 5px;
          border: 1px solid $color-mystic;
          background-color: transparent;
  
          &__img-wrap {
            margin-right: 5px;
            width: 32px;
            min-width: 32px;
            height: 32px;
          }
  
          &__name {
            margin-bottom: 0;
          }
  
          &:hover {
            border-color: $color-bermuda;
            background-color: rgba(136, 214, 217, 0.3);
          }
        }
  
        .mail-composer__mail-create-item {
          padding: 11px 5px;
          border: 1px solid $color-mystic;
          border-radius: 6px;
          cursor: pointer;
  
          &:hover {
            border-color: $color-bermuda;
            background-color: rgba(136, 214, 217, 0.3);
          }
        }
      }
    }
  }

  &__mail-select {
    display: flex;
    align-items: center;
    flex-grow: 1;
    border: 1px solid $color-silver;
    border-radius: 6px;
    transition: all 0.15s ease-in-out;

    &--focused {
      border-color: $color-border-focus;
    }
  }

  &__mail-clear-btn {
    display: flex;
    cursor: pointer;
    padding: 8px;

    &-icon {
      width: 9px;
      height: 9px;
      color: $color-red-orange;
    }
  }

  &__mail-contacts-btn {
    color: $color-bondi-blue;
    width: 20px;
    height: 20px;
    margin-left: 19px;
    cursor: pointer;
  }

  &__mail-remove-btn {
    position: absolute;
    top: 10px;
    left: -25px;
    color: $color-red-orange;
  }
}

.form-text-field--email-error {
  .mail-composer__mail-select {
    border: 1px solid $color-border-error !important;
  }

  .form-text-content__error {
    left: 500px;
  }
}
