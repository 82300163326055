.mailing-log {
  &__list {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
  }

  &__list-container,
  &__load-wrap {
    display: flex;
    height: 100%;
    justify-content: center;
  }

  &__no-items {
    align-self: center;
    width: fit-content;
    font-size: 24px;
  }
}
