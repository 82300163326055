@import 'assets/scss/variables';

.calendar-timeline {
  --gap: 15px;
  --row-height: 36px;
  --date-wrapper-width: 170px;

  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;

  &__header {
    gap: var(--gap);
    width: 100%;
    margin-bottom: 29px;

    &>* {
      height: var(--row-height);
    }
  }

  &__header-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;

  }

  &__filters {
    gap: var(--gap);
  }

  & .booking-status-filters {
    background-color: $color-mystic;

    .booking-status-filters__item--active {
      background-color: $color-white;
    }
  }

  &__select-filter {
    width: 170px;
  }

  &__creator-wrapper {
    display: flex;
    align-items: center;
    height: var(--row-height);
    min-width: var(--date-wrapper-width);
    margin-top: calc(var(--row-height) + var(--gap));
    font-size: 12px;
    font-weight: 500;
    color: $color-persian-pink;

    &--first {
      color: $color-bondi-blue;
    }
  }

  &__date-wrapper {
    width: 70%;
    text-align: center;
  }

  &__new-booking {
    display: flex;
    height: 100%;
    aspect-ratio: 1;
    color: inherit;

    & svg {
      margin: auto;
    }
  }

  &__booking-tag {
    padding: 5px 7px;
    border-color: $color-mystic;

    &--active {
      background-color: rgba(136, 214, 217, 0.44);
    }
  }

  &__status-filters {
    &--two-days {
      margin-left: calc(var(--date-wrapper-width) * -1);
    }
  }

  &__date-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    margin-left: 8px;
    margin-right: 16px;
    font-size: 12px;
    font-weight: 500;
  }

  &__new-booking-btn {
    padding: 0;
    display: flex;
    width: 14px;
    height: 14px;
    color: $color-persian-pink;

    &--first {
      color: $color-bondi-blue;
    }
  }

  &__tags {
    display: flex;
    flex-direction: row;
    gap: var(--gap);
  }
}
