@import 'assets/scss/variables';

.notifications-widget {
  display: flex;
  z-index: 9;
  flex-direction: column;
  width: 300px;
  height: 585px;
  border: 1px solid $color-bondi-blue;
  border-bottom: none;
  border-radius: 6px 6px 0 0;
  color: $color-bondi-blue;
  background-color: $color-mystic;
  box-shadow: 0 24px 24px 0 rgba(0, 0, 0, 0.06);

  &__list {
    flex-grow: 1;
    padding: 12px;
  }

  &__title {
    flex-grow: 1;
    margin-left: 12px;
    color: $color-bondi-blue;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0;
  }

  &__header {
    display: flex;
    align-items: center;
    padding: 12px;
    border-radius: 6px 6px 0 0;
    background-color: $color-bermuda;
  }

  &__btn {
    transition: .5s;

    display: flex;
    align-items: center;
		
    &:hover {
      svg {
        color: $color-persimmon;
      }
    }
  }

  &__icon {
    width: 20px;
    height: 20px;
    color: $color-bondi-blue;
  }

  &__list-container {
    height: 100%;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}

.notifications-item {
  background-color: $color-white;
  border-radius: 6px;
  box-shadow: 0 12px 12px 0 rgba(0,0,0,0.12);

  color: $color-black;
  font-family: 'Roboto';
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.8px;

  &__body {
    // min-height: 78px;
    padding: 16px 12px;
    text-wrap: balance;

    &--task {
      min-height: 42px;
    }

    .lazy-load__container {
      width: 48px;
      height: 48px;
      margin-right: 10px;
      margin-bottom: 0px;
    }
  }

  &__img {
    border-radius: 5px;
  }

  &__footer {
    padding: 9px 10px;
    border-radius: 0 0 6px 6px;
    background-color: $color-froly;
  }

  &__btns-wrapper {
    display: flex;
    justify-content: flex-end;
    gap: 18px;
  }

  &__btn {
    padding: 2px 8px;
    border-radius: 4px;
    color: #4C4C4C;
    font-size: 12px;
    line-height: 16px;
    font-family: Roboto;
    text-align: center;
    letter-spacing: 1px;
    background-color: $color-white;
  }
  &__icon-task {
    fill: $color-white;
    margin-right: auto;
  }
}
