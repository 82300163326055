@import 'assets/scss/variables.scss';
@import 'assets/scss/functions.scss';

$field: '.select-form-field';
$select: '.field-select';

#{$field} {
  position: relative;
  width: 162px;

  &--source {
    width: 260px;
  }

  &__label {
    margin-bottom: 5px;
    width: max-content;
    display: block;

    font-size: 12px;
    letter-spacing: 1px;

    &--taxi-note {
      max-width: 92px;
      resize: none;
      border: none;
      outline: none;
      @include scrollBars;
    }
  }
}

#{$field} #{$select} {
  &__control {
    border: 1px solid $color-silver;
    border-radius: 6px;
    cursor: pointer;
    box-shadow: none;

    &:hover {
      border-color: $color-silver;
    }
  }

  &__value-container {
    padding: 2px 9px;
    padding-right: 0;
    font-size: 14px;
    letter-spacing: 0.88px;
  }

  &__placeholder {
    color: $color-silver;
  }

  &__input-container,
  &__single-value {
    color: $color-tundora;
  }

  &__indicator {
    padding: 11px 9px 11px 0;
    color: $color-gray;

    &:hover {
      color: $color-gray;
    }

    svg {
      width: 16px;
      height: 16px;
      fill: $color-gray;
    }
  }

  &__menu {
    left: 5px;
    right: 5px;
    margin: 0;
    width: auto;

    overflow: hidden;
    border-bottom: 2px solid $color-bondi-blue;
    border-radius: 0px 0px 6px 6px;
    box-shadow: 0 12px 12px 0 rgba(0,0,0,0.12);
  }

  &__menu-list {
    padding: 0;
  }

  &__option {
    padding: 11px 14px 11px 15px;
    color: $color-gray;
    font-size: 12px;
    letter-spacing: 1px;

    &:nth-child(even) {
      background-color: $color-athens-gray;
    }

    &--is-focused:nth-child(n) {
      background-color: $color-bermuda;
    }

    &--is-selected {
      background-color: transparent;
      color: $color-bondi-blue;
    }
  }
}

#{$field}--type #{$select} {
  &__single-value {
    margin-right: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:after {
      content: '';
      width: 10px;
      height: 10px;
      display: block;
      border-radius: 50%;
    }
  }

  &__option {
    display: flex;
    align-items: center;
    gap: 11px;

    &:before {
      content: '';
      width: 10px;
      height: 10px;
      display: block;
      border-radius: 50%;
    }
  }
}
