@import 'assets/scss/variables';
@import 'assets/scss/functions.scss';

.client-card {
  display: flex;
  height: 60px;
  min-width: 300px;

  &__controls {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 6px 0;
  }

  &__control-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 20px;
    height: 20px;
    background-color: #fff;
    border-radius: 6px 0 0 6px;

    &-icon {
      width: 12px;
      height: 12px;
      color: $color-bondi-blue;

      &--close {
        width: 11x;
        height: 11px;
        color: $color-persimmon;
      }

      &--edit {
        transform: scale(-1, 1);
      }
    }
  }

  &__main {
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding: 0 8px;
    background-color: #fff;
    border-radius: 6px;
  }

  &__avatar-wrap {
    width: 36px;
    height: 36px;
    min-width: 36px;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
  }

  &__info {
    margin-left: 10px;
    font-size: 12px;
    max-width: 40%;
  }
  &__name {
    @include webkit-text-overflow();

    color: $color-dodger-blue;
  }
  &__phone {
    margin-top: 8px;
    color: $color-gray;
  }

  &__tags {
    flex-grow: 1;
    margin-left: 30px;
  }

  &__tag {
    background-color: $color-athens-gray;
  }
}
