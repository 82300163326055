@import 'assets/scss/variables';
@import 'assets/scss/functions.scss';

.room-creation-form {
  display: flex;
  flex-direction: column;
  padding: 11px 15px 24px;
  width: 100%;
  height: 100%;
  background-color: $color-mystic;

  &__input-wrap {
    .form-text-content__error {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__input {
    border-color: transparent;
  }

  &__list-title {
    margin: 0;
    padding: 0 8px;
    margin-top: 14px;
    color: $color-gray;
  }

  &__list {
    @include scrollBars;

    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-top: 14px;
    margin-right: -12px;
    padding-right: 8px;
    overflow-y: scroll;
  }

  &__item {
    position: relative;
    display: flex;
    margin-top: 7px;
    padding: 3px 6px 3px 4px;
    border-radius: 6px;
    background-color: #fff;
    font-size: 12px;
    color: $color-gray;
    cursor: pointer;

    &--disabled {
      opacity: 0.8;
      cursor: default;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background-color: rgba(#fff, 0.45);
      }
    }

    &:first-child {
      margin-top: 0;
    }

    &-ava {
      width: 32px;
      height: 32px;
      min-width: 32px;
      border-radius: 50%;
      overflow: hidden;
      background-color: #fff;
    }
    &-info {
      display: flex;
      flex-direction: column;
      // align-items: flex-start;
      justify-content: space-around;
      align-self: stretch;
      text-align: start;
      flex-grow: 1;
      margin: 0 6px;
      overflow: hidden;
    }
    &-name {
      font-weight: 500;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      min-width: 0;
    }
    &-check {
      display: flex;
      align-self: center;

      &-box {
        display: flex;
        width: 15px;
        height: 15px;
        background-color: #fff;
        border: 2px solid rgba(#000, 0.5);
      }
      &-icon {
        width: 13px;
        height: 13px;
        color: $color-caribian-green;
      }
    }
    

    .lazy-load__container {
      overflow: hidden;
    }
  }

  &__server-error-wrap {
    position: relative;
  }
  &__server-error {
    position: absolute;
    top: 8px;
    margin: 0;
    text-align: center;
    width: 100%;
    font-size: 14px;
    color: $color-froly;
  }

  &__actions {
    gap: 15px 30px;
    display: flex;
    flex-wrap: wrap-reverse;
    align-items: center;
    justify-content: center;
    margin-top: 35px;
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 16px;
    box-shadow: none;

    &--submit {
      height: 40px;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}
