@import 'assets/scss/variables';

.finished-list {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  &__header {
    padding: 14px 0;
    background-color: $color-mystic;

    &-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 16px 0 12px;
    }
  }

  &__title {
    font-size: 12px;
    color: $color-gray;
    font-weight: 500;
  }
}
