@import 'assets/scss/variables';

.booking-requirements {
  display: flex;
  flex-flow: row wrap;
  gap: 8px;
  padding-bottom: 20px;
  border-bottom: 1px solid $color-gray;

  &__item {
    width: fit-content;
    border: 1px solid $color-silver;
    border-radius: 6px;

    
    &-label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1px 7px 1px 11px;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 1px;
      cursor: pointer;
      
      &:has(input:disabled) {
        cursor: not-allowed;
        opacity: 0.5;
      }
      
      span {
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &-checkbox {
      display: flex;
      flex-shrink: 0;
      justify-content: center;
      align-items: center;
      width: 13.5px;
      height: 13.5px;
      margin-left: 17px;
      border: 1px solid $color-bondi-blue;
      border-radius: 2px;
      color: $color-white;
      background-color: $color-white;
      &--checked {
        background-color: $color-bondi-blue;
      }
    }

    &-input {
      display: none;
    }
  }

  &__icon-check {
    width: 10px;
  }
}
