@import "assets/scss/variables.scss";

.contact-bookings {
  &__header {
    display: flex;
    flex-direction: column;
  }

  &__filters-list {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    padding: 16px;
    background-color: $color-iceberg-light;
  }

  &__booking-tag {
    height: 32px;
    padding: 0 8px;
    border-radius: 8px;
    border-color: #ECECED;
    background-color: $color-white;

    &--active {
      background-color: $color-bermuda-dark;
      color: $color-white;
      border: transparent;
      border-bottom: 1px solid $color-neptune;
    }

    &:disabled {
      color: inherit;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 16px;
  }

  &__title {
    font-size: 12px;
    color: $color-manatee;
    text-align: left;
    @extend %font-main-text;

    &:not(:last-child) {
      margin-bottom: 6px;
    }
  }
  
  &__subtitle {
    text-transform: capitalize;
    color: $color-manatee;
  }

  &__list-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 16px;
    margin-top: 12px;
  }

  &__no-items {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: #bfbfbf;
    font-size: 14px;
  }

  &__list-container {
    height: 100%;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 4px;

    &:has(.booking-client-item) {
			gap: 12px;
    }

    &:has(.booking-girl-item) {
      gap: 6px;
    }
  }
}
