@import 'assets/scss/variables';

.counter {
  font-size: $font-size-s;
  line-height: 10px;
  font-weight: bold;
  box-sizing: content-box;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: $primary-background-color;
  z-index: $overlying-elements-z-index;
  cursor: pointer;
  overflow: hidden;
  
  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba($tertiary-background-color, $highlighted-opacity);
    height: 100%;
  }
  
  &--color-red {
    background-color: rgb(var(--red-status-color), 0.8);
    color: $primary-background-color;
  }

  &--color-blue {
    background-color: map-get($status-colors, "blue");
    color: $primary-background-color;
  }

  &--color-orange {
    background-color: map-get($status-colors, "orange");
    color: $primary-background-color;
  }

  &--underlied {
    border-radius: 6px;
    padding-top: 12px;

    & span {
      position: relative;
      top: 2px;
    }
  }

  &--overflowed {
    font: $font-xs;
  }

  &:has(svg) {
    border-radius: 6px;
  }

  &--axis-vertical:has(svg) {
    height: 28px;
  }

  &--axis-vertical:has(svg) &__content {
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding-top: 3px;
  }
  
  &--axis-horizontal:has(svg) {
    width: 38px;
  }
  
  &--axis-horizontal:has(svg) &__content {
    flex-direction: row-reverse;
    padding: 0 3px;
  }

  &__number {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    aspect-ratio: 1;
  }

  & svg {
    width: 12px;
    height: 12px;
  }
}
