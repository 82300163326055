@import 'assets/scss/variables';

.booking-escort {
  &__content {
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    gap: 6px;
  }

  &__avatar-container {
    display: flex;
    flex-direction: row;

    &:empty {
      display: none;
    }
  }

  &__avatar {
    height: 36px;
    width: 36px;
    min-width: 36px;
    border-radius: 25%;
  }

  &__row-names {
    display: flex;
    flex-direction: row;
    gap: 4px;
  }

  &__names {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:has(&__extra-text) {
      flex-wrap: wrap;
    }

    &--column {
      flex-direction: column;
    }

    &--reversed {
      flex-direction: column-reverse;
    }
  }

  &__name {
    // display: flex;
    // flex-direction: row;
    // align-items: center;
    // color: $color-azure-radiance;
    // overflow: hidden;
    // white-space: pre;
    // text-overflow: ellipsis;

    // &::before {
    //   box-sizing: border-box;
    //   display: inline-block;
    //   width: 4px;
    //   height: 4px;
    //   border-radius: 50%;
    //   transition: all 0.2s ease-in;
    //   margin-right: 4px;
    // }

    // &--available {
    //   &::before {
    //     content: '';
    //     background-color: rgb(1, 223, 133);
    //   }
    // }

    // &--off {
    //   &::before {
    //     content: '';
    //     background-color: #f8737f;
    //   }
    // }

    // &--off-today {
    //   &::before {
    //     content: '';
    //     background-color: #933131;
    //   }
    // }

    &--agent {
      color: $color-seance;
    }
  }

  &__extra-text {
    width: 100%;
  }
}
