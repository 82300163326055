@import 'assets/scss/variables';

.gorizontal-list {
  display: flex;
  position: relative;
  flex-flow: row wrap;
  width: 100%;

  &__pending {
    margin: auto;
  }

  & strong {
    margin: auto;
  }

  &__more-button {
    position: absolute;
    right: -42px;
    background-color: $color-bermuda;
    width: 26px;
    height: 26px;
    border-radius: 50%;

    &--revealed {
      display: flex;
      height: 22px;
      width: 22px;
      background-color: $color-white;
      margin: 2px;
    }

    & > svg {
      margin: auto;
      height: 10px;
      width: 10px;
      color: $color-tundora-light;
    }
  }
}
