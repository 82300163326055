@import 'assets/scss/variables';

.services-field-item {
  &__input {
    display: none;
  }

  &__check {
    width: 10px;
    height: 10px;
    margin-left: 5px;
    color: $color-spring-green;
  }

  &__label {
    display: flex;
    align-items: center;
    padding: 2px 5px;
    border-radius: 6px;
    color: $color-white;
    font-weight: 500;
    font-size: 12px;
    background-color: $color-gray;
    cursor: pointer;

    &--is-paid-service {
      background-color: $color-dodger-blue;
      span {
        margin-left: 4px;
      }
    }

    &--unchecked::after {
      display: block;
      width: 9px;
      height: 9px;
      margin-left: 5px;
      border: 1px solid $color-white;
      border-radius: 2px;
      content: '';
    }
  }
}
