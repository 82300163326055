@import 'assets/scss/variables';

.availability-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: $color-mystic;

  &__pending,
  &__no-items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px 0 6px;
    width: 100%;
    height: 100%;
    background-color: $color-mystic;
    font-size: 14px;
    font-style: italic;
  }

  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-bottom: 12px;
    padding-right: 12px;
    border-radius: 6px;
    color: $color-gray;
  }
  
  &__list {
    padding: 0 3px 0 15px;
    margin-top: 15px;
    width: 100%;
    height: 100%;
    list-style: none;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 0;

    &-top {
      display: flex;
      flex-grow: 1;
      justify-content: space-between;
      align-items: center;
      padding: 0 16px 0 12px;

      & .clipboard-btn {
        justify-content: space-between;
        align-items: center;
        width: 50px;
        height: 30px;
        padding: 0 4px 0 11px;
        border: 1px solid $color-silver;
        border-radius: 6px;
        background-color: $color-black-squeeze-light;

        &__icon {
          width: 15px;
          height: 15px;
        }

        &::before {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          content: "";
          background-color: $color-caribbean-green;
        }
      }
    }

    &--is-available {
      padding: 6px 0;
    }
  }

  &__title {
    font-size: 12px;
    font-weight: 500;
    color: $color-gray;
  }
}

