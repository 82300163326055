@import 'assets/scss/variables.scss';

.feedback-textarea-field {
  position: relative;
  width: 100%;

  &__label {
    margin-bottom: 5px;
    width: max-content;
    display: block;

    font-size: 12px;
    letter-spacing: 1px;
  }

  &__textarea-wrap {
    height: 146px;
    padding: 11px 5px 11px 11px;

    border: 1px solid #bfbfbf;
    border-radius: 6px;

    textarea {
      width: 100%;
      height: 100%;
      padding: 0 8px 0 0;
  
      border: none;
      resize: none;
      outline: none;
  
      color: #4C4C4C;
      font-family: Roboto;
      font-size: 14px;
      line-height: 1.35;

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #808080;
        border-radius: 3px;
      }
    }
  }
}
