@import 'assets/scss/variables';

.calendar-page {
  display: flex;
  flex-direction: column;
  min-width: 1280px;
  height: 100vh;

  &__main {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    padding: 25px 70px 0;

    .booking-status-filters {
      background-color: $color-mystic;
      border: 1px solid $color-mystic;

      &__item {
        color: $color-tundora;

        &--active {
          background-color: $color-white;
        }
      }
    }

    .booking-filters-list {
      &__item {
        border: 1px solid $color-mystic;
      }
    }
  }

  & .booking-status-filters-new {
    background-color: $color-mystic;

    &__item--active {
      background-color: $color-white;
    }
  }
}
