@import 'assets/scss/variables';

.time-filter {
  display: flex;
  gap: 16px;
  
  &__item {
    color: #067bf9;
    line-height: 19px;
    text-transform: capitalize;
    letter-spacing: 0;
    cursor: pointer;
  }
}
