@import 'assets/scss/variables';

.calendar-week-list {
  height: 100%;

  &__list-container {
    height: 100%;
    width: 100%;
  }

  &__scrollbar {
    right: -15px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    width: calc(100% - 14px);
  }

  &__item {
    display: flex;
    flex-direction: column;

    &-header {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
    }
  }

  &__dateline {
    position: relative;
    width: calc(100% / 7);
    display: flex;
    flex-direction: column;
  }

  &__date {
    &--first {
      color: $color-bondi-blue;
    }

    &-next, &-prev {
      display: flex;
      position: absolute;
      transform: scale(1.4);
      color: $color-bondi-blue;
    }
    
    &-prev {
      left: -36px;
    }

    &-next {
      right: -36px;
      transform: rotate(180deg) scale(1.4);
    }
  }

  &__item-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;

    &--collapsed {
      .calendar-week-list__list {
        gap: 6px;
      }
    }
  }

  &__load-wrap {
    display: flex;
    margin-top: 40px;
    justify-content: center;
  }

  &__header {
    display: flex;
    width: 92%;
    flex-direction: row;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 500;
    height: 16px;
    margin-bottom: 23px;
  }

  &__counts-control {
    display: flex;
    align-items: center;
    gap: 14px;
    color: $color-gray;
    font-weight: 400;
  }

  &__new-booking {
    padding: 0;
    display: flex;
    width: 14px;
    height: 14px;
    color: $color-persian-pink;

    &--first {
      color: $color-bondi-blue;
    }
  }

  &__load-wrap {
    height: 50px;
  }
}

.calendar-week-list-collapsed-item {
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 5px;
  min-height: 29px;
  background-color: $color-mystic;
  border-radius: 6px;
  border-top: 2px solid $color-persimmon;
  border-right: 1px solid $color-persimmon;
  border-left: 1px solid $color-persimmon;
  font-size: 12px;
  box-sizing: content-box;
  overflow: hidden;
  line-height: 16px;
  cursor: pointer;
  & > span:not(:last-child) {
    margin-right: 5px;
  }

  &__girl-name {
    color: $color-azure-radiance;
    max-width: 40%;
    overflow: hidden;
    white-space: pre;
    text-overflow: ellipsis;
  }
  &__time {
    text-transform: lowercase;
    white-space: nowrap;
  }
}
