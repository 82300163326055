@import 'src/assets/scss/variables.scss';

.toggle {
  position: relative;
  border-radius: 40px;
  width: 48px;
  height: 20px;
  background-color: #808080;
  cursor: pointer;

  &--disabled {
    opacity: 0.5;
    cursor: default;
  }

  &__indicator {
    position: absolute;
    top: 50%;
    width: 16px;
    height: 16px;
    background-color: #01DF85;
    border-radius: 100%;
    transform: translateY(-50%);
    transition: transform 0.3s;

    &--true{
      transform: translate(189%, -50%);
    }

    &--false{
      transform: translate(11%, -50%);
    }
  }
}
