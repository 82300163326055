@import 'assets/scss/variables';

.profile-card-contacts {
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: $color-mystic;
  padding: 8px 15px 15px;

  &__btns {
    display: flex;
    align-items: center;
    gap: 18px;
  }

  &__btn {
    display: flex;
    padding: 0;

    &--mess {
      color: $color-bondi-blue;

      &:disabled {
        cursor: not-allowed;
        
         & > svg {
          fill: grey;
        }
      }
    }
  }

  &__info {
    color: #808080;
    font-size: 12px;
  }

  &__tels,
  &__emails {
    display: flex;
    
    flex-wrap: wrap;
  }

  &__emails {
    margin-top: 3px;
  }

  &__tel, &__email {
    margin-right: 5px;
  }
}