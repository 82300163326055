@import 'assets/scss/variables.scss';

.contact-links-tab {
  padding: 16px;

  &__item-header {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }

  &__item-icon {
    color: $color-silver;
  }

  &__item {
    margin-bottom: 15px;

    &-btns {
      display: flex;
      flex-grow: 1;
      justify-content: flex-end;
      align-items: center;

      .clipboard-btn {
        margin-right: 18px;
        align-items: center;
        justify-content: center;
        @extend %adr-book-btn;

        &__icon--copy {
          color: $color-white;
        }
      }
    }

    &-btn {
      &--delete {
        padding: 0;
        @extend %adr-book-btn-warn;
      }
    }
  }

  &__msg-template-title {
    margin: 28px 9px;
    color: $color-gray;
    font-size: 14px;
    line-height: 24px;
    font-family: Roboto;
    letter-spacing: 0;
  }

  &__link-input {
    border: 1px solid;
    outline: none;
    background-color: $color-white;
    width: 100%;
    border-radius: 6px;
    padding: 6px 9px;
    color: $color-gray;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 19px;
  }

  &__note {
    width: 100%;
    padding: 12px 9px 6px;
    border: none;
    border-radius: 6px;
    outline: none;
    color: $color-tundora-light;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0;
    resize: none;
    background-color: $color-white;
  }

  &__btn {
    &-add {
      display: flex;
      align-items: center;
      color: $color-gray;
      @extend %adr-book-btn;

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
}