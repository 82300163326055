@import 'assets/scss/variables';

.tag {
  display: flex;
  align-items: center;
  border-radius: 6px;
  letter-spacing: 0.83px;
  overflow: hidden;
  font-size: 11px;
  height: 20px;
  padding: 2px 8px;
  white-space: nowrap;
  background-color: $color-white;
  color: $color-black;
  cursor: pointer;
  width: min-content;
  text-transform: capitalize;

  &__remove-btn {
    display: flex;
    padding: 4px;
    margin-right: -8px;
  }

  &--important {
    font-size: 12px;
    font-weight: bold;
  }

  &--capitalized {
    text-transform: uppercase;
  }

  &[class*='debt'] {
    background-color: $color-persian-pink !important;
    color: $color-white;
  }

  &--going-to-be-deleted {
    opacity: 0.5;
  }
  
  &--going-to-be-created {
    opacity: 0.5;
  }
}

// @keyframes fadeIn {
//   from {
//     opacity: 0.5;
//   }
//   to {
//     opacity: 1;
//   }
// }

// @keyframes fadeOut {
//   from {
//     background-color: green;
//     opacity: 1;
//   }
//   to {
//     background-color: green;
//     opacity: 0.5;
//   }
// }
