@import 'assets/scss/variables';

.recently-viewed-profiles {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 270px;
  &:not(:last-child) {
    margin-bottom: 12px;
  }

  &--not-default {
    margin-left: auto;
    height: calc(100% - 10px);
  }
  &--open {
    height: calc(50% - 10px);
  }
  &__scrollbar-wrap {
    flex-grow: 1;
    width: 100%;
  }
  &__scrollbar-thumb {
    border-radius: 6px;
    background-color: $color-silver;
  }
  &__title-box {
    min-height: 35px;
    padding-right: 11px;
    color: $color-gray;
    font-size: 14px;
  }
  &__title {
    display: flex;
    justify-content: space-between;
    &--click {
      cursor: pointer;
    }
  }
  &__subtitle {
    display: flex;
    justify-content: space-between;
    margin-top: 4px;
    min-height: 40px;
    padding: 4px 0 4px 5px;
    cursor: pointer;
  }
  &__client-info {
    display: flex;

    .lazy-load__container {
      margin-right: 5px;
      width: 32px;
      height: 32px;
      min-width: 32px;
    }
  }
  &__client-name {
    font-size: 12px;
    line-height: 16px;
    color: $color-dodger-blue;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  &__chevron {
    width: 13px;
    fill: $color-bondi-blue;
    &--rotated {
      transform: rotate(180deg);
    }
  }
  &__icon-wrapper {
    display: flex;
    align-items: center;
    margin: 0 8px;
  }
  &__items {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-top: 25px;
    padding: 0;
    width: 100%;
  }
  &__item {
    display: flex;
    opacity: 0.5;
    overflow: hidden;
    transition: opacity linear 0.1s;
    margin-bottom: 5px;
    border: 2px solid $color-black-squeeze-light;
    border-radius: 6px;
    background-color: $color-mystic;
    cursor: pointer;

    &:not(&--disabled) {
      &:hover {
        opacity: 0.8;
      }
    }

    &--disabled {
      cursor: default;
    }
  }
  &__img-wrap {
    max-width: 60px;
    height: 100px;
    width: 30%;
  }
  &__info {
    padding: 6px;
    padding-left: 13px;
    font-size: 12px;
    line-height: 16px;
    color: $color-tundora;
  }
  &__name {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    font-weight: 500;
    letter-spacing: 1px;

    &-icon {
      margin-right: 7px;
      width: 16px;
      height: 16px;
      color: $color-gray;
    }
  }
}

@media (max-width: 1450px) {
  .recently-viewed-profiles {
    max-width: 245px;
  }
}
