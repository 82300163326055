@import 'assets/scss/variables';

.booking-cancel-form {
  display: flex;
  font-size: 12px;
  width: 500px;
  height: 120px;
  padding: 12px 15px;
  background-color: $color-white;
  border-radius: 6px;
  box-shadow: 0 12px 12px 0 rgba(0,0,0,0.12);
  z-index: 1;

  &__reasons {
    display: flex;
    flex-direction: column;
    padding: 0 5px;
    width: 50%;

    &-title {
      font-size: 14px;
      font-weight: 500;
      padding: 5px 0;
      text-align: left;
    }

    &-label {
      display: flex;
      align-items: center;
      padding: 5px 0;

      input {
        margin-right: 5px;
      }

      span {
        text-transform: none;
      }
    }
  }

  &__radio {
    align-items: center;
    cursor: pointer;
    display: flex;
    margin-right: 5px;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    width: 50%;

    &-descr {
      height: 70%;
      padding: 5px 0;

      textarea {
        border: 1px solid $color-silver;
        border-radius: 6px;
        font-size: 14px;
        height: 100%;
        outline: none;
        padding: 5px;
        resize: none;
        width: 100%;
      }
    }

    &-btns {
      align-items: center;
      display: flex;
      height: 30%;
      justify-content: space-evenly;
    }
  }

  &__btn {
    border-radius: 4px;
    color: $color-white;
    min-width: 72px;
    padding: 5px 15px;

    &:disabled {
      cursor: not-allowed;
      opacity: .5;
    }

    &--submit {
      background-color: $color-jade;
    }

    &--close {
      background-color: $color-mandy;
    }
  }
}