@import 'assets/scss/variables';

.today-btn {
  display: flex;
  align-items: center;
  font-size: 12px;
  letter-spacing: 0;
  color: $color-bondi-blue;
  max-height: 19px;
  cursor: pointer;

  &__icon {
    width: 22px;
    height: 22px;
    color: $color-bondi-blue;
  }

  &__text {
    margin-top: 2px;
    margin-left: 8px;
  }
}
