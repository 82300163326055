@import 'assets/scss/variables';

.call-btn {
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  padding: 0;

  &:disabled {
    cursor: not-allowed;

    & > svg {
      fill: grey;
    }
  }

  &__option {
    padding: 2px;
  }

  &__dropdown {
    background-color: $color-bondi-blue;
    border-radius: 5px;
    box-shadow: 0 3px 12px rgba(27, 31, 35, 0.15);
    // position: absolute;
    // top: 103%;
    padding: 5px;
    z-index: 100;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 100;
    letter-spacing: 1px;

    button {
      color: white;
    }

    .call-btn {
      width: unset;
      height: unset;
    }
  }
}
