// Colors
$color-white: #fff;
$color-white-opacity: rgba(255, 255, 255, 0.5);
$color-black: #000;
$color-amber: #ffc107;
$color-bermuda: #88D6D9;
$color-bermuda-dark: #80D3D6;
$color-bermuda-opacity: rgba(136, 214, 217, 0.3);
$color-bondi-blue: #00A6AD;
$color-bondi-blue-dark: #00A9AF;
$color-persian-green: #00949a;
$color-caribbean-green: #01DF85;
$color-japanese-laurel: #058421;
$color-eucalyptus: #28a745;
$color-mystic: #E6EBF1;
$color-morning-glory: #99DBDE;
$color-iceberg-light: #E5F6F7;
$color-hawkes-blue: #cce9fc;
$color-razzle-dazzle-rose: #FF41BF;
$color-wild-sand: #f6f6f6;
$color-geyser: #dae0e6;
$color-tundora: #404040;
$color-mine-shaft: #222;
$color-shark: #2e2e30;
$color-tundora-light: #4a4a4a;
$color-gray: #808080;
$color-gray-approx: #828282;
$color-mecury: #e5e5e5;
$color-punch: #dc3545;
$color-manatee: #8E8E93;
$color-screaming-green: #3ef18e;
$color-alto: #D8D8D8;
$color-gallery : #EEEEEE;
$color-persimmon: #FF5E57;
$color-athens-gray: #F3F3F4;
$color-gun-powder: #494656;
$color-jagged-ice: #c9e4e9;
$color-viking: #5ac0de;
$color-azure-radiance: #0092F2;
$color-dodger-blue: #3565FD;
$color-silver: #bfbfbf;
$color-selective-yellow: #fbbc06;
$color-bali-hai: #8C97B2;
$color-jade: #00B936;
$color-green-haze: #00A562;
$color-fun-green: #008F14;
$color-apple: #2BC741;
$color-jade: #03c275;
$color-caribian-green: #01df85;
$color-spring-green: #29F18E;
$color-aqua-deep: #005947;
$color-aqua-island: #a7d7db;
$color-japanese-laurel: #0E970C;
$color-mandy: #e75d68;
$color-froly: #F8737F;
$color-rose-bud: #f9a9b0;
$color-pippin: #FFE0E0;
$color-red: #F20C0C;
$color-radical-red: #ff4052;
$color-pomegranate: #F03D3D;
$color-red-orange: #FF3D2B;
$color-orange: #FFA500;
$color-dawn-pink: #f6e9ec;
$color-mint-talib: #C5E9F4;
$color-zanah: #DDEFDD;
$color-ripe-lemon: #F2CC0C;
$color-black-squeeze-light: #F6F9FC;
$color-black-squeeze: #E7F6F7;
$color-seance: #88259C;
$color-pink: #E064F1;
$color-melanie: #eccdec;
$color-persian-pink: #F771A8;
$color-neon-carrot: #FF8C37;
$color-iceberg: #CBEDEF;
$color-tahiti-gold: #E88204;
$color-blaze-orange: #FF6F01;
$color-lucky-point: #1D1F7A;
$color-white-smoke: #F5F5F5;
$color-misty-rose: #FDE3E5;
$color-carousel-pink: #FDE2ED;
$color-persian-pink: #F77FBE;
$color-crystal-blue: #5CB3FF;
$color-sunset-orange: #FD5E53;
$color-outer-space: #454545;
$color-persian-green: #00A4AB;
$color-malibu: #62aff8;
$color-silver-sand: #bfc0c0;
$color-floral-white: #f6f8fb;
$color-neptune: #70b9bc;
$color-manatee: #8C8A94;
$color-salem: #058F43;
$color-alabaster: #F8F8F8;
$color-persian-red: #D33636;

$status-colors: (
  dark-red: #933131,
  red: $color-pomegranate,
  light-red: #FF7373,
  yellow: #FCBF04,
  green: #0ED065,
  dark-green: #058f43,
  gray: #B3B1B8,
  blue: #3565fd,
  orange: #f27e3f,
  pink: #FF6DE9,
  black: #0c201f,
);

$primary-background-color: rgb(255, 255, 255); // white
$secondary-background-color: rgb(0, 166, 173); // blue
$tertiary-background-color: rgb(73, 70, 86); // black
$darker-tertiary-background-color: rgb(0, 0, 0); // full black

$selected-opacity: 0.4;
$strong-highlighted-opacity: 0.1;
$highlighted-opacity: 0.07;
$light-highlighted-opacity: 0.04;
$difference-opacity: 0.05;
$focused-border-opacity: 0.7;
$dimmed-text-opacity: 0.6;

// Config
$color-text-primary: $color-tundora;
$color-text-primary-light: $color-gray;
$color-text-primary-darkest: $color-black;

$color-border-primary: $color-silver;
$color-border-success: $color-jade;
$color-border-error: $color-froly;
$color-border-focus: $color-bondi-blue;

$color-dark-background-tag: $color-athens-gray;

$shadow-primary: 0 12px 12px 0 rgba(0, 0, 0, .12);
$shadow-focus: 0 0 0 1px rgba($color-border-focus, 0.3);

$header-height: 64px;

$send-modal-header-height: 93px;
$send-modal-main-container-height: 360px - $send-modal-header-height * 2;

$box-shadow-popups: 0 24px 24px 0 rgba(0, 0, 0, 0.06);


// Fonts
$font-family-main: Manrope, Roboto, sans-serif;

$font-size-m: 12px;
$font-line-m: 20px;
$font-m: #{$font-size-m} / #{$font-line-m} #{$font-family-main};

$font-size-l: 14px;
$font-line-l: 22px;
$font-l: #{$font-size-l} / #{$font-line-l} #{$font-family-main};

$font-size-xl: 16px;
$font-line-xl: 24px;
$font-xl: #{$font-size-xl} / #{$font-line-xl} #{$font-family-main};

$font-size-s: 10px;
$font-line-s: 18px;
$font-s: #{$font-size-s} / #{$font-line-s} #{$font-family-main};

$font-size-xs: 8px;
$font-line-xs: 16px;
$font-xs: #{$font-size-xs} / #{$font-line-xs} #{$font-family-main};

// Other
$default-animation: 0.2s ease-in-out;

$default-modal-shadow: 0 24px 24px 0 rgba(0, 0, 0, 0.06);
$default-floating-box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.12);
$default-control-elements-shadow: 0px 1px 3px -1px rgba(0, 0, 0, 0.2);
$default-control-elements-hover-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2);

$radius-xl: 24px;
$radius-l: 16px;
$radius-m: 12px;
$radius-s: 8px;
$radius-xs: 6px;

$default-z-index: 2;
$underlying-elements-z-index: 1;
$overlying-elements-z-index: 4;
$floating-buttons-z-index: 10;
$modals-z-index: 100;
$tooltips-z-index: 200;

%font-main-text {
  font-family: $font-family-main;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0;
}

%adr-book-btn {
  width: 24px;
  height: 24px;

  border-radius: 6px;
  background-color: $color-bermuda-dark;
  border-bottom: 1px solid $color-neptune;
  position: relative;

  & > svg {
    position: absolute;
    inset: 0;
    margin: auto;
    width: 11px;
    height: 11px;
    color: $color-white;
  }
}

%adr-book-btn-warn {
  border-bottom: 1px solid $color-persian-red;
  @extend %adr-book-btn;
  background-color: $color-pomegranate;
}
