:root {
  --primary-color: 255, 255, 255; // white
  --secondary-color: 0, 166, 173; // blue
  --tertiary-color: 73, 70, 86; // black
  --darker-tertiary-color: 0, 0, 0; // full black

  --selected-opacity: 0.4;
  --strong-highlighted-opacity: 0.1;
  --highlighted-opacity: 0.07;
  --light-highlighted-opacity: 0.04;

  --pink-status-color: 255, 109, 233;
  --red-status-color: 240, 61, 51;
  --orange-status-color: 242, 126, 63;
  --yellow-status-color: 252, 191, 4;
  --gray-status-color: 179, 177, 184;
  --green-status-color: 5, 193, 67;
  --dark-green-status-color: 5, 123, 67;
  --black-status-color: 12, 32, 31;
  --blue-status-color: 11, 171, 251;
  --yellow-green-status-color: 153, 194, 58;
  --violet-status-color: 179, 128, 255;

  --primary-font-color: rgb(var(--tertiary-color));
  --darker-font-color: rgb(var(--darker-tertiary-color));
  --dimmed-font-color: rgba(var(--tertiary-color), 0.7);
}
