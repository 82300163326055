@import 'assets/scss/variables';

.mail-list-item {
  position: relative;

  padding: 10px 20px 10px 0;
  display: flex;
  align-items: center;
  
  background-color: #f6f6f8;
  cursor: pointer;
  
  color: $color-gray;

  overflow: hidden;

  &:hover {
    background-color: $color-mystic;

    & .mail-list-item__actions-wrapper {
      width: 120px;
      min-width: 120px;

      &--extended {
        width: 154px;
        min-width: 154px;
      }
    }
  }

  &:hover &__checkbox-btn {
    border-color: $color-black;
  }

  &:hover &__actions, &--pending &__actions {
    transform: translateX(-100%);
  }

  &--unread {
    background-color: $color-white;
  }

  &--pending {
    cursor: default;
  }

  &--starred {
    &::before {
      content: "";
      position: absolute;
      bottom: 1px;
      left: 2px;
      width: 14px;
      height: 14px;
      background: $color-froly;
      border-bottom-left-radius: 5px;
      clip-path: polygon(100% 100%, 0% 0%, 0% 100%);
    }
  }

  &__prebooking-icon {
    color: $color-persian-pink;
    margin-right: 10px;
  }

  &__draft-label {
    position: absolute;
    left: -20px;
    top: 50%;
    transform: translateY(-50%);

    height: 12px;
    width: 12px;

    pointer-events: none;
    fill: $color-froly;
  }

  &__checkbox-btn {
    padding: 12px 15px 12px 15px;
  }

  &__checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    background-color: white;
    border: 2px solid $color-silver;

    &--active {
      background-color: transparent;
      border: none;
      padding: 0;
    }

    &-check-icon {
      color: $color-jade;
    }
  }

  &__avatar {
    width: 32px;
    height: 32px;
    margin-right: 10px;
    margin-left: 10px;
    flex-shrink: 0;

    border-radius: 50%;
    overflow: hidden;
  }

  &__preview {
    min-height: 40px;
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    line-height: 1.3;   // for top & bottom text spacing

    &-header {
      display: flex;
    }
  }

  &__name {
    max-width: 300px;
    margin: 0 30px 0 0;
    flex-shrink: 0;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    font-size: 12px;
    color: $color-dodger-blue;

    .mail-list-item:hover & {
      color: $color-gray;
    }
  }

  &__username {
    margin: 0 30px 0 0;
    flex-shrink: 0;

    font-size: 12px;
    color: $color-azure-radiance;

    .mail-list-item:hover & {
      color: $color-gray;
    }
  }
  
  &__count {
    margin: 0 30px 0 0;
    flex-shrink: 0;

    font-weight: 300;
    font-size: 12px;
    letter-spacing: 1px;
    color: $color-black;

    .mail-list-item:hover & {
      color: $color-gray;
    }
  }

  &__attachments-icon {
    width: 14px;
    height: 14px;
    margin: 0 30px 0 0;
    flex-shrink: 0;

    fill: $color-gray;
  }

  &__subject {
    margin: 0;
    flex-grow: 1;
    
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; 
    -webkit-box-orient: vertical;
    word-break: break-all;

    font-size: 12px;
    color: $color-gray;

    .mail-list-item--unread & {
      color: $color-tundora;
      font-weight: 500;
    }

    .mail-list-item:hover & {
      color: $color-gray;
      font-weight: 400;
    }
  }

  &__date {
    flex-shrink: 0;

    font-size: 12px;
    color: $color-gray;
    width: 100%;

    .mail-list-item--unread & {
      color: #454545;
      font-weight: 500;
    }
  }

  &__message {
    margin: 0;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; 
    -webkit-box-orient: vertical;
    word-break: break-all;

    font-size: 13px;
    color: $color-gray;
  }

  &__highlight {
    color: $color-red;
    background: transparent;
  }

  &__actions-wrapper {
    width: 70px;
    min-width: 70px;
    overflow: hidden;
  }

  &__actions {
    display: flex;
    transition: transform 200ms;
  }

  &__action-btn {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    color: $color-gray;

    &:hover {
      color: $color-tundora;
    }

    &:disabled {
      cursor: inherit;
      color: $color-gray;
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }

  &__mark-sign {
    width: 20px;
    height: 20px;
    background-color: $color-gray;
    border-bottom-left-radius: 5px;
    clip-path: polygon(100% 100%, 0% 0%, 0% 100%);

    .mail-list-item--starred & {
      background-color: $color-froly;
    }

    .mail-list-item__action-btn:hover & {
      background-color: $color-tundora;

      .mail-list-item--starred & {
        background-color: $color-mandy;
      }
    }
  }

  &__folder {
    font-size: 10px;
    font-weight: 500;
    text-transform: capitalize;
    padding: 2px 20px;
    margin-right: 10px;
    background-color: $color-white;
    border-radius: 6px;
  }

  &__notes {
    height: 18px;
    color: $color-azure-radiance;

    & svg {
      height: 18px;
      width: 18px;
    }

    &--empty {
      color: $color-gray;
    }
  }

  &__remainder {
    position: relative;
    margin-left: 15px;
    height: 18px;
    color: $color-froly;

    & svg {
      height: 18px;
      width: 18px;
    }

    &--empty {
      color: $color-gray;
    }
  }

  &__spinner {
    z-index: 999;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__booking {
    display: flex;
    min-width: 620px;
    padding: 0 10px;

    @media (max-width: 1400px) {
      min-width: 450px;
    }
  }
}
