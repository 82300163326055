.booking-details {
  &--column {
    display: flex;
    flex-direction: column;
  }

  :is(&--row, .tooltip) {
    & span:not(:first-of-type)::before {
      content: '/';
      margin: 0 4px;
    }
  }
}
