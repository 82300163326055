.calendar-week {
	--gap: 15px;

  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 23px;

  &__header {
    gap: var(--gap);
  }

	&__filters {
    gap: var(--gap);
  }

  &__select-filter {
    width: 170px;
  }

  &__filters-container {
    display: flex;
  }

  &__content-wrapper {
    height: max-content;
    display: flex;
    position: relative;
    flex-direction: row;
    flex-grow: 1;
    gap: 3px;
  }

  &__reveal-btn {
    display: flex;
		position: absolute;
    left: -50px;
    top: 50%;
    transform: translateY(-50%);
  }
}
