@import 'assets/scss/variables';

.photo-gallery {
  &__carousel {
    overflow: hidden;
    animation: fade 0.3s;

    &-inner {
      display: flex;
      white-space: nowrap;
      gap: 30px;
      transition: transform 0.3s;
      height: 100%;
    }

    &-item {
      display: grid;
      grid-template-columns: repeat(4, 240px);
      grid-template-rows: repeat(2, 360px);
      gap: 30px;
      height: 100%;
    }

    &-controller {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 50%;
      transform: translateY(-50%);
      padding: 25px;
      color: $color-white;
      cursor: pointer;

      &--prev {
        left: 25px;
      }

      &--next {
        right: 25px;
      }

      &:disabled {
        opacity: 0.5;
        cursor: default;
      }
    }

    &-controller-icon {
      width: 53px;
      height: 53px;

      &--prev {
        transform: rotate(90deg);
      }

      &--next {
        transform: rotate(-90deg);
      }
    }
  }
}
