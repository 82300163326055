@import 'assets/scss/variables';

.active-call {
  display: flex;
  align-items: center;

  &__mute-btn {
    overflow: hidden;
    display: flex;
    margin-right: 15px;
    padding: 2px;
    border: 1px solid $color-bondi-blue;
    border-radius: 50%;
    color: $color-gray;
    transition: color ease-out .1s;

    &--muted {
      color: $color-froly;
    }
    
    &-icon {
      width: 20px;
      height: 20px;
    }
  }
}