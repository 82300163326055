@import 'assets/scss/variables.scss';

.calendar-form-field {
  position: relative;
  width: 100%;
  max-width: 260px;

  &__label {
    margin-bottom: 5px;
    font-size: 12px;
    letter-spacing: 1px;
  }

  &__button {
    width: 100%;
    padding: 11px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 22px;

    border: 1px solid #bfbfbf;
    border-radius: 6px;

    font-size: 14px;
    letter-spacing: 0.88px;
    text-align: left;

    &:disabled {
      background-color: hsl(0, 0%, 95%);
      cursor: not-allowed;

      & time {
        color: $color-silver;
      }
    }
  }

  &__placeholder {
    color: #BFBFBF;
  }

  &__date-value {
    color: #404040;
  }

  &__chevron-icon {
    width: 10px;
    height: 10px;
    color: #808080;
  }

  &__tooltip {
    z-index: 400;
  }
}
