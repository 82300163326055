@import "assets/scss/variables.scss";

.mail-list {
  position: relative;

  padding-bottom: 10px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  &--pending {
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: transparent;
      z-index: 100;
      cursor: wait;
    }
  }
}

.mail-list__spinner {
  margin: auto;
}

.mail-list__header {
  margin-bottom: 2px;
  display: flex;
  align-items: center;
}

.mail-list__tabs {
  display: flex;
}

.mail-list__tab {
  padding: 10px 12px;

  border-radius: 6px 6px 0px 0px;
  background-color: #e6ebf1;
  cursor: pointer;

  font-size: 12px;
  color: #454545;

  &:not(:last-child) {
    margin-right: 5px;
  }

  &--selected {
    font-weight: 500;
    color: #ffffff;

    background-color: #00a6ad;
    cursor: default;
  }
}

.mail-list__pagination-sort-wrap {
  display: flex;
  margin-left: auto;
}

.mail-list__pagination {
  min-width: 170px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mail-list__pagination-btn {
  width: 32px;
  height: 32px;
  padding: 0;

  color: #00a6ad;

  &:disabled {
    cursor: default;
    color: #bfbfbf;
  }

  svg {
    width: 14px;
    height: 14px;
  }
}

.mail-list__pagination-info {
  color: #808080;
  font-size: 12px;
  font-weight: 700;
}

.mail-list__sort {
  display: flex;
  align-items: center;

  .react-select {
    width: 130px;

    &__control {
      flex-direction: row-reverse;
      padding-left: 0;

      &--menu-is-open {
        background-color: $color-athens-gray;
      }
    }
    &__indicator {
      padding: 0 4px;
    }
  }
}

.mail-list__no-items {
  margin: auto;

  font-size: 34px;
  font-weight: bold;
  color: rgba(136, 214, 217, 0.6);
}

.mail-list__scrollbars {
  margin-left: -20px;
  margin-right: -12px;
  flex-grow: 1;
}

.mail-list__conversations {
  padding-left: 20px;

  // *** with Scrollbars
  padding-right: 12px;

  // *** w/o Scrollbars
  // margin-left: -20px;
  // height: 0;
  // flex-grow: 1;
  // overflow-y: auto;

  > li:not(:last-child) {
    border-bottom: 1px solid rgba(128, 128, 128, 0.5);
  }
}
