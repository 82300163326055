@import 'assets/scss/variables';

.status-marker {
  border-radius: 50%;
  aspect-ratio: 1;
  box-sizing: border-box;

  &--size-medium {
    height: 10px;
    border: 2px solid $primary-background-color;
  }

  &--size-small {
    height: 4px;
  }

  &--online {
    background-color: #0ED065;
  }

  &--offline {
    background-color: $color-pomegranate;
  }

  &--away {
    background-color: #FCBF04;
  }

  &--offline-today {
    background-color: #933131;
  }

  &--not-identified {
    background-color: #B3B1B8;
  }
}
