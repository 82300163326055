.debounce-input {
  $block: &;
  position: relative;

  &__input {
    width: 100%;
    padding: 11px 32px 11px 10px;
  
    border: 1px solid #bfbfbf;
    border-radius: 6px;
    outline: none;
    transition: border-color 200ms;
  
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0.875px;
    color: #404040;
  
    &:focus {
      border-color: #00A6AD;
    }
  
    &::placeholder {
      opacity: 1;
      color: #BFBFBF;
    }
  }
  
  &__clear-button {
    @extend %position-right;
  
    padding: 5px;
    display: inline-grid;
    color: #FF5E57;
  
    svg {
      width: 9px;
      height: 9px;
    }
  }
  
  &__search-icon {
    @extend %position-right;

    width: 13px;
    height: 13px;

    pointer-events: none;
    transition: color 200ms;
    color: #bfbfbf;

    #{$block}:focus-within & {
      color: #00A6AD;
    }
  }
}

%position-right {
  position: absolute;
  right: 17px;
  top: 50%;
  transform: translate(50%, -50%);
}
