@import 'assets/scss/variables.scss';

.sidebar-sections {
  --content-offset: 20px;
  --decoration-offset: 8px;

  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;

  &__header {
    margin-bottom: 10px;
    padding: 0 4px 0 1.5px;
  }
  
  &__list {
    flex-grow: 1;
  }

  &--left &__list {
    margin-right: calc(-1 * var(--decoration-offset));
    margin-left: calc(-1 * var(--decoration-offset));
  }

  &--right &__list {
    margin-left: calc(-1 * var(--content-offset));
    margin-right: calc(-1 * var(--decoration-offset));
  }

  &--right .sidebar-contacts-list__contact-item {
    width: calc(100% - calc(var(--content-offset) + var(--decoration-offset)));
    right: var(--decoration-offset);
  }

  &--left .sidebar-contacts-list__contact-item {
    left: var(--decoration-offset);
    width: calc(100% - calc(var(--decoration-offset) * 2))
  }

  &__title {
    padding: 15px 0;
		margin: 0 4px;
  }
}
