.geo-circle {
  position: absolute;
  border-width: 5px;
  border-style: solid;
  border-radius: 50%;
  opacity: 0.1;
  width: calc(var(--radius) * 2 * 1px);
  height: calc(var(--radius) * 2 * 1px);
  transform: translate(-50%, -50%);
  transition: all 0.15s ease-in-out;
  pointer-events: none;
}
