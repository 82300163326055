@import "assets/scss/variables.scss";

.info-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    &__icon {
        fill: $color-gray;
		transition: fill .1s;
	}

	&--active {
        .info-menu__icon {
            fill: $color-bondi-blue;
        }
    }
        

    &__popup {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 156px;
        max-height: 105px;
        border-radius: 6px;
        background-color: rgb(247, 250, 252);
        padding: 12px 12px 3px;
        box-shadow: 0 -4px 8px 0 rgba(0,0,0,0.2);
        z-index: 100;
    }

    &__title {
        width: 100%;
        font-size: 12px;
        font-weight: 300;
        line-height: 16px;
        letter-spacing: 1px;
        color: $color-gray;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__actions {
        margin-top: 16px;
        max-width: 100px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        row-gap: 6px;
        column-gap: 17px;
    }

    &__action-btn {
        svg {
            width: 20px;
            height: 20px;
            fill: $color-gray;
            cursor: pointer;
        }

        &:hover {
           svg {
            fill: $color-bondi-blue;
            transition: fill .1s;
           }
        }
    }
}