@import 'assets/scss/variables.scss';

.adr-book-page {
  display: flex;
  flex-direction: column;
  height: 100vh;

  &--pending {
    position: relative;
    z-index: 99999;
    cursor: wait;
  }
  
  &__selected-list {
    margin: 14px 20px;
  }
  
  &__selected-list-wrap {
    width: 100%;
    
    &-title {
      color: $color-gray;
      font-weight: bold;
      font-size: 12px;
      font-family: $font-family-main;
      line-height: 16px;
    }
  }
  
  &__main {
    display: flex;
    height: calc(100vh - 65px);
    background: linear-gradient(to right, transparent 25%, $color-iceberg-light 75%);

    & > .container {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }
}
