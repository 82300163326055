@import 'assets/scss/variables';
@import 'assets/scss/functions';

$icon-sizes: (
  xs: 25%,
  s: 38%,
  m: 50%,
  l: 65%,
  xl: 75%,
  full: 100%,
);

$radius-sizes: (
  s: $radius-s,
  m: $radius-m,
  full: 9999px,
);

$calculated-opacity: $selected-opacity + $difference-opacity;

.icon-button {
  $base-class: &;

  position: relative;
  z-index: $default-z-index;
  background-color: rgb(var(--primary-color));
  height: 100%;
  font-size: inherit;
  white-space: pre;

  & .content {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    width: 100%;
    border-radius: $radius-s;
    transition: background-color $default-animation, color $default-animation, transform $default-animation;

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: 8px;
      transition: all $default-animation;
    }
  }

  &--broad .content {
    padding: 0 4px;
  }

  & svg {
    transition: transform $default-animation;
  }

  &--showed-check-icon svg {
    color: green;
  }

  &__part-wrapper {
    display: flex;
    height: 100%;
    aspect-ratio: 1;
    position: relative;
    align-items: center;
    justify-content: center;
  }

  &__text {
    display: inline-block;
    text-align: left;
    position: relative;

    & svg {
      display: flex;
    }

    &:first-child {
      margin-left: 8px;
    }

    &:last-child {
      margin-right: 8px;
    }
  }

  &__status {
    display: inline-block;
    height: 6px;
    width: 6px;
    border-radius: 50%;
  }

  &--icon-size {
    @each $name, $size in $icon-sizes {
      &-#{$name} svg {
        max-height: $size;
        max-width: $size + 2%;
      }
    }
  }

  &--squared {
    aspect-ratio: 1;

    & svg {
      margin: auto;
      width: -webkit-fill-available;
      height: -webkit-fill-available;
    }
  }

  &--radius {
    @each $name, $radius in $radius-sizes {
      &-#{$name} {
        border-radius: $radius;

        & .content {
          border-radius: $radius;
        }
      }
    }
  }

  &.disabled {
    cursor: not-allowed;
  }

  &--tab-bottom .content {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  /* THEME CSS */

  &--theme-none-dark .content {
    --main-color: var(--primary-font-color);

    @extend %inheritedColorsByIcon;

    color: var(--main-color);
  }

  &--theme-none-light .content {
    --main-color: var(--dimmed-font-color);

    @extend %inheritedColorsByIcon;

    color: var(--main-color);
  }

  :is(&--theme-none-dark, &--theme-none-light) {
    background-color: transparent;

    &:hover:not(:disabled) .content {
      color: $darker-tertiary-background-color;
    }
  }

  &--theme-secondary-dark .content {
    color: rgba($tertiary-background-color, 1);
  }

  &--theme-secondary-light .content {
    color: rgba($tertiary-background-color, $dimmed-text-opacity);
  }

  &--theme-secondary-dark,
  &--theme-secondary-light {
    &.content {
      background-color: $primary-background-color;
    }

    &:hover:not(:disabled) .content {
      background-color: rgba($tertiary-background-color, $highlighted-opacity);
      box-shadow: $default-control-elements-hover-shadow;
    }
  }

  :where(&--theme-secondary-dark, &--theme-secondary-light, &--theme-none-dark, &--theme-none-light) {
    &:hover:not(:disabled) .content {
      color: $darker-tertiary-background-color;
    }

    &.active .content #{$base-class}__icon--chevron {
      // prohibit styling shevron icon
      color: $darker-tertiary-background-color;
    }
  }

  :where(&--theme-tag-light, &--theme-tag-dark) {
    --main-color: var(--tertiary-color);

    @extend %inheritedColorsByIcon;

    box-shadow: $default-control-elements-shadow;

    & .content {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgb(var(--primary-color));
      box-shadow: inset 0 0 0 1px rgba(var(--tertiary-color), 0.1);
      color: rgb(var(--main-color));
      text-transform: capitalize;

      // & svg {
      //   transition: fill $default-animation;
      // }
    }
  }

  :where(&--theme-tag-light, &--theme-tag-dark) {
    &:hover:not(:disabled) .content {
      background-color: rgba($secondary-background-color, $selected-opacity);
    }
  }

  &--theme-filter {
    box-shadow: $default-control-elements-shadow;
    text-transform: capitalize;

    & .content {
      box-shadow: inset 0 0 0 1px rgba($tertiary-background-color, $difference-opacity);
      background-color: $primary-background-color;
    }

    &:hover:not(:disabled, .active) {
      box-shadow: $default-control-elements-hover-shadow;

      & .content {
        background-color: rgba($tertiary-background-color, $highlighted-opacity);
      }
    }
  }

  &--theme-primary {
    --main-color: var(--secondary-color);
    --opacity: 0.6;
    --border-bottom-opacity: calc(var(--opacity) + 0.05);
    --hover-difference-opacity: 0.15;

    @extend %inheritedColorsByIcon;

    & .content {
      border: 0;
      background-color: rgba(var(--main-color), var(--opacity));
      box-shadow: none;
      color: $primary-background-color;

      & svg {
        fill: $primary-background-color;
      }

      &::before {
        border-bottom: 1px solid rgba(var(--main-color), var(--border-bottom-opacity));
      }
    }

    & .content {
      background-color: rgba(var(--main-color), var(--opacity));
    }

    &:hover:not(:disabled) .content {
      background-color: rgba(var(--main-color), calc(var(--opacity) - var(--hover-difference-opacity)));

      &::before {
        border-bottom: 1px solid rgba(var(--main-color), calc(var(--border-bottom-opacity) - var(--hover-difference-opacity)));
      }
    }
  }

  :where(
    &--theme-tag-light,
    &--theme-tag-dark,
    &--theme-filter
  ).active {
    & .content {
      border: 0;
      background-color: rgba(var(--secondary-color), $selected-opacity);
      box-shadow: none;
      color: $primary-background-color;

      & svg {
        fill: $primary-background-color;
      }

      &::before {
        border-bottom: 1px solid rgba($secondary-background-color, $calculated-opacity);
      }
    }
  }

  &--theme-dimmed {
    & .content {
      background-color: rgba($tertiary-background-color, $highlighted-opacity);
      color: $darker-tertiary-background-color;

      &:hover:not(:disabled) {
        background-color: rgba($tertiary-background-color, $strong-highlighted-opacity);
      }
    }

    &.active .content {
      background-color: rgba($secondary-background-color, $selected-opacity);
      color: $primary-background-color;
    }
  }

  &--theme-primary-inversion {
    & .content {
      background-color: $primary-background-color;
      color: $secondary-background-color;
    }
  }

  &:hover:not(:disabled) {
    & #plus-circle {
      transform: scale(0.8);
    }
  }

  /* ICON ANIMATIONS */

  &.active:not(&--tab-bottom) #{$base-class}__icon--arrow {
    transform: rotate(180deg);
  }

  &--tab-bottom:not(.active) #{$base-class}__icon--arrow {
    transform: rotate(180deg);
  }

  &:hover {
    &:not(.active) #{$base-class}__icon--arrow {
      animation: bottomArrowBounceBottom 0.3s infinite alternate;
    }

    &.active #{$base-class}__icon--arrow {
      animation: bottomArrowBounceTop 0.3s infinite alternate;
    }
  }

  &--tab-bottom:hover {
    &:not(.active) #{$base-class}__icon--arrow {
      animation: bouncetop 0.3s infinite alternate;
    }

    &.active #{$base-class}__icon--arrow {
      animation: bouncebottom 0.3s infinite alternate;
    }
  }

  @keyframes bottomArrowBounceBottom {
    from {
      transform: translateY(0);
    }

    to {
      transform: translateY(2px);
    }
  }

  @keyframes bottomArrowBounceTop {
    from {
      transform: translateY(-2px) rotate(180deg)
    }

    to {
      transform: translateY(0) rotate(180deg)
    }
  }

  @keyframes bouncetop {
    from {
      transform: translateY(0) rotate(180deg);
    }

    to {
      transform: translateY(-2px) rotate(180deg);
    }
  }

  @keyframes bouncebottom {
    from {
      transform: translateY(0);
    }

    to {
      transform: translateY(2px);
    }
  }

}
