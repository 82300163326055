
@import 'assets/scss/variables';

.buffered-girls-bookings-modal {
  display: flex;
  flex-direction: column;
  height: 850px;
  width: 610px;
  background-color: $color-mystic;
  border-radius: 6px;
  border-bottom: 2px solid #00A6AD;
  overflow: hidden;

  &__body {
    background-color: $color-white;
    height: 100%;
  }

  &__list-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__header {
    margin: 28px 24px 16px 24px;
  }

  &__main-filters {
    display: flex;
    flex-direction: row;
    align-items: center; 
    justify-content: space-between;
  }

  &__date-changer {
    width: 230px;
  }

  &__search-input {
    height: 34px;
  }

  &__statuses {
    margin-top: 10px;
  }

  &__item {
    margin-top: 16px;
  }

  &__no-items {
    font-size: 14px;
    color: $color-bali-hai;
  }
}
