@import 'assets/scss/variables';

.gallery-sidebar {
  display: flex;
  flex-direction: column;
  grid-area: sidebar; 
  padding: 21px 0 15px 0;

  &__actions {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 24px;
  }

  &__filters-action-btn {
    margin-bottom: 12px;
    padding: 0;
    padding-left: 10px;
    width: 100%;
    text-align: left;
    font-size: 12px;
    color: $color-azure-radiance;

    &--reset {
      color: $color-persimmon;
    }
  }

  &__filters {
    flex-grow: 1;
    margin-right: -10px;
  }
}

.gallery-sidebar-filters {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 5px;
  padding-right: 10px;
  
  &__item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 30px;
    border: 2px solid $color-mystic;
    border-radius: 6px;
    font-size: 14px;
    letter-spacing: 0.88px;
    color: #000;
    padding: 4px;
    cursor: pointer;
    background-color: #F6F9FC;
    text-align: center;

    &--active {
      background-color: $color-bermuda;
      border-color: #fff;
    }

    &--disabled {
      opacity: 0.7;
      color: $color-tundora-light;
      cursor: not-allowed;
  
      &:after {
        box-sizing: border-box;
        content: '';
        position: absolute;
        inset: -2px;
        border-radius: 6px;
        background-color: rgba(#fff, 0.4);
      }
    }
  }

  &__group {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    width: 100%;

    li {
      display: inline-block;
      align-self: flex-start;
      overflow: hidden;
      margin-top: 0;
      padding: 5px 0;
      width: 48%;
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__action-btn {
    position: relative;
    margin: 0 4px 0 4px;
    height: 24px;
    border: 2px solid $color-bondi-blue;
    border-radius: 6px;
    background-color: #fff;
    font-size: 14px;
    letter-spacing: 0.88px;
    color: #000;

    &--disabled {
      opacity: 0.7;
      color: $color-tundora-light;
      cursor: not-allowed;

      &:after {
        box-sizing: border-box;
        content: '';
        position: absolute;
        inset: -2px;
        border-radius: 6px;
        background-color: rgba(#fff, 0.4);
      }
    }
  }
}
