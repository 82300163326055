@import 'assets/scss/variables';

.menu {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0;

	&__icon {
		color: $color-text-primary-light;
		transition: color .1s;
	}

	&--active &__icon {
		color: $color-bondi-blue;
	}
}