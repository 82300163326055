@import 'assets/scss/variables';

.overlapped-booking-msg {
  &__warning-msg {
    width: 91%;
    margin: 2px;
    padding: 15px;
    border-radius: 6px;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1px;
    background-color: $color-white;

    &-wrap {
      display: flex;
      margin-top: 18px;
      border-radius: 6px;
      background-color: $color-froly;
      box-shadow: 0 12px 12px 0 rgba(0,0,0,0.12);
    }
    &-icon {
      width: 19px;
      height: 19px;
      color: $color-white;
      &-wrap {
        display: flex;
        flex-grow: 1;
        justify-content: center;
        align-items: center;
      }
    }
    &-link {
      cursor: pointer;
      color: $color-azure-radiance;
    }
  }
}