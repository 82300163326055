@import 'assets/scss/variables';

.sales-sessions {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 10px 0;

  &__header {
    display: flex;
    align-items: baseline;
    padding-bottom: 15px;
    padding-left: 2px;
  }

  &__icon {
    color: $color-tundora;

    &--marker-open {
      color: $color-bondi-blue;
      height: 8px;
      width: 13px;
      transform: rotate(180deg) translateY(1px);
    }

    &--marker-close {
      color: $color-bondi-blue;
      height: 8px;
      width: 13px;
    }
  }

  &__title {
    color: $color-gray;
    font-size: 14px;
    padding-left: 7px;
  } 

  &__list-wrap {
    height: 83%;
    flex-grow: 1;
    margin-right: -10px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-right: 10px;
    padding-top: 10px;
  }

  &--wrap {
    padding: 0 10px;
  }
}
