@import "assets/scss/variables.scss";
@import 'assets/scss/functions.scss';

.contact-requests {
  height: 100%;
  padding: 24px 0 6px 16px;
  overflow-y: auto;
  @include scrollBars;

  &__no-items {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: $color-silver;
    font-size: 14px;
  }

  &__list-container {
    height: 100%;
  }

  &__item {
    display: flex;
    flex-direction: column;
    margin-right: 11px;
    margin-bottom: 6px;
    padding: 7px 7px 7px 10px;
    border-radius: 6px;
    font-family: Roboto;
    text-align: left;
    cursor: default;
    background-color: $color-athens-gray;

    & > div:first-child {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &-date {
      margin-left: auto;
      color: $color-bali-hai;
      font-size: 12px;
      line-height: 16px;

      & > span {
        text-transform: lowercase;
      }
    }

    &-session-btn {
      color: $color-gray;
      cursor: pointer;
      margin-left: 5px;
    }

    &-status {
      &--success, &--unsuccess {
        font-size: 12px;
        text-transform: capitalize;
      }
      &--success {
        color: $color-fun-green;
      }
      &--unsuccess {
        color: $color-froly;
      }
    }

    & p {
      max-height: 110px;
      margin: 0;
      margin-bottom: 13px;
      margin-left: 5px;
      overflow: auto;
      color: $color-black;
      font-size: 11px;
      line-height: 16px;
      letter-spacing: 0;
      white-space: pre-wrap;
      word-break: break-word;
      @include scrollBars;

      &:last-child {
        margin-bottom: 0;
        width: 100%;
      }
    }
  }

  &__spinner-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-right: 10px;
  }
}
