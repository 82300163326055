@import 'assets/scss/variables';

.geo-marker {
  --height: 60px;
  --svg-width-koef: 1.41;
  --bottom-content-width-koef: 3.5;
  // --box-shadow: 0 -6px 8px 2px rgba(0, 0, 0, 0.5);
 
  --width: calc(var(--height) / var(--svg-width-koef));
  --bottom-content-size: calc(var(--width) / var(--bottom-content-width-koef));

  position: relative;
  font-size: 14px;
  font-weight: 600;
  color: var(--text-color);
  transform: translate(-50%, -100%);
  cursor: pointer;

  &--small {
    --height: 28px;
  }

  &--large {
    --height: 76px;
  }

  &__floating-label {
    position: absolute;
    top: calc(var(--height) * -1);
    left: calc(var(--width) * 0.4);
  }
  
  &__svg {
    position: absolute;
    height: var(--height);
    color: var(--color);
    transform: translate(-50%, -100%);
    z-index: 2;
    overflow: visible;

    &:not(&--empty) > path {
      mask: url(#mask-circle)
    }
  }

  &__selected-svg {
    position: absolute;
    height: calc(var(--height) * 1.15);
    transform: translate(-50%, calc(var(--height) * -1.06));
  }
  
  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background-color: var(--content-color);
    height: calc(var(--width) - 2px);
    width: calc(var(--width) - 2px);
    border-radius: 50%;
    transform: translate(-50%, calc(var(--height) * -1));
    overflow: hidden;
    margin: auto;
    z-index: 1;
  }

  &__bottom-icon {
    position: absolute;
    height: var(--bottom-content-size);
    width: var(--bottom-content-size);
    left: calc(var(--bottom-content-size) / -2);
    top: calc(var(--bottom-content-size) * -1.6);
    z-index: 3;
  }

  &__circle {
    background-color: var(--color);
    border-color: var(--border-color)
  }
}
