@import 'assets/scss/variables';

$grid-gap: 10px;

.attachment-img {
  display: grid;
  grid-auto-flow: row;
  position: relative;
  gap: $grid-gap;
  width: 100%;
  height: 100%;

  @for $i from 1 through 9 {
    &--col-#{$i} {
      grid-template-columns: repeat(#{$i}, 1fr);
    }

    @if $i != 1 {
      &--row-#{$i} {
        grid-template-rows: repeat(#{$i}, calc(100% / $i - $grid-gap / $i));
      }
    }
  }

  &__btn {
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 15px;
    line-height: 24px;
    opacity: 0;
    z-index: 2;

    &--download-all,
    &--download,
    &--expanced {
      width: 24px;
      height: 24px;
      padding: 0;
      color: #fff;
      background-color: #00A6AD;
      box-shadow: -2px 2px 10px 0px rgba(0, 0, 0, 0.1);
    }

    &--download-all,
    &--download {
      top: 0;
      right: 0;
      border-radius: 7px 0 7px 7px;
    }

    &--expanced {
      opacity: 1;
      border-radius: 50%;
      bottom: 4px;
      right: 4px;
    }

    & svg {
      transform: rotate(180deg) scale(0.9);
      margin: auto;
    }
  }

  &__img-wrap {
    position: relative;
    cursor: pointer;
    display: flex;
    text-align: center;

    &:hover .attachment-img__btn {
      opacity: 1;
    }
  }

  &__img {
    background-color: #fff;
    object-position: 50% 50%;
  }
}
