@import 'assets/scss/functions.scss';

.navbar {
  height: 56px;

  @include underliedBackground($radius-m);
  
  &-dropdowned {
    height: 40px;
  }
}
