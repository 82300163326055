@import 'assets/scss/variables';

.booking-table-item {
  display: flex;
  font-size: 12px;
  height: 51px;

  &--deleted {
    opacity: 0.5;

    .booking-status1 {
      color: $color-red;
    }
  }

  &__client, &__escort {
    width: 100%;
    height: 100%;

    & p {
      max-height: 86%;
    }
  }


  &__status {
    height: 50px;
    width: 50px;
  }

  & &__table-col--status {
    padding: 0;
  }

  &__notes {
    max-height: 84%;
  }

  &__date {
    display: flex;
    flex-direction: column;
  }
}
