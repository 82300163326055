@import "assets/scss/variables";

.mail-attachment-files {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  border-radius: 6px;
  margin-top: 20px;

  &:not(:last-child) {
    margin-right: 30px;
  }
  
  &__info {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    align-items: center;
  }

  &__content {
    position: relative;
    display: flex;
    width: 108px;
    height: 108px;
    margin-bottom: 5px;
    border-radius: 6px;
    justify-content: center;
  }

  &__counter {
    margin-bottom: 3px;
  }

  &__icon-file {
    width: 100%;
    height: 100%;
  }

  &__text {
    display: flex;
    max-width: 108px;
    overflow: hidden;
  }

  &__filename {
    margin: 0;
    font-size: 12px;
    color: $color-bali-hai;
    text-decoration: none;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__preview {
    border-radius: 6px;
    cursor: pointer;
  }

  &__banner-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    transform: translate(-50%, -50%);
    background-color: $color-bondi-blue;
    color: $color-white;
    border-radius: 50%;
    box-shadow: 0 0 14px 0 rgba(0,0,0,0.5);
    cursor: pointer;

    &--default {
      cursor: default;
    }
  }

  & .video-thumbnail__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    transform: translate(-50%, -50%);
    background-color: $color-bondi-blue;
    color: $color-white;
    border-radius: 50%;
    box-shadow: 0 0 14px 0 rgba(0,0,0,0.5);
    cursor: pointer;

    & svg {
      position: static;
      width: 12px;
      height: 12px;
    }
  }
} 
