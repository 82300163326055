@import 'assets/scss/variables';
@import 'assets/scss/functions.scss';

.mailing {
  @include ModalWindow(750px, 1128px, row);
  position: relative;
  top: 40px;

  &__dialog-wrapper {
    width: 72%;
    border-right: 2px solid $color-gray;

    display: flex;
    flex-direction: column;
  }

  &__contacts-wrapper {
    width: 28%;
  }

  &__dialog-wrapper,
  &__contacts-wrapper {
    padding: 24px 32px;
  }

  &__dialog-wrapper {
    gap: 28px;
  }

  &__messages-container {
    flex-grow: 1;
    border-radius: 6px;
    overflow: hidden;
  }

  &__input-container {
    position: relative;
    border-radius: 6px;
    background-color: #d3dbee;
    font-size: 12px;
  }

  &__selected-username-labels {
    visibility: hidden;
    width: 100%;
    
    &--hidden {
      pointer-events: none;
    }
  }

  &__selected-username-container {
    display: flex;
    gap: 18px;
    flex-grow: 1;
    max-width: 94%;
  }

  &__forwarding-message {
    display: flex;
    align-items: center;
    position: absolute;
    left: 14px;
    top: -22px;
    color: $color-black;
    gap: 6px;

    &--hidden {
      visibility: collapse;
    }

    & svg {
      width: 20px;
      height: 15px;
    }
  }

  &__error-message {
    display: flex;
    color: $color-persimmon;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;

    &--hidden {
      visibility: collapse;
    }

    & svg {
      transform: rotate(90deg);
    }
  }

  &__message-header {
    display: flex;
    align-items: center;
    padding: 8px 20px;
    justify-content: space-between;

    &--hidden {
      display: none;
    }
  }

  .value-container {
    padding: 0;
    max-height: 300px;
    overflow-y: scroll;

    & * {
      margin: 0;
    }
  }

  .indicators-container {
    display: none;
  }

  & .search-list {
    overflow: hidden;

    &__header {
      margin-bottom: 24px;
      padding: 0;
    }

    &__list-label {
      margin-bottom: 16px;
    }
  }

  &__esc-btn {
    display: flex;
    position: absolute;
    top: -40px;
    right: -120px;
    align-items: center;
    margin-left: 62px;
    height: 36px;
    color: $color-white;
    font-size: 14px;
    letter-spacing: 0.88px;
    padding: 0;
    cursor: pointer;

    &-icon {
      width: 13px;
      height: 13px;
      margin-right: 10px;
    }
  }

  &__contact-types-container {
    display: flex;
    position: relative;
    flex-direction: column;
    gap: 8px;
    padding-bottom: 16px;
    margin-bottom: 16px;
    color: $color-black;
    letter-spacing: 0.88px;

    &::after {
      content: '';
      position: absolute;
      width: 200px;
      height: 2px;
      bottom: 0;
      background-color: #bfc0c0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__contact-types-items {
    display: flex;
    align-items: center;
    padding: 0 22px;
    height: 47px;
    background-color: #F6F9FC;
    border-radius: 6px;
  }

  & [class*=IndicatorsContainer] {
    display: none;
  }

  :has(.username-label) {
    gap: 6px;
  }
}
