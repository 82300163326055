@import 'assets/scss/variables';

.booking-form {
  width: 1140px;
  max-height: 100vh;
  padding: 30px 0;
  // overflow-y: scroll; 

  &__client-tag {
    background-color: $color-dark-background-tag;
  }

  &__main {
    display: flex;
    position: relative;
    height: 800px;
    border-radius: 6px;
    color: $color-black;
    margin-top: 10px;
    font-size: 12px;
    line-height: 16px;
    font-family: Roboto;
    letter-spacing: 1px;
    background-color: $color-white;
    box-shadow: 0 24px 24px 0 rgba(0, 0, 0, 0.06);
  }

  &__avatar-container {
    position: relative;
  }

  &__channel-type {
    position: absolute;
    bottom: -10px;
    left: -6px;
    transform: translateX(-50%);
    height: 18px;
    width: 18px;

    &--telegram, &--robot {
      color: $color-azure-radiance;
    }
    &--whatsapp {
      color: $color-jade;
    }
    &--apple {
      color: $color-gray;
    }
  }

  &__map {
    display: flex;
    justify-content: center;
    align-items: center;

    .leaflet-container {
      width: 100%;
      height: 100%;
    }

    .leaflet-bottom {
      display: none;
    }

    .leaflet-marker-icon {
      display: flex;
      justify-content: center;
    }

    &--disabled {
      cursor: not-allowed;
      pointer-events: none;
      filter: grayscale(1);
    }
  }

  &__marker {
    position: relative;
    justify-content: center;
    width: 44px;
    height: 44px;
    border-radius: 0 50% 50% 50%;
    background: $color-persimmon;
    box-shadow: 0 -4px 8px 0 rgba(0, 0, 0, 0.5);
    transform: rotate(-136deg);

    &-img {
      position: absolute;
      margin: auto;
      border-radius: 50%;
      transform: rotate(135deg);
      inset: 0;
    }
  }

  &__content-wrapper {
    position: relative;
    width: 715px;
    padding: 32px 25px 32px 60px;
    border-right: 1px solid $color-gray;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  &__booking-total-rate {
    font-weight: bold;
  }

  &__type-input-group {
    display: flex;
    gap: 15px;
  }

  &__duo-logo {
    position: absolute;
    top: 11px;
    right: 27px;
    color: $color-razzle-dazzle-rose;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0;
  }

  &__diva-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
  }

  &__diva-control-btns {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .feedback-contact-field {
      width: auto;
      &__button {
        border: none;
      }
    }
  }

  &__diva-info-block {
    display: flex;
    flex-grow: 1;
    border-radius: 6px;
    overflow: hidden;
    background-color: $color-mystic;
    border: 1px solid #E6EBF1;
    border-radius: 6px;

    .lazy-load__container {
      width: 64px;
      height: 94px;
    }
    .lazy-load__image {
      border-radius: 6px;
    }
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  &__diva-info {
    position: relative;
    flex-grow: 1;
    width: 250px;
    height: 94px;
    margin-left: 15px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 0;
    }
  }

  &__requirements {
    &-title {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      color: $color-azure-radiance;
      font-size: 12px;
      letter-spacing: 0;
      &--has-agent {
        color: $color-seance;
      }
    }
  }

  &__diva-avatar {
    cursor: pointer;
  }

  &__diva-name {
    display: flex;
    align-items: center;
    color: $color-azure-radiance;
    margin: 6px 0 6px 6px;
    cursor: pointer;

    &::before {
      display: block;
      width: 7px;
      height: 7px;
      margin-right: 10px;
      border-radius: 50%;
    }

    &--available-now::before {
      content: '';
      background-color: $color-caribian-green;
    }

    &--off-today::before {
      content: '';
      background-color: $color-radical-red;
    }

    &--has-agent {
      color: $color-seance;
    }

    &-wrapper {
      display: flex;
      align-items: center;
      color: $color-froly;
    }
  }

  &__agent-icon {
    width: 10px;
    height: 10px;
    margin-left: 11px;
  }

  &__no-tags {
    display: block;
    margin-top: 10px;
    margin-left: 10px;
    color: $color-gray;
    font-size: 10px;
  }

  &__client {
    &-info {
      display: flex;
      .lazy-load__container {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        overflow: hidden;
      }
    }

    &-info-block-wrapper {
      width: 270px;
      text-align: center;;
    }

    &-info-block {
      padding: 5px;
      border: 1px solid $color-mystic;
      border-radius: 6px;
      height: 96px;
      cursor: pointer;

      &::-webkit-scrollbar {
        width: 0;
      }
    }

    &-text-content {
      display: flex;
      flex-direction: column;
      max-width: 210px;
      margin-left: 10px;
    }

    &-name {
      overflow: hidden;
      cursor: pointer;
      color: $color-dodger-blue;
      text-overflow: ellipsis;
      letter-spacing: 0;
      white-space: nowrap;
    }

    &-contact {
      margin-top: 6px;
      overflow: hidden;
      color: $color-gray;
      text-overflow: ellipsis;
      letter-spacing: 0;
      white-space: nowrap;
    }

    &-tags-wrapper {
      margin-top: 4px;
      margin-left: 10px;

      & .contact-tags__tag--hidden-count {
        height: 16px;
        line-height: 13px;
        width: 36px;
      }
    }
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
  }

  &__services-input {
    outline: none;
    border: none;
    padding: 5px 10px;
    
    &::placeholder {
      color: $color-black;
      letter-spacing: 0.9px;
    }
    
    &-wrap {
      position: absolute;
      right: 0;
      top: 52px;
      width: 230px;
      border: 2px solid $color-gray;
      border-radius: 8px;
      overflow: hidden;
    }
  }

  &__tag {
    border-radius: 6px;
    color: $color-tundora-light;
    font-size: 11px;
    text-align: center;
    letter-spacing: 0;
    background-color: $color-athens-gray;
    margin-right: 0;
    height: 16px;

    & > span {
      line-height: 13px;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 26px;
  }

  &__btns-wrap {
    display: flex;
    align-items: center;

    .feedback-contact-field {
      width: 138px;
      margin-left: 60px;
      &__button {
        width: 138px;
        border: none;
      }
    }
  }

  &__status-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 36px;
    width: 98px;
    background: $color-white;
    align-self: flex-start;
    border-style: solid;
    border-width: 1px;
    padding: 10px;
    margin-left: 132px;
    font-size: 12px;
    border-radius: 6px;
    text-transform: capitalize;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  &__status-popup {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    width: 240px;
    padding: 12px 15px;
    background-color: $color-white;
    border-radius: 6px;
    box-shadow: 0 12px 12px 0 rgba(0,0,0,0.12);
    z-index: 1;
    
    &-header {
      color: $color-gray;
      margin-bottom: 15px;
      text-align: start;
    }
    
    &-list {
      display: flex;
      flex-wrap: wrap;
      row-gap: 10px;
      column-gap: 15px;

      &-item {
        display: flex;
        align-items: center;
        padding: 2px 10px;
        border-style: solid;
        border-width: 1px;
        border-radius: 6px;
        margin-right: 15px;
        margin-bottom: 11px;
        cursor: pointer;

        .booking-form__dot {
          margin-left: 5px;
        }
      }
    }
  }

  &__dot {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }

  &__profile {
    &-tags-wrapper {
      margin-top: 10px;
    }
  }

  &__fields-request {
    margin-left: -16px;
  }

  &__fields-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 23px 18px 19px;
    border-bottom: 1px solid $color-gray;
    text-align: left;

    &--time {
      position: relative;
      padding: 25px 18px 19px;
      gap: 30px;
    }

    &--is-duo {
      padding: 0 0 15px 0 !important;
      border: 0;
    }

    &--is- {
      padding: 0;
    }

    &--select-fields {
      padding: 27px 0 30px;
      gap: 15px;
    }

    &--services {
      display: flex;
      position: relative;
      flex-direction: column;
      align-items: flex-start;
      gap: 17px;
      padding: 25px 17px;

      & .debounce-input {
        position: absolute;
        right: 0;
        top: 52px;
        
        &__input {
          border-color: $color-gray;
          height: 26px;

          &::placeholder {
            color: $color-gray;
          }
        }
      }
    }

    &--contacts {
      flex-wrap: nowrap;
      padding: 0 0 34px;
      border-bottom: 1px solid $color-gray;
      gap: 30px;
      min-height: 131px;
    }

    &--location {
      padding: 23px 15px 20px;
    }
  }

  &__select {
    &-wrapper {
      flex-grow: 1;
  
      .select-form-field {
        width: 100%;
      }
    }

    &-title {
      color: $color-black;
    }

    &-incall-address {
      padding: 11px;
      color: $color-tundora;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.88px;
    }
  }

  &__field {
    &:has(input:disabled) {
      cursor: not-allowed;
    }

    &-select-group {
      display: flex;
      margin-bottom: 11px;
      gap: 13px;
    }

    &--type {
      display: flex;
      position: relative;
      flex-direction: column;
    }
    &--location {
      display: flex;
      position: relative;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      gap: 30px;

      .booking-form__location-info {
        margin-right: 30px;
      }

      .field-select {
        width: 100%;
        &__control {
          border: 1px solid $color-silver;
          border-radius: 6px;
          cursor: pointer;
          box-shadow: none;
      
          &:hover {
            border-color: $color-silver;
          }
        }
      
        &__value-container {
          padding: 2px 9px;
          padding-right: 0;
          font-size: 14px;
          letter-spacing: 0.88px;
        }
      
        &__placeholder {
          color: $color-silver;
        }
      
        &__input-container,
        &__single-value {
          color: $color-tundora;
        }
      
        &__indicator {
          padding: 11px 9px 11px 0;
          color: $color-gray;
      
          &:hover {
            color: $color-gray;
          }
      
          svg {
            width: 16px;
            height: 16px;
            fill: $color-gray;
          }
        }

        &__menu {
          left: 5px;
          right: 5px;
          margin: 0;
          width: auto;
      
          overflow: hidden;
          border-bottom: 2px solid $color-bondi-blue;
          border-radius: 0px 0px 6px 6px;
          box-shadow: 0 12px 12px 0 rgba(0,0,0,0.12);
        }
      
        &__menu-list {
          padding: 0;
        }
      
        &__option {
          padding: 11px 14px 11px 15px;
          color: $color-gray;
          font-size: 12px;
          letter-spacing: 1px;
      
          &:nth-child(even) {
            background-color: $color-athens-gray;
          }
      
          &--is-focused:nth-child(n) {
            background-color: $color-bermuda;
          }
      
          &--is-selected {
            background-color: transparent;
            color: $color-bondi-blue;
          }
        }
      }
    }
    
    &--rate, &--taxi, &--discount {
      .select-form-field {
        width: 102px;
      }
    }
  }

  &__booking-type {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    cursor: pointer;

    &:has(input:disabled) {
      cursor: not-allowed;
      opacity: 0.5;
    }

    &-duo-wrapper {
      padding: 8px;
    }

    &-name {
      width: 98px;
      text-align: left;
    }
    
    & input {
      display: none;

      &:disabled + [class*=selected]::before {
        background-color: $color-silver;
      }
    }
  }

  &__location {
    width: 100%;
    height: 32px;
    height: 32px;
    padding: 0;
    border: none;
    outline: none;
    outline: none;
    color: $color-silver;
    font-size: 14px;
    line-height: 19px;
    font-family: Roboto;
    letter-spacing: 0.88px;

    &-info {
      width: 100%;
    }

    &-controls {
      display: flex;
    }

    &-error {
      display: block;
      margin-top: 2px;
      margin-left: 10px;
      color: $color-persimmon;
    }

    // &--value-block {
    //   position: absolute;
    //   top: 23px;
    //   width: 330px !important;
    //   padding-top: 13px !important;
    //   background-color: $color-white;
    // }
  }

  &__location-info-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 9px 5px;
    font-size: 12px;

    & ~ div {
      width: 100%;
      height: 93px;
      padding: 6px 9px 8px;
      border: 1px solid $color-gray;
      border-radius: 6px;
      outline: none;
      color: $color-silver;
      font-size: 14px;
      line-height: 19px;
      font-family: Roboto;
      letter-spacing: 0.88px;
    }
  }

  &__booking-radio-field {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    cursor: pointer;

    &--location {
      margin: 0;
      &:last-child {
        margin-left: 18px;
      }
    }
    
    & input {
      display: none;
    }
  }

  &__btn {
    padding: 5px 24px;
    font-size: 14px;
    border-radius: 6px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 2px;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    &-icon {
      color: $color-bondi-blue;
      margin-left: 15px;
      width: 16px;
      height: 16px;

      &--add-special {
        width: 26px;
      }

      &--location {
        margin: 0;
        margin-right: 9px;
      }

      &--edit {
        margin: 0;
      }

      &--delete-profile {
        width: 6px;
        height: 6px;
        margin: 0;
        color: $color-white;
      }

      &--clear-profile {
        width: 20px;
        height: 20px;
        color: $color-red-orange;
      }
    }

    &--clear-profile {
      position: absolute;
      top: 9px;
      right: 2px;
      padding: 0;
      transform: rotate(45deg);
    }

    &--location {
      display: flex;
      align-items: center;
      padding: 0;
      line-height: normal;
      letter-spacing: normal;

      &--edit {
        margin: 0;
      }

      &--delete-profile {
        width: 6px;
        height: 6px;
        margin: 0;
        color: $color-white;
      }
    }

    &--add-duo {
      display: flex;
      align-items: center;
      width: 100%;
      max-width: 138px;
      height: 36px;
      padding: 5px 18px;
      letter-spacing: 1px;
      background-color: $color-white;
    }

    &--add-special {
      display: flex;
      align-items: center;
      max-width: 162px;
      height: 36px;
      margin-left: 22px;
      padding: 0 19px;
      line-height: 16px;
      text-align: left;
      letter-spacing: 1px;
      background-color: $color-white;
    }

    &--request-messages {
      padding: 0;
      svg {
        margin: 0;
      }
    }

    &--booking-editor {
      position: absolute;
      top: 18px;
      left: -40px;
      padding: 0;
      svg {
        color: $color-gray;
      }
    }

    &--done, &--book-another {
      display: block;
      width: 100%;
      height: 40px;
      margin: 55px auto 36px;
      color: $color-white;
      font-weight: 600;
      background-color: $color-bondi-blue;
    }

    &--done {
      max-width: 110px;
    }

    &--book-another {
      max-width: fit-content;
      margin: 0 auto;
    }

    &--cancel {
      color: $color-white;
      font-weight: 600;
      background-color: $color-froly;
    }

    &--submit {
      height: 40px;
      color: $color-white;
      font-weight: 600;
      background-color: $color-bondi-blue;
    }

    &--edit {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 11px;
      height: 11px;
      padding: 0;
    }

    &--delete-profile {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 12px;
      height: 12px;
      margin-left: 17px;
      padding: 0;
      border-radius: 50%;
      background-color: $color-persimmon;
    }

    &:disabled {
      opacity: .5;
      cursor: not-allowed;
    }
  }

  &__radio {
    position: relative;
    width: 18px;
    height: 18px;
    margin-right: 8px;
    border-radius: 50%;
    background: $color-gray;

    &--selected::before {
      display: block;
      position: absolute;
      width: 10px;
      height: 10px;
      margin: auto;
      border-radius: 50%;
      content: '';
      background-color: $color-screaming-green;
      inset: 0;
    }
  }

  &__messages-list {
    max-height: 160px;
    padding-bottom: 20px;
    border-bottom: 1px solid $color-gray;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      width: 4px;
      border-radius: 2px;
      background-color: $color-mystic;
    }
  }

  &__booking-details {
    &-wrapper {
      padding: 24px;
      border-radius: 6px;
      
      &--info {
        background-color: #88d6d94d;
      }

      &--messages {
        padding: 0 24px 13px 24px;
        overflow: hidden;
        background-color: transparent;
      }

      &--notes {
        padding: 0 57px 0 43px;
      }

      &--requirements-duo {
        padding-bottom: 0px;
      }
    }

    &-group-block {
      display: flex;
    }

    &-title {
      margin-right: 8px;
      color: $color-bali-hai;
      font-size: 12px;
      line-height: 27px;
      letter-spacing: 0;
      &-label {
        text-transform: capitalize;
      }
    }

    &-group {
      display: flex;
      flex-wrap: wrap;
      letter-spacing: 0;

      time {
        align-self: center;
      }

      span {
        font-size: 12px;
        line-height: 27px;
        letter-spacing: 0;
      }

      &--request-msg {
        flex-direction: column;
        line-height: 16px;
      }

      &--rate, &--extras {
        width: 176px;
        margin-right: 30px;
      }

      &--discount {
        span {
          color: $color-japanese-laurel;
          font-weight: bold;
        }
      }

      &--girl-name {
        margin-top: 8px;
        & > h4 {
          color: $color-azure-radiance;
        }
      }

      &--has-agent > h4 {
        color: $color-seance;
      }
    }
  }

  &__agent-icon {
    width: 10px;
    height: 10px;
    margin-left: 16px;
  }

  &__booking-request-msg {
    margin: 6px 0;
    line-height: 16px !important;
  }

  &__info-wrapper {
    width: 425px;
    text-align: left;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  &__field-error {
    position: absolute;
    bottom: -2px;
    left: 11px;
    transform: translateY(100%);
    color: $color-persimmon;
    font-size: 12px;
    letter-spacing: 1px;
    &--type {
      transform: translateY(50%);
    }
  }

  &__front-wrap {
    display: flex;
    z-index: 3;
    position: absolute;
    justify-content: center;
    align-items: center;
    margin: auto;
    border-radius: 6px;
    background-color: $color-white;
    inset: 0;
    
    &-title {
      color: $color-gray;
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 0;
    }

    &-success {
      display: flex;
      flex-direction: column;
      align-self: start;
      margin-top: 120px;
    }
  }

  &::-webkit-scrollbar {
    width: 0;
  }

  &__selected-profiles {
    width: 314px;
    margin-right: 15.5px;
    text-align: center;
  }

  &__choose-button {
    width: 160px;
    height: 50px;
    font-size: 14px;
    letter-spacing: 1px;
    margin: 23px 0;
  }
}

.services-list {
  display: flex;
  flex-wrap: wrap;

  &__item {
    margin-right: 3px;
    margin-bottom: 5px;
  }
}

.fields-request {
  display: flex;
  gap: 60px;
  font-size: 14px;
  color: $color-gray;

  &__item {
    display: flex;
    gap: 5px;
    align-items: center;
  }

  & input {
    display: none;
  }

  &__radio {
    position: relative;
    width: 15px;
    height: 15px;
    border: 1px solid $color-gray;

    &--selected {
      background-color: $color-gray;

      &::after {
        content: '';
        position: absolute;
        width: 85%;
        height: 90%;
        left: 1px;
        top: 1px;
        mask-image: url(../../assets/icons/check.svg);
        mask-size: 100%;
        background-color: $color-screaming-green;
      }
    }
  }
}
