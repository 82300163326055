@import 'assets/scss/variables';

.profile-labels {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-right: 3px;
  padding-bottom: 3px;
  overflow: hidden;
  position: absolute;

  &__label {
    display: flex;
    justify-content: center;
    align-content: center;
    width: fit-content;
    border-radius: 4px;
    color: white;
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 1px;
    padding: 5px 6px;
    margin-top: 3px;
    margin-left: 3px;
    white-space: nowrap;

    &--contact-det {
      font-size: 9px;
      padding: 3px 5px;
    }
    &--table-item  {
      font-size: 7px;
      padding: 2px 3px;
    }
    &--bi {
      background-color: #f2cc0c;
    }
    &--secret {
      background-color: #ff4052
    }
    &--hetero {
      background-color: #0092f2;
    }
    &--green {
      background-color: #00a562;
    }
    &--red {
      background-color: #f01e1e;
    }
    &--lightpink {
      background-color: #f8737f;
    }
    &--recommended {
      background-color: #0092f2;
    }
    &--featured {
      background-color: #9370dbff;
    }
    &--grey {
      background-color: $color-gray;
    }
    &--compared {
      color: $color-black;
      background-color: $color-white;
    }
  }

  &--profile-card-mini {
    height: 100%;
    flex-direction: column;
    justify-content: flex-end;
  }
  &--compared {
    align-items: flex-end;
  }
  &--profile-card {
    position: relative;
    padding-left: 12px;
    padding-right: 15px;
  }
  &--adr-book {
    position: relative;
    max-width: 50%;
    justify-content: flex-end;
    padding-right: 6px;
  }
  &--contact-det {
    position: relative;
    justify-content: center;
    margin: 0 auto;
    padding-left: 30px;
    padding-right: 30px;
  }
  &--table-item {
    position: absolute;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    bottom: 5px;
    left: 5px;
    width: auto;
  }
}
