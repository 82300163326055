.mail-form-autosave {
  &__group {
    display: flex;
    align-items: flex-start;
  }

  &__status-icon {
    margin-right: 5px;
    font-size: 16px;
    color: #808080;
  }

  &__status-label {
    margin: 0;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    color: #808080;
  }

  &--hide {
    display: none;
  }
}
