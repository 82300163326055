@import 'assets/scss/variables';

.search-input {
  $inversion-theme-color: rgba($secondary-background-color, $highlighted-opacity);
  $inversion-theme-icon-color: rgba($secondary-background-color, $selected-opacity);
  $inversion-theme-focus-border-color: rgba(
    $secondary-background-color,
    $focused-border-opacity
  );

  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  border-radius: 12px;
  height: 100%;

  transition: border-color $default-animation;

  &:focus-within {
    border: 1px solid $inversion-theme-focus-border-color;

    .search-input__btn {
      color: $color-border-focus;
    }
  }

  &__extra-buttons-wrapper {
    width: 24px;
    height: 24px;
  }

  &--inversion {
    background-color: $inversion-theme-color;
    border: 1px solid transparent;
    border-radius: $radius-s;
    letter-spacing: 0.3px;
    color: rgba($tertiary-background-color, $dimmed-text-opacity);
    
    & input {
      color: $tertiary-background-color;
      letter-spacing: 0.3px;
    }
  }

  :where(&--inversion, &--primary) &__icon--search {
    color: $tertiary-background-color;
  }

  &--inversion &__icon--children {
    color: $inversion-theme-icon-color;
  }

  &--primary {
    border: 1px solid;
    border-color: transparent;
    color: #404040;
    background-color: #fff;
    border-radius: $radius-s;

    &::placeholder {
      color: black;
    }
  }

  &--dim {
    background-color: $color-athens-gray;
    border: 1px solid $color-athens-gray;

    &::placeholder {
      color: $color-silver;
    }
  }

  &--dim-width-border {
    background-color: $color-iceberg-light;

    &::placeholder {
      color: $color-silver;
    }
  }

  &__input {
    border-radius: 6px;
    line-height: 1;
    letter-spacing: 1px;
    width: 100%;
    height: 100%;
    border: 0;
    padding: 0;
    background-color: transparent;
    transition: all 0.15s ease-in-out;
    outline: none;
    margin-left: 10px;
    letter-spacing: 0.88px;

    // &:focus {
    //   border-color: $color-border-focus;
    // }

    &--primary {
      // &--error {
      //   border: 1px solid $color-border-error !important;
      // }
    }
  }

  &__icon-wrapper, &__btn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    aspect-ratio: 1;
    padding: 0;
    color: $color-silver;
    transition: color 0.15s ease-in-out;

    &--clear {
      cursor: pointer;
    }

    & + input {
      margin-left: 0;
    }
  }

  &__icon {
    height: 40%;
    aspect-ratio: 1;
    margin: auto;

    &--clear {
      width: 8px;
      color: $color-red-orange;
    }
  }

  &__button-container {
    display: flex;
    align-items: center;
    position: absolute;
    gap: 4px;
    right: 12px;
    top: 0;
    height: 100%;
  }
}
