@import 'assets/scss/variables';

.app {
  --outer-background-color: rgb(var(--primary-color));

  display: flex;
  height: 100vh;
  flex-direction: column;

  // move it to common after redisign
  font: $font-m;
  color: rgba($tertiary-background-color, 1);
  background-color: $primary-background-color;
}
