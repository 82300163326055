@import 'assets/scss/variables.scss';
@import 'assets/scss/functions.scss';

.search-list-escort-item {
  display: flex;
  column-gap: 15px;

  background-color: #F6F9FC;
  border: 1px solid transparent;
  border-radius: 6px;
  cursor: pointer;

  &__avatar-thumb {
    width: 64px;
    height: 70px;
    flex-shrink: 0;

    border-radius: 6px;
    overflow: hidden;
    background-color: #fff;

    img {
      display: block;
    }
  }

  &__info {
    padding: 9px 9px 9px 0;
    flex-grow: 1;
  }

  &__name {
    @include webkit-text-overflow;

    position: relative;
    margin-bottom: 10px;
    padding-left: 23px;

    color: #0092F2;
    font-size: 12px;

    &::before {
      content: '';
      position: absolute;
      left: 9px;
      top: 50%;
      transform: translateY(-50%);

      width: 4px;
      height: 4px;

      border-radius: 50%;
      background-color: #000;
    }

    &--available-now::before {
      background-color: #29F18E;
    }

    &--off-today::before {
      background-color: #F8737F;
    }
  }

  &__short-info {
    color: #000;
    font-size: 12px;

    span {
      @include webkit-text-overflow;
    }
  }
}
