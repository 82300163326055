@import 'assets/scss/variables.scss';

.mail-header {
  display: flex;
  align-items: center;
  min-height: 84px;
  padding: 0 20px 0 0;
  background-color: $color-mystic;

  &__email {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    width: 280px;
    position: relative;
    z-index: 1;
  }

  &__search-input-wrap {
    min-width: 370px;
    margin-left: 20px;
    height: 100%;
  }

  &__search-input {
    width: 100%;
    height: 36px;
  }

  &__actions {
    flex-grow: 1;
    display: flex;
    align-items: center;
    margin-left: 60px;
  }

  &__move-to-wrap {
    position: relative;
    margin-right: 12px;
  }

  &__move-to-popup-wrap {
    width: 100%;
    z-index: 15;
    border-radius: 6px;
    box-shadow: 0 2px 0 0 $color-bondi-blue;
  }

  &__move-to-wrap {
    &--move-menu-active>.button {
      outline: 2px solid $color-bondi-blue;
    }
  }

  &__actions {
    display: flex;
    gap: 16px;
    height: 38px;
  }

  &__action-btn {
    height: 36px;

    &--archive,
    &--move-to {
      width: 130px;
    }

    &--delete {
      width: 120px;
    }

    &--send {
      width: 110px;
    }

    &--save {
      gap: 10px;
    }

    &--discord {
      width: 125px;
    }

    &--hidden {
      visibility: hidden;
    }
  }

  &__select-spinner {
    width: 200px;
    margin-left: 8px;
  }

  &__mail-icon {
    fill: $color-bondi-blue;
    width: 30px;
    height: 30px;
    margin: 0;
  }

  &__mail-select {
    display: flex;
    align-items: center;

    .react-select {
      &__control {
        &--menu-is-open {
          background-color: inherit;
        }
      }

      &__option {
        max-width: 240px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &__indicator {
        padding: 0 4px;
      }
    }
  }

  &__mailbox-users {
    display: flex;
    align-items: center;
    min-width: 0;

    &-list {
      display: flex;
      overflow: hidden;
    }

    &-title {
      min-width: 185px;
      font-size: 14px;
      color: $color-gray;
    }

    &-img-wrap {
      width: 32px;
      height: 32px;
      min-width: 32px;
      margin-left: -8px;
      border-radius: 50%;
      overflow: hidden;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  &__navbar-btn-wrap {
    width: 49px;
    height: 84px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-top: 16px;
    margin-right: 30px;
    padding-right: 5px;
    width: 54px;
    background-color: $color-mystic;
  }
}
