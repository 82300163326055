@import 'assets/scss/variables';

.main-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  font-family: $font-family-main;;
  overflow: hidden;

  &__calls {
    display: flex;
    height: 48px;
    outline: none;
    overflow: hidden;
    user-select: text;
    bottom: 0px;
    flex-direction: column;
    top: 0px;
    width: 100%;

    position: static !important;
    
    &--show {
      height: 160px;
    }
  }
  
  &__main {
    flex-grow: 1;
    
    & > .container {
      height: 100%;
    }
  }

  &__sidebar {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;

    &--left {
      margin-right: 38px;
    }

    &--right {
      margin-left: 38px;
    }

    &-wrapper {
      // display: flex;
      height: 100%;
    }
  }

  &__chats {
    display: flex;
    justify-content: space-between;
    column-gap: 21px;
    height: 100%;
    padding-bottom: 52px;
  }

  &__contacts {
    height: 70%;
  }

  &__sessions {
    display: flex;
    height: 30%;
  }

  .blocked { // [ ]: redisign block marker
    &::before {
      content: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' enableBackground='new 0 0 510 510' version='1.1' viewBox='0 0 510 510' width='12' height='12' fill='red'><path d='M255,0C114.75,0,0,114.75,0,255s114.75,255,255,255s255-114.75,255-255S395.25,0,255,0z M51,255c0-112.2,91.8-204,204-204 c45.9,0,89.25,15.3,124.95,43.35l-285.6,285.6C66.3,344.25,51,300.9,51,255z M255,459c-45.9,0-89.25-15.3-124.95-43.35 L415.65,130.05C443.7,165.75,459,209.1,459,255C459,367.2,367.2,459,255,459z' /></svg>");
      display: block;
      position: absolute;
      aspect-ratio: 1;
      z-index: 1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .blocked img {
    opacity: 0.8;
  }
}

.placeholder {
  display: flex;
  justify-content: center;
  align-items: center;  
  margin: 5px;
  height: calc(100% - 10px);
  background-color: rgba($color-bermuda, 0.4);
  border-radius: 20px;
}
