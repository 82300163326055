@import 'assets/scss/variables.scss'; 
@import 'assets/scss/functions.scss';

.contact-address-tab {
  &__input-wrap {
    min-height: 32px;
    padding: 0 15px 0 17px;

    &--joined-address {
      margin-top: 12px;
    }

    & .form-textarea--dark-background {
      border-color: transparent;
      background-color: transparent;
    }

    & .form-textarea__input-wrapper {
      padding: 6px 0;
    }
  }

  &__input {
    border-radius: 6px;
    border: 1px solid;
    resize: none;
    width: 100%;
    color: $color-gray;
    font-size: 14px;
    letter-spacing: 0.88px;
    line-height: 19px;
    outline: none;
    cursor: pointer;
    @extend %font-main-text;
  }

  &__textarea {
    height: 20px;
    overflow-y: hidden;
  }

  &__addresses-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__addr-title {
    color: $color-tundora-light;
    font-family: Roboto;
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 16px;
    max-width: 360px;
  }

  &__addr-search {
    &-wrap {
      box-shadow: 0 2px 0 0 $color-bondi-blue;
    }

    &-form {
      position: relative;
      margin-top: 16px;
      margin-bottom: 12px;

      &-wrap {
        display: flex;
        flex-direction: column-reverse;
        padding: 16px;
        gap: 5px;
      }

      & .spinner {
        position: absolute;
        top: 11px;
        right: 25px;
      }
    }

    &-input {
      width: 100%;
      font-size: 14px;
      letter-spacing: 0.88px;
      line-height: 19px;
      padding: 14px 38px 14px 15px;
      background-color: $color-white;
      border-radius: 6px;
      color: $color-gray;
      border: 1px solid;
      overflow: none;
      transition: background-color linear 1s;

      &:focus-within {
        box-shadow: 0 0 0 1px $color-border-focus;
      }
    }

    &-list {
      position: relative;
      width: calc(100% - 6px);
      background-color: $color-white;
      margin: auto;
      box-shadow: 0 2px 0 0 $color-bondi-blue;
      max-height: 172px;
      overflow-y: scroll;
      margin-top: 1px;
      box-shadow: 0 12px 12px 0 rgba(0,0,0,0.12);
      border-radius: 0 0 6px 6px;
      border-bottom: 2px solid $color-bondi-blue;
      @include scrollBars;

      &-item {
        height: fit-content;
        padding: 9px 28px 9px 15px;
        font-family: Roboto;
        font-size: 12px;
        letter-spacing: 1px;
        line-height: 33px;
        color: $color-gray;
        cursor: pointer;
        &:nth-child(even) {
          background-color: $color-athens-gray;
        }

        &--manually {
          color: $color-azure-radiance;
        }

        &--no-item {
          cursor: default;
          text-align: center;
        }
      }
    }
  }

  &__btn {

    &--clear {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 16px;
      margin: auto;

      & svg {
        color: $color-persimmon;
        width: 12px;
        height: 12px;
      }
    }

    &--delete {
      color: $color-froly;
      display: flex;
      align-items: center;
      gap: 9px;

      & svg {
        color: $color-froly;
      }
    }

    &--done {
      color: $color-bondi-blue;
      display: flex;
      align-items: center;
      gap: 9px;

      & svg {
        color: $color-bondi-blue;
      }
    }

    &-address-wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 16px;
      gap: 32px;
      margin-top: 12px;
      padding: 17px 0 0 0;
      margin: 0 24px;
      border-top: 1px solid $color-gray;
      margin-top: 10px;

      &--is-first {
        padding: 17px 0 0 0;
        margin: 0 24px;
        border-top: none;
      }
    }

    &-add {
      @extend %adr-book-btn;

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }

  &__map-icon-wrap {
    width: 12px;
  }

  &__input-wrap {
    position: relative;
    display: flex;
    gap: 6px;
    align-items: center;
    border-radius: 8px;
    padding: 0 16px;
    background-color: $color-alabaster;

    &--error {
      & .contact-address-tab__input {
        border: 1px solid $color-red !important;
      }

       & .form-error-text__wrap {
         position: static;
       }
    }

    &--address {
      display: flex;
      flex-direction: column;
      border-radius: 0;
      background: none;
      align-items: flex-start;
      padding: 0;

      & .contact-address-tab__input-wrap { 
        padding: 0;
        width: 100%;
      }

      &:first-child {
        margin-top: 23px;
      }
    }
  }
}
