@import 'assets/scss/variables';

$calculated-opacity: $highlighted-opacity + $difference-opacity;

.contact-item-block {
  --background-color: rgb(var(--primary-color));
  --hover-background-color: rgba(
    var(--tertiary-color),
    var(--highlighted-opacity)
  );
  --border-color: rgba(
    var(--tertiary-color),
    calc(var(--highlighted-opacity) + 0.05)
  );
  --active-background-color: rgba(
    var(--secondary-color),
    var(--highlighted-opacity)
  );
  --active-border-color: rgba(
    var(--secondary-color),
    calc(var(--highlighted-opacity) + 0.05)
  );

  --broad-container-padding: 10px;

  height: 48px;
  border-radius: $radius-s;
  background-color: rgb(var(--primary-color));
  width: 100%;
  font-size: $font-size-s;
  cursor: pointer;
  z-index: $default-z-index;
  container-type: inline-size;
  container-name: contact-item-block;
  
  &__content {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: $radius-s;
    padding-left: 4px;
    transition: background-color $default-animation, 
                border-color $default-animation;

    @container style(--background-color: var(--outer-background-color)) {
      border: 1px solid var(--border-color);
    }

    @container contact-item-block (min-width: 300px) {
      padding: 0 var(--broad-container-padding);
    }
  }

  &:hover &__content {
    background-color: var(--hover-background-color);
    box-shadow: $default-control-elements-shadow;
  }

  &.active &__content {
    background-color: var(--active-background-color);
    border-color: var(--active-border-color);
    box-shadow: $default-control-elements-shadow;
  }

  &__avatar {
    aspect-ratio: 1;
    position: relative;
    margin: 4px;
    margin-left: 0;

    @container contact-item-block (min-width: 300px) {
      margin-right: var(--broad-container-padding);
    }
  }

  &__avatar-img {
    border-radius: 50%;
  }

  &__contact-status {
    position: absolute;
    top: -2px;
    left: -2px;
  }

  &__body {
    display: flex;
    padding: 4px;
    padding-left: 0;
    width: 100%;
    height: 100%;
    white-space: pre;
    overflow: hidden;
    margin-left: -8px;

    &--row {
      flex-direction: row;
      justify-content: space-between;
    }

    &--column {
      flex-direction: column;
      justify-content: space-between;
    }
  }

  &__extra-content {
    position: relative;
    color: rgba($tertiary-background-color, $dimmed-text-opacity);
    width: auto;

    // &:only-child {
    //   height: 100%;
    // }

    & > * {
      display: inline-block;
      vertical-align: middle;
    }
  }
  
  &__name {
    font-size: $font-size-m;
    line-height: $font-line-xl;
    overflow: hidden;
    text-overflow: ellipsis;
    color: rgba($tertiary-background-color, 1);
  }

  &__row > *:first-child {
    padding-left: 8px;
  }

  &__column:first-child > * {
    padding-left: 8px;
  }

  &__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 2px;
  }

  &__row &__extra-content:first-child {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  // &__row &__extra-content:last-child {
  //   display: flex;
  // }

  &__column {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    justify-content: space-between;

    &:has(*:only-child) {
      // justify-content: center;
    }
    

    &:first-child {
      flex-grow: 1;
    }

    &:last-child {
      text-align: right;
      flex-shrink: 0;
    }
  }

  &__column &__extra-content:only-child {
    height: 100%;
  }

  &__column:last-child &__extra-content:only-child button {
    margin-left: 4px;

    @container contact-item-block (min-width: 300px) {
      margin-right: calc(var(--broad-container-padding) * -1);
    }
  }

  &__column:first-child &__extra-content {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  &__unread-message-counter {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

