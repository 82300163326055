@import 'assets/scss/variables';

.calendar-table {
	--header-gap: 15px;
  --select-filters-gap: 30px;

  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;

  &__filters {
    gap: var(--header-gap);
  }

  &__header {
    margin-bottom: 18px;
    gap: var(--header-gap);
  }

  &-select-filter__item {
    width: 180px;
  }

  &__select-filters {
    display: flex;
    flex-direction: row;
    gap: var(--select-filters-gap);
  }

  &__agent-filters {
    display: flex;
    flex-direction: row;
    gap: var(--header-gap);
  }

  &__main-filters {
    position: relative;
    display: flex;
    align-items: center;
    gap: var(--header-gap);
  }

  &__secondary-filters {
    display: flex;
    align-items: center;
    gap: var(--select-filters-gap);
  }

  &__agents {
    display: flex;
    flex-direction: row;
    gap: var(--header-gap);
  }

  &__secondary-filter {
    display: flex;
    align-items: center;
    padding: 5px;
    border: 1px solid $color-mystic;
    border-radius: 6px;
    margin-left: 15px;
    letter-spacing: 0.5px;
    font-size: 12px;

    span {
      display: contents;
    }

    &--active {
      background-color: rgba(136, 214, 217, 0.44);
    }

    &--finished span {
      color: $color-aqua-deep;
    }
  }

  &__booking-tag {
    padding: 5px;
    border-color: $color-mystic;

    &--active {
      background-color: rgba(136, 214, 217, 0.44);
    }
  }
}
