@import 'assets/scss/variables.scss';

.undo-action-block {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 72px;
  margin-bottom: 12px;
  background-color: $color-mystic;
  color: $color-black;
  border-radius: 6px;
  font-size: 14px;
  letter-spacing: 0.88px;
  line-height: 20px;
  padding: 10px 13px;
  overflow: hidden;
  z-index: 10;
}

.undo-action-block__text {
  display: flex;
  flex-direction: column;
  justify-content: center;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}

.undo-action-block__cancel-btn {
  &-icon {
    color: $color-bondi-blue;
  }

  &:disabled {
    opacity: .5;
    cursor: default;
  }
}
