@import "assets/scss/variables.scss";

.contact-card {
  .react-tabs {
    display: flex;
    flex-direction: column;
    height: 90vh;

    &__tab-list {
      display: flex;
      justify-content: space-between;
      padding: 0 1px;
    }
    &__tab {
      padding: 5px 15px 6px 10px;
      border-radius: 4px 4px 0 0;
      font-size: 12px;
      cursor: pointer;
      background-color: $color-mystic;

      &--selected {
        color: $color-white;
        background-color: $color-bondi-blue;
      }
    }
    &__tab-panel {
      &--selected {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        width: 370px;
        height: 100%;
        background-color: $color-mystic;
      }
    }

    & .contact-info,
    & .edit-contact-form,
    & .contact-card__feedbacks-tab,
    & .contact-bookings__header {
      margin-top: 16px;
    }
  }

  &__feedbacks-tab {
    height: 100%;
    padding: 0 4px 0 12px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    text-align: left;

    .feedback-list__list {
      padding-right: 8px;
    }
  }
}
