@import 'assets/scss/variables';

.attachments {
  text-align: left;
  font-size: 14px;

  &--shared-msg {
    padding: 0 23px 0 9px;
  }

  &__title {
    // color: #0092F2;
    margin: 3px 0 8px;
    font-weight: 500;
    letter-spacing: 1px;
  }

  &__links,
  &__images,
  &__contact,
  &__msg,
  &__voice-message,
  &__session {
    background-color: inherit;
    // margin-right: 10px;
  }

  &__msg {
    background-color: $color-athens-gray;

    .attachment-msg__header svg {
      display: block;
    }
  }

  &__row {
    display: flex;
  }

  &__voice-message {
    height: 36px;

    & .player__thumb {
      position: absolute;
      top: 0;
      height: 100%;
      width: 1px;
      background-color: $color-bondi-blue;
      transform: none;
    }

    & .player__slider-path {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background-color: initial;
    }

    & .player__slider-left-path {
      background: $color-azure-radiance;
      opacity: 0.5;
      height: 100%;
      mix-blend-mode: color-dodge;
    }

    & .player__control {
      display: flex;
      position: relative;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      background-color: $color-bondi-blue;
      border-radius: 50%;
      color: $color-gray;
      width: 20px;
      height: 20px;
      flex-shrink: 0;

      &-icon {
        display: block;
        height: 6px;
        width: 6px;
        color: $color-white;

        &--play {
          position: relative;
          transform: translateX(1px);
        }
      }

      &--pause {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 5px;

        &::after {
          content: "";
          display: block;
          width: 70%;
          height: 60%;
          background: linear-gradient(to right,
              $color-gray 0% 40%,
              transparent 40% 60%,
              $color-gray 60% 100%);
        }
      }

      &--volume {
        display: none;
      }
    }
  }

  &__images {
    position: relative;
  }
}

.attachment-link {
  &__icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }

  &__url {
    margin: 5px 0;
    color: #0092f2;
  }

  &__desc {
    margin: 0 0 5px;
    line-height: 1.3;
    color: #858181;
    font-weight: 200;
  }

  &__img {
    max-width: 100px;
    height: 100px;
    max-height: 100px;
    overflow: hidden;
  }

  &__btn {
    position: absolute;
    right: 0;
    padding: 2px 4px;
    border-radius: 4px 0px 4px 4px;
    color: #fff;
    background-color: #00A6AD;
  }
}

.attachment-contact {
  &__body {
    display: flex;
    align-items: center;
    font-size: 14px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.17);
    border-radius: 6px;
    border: 1px solid grey;
    background-color: #fff;
    padding: 4px;
  }

  &__img-wrap {
    width: 32px;
    height: 32px;
    min-width: 32px;
    cursor: pointer;
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    line-height: 1.4;
    font-size: 13px;
    margin: 0 10px;
  }

  &__meta {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 120px;
    color: #858181;
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;
  }

  &__btn {
    width: 32px;
    height: 32px;
  }
}

.attachment-msg {
  padding-top: 5px;

  &__ava {
    width: 26px;
    min-width: 26px;
    height: 26px;
    cursor: pointer;
    margin: 0 5px;

    img {
      border-radius: 50%;
    }
  }

  &__header {
    display: flex;
    position: relative;

    svg {
      position: absolute;
      display: none;
      right: 0;
      top: 0;
    }
  }

  &__name {
    font-size: 12px;
    color: #bfbfbf;
    margin: 0;
    line-height: 0.9;
  }

  &__time {
    font-style: italic;
    font-size: 11px;
  }

  &__content {
    padding: 5px 0 5px 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;

    p {
      font-size: 12px;
      padding-left: 10px;
    }
  }
}

.attachment-voice-msg {
  display: flex;
}

.attachment-session {
  &__deleted-session {
    font-family: Roboto;
    color: #dc3545;
    font-size: 12px;
    font-weight: 500;
    margin-top: 10px;
  }

  &__spinner-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__body {
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.17);
    border-radius: 6px;
    border: 1px solid grey;
    background-color: #fff;
    padding: 5px 10px;
    margin-bottom: 10px;
    height: 52px;
    max-height: 52px;
    cursor: pointer;
  }

  &__meta {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.chat-attachment {
  position: relative;
  margin-bottom: 10px;
  padding: 5px 5px;
  background: $color-athens-gray;
  bottom: 0;
  text-align: left;
  box-shadow: 0 10px 20px rgba(27, 31, 35, 0.25);
  font-size: 13px;
  border-top: 1px solid $color-bondi-blue;

  &__btn {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 4px;
    right: 4px;
    z-index: 1;
    padding: 0;
    height: 18px;
    width: 18px;
    color: $color-sunset-orange;

    &-icon {
      height: 8px;
      width: 8px;
    }
  }

  & .attachment-msg__content .interaction__text,
  & .attachment-reply__content .interaction__text {
    font-size: 11px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.attachment-booking {
  width: 100%;

  &__header {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    &-date {
      font-size: 11px;
      line-height: 15px;
    }

    .booking-icon {
      font-size: 17px;
      margin-left: 10px;
    }

    &-fb-available {
      font-size: 11px;
      font-style: italic;
    }
  }

  &__body {
    display: flex;
    align-items: center;
    height: auto;
    padding: 6px 5px;
    background: #ffffff;
    border-radius: 6px;
    cursor: pointer;

    &-avatar {
      display: flex;
      align-items: center;
      margin-right: 5px;
      gap: 5px;

      .lazy-load__container {
        width: 32px;
        height: 32px;

        img {
          border-radius: 50%;
        }
      }

      &--is-duo .lazy-load__container {
        width: 28px;
        height: 28px;
      }
    }

    &-info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      min-width: 0;
      flex-grow: 1;
      font-size: 12px;
    }

    &-details {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 4px;
    }

    &-name {
      display: flex;
      justify-content: space-between;
      padding-right: 11px;
      width: 100%;
    }

    &-girl,
    &-operator {
      font-style: italic;
      font-weight: 500;
      margin-left: 4px;
      letter-spacing: 1px;
    }

    &-operator {
      margin-left: 10px;
    }

    &-girl {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      svg {
        margin-left: 3px;
        color: #f2cc0c;
        font-size: 10px;
      }
    }

    &-type {
      text-transform: capitalize;
    }

    &-duration {
      position: relative;
      & span {
        position: absolute;
        top: -3px;
        right: -5px;
        font-weight: bold;
      }
    }

    &-type,
    &-time,
    &-duration,
    &-price {
      margin: 0 4px;
    }

    &-pending {
      display: flex;
      width: 100%;
      min-height: 43px;
      justify-content: center;
      align-items: center;
    }
  }

  &__fb-action {
    display: flex;

    &-btn {
      width: 100%;
      padding: 5px;
      font-size: 12px;
      border-radius: 3px;
      color: white;
      background: #997cb5;
      line-height: 12px;
      cursor: pointer;
    }
  }

  &-removed {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 12px;
    font-style: italic;
    padding: 5px 0;

    span {
      padding: 10px 30px;
      background: #f3cdcd71;
      border-radius: 5px;
    }
  }
}

.attachment-reply {
  padding-top: 5px;
  cursor: pointer;

  &__ava {
    width: 24px;
    min-width: 24px;
    height: 24px;
    cursor: pointer;
    margin: 0 11px;

    display: flex;
    align-items: center;
    font-size: 20px;

    img {
      border-radius: 50%;
    }
  }

  &__header {
    display: flex;
    align-items: center;
  }

  &__reply-btn {
    padding: 0;
    margin-left: 8px;
    margin-right: 5px;
    color: #808080;
    transition: color .2s cubic-bezier(0.4, 0.0, 0.2, 1);

    &:hover {
      color: #0092f2;
    }

    svg {
      font-size: 16px;
    }
  }

  &__name {
    font-size: 12px;
    color: $color-dodger-blue;
    margin: 0;
    line-height: 0.9;
  }

  &__time {
    font-size: 10px;
  }

  &__content {
    position: relative;
    margin: 10px 0;
    padding: 0 0 0 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;

    &:before {
      content: '';
      position: absolute;
      left: 0px;
      top: 0;
      bottom: 0;
      width: 2px;
      background-color: $color-bondi-blue;
      z-index: 1;
    }

    p {
      font-size: 12px;
    }
  }

  &__attachments {
    margin-top: 5px;
    font-size: 14px;

    &-group {
      position: relative;
      display: flex;
      gap: 2px;
      flex-wrap: nowrap;
      overflow: hidden;
      &-item {
        width: 50px;
        height: 50px;
        flex-shrink: 0;
      }

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 2;
      }
    }

    svg {
      margin-right: 5px;
    }
  }
}

.attachment-email {
  padding: 4px 8px;
  border-radius: 6px;
  background-color: #fff;
  font-size: 12px;
  cursor: pointer;

  &__title {
    color: $color-tundora-light;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__body {
    color: $color-gray;
    opacity: .9;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.attachment-girls {
  display: flex;
  flex-flow: row wrap;
  margin-top: 16px;
  gap: 14px;
  max-height: 172px;

  & .lazy-load__container {
    margin-bottom: 4px;
    height: 60px;
  }

  & figure {
    max-width: 60px;
  }

  & img {
    height: 60px;
    max-width: 60px;
    aspect-ratio: 1;
    border-radius: 8px;
  }

  & figcaption {
    text-align: center;
    color: $color-azure-radiance;
    font-size: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__tooltip {
    width: 374px;
  }
}

.attachment-map {
  height: 150px;

  & div {
    cursor: pointer;
  }
}
