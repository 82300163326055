@import 'assets/scss/variables';

.bookings-by-date {
  display: flex;
  flex-direction: column;
  width: clamp(660px, 36.2vw, 700px);
  height: 76vh;
  border-radius: $radius-xl;
  font-size: 12px;
  background-color: $primary-background-color;
  box-shadow: 0 24px 24px 0 rgba(0, 0, 0, 0.06);
  font-family: $font-family-main;
  overflow: hidden;

  &__date-changer {
    width: 322px;
  }

  &__statuses {
    height: 100%;
  }

  &__main-filters {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__agent-filter {
    width: 170px;
  }

  &__secondary-filters {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
  }

  &__header-wrapper {
    background-color: rgba($secondary-background-color, $highlighted-opacity);
  }

  &__header {
    display: flex;
    flex-direction: column;
    margin: 16px;
    gap: 16px;
  }

  &__header-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    height: 32px;
  }

  &__row-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:nth-child(2) {
      margin: 10px 0;
    }
  }

  &__today-btn {
    display: flex;
    align-items: center;
    font-size: 12px;
    letter-spacing: 0;
    color: $color-jade;
    margin-right: 36px;
    max-height: 19px;
    cursor: pointer;

    &-icon {
      width: 19px;
      height: 19px;
      color: $color-bondi-blue;
    }

    &-text {
      margin-top: 2px;
      margin-left: 8px;
    }
  }

  &__date-container {
    display: flex;
    align-items: center;

    &-date {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 190px;
      height: 32px;
      margin: 0 12px;
      padding: 7px 15px;
      border-radius: 6px;
      color: $color-bali-hai;
      background-color: $color-white;
      border: 1px solid $color-bondi-blue;
      cursor: pointer;
    }

    &-icon {
      width: 11px;
      height: 11px;
      color: $color-bondi-blue;
      cursor: pointer;

      &--next {
        transform: rotate(180deg);
      }
    }
  }

  &__search-input {
    height: 36px;
    width: 257px;
  }

  &__filters-wrap {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
  }

  &__counter {
    margin-right: 4px;
    font-size: 12px;
    letter-spacing: 0.5px;
  }

  &__bookings-list-wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 12px 15px 27px 16px;

    .scrollbars-thumb {
      background-color: $color-mystic;
    }
  }

  &__list-container {
    height: 100%;
  }

  &__bookings-list-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 13px;
    margin-left: 50px;
    color: $color-bali-hai;
    font-size: 14px;
  }

  &__spinner-wrap,
  &__no-items {
    position: absolute;
    width: fit-content;
    top: 40%;
    left: 50%;
    transform: translateX(-50%);
  }

  &__no-items {
    font-size: 14px;
    color: $color-bali-hai;
  }

  &__item {
    display: flex;
    flex-direction: row;
    margin-left: 50px;

    &:first-of-type>time {
      visibility: visible;
    }

    @for $i from 0 through 22 {
      @for $j from 0 through 22 {
        &[data-hourmarker='#{$i}']+&[data-hourmarker='#{$i + 1 + $j}']>time {
          visibility: visible;
        }
      }
    }
  }

  &__hour-marker {
    position: absolute;
    z-index: 2;
    visibility: hidden;
    font-size: 14px;
    color: $color-bali-hai;
    white-space: pre;
    transform: translateX(calc(-100% - 10px));

    margin-right: 12px;
  }

}
