.bookings-list-by-form {
  &-list {
    height: 60px;
    gap: 14px;

    &__item {
      // animation: appearing 0.3s ease-in-out forwards;
    }

    &--revealed {
      height: inherit;
    }

    &__more-button {
      bottom: 19px
    }
  }

  & .typing-indicator {
    zoom: 5;
  }

  & strong {
    margin: auto;
    color: #9e9ea1;
    font-size: 36px;
  }
}

@keyframes appearing {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
