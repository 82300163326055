@import 'assets/scss/variables';

.sales-header {
  grid-area: header;
  display: grid;
  grid-template-columns: 885px 1fr;
  align-items: center;

  background-color: $color-mystic;

  &__sales-page-btn {
    color:$color-bondi-blue;
    width: 30px;
    height: 30px;
  }

  &__navbar-btn-wrap {
    width: 54px;
    height: 84px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-top: 16px;
    background-color: $color-mystic;
    padding-right: 5px;
  }

  &__default-session-btn,
  &__add-client-btn {
    height: 36px;
    width: 150px;
  }

  &__secondary-button {
    height: 30px;
    width: 150px;

    svg {
      width: 16px;
      height: 16px;
    }

    &--disabled {
      cursor: not-allowed;
    }
  }
}

.sales-header__client-card {
  flex-grow: 1;
}

.sales-header__add-client-btn {
  & svg {
    width: 18px;
    height: 18px;
  }
}

.sales-header__left-wrap {
  display: flex;
  align-items: center;
  gap: 33px;
  padding: 0 25px 0 0;
  position: relative;
  z-index: 1;
}

.sales-header__right-wrap {
  padding: 5px 15px 5px 25px;
  display: grid;
  grid-template-rows: [row-begin] auto auto [row-end];
  grid-template-columns: 150px [col-center] 1fr [col-end];
  gap: 12px 30px;
}

.sales-header__buffer-button-wrap {
  position: relative;
}

.sales-header__clear-buffer-button {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 17px;
  height: 17px;
  display: grid;
  justify-content: center;
  align-items: center;

  background-color: $color-persimmon;
  border-radius: 50%;

  &::after {
    $touch-radius: calc(100% + 6px);

    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: $touch-radius;
    height: $touch-radius;
  }

  svg {
    width: 7px;
    height: 7px;
    color: $color-white;
  }
}

.sales-header__search-wrap {
  position: relative;
  grid-row: row-begin / row-end;
  grid-column: col-center / col-end;
  align-self: center;
  display: block;
}
