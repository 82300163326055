@import 'assets/scss/variables';

.caller {
  &__name {
    display: inline-block;
    cursor: pointer;
    max-width: 100%;
    overflow: hidden;
    white-space: pre;
    text-overflow: ellipsis;
    vertical-align: middle;
    
    &--client {
      color: $color-dodger-blue;
    }

    &:not(&--client) {
      color: $color-azure-radiance;
    }
  }

  &__avatar {
		height: 48px;
    width: 48px;
    border-radius: 6px;
    cursor: pointer;
    white-space: pre;
  }
}
