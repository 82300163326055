@import 'assets/scss/variables.scss';

.feedback-item {
  max-height: 250px;
  padding: 14px 14px 9px;
  display: flex;
  flex-direction: column;

  background-color: $color-white;
  border-radius: 6px;

  &:not(:last-child) {
    margin-bottom: 2px;
  }
}

.feedback-item__header {
  position: relative;

  margin-bottom: 18px;
  display: flex;
  align-items: center;
  column-gap: 10px;

  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    right: 0;
    height: 1px;

    background-color: $color-mystic;
  }
}

.feedback-item__escort-name {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  text-overflow: ellipsis;
  word-break: break-all;
  color: $color-azure-radiance;
  font-size: 12px;
}

.feedback-item__date {
  color: $color-gray;
  font-size: 12px;
}

.feedback-item__added-by {
  max-width: 130px;
  flex-shrink: 0;

  overflow: hidden;

  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  color: $color-azure-radiance;
}

.feedback-item__actions {
  margin-left: auto;
  flex-shrink: 0;
  display: flex;
  align-items: center;
}

.feedback-item__type-label {
  margin-right: 7px;
  height: 16px;
  width: 16px;

  border-radius: 50%;

  &--neutral {
    background-color: $color-silver;
  }

  &--negative {
    background-color: $color-froly;
  }

  &--positive {
    background-color: #00B936;
  }
}

.feedback-item__action-button {
  width: 18px;
  height: 18px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &:not(:last-child) {
    margin-right: 5px;
  }

  svg {
    width: 15px;
    height: 15px;
  }
  
  &--edit {
    color: $color-azure-radiance;

    svg {
      width: 12px;
      height: 12px;
    }
  }

  &--delete {
    color: $color-froly;
  }
}

.feedback-item__text {
  padding-right: 5px;
  overflow: auto;

  white-space: pre-wrap;
  word-break: break-word;
  color: #828282;
  font-size: 14px;
  line-height: 1.35;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-silver;
    border-radius: 3px;
  }
}
