@import "assets/scss/variables.scss";

.player{
  width: 214px;
  height: 36px;
  background-color: $color-wild-sand;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 12px;

  &--not-active svg {
    transform: translateX(1px);
  }

  &__audio-source{
    position: absolute;
    width: 0;
    height: 0;
  }
  &__spinner{
    height: 30px;
    width: 30px;
    margin: auto;
  }

  &__control{
    cursor: pointer;
    position: relative;
    color: $color-gray;

    &-icon {
      display: block;
    }

    &--modern {
      align-items: center;
      background-color: #00a6ad;
      border-radius: 50%;
      color: gray;
      cursor: pointer;
      display: flex;
      flex-shrink: 0;
      height: 20px;
      justify-content: center;
      position: relative;
      width: 20px;

      & svg {
        color: #fff;
        display: block;
        height: 6px;
        width: 6px;
      }
    }
    
    &--play{
      position: relative;

      & svg {
        transform: translateX(1px);
      }
    }
    &--pause{
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 5px;

      &::after{
        content: "";
        display: block;
        width: 70%;
        height: 60%;
        background: linear-gradient(to right,
        $color-gray 0% 40%,
        transparent 40% 60%,
        $color-gray 60% 100%);
      }
    }
    &--volume{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 25%;
      max-width: none;
      margin-right: 5px;
      user-select: none;

      .player__slider{
        margin-left: 5px;
      }
    }
  }
  
  &__control-panel {
    width: calc(100% - 30px);
  }
  &__time{
    font-size: 8px;
    font-family: Roboto;
    color: #AECECD;
    max-width: 25px;
    user-select: none;
  }

  &__slider {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    flex-grow: 1;
    user-select: none;

    &-zoom {
      &-btns {
        display: flex;
        justify-content: space-between;
        padding: 0 15px;
      }

      &-btn svg {
        width: 22px;
        height: 22px;
        transform: rotate(90deg);
        color: $color-silver;
      }
    }

    &-path {
      display: flex;
      flex-grow: 1;
      height: 5px;
      background-color: $color-silver;
      border-radius: 6px;
      cursor: pointer;

      &--modern {
        background-color: initial;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }

    &-left-path {
      background-color: $color-persian-green;
      border-radius: 6px;
      cursor: pointer;

      &--modern {
        background: $color-azure-radiance;
        opacity: 0.5;
        height: 100%;
        mix-blend-mode: color-dodge;
      }
    }
  }

  &__thumb {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 15px; height: 15px;
    background-color: $color-bondi-blue;
    border-radius: 100%;
    cursor: pointer;

    &--modern {
      background-color: #00a6ad;
      height: 100%;
      position: absolute;
      top: 0;
      -webkit-transform: none;
      transform: none;
      width: 1px;
    }
  }
}

.voicemail {
  display: flex;

  &__box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $color-white;
    border-radius: 6px;
    padding: 3px 6px;
  }
  
  &__text {
    display: flex;
    text-transform: capitalize;
    font-size: 11px;
    color: $color-black;
    margin-right: 11px;
  }
  
  &__time {
    padding: 0 2px;
  }
  
  &__icon {
    fill: $color-neon-carrot;
    width: 16px;
    height: 14px;
  }
}
