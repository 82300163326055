@import 'assets/scss/variables.scss';

.feedback-list {
  flex-grow: 1;
}

.feedback-list__list-container {
  height: 100%;
}

.feedback-list__load-wrap {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.feedback-list .track-vertical {
  bottom: 12px;
  width: 4px;
}

.feedback-list .thumb-vertical {
  border: none;
}

.feedback-list__list {
  padding: 0 11px 10px 0px;
}

.feedback-list__no-items {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
  color: $color-silver;
  font-size: 14px;
}
