@import 'assets/scss/variables';

.notification {
  background-color: $color-white;
  position: relative;
  border-radius: 6px;
  box-shadow: 0 12px 12px 0 rgba(0,0,0,0.1);

  color: $color-black;
  font-family: 'Roboto';
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0.8px;
  overflow: hidden;
  
  &--deprecated {
    transition: all 0.3s ease-in-out;

    &::before {
      content: attr(title);
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      font-size: 28px;
      font-weight: 800;
      color: rgba($color: $color-gray, $alpha: 0.5);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba($color: $color-mystic, $alpha: 0.65);
      z-index: 1;
    }

    & .notification__read-btn {
      position: relative;
      z-index: 3;
    }

    & footer {
      background-color: lightgray;
    }
  }

  &--highlighted {
    animation: blink 5s ease-in-out;
  }

  &__body {
    display: flex;
    padding: 12px;
    // text-wrap: balance;
    min-height: 50px;
    align-items: center;
    width: 100%;

    & .lazy-load__container {
      width: 48px;
      height: 48px;
    }
  }

  &__paragraph {
    width: 100%;
  }

  &__avatar-wrapper {
    width: 48px;
    height: 48px;
    margin-right: 10px;

    &:empty {
      display: none;

      & + .notification__paragraph {
        width: 100%;
      }
    }

    & + .notification__paragraph {
      width: 82%;
    }
  }

  &__footer {
    display: flex;
    padding: 0px 12px;
    background-color: $color-froly;
    color: $color-white;
    height: 42px;
    align-items: center;
    justify-content: space-between;

    &--pending {
      background-color: $color-manatee;
    }
  }

  &__footer-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 18px;
    width: 100px;
  }

  &__attachments-wrapper {
    position: relative;
  }
  
  &__attachments {
    cursor: default;
  }

  &__date {
    display: flex;
    flex-direction: column;
    line-height: 16px;
  }
  
  &__spinner-wrapper {
    position: absolute;
    color: $color-white;
    background-color: rgba($color: $color-mystic, $alpha: 0.65);
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
  }

  &__spinner {
    margin: auto;
  }

  &__icon {
		height: 24px;
    width: 24px;
    min-width: 24px;
    color: $color-white;
  }

  &__btns-wrapper {
    display: flex;
    justify-content: flex-end;
    gap: 18px;

    & button {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 50px;
      height: 20px;
      border-radius: 6px;
      color: #4C4C4C;
      font-size: 12px;
      font-family: Roboto;
      text-align: center;
      padding: 0 9px;
      letter-spacing: 1px;
      background-color: $color-white;
      cursor: pointer;
    }
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  10% {
    opacity: 0.5;
  }
  20% {
    opacity: 1;
  }

  30% {
    opacity: 0.5;
  }

  40% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  60% {
    opacity: 1;
  }

  70% {
    opacity: 0.5;
  }

  80% {
    opacity: 1;
  }

  90% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}
