@import 'assets/scss/variables';

.webmaster-list {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px 12px;
    background-color: $color-mystic;

    &-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__title {
    font-size: 12px;
    color: $color-gray;
    font-weight: 500;
  }

  & .timeline {
    padding-top: 12px;
  }

  & .pinned-items {
    box-shadow: none;
  }

  &__show-btn {
    margin-left: 22px;
    font-size: 12px;
    background-color: $color-white;
    padding: 6px;
    border-radius: 8px;
    color: $color-bondi-blue;
    width: 118px;
    height: 26px;
    cursor: pointer;

    &--active {
      background-color: $color-bondi-blue;
      color: $color-white;
    }
  }

  &__add-btn {
    height: 22px;
    width: 22px;
    margin-right: 8px;
    color: $color-bondi-blue;
    cursor: pointer;

    &>svg {
      width: 100%;
      height: 100%;
    }
  }

  & li {
    margin: 0 5px;
  }
}
