@import 'assets/scss/variables';

.booking-short-lined-item {
  position: relative;
  display: flex;
  align-items: center;
  height: 41px;
  width: 100%;
  background-color: $color-white;
  border-top: 1px solid $color-mystic;
  font-size: 12px;
  overflow: hidden;
  cursor: pointer;

  &--deleted {
    opacity: 0.5;

    .booking-status1 {
      color: $color-red;
    }
  }

  &__color-line {
    height: 100%;
    width: 3px;
    border-radius: 6px;
    position: absolute;
    left: 0;
  }

  &__column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    padding-right: 2px;

    &--escort {
      width: 11%;
      margin-left: 8px;

      & :has(> p) {
        gap: 3px;
      }
    }

    &--duo {
      width: 7%;
      align-items: center;
    }

    &--client {
      display: flex;
      justify-self: flex-start;
      width: 16%;
    }
    &--details {
      width: 28%;
      gap: 3px
    }

    &--status {
      width: 15%;
      gap: 3px
    }

    &--rest {
      width: 23%;
      align-items: end;
    }
  }

  &__client {
    height: 100%;
    width: 100%;

    & p {
      max-height: 70%;
    }

    & img {
      height: 32px;
      width: 32px;
    }
  }

  &__escort {
    width: 100%;
  }

  &__date {
    color: $color-bali-hai;
  }

  &__column-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }
}
