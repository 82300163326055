@import 'assets/scss/variables.scss';
@import 'assets/scss/functions.scss';

.feedback-contact-field {
  position: relative;
  width: 260px;

  &__label {
    margin-bottom: 5px;
    font-size: 12px;
    letter-spacing: 1px;
  }

  &__button {
    border: 1px solid #bfbfbf;
    border-radius: 6px;
    cursor: pointer;
  }

  &__no-contact {
    padding: 11px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__placeholder {
    color: #BFBFBF;
    font-size: 14px;
    letter-spacing: 0.88px;
  }

  &__adr-book-icon {
    width: 12px;
    height: 12px;
    color: #808080;
  }

  &__drop-menu-icon {
    width: 10px;
    height: 10px;
    color: #808080;
  }

  &__contact {
    padding: 3px;
    display: flex;
    gap: 5px;
  }

  &__avatar-thumb {
    width: 32px;
    height: 32px;
    flex-shrink: 0;

    border-radius: 50%;
    overflow: hidden;
    background-color: #fff;
  }

  &__text-info {
    padding: 1px 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__name {
    @include webkit-text-overflow;

    color: #808080;
    font-size: 12px;
    font-weight: 500;
  }

  &__phone {
    @include webkit-text-overflow;
    
    color: #808080;
    font-size: 12px;
  }

  &__tooltip {
    width: 95%;
    border-radius: 0 0 6px 6px;
    border-bottom: 2px solid #00A6AD;
    z-index: 10;

    .popup-menu__item {
      text-transform: capitalize;

      &:first-child {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }
}
