@import 'assets/scss/variables';

.booking-log {
  &__list-container {
    padding-top: 30px;
    height: 100%;
    margin-left: -18px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-left: 16px;
  }

  &__item {
    display: flex;
    flex-direction: column;
  }

  &__operator {
    color: $color-azure-radiance;
    font-style: italic;
    font-weight: 500;
  }

  &__spinner {
    display: block;
    margin: 0 auto;
    margin-top: 30px;
  }

  &__date {
    margin-left: 18px;
    color: $color-bali-hai;
    letter-spacing: 0;
  }

  &__label-icon {
    height: 10px;
    vertical-align: middle;
    margin-left: -19px;
    margin-right: 3px;

    &--plus {
      color: $color-caribbean-green;
    }

    &--minus {
      color: $color-persimmon;
      clip-path: inset(4px 0);
    }
  }

  &__log-description {
    // display: flex;
    // flex-direction: column;
    // gap: 2px;
    margin-top: 6px;
    color: $color-black;
    letter-spacing: 0;
    word-wrap: break-word;
  }

  &__log-item {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: baseline;
  }

  &__log-label {
    font-weight: 400;
    white-space: pre;
    line-height: 20px;
  }

  &__log-services {
    display: flex;
    flex-flow: row wrap;
    gap: 2px;
  }

  &__log-deleted {
    display: flex;
    align-items: center;
    height: 14px;
    background-color: transparent;
    font-variant: small-caps;
    font-size: 16px;
    font-weight: bold;
    color: $color-persimmon;
  }

  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    width: 4px;
    border-radius: 2px;
    background-color: $color-mystic;
  }

  &__girl-name {
    font-weight: 600;
    margin-right: 8px;
    line-height: 20px;
  }
}
