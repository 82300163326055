@import 'assets/scss/variables';

.buffer-all-btn {
  &__warning {
    width: 308px;
    padding: 32px 15px 8px;
  
    background-color: #f6f9fc;
    border-radius: 6px;
  }
  
  &__warning-text {
    margin-bottom: 10px;
    margin-top: 0;
  
    font-family: Roboto;
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 1.35;
    text-align: center;
  }

  &__warning-icon {
    margin-left: auto;
    margin-right: auto;
    display: block;
    
    color: #808080;
    font-size: 18px;
  }
}
