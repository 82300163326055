@import 'assets/scss/variables';

.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  margin-top: 10vh;
  padding: 50px 45px 36px;
  width: 420px;
  box-shadow: 0 12px 12px 0 rgba(0,0,0,0.12);
  color: #808080;
  font-family: Roboto;

  &__title {
    font-size: 24px;
    color: $color-text-primary-light;
  }

  &__subtitle {
    margin-top: 15px;
    font-weight: 400;
    font-size: 18px;
    color: $color-text-primary-light;
  }

  &__form {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
  }

  &__form-field-wrap {
    position: relative;
  }

  &__form-field {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 42px;
    
    &-btn {
      position: absolute;
      top: 4px;
      right: 0;
      padding: 0;
      padding-right: 8px;
      color: $color-azure-radiance;
      font-size: 12px;

      &:disabled {
        cursor: default;
      }
    }

    &--checkbox {
      flex-direction: row-reverse;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 22px;
      padding: 6px;
      background-color: $color-black-squeeze-light;
      border-radius: 6px;
    }

    &-code {
      margin-bottom: 20px;
    }
  }

  &__label {
    color: $color-text-primary-darkest;
    letter-spacing: 1px;
    font-size: 12px;
  }

  &__checkbox-label {
    margin-left: 7px;
    letter-spacing: 1px;
    color: $color-text-primary-light;
    font-size: 12px;
    font-weight: bold;
  }

  &__input-wrap {
    margin-top: 5px;

    .form-text-content__error {
      font-size: 12px;
      bottom: -25px;
    }
  }

  &__input {
    &:focus-visible {
      box-shadow: $shadow-focus;
    }

    &--code {
      text-align: center;
      letter-spacing: 3px;
    }
  }

  &__recaptcha {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
  }

  &__submit-btn {
    margin: 0 auto;
    margin-top: 23px;
    min-width: 160px;
    height: 50px;
    letter-spacing: 1px;
    font-size: 16px;
    font-weight: bold;
  }

  &__spinner {
    margin-left: 10px;
    width: 30px;
    display: flex;
  }

  & .invalid-feedback {
    bottom: unset;
    left: unset;
  }

  &__container {
    width: 100%;
    display: flex;
  }

  &__general-error {
    width: 100%;
    text-align: center;
    color: $color-red;
  }
}
