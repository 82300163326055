@import "assets/scss/variables.scss";

.block-form {
  display: flex;
  flex-direction: column;
  background-color: $color-white;
  padding: 10px;
  width: 600px;
  border-bottom: 2px solid $color-bondi-blue-dark;
  border-radius: 6px;
  overflow: hidden;

  &__btn {
    color: $color-white;
    background-color: $color-bondi-blue-dark;
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    border: 1px solid transparent;
    border-radius: 4px;
    cursor: pointer;
    outline: none;
    width: 100%;
    margin-top: 15px;

    &[disabled] {
      background-color: $color-mystic;
    }
  }

  &__title {
    span {
      color: $color-bondi-blue-dark;
    }
  }
}

.block-form-input {
  display: block;
  width: 100%;
  height: 80px;
  margin: 20px 0;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: $color-gray;
  background-color: $color-white;
  background-image: none;
  border: 1px solid $color-gray;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);

  .form-textarea__input {
    height: 100%;
  }

  &--error {
    border-color: $color-border-error;
  }

  &__wrap {
    width: 100%;
    position: relative;
    margin-bottom: 20px;
  }

  &__error-wrap {
    position: absolute;
    bottom: calc(0px - 3px);
    z-index: 1;
    width: 100%;
  }

  &__error-text {
    color: $color-border-error;
    font-size: 12px;
    position: absolute;
    left: 0;
  }
}