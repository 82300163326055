@import 'assets/scss/variables';
@import 'assets/scss/functions.scss';

.notes-form {
  @include ModalWindow(650px, 470px, column);
  box-shadow: $box-shadow-popups;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;

  &__notes-container {
    flex-grow: 1;
    background-color: $color-floral-white;
  }
  
  &__textarea-container {
    flex-basis: 230px;
    background-color: $color-white;
  }

  &__list-container {
    height: 100%;
    display: flex;
  }

  &__note-info {
    background: $color-white;
    padding: 9px 20px;
    line-height: 20px;
    height: 60px;
  }

  &__date {
    font-size: 12px;
    letter-spacing: 1px;
    font-weight: 500;
  }

  &__author {
    font-size: 12px;
    color: $color-azure-radiance;
  }

  &__note {
    padding: 10px 20px;
    font-size: 12px;
    line-height: 16px;
    min-height: 60px;
    word-break: break-word;
    background-color: $color-floral-white;
  }

  &__textarea-container {
    display: flex;
    flex-direction: column;
    padding: 10px 18px;
    gap: 6px;

    & label {
      font-size: 12px;
      letter-spacing: 1px;
    }
  }

  &__button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 30px;
    height: 44px;
    align-items: center;
    font-weight: 500;
    letter-spacing: 1px;
  }

  &__send-btn {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 22px;
    border-radius: 6px;
    font-size: 14px;
    letter-spacing: 0.875px;
    white-space: pre;
    text-align: left;
    height: 36px;
    background-color: $color-bondi-blue;
    color: $color-athens-gray;
  }

  &__cancel-btn {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 22px;
    border-radius: 6px;
    font-size: 14px;
    letter-spacing: 0.875px;
    white-space: pre;
    text-align: left;
    height: 30px;
    background-color: $color-silver;
    color: $color-white
  }

  &__textarea {
    padding: 10px;
  }

  &__textarea-input {
    height: 100%;
  }

  &__spinner {
    display: flex;
    opacity: 0.6;
    z-index: 1;
    position: absolute;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: $color-white;
  }
}

