@import 'assets/scss/variables';

.contact-tags {
  display: flex;
  flex-flow: row wrap;
  gap: 5px;

  &--no-tags {
    font-size: 11px;
    color: $color-gray;
  }
}
