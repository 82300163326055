.default-calendar {
  width: 350px;

  .react-calendar {
    &__navigation {
      margin-bottom: 16px;
      padding: 0 10px;
      display: flex;

      &__arrow {
        width: 24px;
        height: 24px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        &:disabled {
          cursor: unset;
          color: rgba(0, 0, 0, 0.2);
        }

        svg {
          width: 13px;
          height: 13px;

          &:nth-child(2) {
            margin-left: -7px;
          }
        }
      }

      &__label {
        cursor: unset;
        font-size: 14px;
        color: #000;
      }

      &__next-button,
      &__next2-button {
        svg {
          transform: rotate(180deg);
        }
      }
    }

    &__viewContainer {
      min-height: 340px;
    }

    &__month-view {
      &__weekdays {
        margin-bottom: 16px;

        &__weekday {
          padding: 4px 0;

          color: #000;
          font-size: 14px;
          text-align: center;
          
          abbr {
            text-decoration: none;
          }
        }
      }

      &__days__day {
        $tile: react-calendar__tile;

        margin: 5px;
        height: 40px;
        flex-basis: 40px !important;
  
        border-radius: 50%;
  
        &:hover {
          background-color: #88D6D9;
        }
  
        &:disabled {
          cursor: default;
          background-color: transparent;
          color: rgba(0, 0, 0, .2);
        }

        // Now date (instead of hover) variant
        // &.#{$tile}--now {
        //   border-radius: 50%;
        //   background-color: #88D6D9;
        // }
  
        &.#{$tile}--active {
          background-color: #00A6AD;
          border-radius: 6px;
  
          color: #FFFFFF;
          font-weight: bold;
        }
        
        &--neighboringMonth {
          color: rgba(0, 0, 0, .2);
        }
      }
    }

    &__tile {
      font-size: 14px;
    }
  }
}
