@import 'assets/scss/variables.scss';

.booking-special-request-messages {
  position: relative;
  width: 100%;

  &__tooltip {
    display: flex;
    z-index: 2;
    flex-direction: column;
    width: 100%;
    max-width: 571px;
    height: 588px;
    border-radius: 6px;
    background-color: $color-mystic;
    box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.08);
  }

  &__message {
    display: flex;
    justify-content: space-between;
    min-height: 198px;
    margin-top: 29px;
    margin-bottom: 9px;
    border-bottom: 1px solid $color-silver;

    &-field {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
    }
  }

  &__main {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    height: 534px;
    padding: 0 14px 10px;
    border-bottom: 2px solid $color-bondi-blue;
    border-radius: 6px;
    overflow-y: scroll;
    background-color: $color-white;

    &-header {
      display: flex;
      justify-content: space-between;
      padding: 0 17px;
      margin-bottom: 3px;
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-gray;
      border-radius: 3px;
    }
  }

  &__date-field {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 190px;
    margin-right: 10px;
    padding-bottom: 25px;
  }

  &__request {
    position: relative;
    border-radius: 6px;
    background-color: $color-athens-gray;
    color: $color-tundora-light;
    font-size: 14px;
    font-style: italic;
    line-height: 19px;
    text-align: left;
    padding: 22px 12px;

    &-wrap {
      flex-grow: 1;
      padding: 23px 17px;
    }
  }

  &__title {
    color: $color-gray;
    font-size: 14px;
    letter-spacing: 0;
  }

  &__subtitle {
    color: $color-black;
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 16px;
  }

  &__btns-wrapper {
    display: flex;
    align-items: center;
  }

  &__btn {
    padding: 0;
    border: none;
    font-size: 12px;

    &-icon {
      width: 20px;
      &--check {
        margin-left: 9px;
      }

      &--clear {
        width: 8px;
        color: $color-white;
      }

      &--add {
        color: $color-bondi-blue;
        margin-right: 6px;
      }

      &--request {
        position: absolute;
        top: 0;
        left: 0;
        width: 16px;
        color: $color-gray;
      }

      &--delete {
        width: 5px;
        height: 5px;
      }
    }

    &--cancel {
      color: $color-froly;
    }

    &--save {
      display: flex;
      align-items: flex-end;
      margin-left: 25px;
      padding: 0;
      color: $color-bondi-blue;
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    &--clear {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 17px;
      height: 17px;
      margin-right: 3px;
      border-radius: 50%;
      font-size: 14px;
      background-color: $color-froly;
      &:disabled {
        opacity: .5;
        cursor: not-allowed;
      }
    }

    &--add-from {
      display: flex;
      align-items: center;
      align-self: flex-end;
      margin-right: 10px;
      color: $color-gray;
      font-size: 14px;
      text-transform: capitalize;
      &:disabled {
        opacity: .5;
        cursor: not-allowed;
      }
    }

    &--delete {
      display: flex;
      position: absolute;
      top: 1px;
      right: 2px;
      justify-content: center;
      align-items: center;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      color: $color-white;
      background-color: $color-froly;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding: 19px 31px;
    align-items: center;
  }

  &__label {
    margin-bottom: 5px;
    width: max-content;
    display: block;
    font-size: 12px;
    letter-spacing: 1px;
  }

  &__textarea-wrap {
    flex-grow: 1;
    padding: 0 17px 23px;

    textarea {
      width: 100%;
      height: 100%;
      padding: 13px 8px 8px;
      border: 1px solid $color-silver;
      border-radius: 6px;
      outline: none;
      color: $color-gray;
      font-size: 14px;
      line-height: 19px;
      font-family: Roboto;
      letter-spacing: 0.88px;
      resize: none;

      &::placeholder {
        color: $color-silver;
      }

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $color-gray;
        border-radius: 3px;
      }
    }
  }
}
