@import 'assets/scss/variables.scss';
@import 'assets/scss/functions.scss';

.girls-list {
  display: flex;
  flex-direction: column;
  text-align: left;
  height: 100%;
  margin: 8px 12px;
  
  &__title {
    font-size: 10px;
  }

  &__contacts-container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    margin-top: 24px;
    gap: 6px;
  }


  &__spinner-wrapper {
    display: flex;
    justify-content: center;
    padding: 10px 0;
  }

  &__infinite-scroll {
    display: flex;
    flex-direction: column;
  }
}
