@import 'assets/scss/variables';

.booking-girl-pinned-item {
  display: flex;
  flex-direction: row;
  height: 46px;
  border: 1px solid;
  border-radius: 5px;
  padding: 5px 6px;
  box-sizing: border-box;
  font-size: 12px;
  justify-content: space-between;
  line-height: 16px;

  &--deleted {
    opacity: 0.5;

    .booking-status1 {
      color: $color-red;
    }
  }

  &--prebooking {
    border-color: $color-persian-pink;
  }

  &--booking {
    border-color: $color-froly;
  }

  &__escort {
		width: 90%;

    & img {
      height: 36px;
      width: 36px;
      border-radius: 50%;

      & + div {
        width: 84%;
      }
    }

    & .lazy-load__container {
      width: 36px;
    }

    & > div > div {
      gap: 3px;
    }

    & p {
      white-space: pre;
      width: 100%;
    }
  }

  &__deleted-status {
    position: absolute;
    right: 36px;
    color: $color-red;
  }

  &__details {
    color: $color-black;
  }

  &__markers {
    display: flex;
    height: 100%;
    aspect-ratio: 1;

    & div {
      margin: auto
    }
  }
}
