.typing-indicator {
  display: flex;
  // width: 24px;
  padding-bottom: 1px;
  // border: 1px solid darken(#e6e7ed, 30%);
  // animation: 2s bulge infinite ease-out;

  // .timeline__footer & {
  //     margin-right: 1.5em;
  // }

  // .dispatcher-item & {
  //     position: absolute;
  //     right: 5px;
  //     top: 50%;
  //     transform: translateY(-50%);
  // }

  .contact-item & {
    position: absolute;
    bottom: 0;
    left: 4px;
  }

  span {
    height: 3px;
    width: 3px;
    margin: 0 2px;
    background-color: #9e9ea1;
    border-radius: 50%;
    opacity: 0.4;

    @for $i from 1 through 3 {
        &:nth-of-type(#{$i}) {
            animation: 1s blinks infinite ($i * 0.3333s);
        }
    }
  }
}

@keyframes blinks {
  50% {
      opacity: 1;
  }
}

// @keyframes bulge {
//   50% {
//       transform: scale(1.1);
//   }
// }
