@import 'assets/scss/functions.scss';

.header {
  min-height: 80px;
  width: 100%;
  margin-bottom: 16px;

  --outer-background-color: rgba(
    var(--secondary-color),
    var(--highlighted-opacity)
  );

  --border-color: rgba(
    var(--secondary-color),
    calc(var(--highlighted-opacity) + 0.25)
  );
  
  & > .container {
    display: flex;
    justify-content: space-between;
    background-color: var(--outer-background-color);
    align-items: center;
    height: 100%;
    gap: 30px;
    border-bottom: 1px solid var(--border-color);
  }

  &__user-menu {
    margin-right: 30px;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 400px;
    height: 48px;

    &:nth-child(2) {
      justify-content: center;
    }

    &:nth-child(3) {
      justify-content: flex-end;
    }

    &--active-call {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-width: 455px;
      margin: 0;
      padding-left: 0;
    }
  }

  &__button-container {
    position: relative;

    @include underliedBackground($radius-m);
  }
}
