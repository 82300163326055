@import 'assets/scss/variables';

.label {
  display: flex;
  flex-direction: row;
  height: 18px;
  align-items: center;
  background-color: $primary-background-color;
  color: rgba($tertiary-background-color, $dimmed-text-opacity);
  border-radius: $radius-xs;
  padding-right: 12px;
  flex-shrink: 1;
  font-size: $font-size-s;
  transition: color $default-animation;

  &__icon-wrapper {
    display: flex;
    height: 100%;
    min-width: 32px;

    &>svg {
      max-width: 10px;
      height: -webkit-fill-available;
      aspect-ratio: 1;
      margin: auto;
    }
  }

  &__text {
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &--clickable {
    &:hover {
      cursor: pointer;
      color: $darker-tertiary-background-color;
    }
  }
}
