@import "assets/scss/variables.scss";
@import 'assets/scss/functions.scss';

.chats {
  display: flex;
  margin: 10px 0;
  padding: 0 5px;
  justify-content: space-between;
}

.client-chat,
.girl-chat {
  & .interaction--inc {
    margin-right: 20px;
  }

  & .interaction--out {
    margin-left: 20px;
  }
}

.chat {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 32%;

  @include TabContainer;

  &:first-child .chat-header {
    z-index: 8;
  }


  &__title {
    margin: 0 4px 18px;
  }

  &__content {
    display: flex;
    position: relative;
    flex-direction: column;
    height: 100%;
  }

  &__pending {
    width: 32%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__dropzone {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
    outline: none;
    background-color: $color-mystic;

    // &--with-bg {
    //   background-image: url('/assets/images/bg-chat.png');
    //   background-size: cover;
    // }
  }

  &__contacts-list {
    &--more {
      background-color: #fafafa;
      margin: 0;
      list-style: none;
      padding: 10px;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
      border-radius: 2px;
      min-width: 250px;
    }
  }

  &__tabs-container {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  &__more-contact-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    padding-right: 30px;
    background-color: #e6ebf1;
    margin-bottom: 10px;
    border-radius: 6px;
    font-size: 12px;
    color: #808080;
    position: relative;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }

    &.chat__contact-item--selected {
      border-bottom-color: #0092f2;
    }

    &--not-read {
      background-color: #f6f6f8;
      padding-right: 5px;
    }

    &>*:not(.chat__remove-img):not(.chat__more-contact-not-read-msg) {
      width: 50%;
      display: flex;
      justify-content: flex-start;
      padding-left: 15px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
    }

    .chat__remove-img {
      color: #808080;
      position: static;
      padding: 0;
      font-size: 14px;
    }
  }

  &__contact-item {
    min-width: 82px;
    max-width: 100px;
    padding-right: 15px;

    &--small {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      min-width: unset;
      max-width: unset;
      padding: 0;

      & .chat__contact-name {
        display: flex;
      }
    }

    &--small-muted {
      padding-left: 5px;
    }

    &--notifications {
      color: $color-red;
    }

    &-wrapper {
      // margin-right: 6px;
    }
  }

  &__remove-img {
    display: none;
    position: absolute;
    right: 6px;
    top: 8px;
    color: $color-text-primary;
    font-size: 12px;
    cursor: pointer;

    &:before {
      content: "";
      position: absolute;
      padding: 10px;
      left: -5px;
      top: -3px;
    }
  }

  &__contact-item-mute-icon {
    width: 12px;
    height: 12px;
    margin-right: 4px;
  }

  &__more-contact-not-read-msg {
    color: #dc3545;
    font-size: 14px;
    display: flex;
    align-items: center;

    svg {
      margin-left: 5px;
    }
  }

  &__show-more-contacts {
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 6px 6px 0 0;
    border: 1px solid transparent;
    background-color: #bfbfbf;
    padding-top: 3px;
    position: relative;
    padding: 4px 10px;

    &--have-notread {
      &::after {
        content: "";
        display: block;
        position: absolute;
        right: 13px;
        top: 2px;
        width: 6px;
        height: 6px;
        background-color: #ec2828;
        border-radius: 100%;
      }
    }
  }

  &__show-more-contacts-icon {
    transform: rotate(270deg);
    margin-left: 5px;
  }

  &__restore {
    margin-left: 7px;
  }

  &__unread-indicator {
    position: absolute;
    top: -10px;
    right: -7px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    background-color: $color-froly;
    color: #fff;
    font-size: 10px;
    font-weight: 500;
  }

  &--girls {
    margin-left: auto;
  }

  &__icon-circle {
    width: 12px;
    height: 12px;
    color: $color-caribian-green;

    &--off_today {
      color: $color-froly;
    }

    &--finished {
      color: $color-malibu;
    }

    &--started {
      color: $color-tahiti-gold;
    }
  }
}

.public-chat {
  max-width: 32%;

  &__title {
    font-size: 12px;
    color: $color-gray;
    font-weight: 500;
  }
}

.dropzone-overlay {
  // border: 2px dashed red;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.78);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 5;

  &__btn {
    &--remove {
      color: #f8737f;
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }
}

.dropzone-accepted {
  width: 100%;
  height: 100%;
  background-color: #fff;

  &__title {
    text-align: center;
    margin-top: 13px;
    margin-bottom: 0;
  }

  &__limit {
    text-align: center;
    font-size: 13px;
    color: #f8737f;
  }

  &__thumbs {
    @include scrollBars;

    display: flex;
    justify-content: flex-start;
    padding: 12px;
    background: #f6f6f8;
    border: 1px solid rgba(197, 194, 194, 0.801);
    border-radius: 4px;
    overflow-x: auto;
  }

  &__thumb-wrap {
    width: 62px;
    min-width: 62px;
    height: 62px;
    margin: 2px;
    position: relative;

    &--video {
      width: 110px;
      min-width: 110px;
    }
  }

  &__thumb-wrap:hover &__btn--remove {
    display: block;
  }

  &__thumb {
    border-radius: 4px;
    cursor: pointer;
  }

  &__btns {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  &__btn {
    // border: 1px solid black;

    &--cancel,
    &--submit {
      padding: 10px 0;
      border-radius: 5px;
      font-size: 13px;
      color: #fff;
      width: 66px;
    }

    &--cancel {
      background-color: #f8737f;
    }

    &--submit {
      background-color: #0092f2;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &--remove {
      display: none;
      background-color: rgba(0, 0, 0, 0.6);
      border-radius: 50%;
      position: absolute;
      top: -5px;
      right: -5px;
      z-index: 5;
      font-size: 10px;
      width: 20px;
      height: 20px;
    }
  }
}

.chat-title {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: normal;
  color: $color-gray;
  min-width: 0;
  margin: 0;

  &__icon-wrap {
    display: inline-flex;
    // width: 15px;
    // min-width: 15px;

    // & svg {
    //   width: 15px;
    // }
  }

  &__text {
    margin: 0 7px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.chat-restore {
  display: flex;
  align-items: center;
  padding: 0;
  color: #808080;
  cursor: pointer;
  user-select: none;
  transition: color linear .1s;

  &:hover {
    color: $color-bondi-blue;
  }
}

.shake-me {
  animation: shakeMe 1s linear infinite;
}

[data-animationclass="shake-me"] {
  animation: shakeMe 1s linear infinite;
}

[data-isfixed="false"] {
  font-style: italic;
}
