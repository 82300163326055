@import 'assets/scss/variables';

.booking-item {
  position: relative;
  display: flex;
  align-items: center;
  gap: 25px;
  min-height: 41px;
  background-color: $color-white;
  border-top: 1px solid $color-mystic;
  padding: 4px 13px 4px 8px;
  margin-bottom: 6px;
  font-size: 12px;
  cursor: pointer;

  &--deleted {
    opacity: 0.5;

    .booking-item__text-status {
      color: $color-red;
    }
  }

  &--is-prebooking {
    gap: 10px;
    cursor: default;
    &-gap {
      gap: 2px;
      cursor: default;
    }
  }

  &--displayed-with-marker {
    margin-left: 50px;
  }

  &--displayed-with-date-marker {
    margin-top: 38px;
  }

  &--by-date-modal {
    .booking-item__client-name {
      width: 139px;
    }
  }

  &--profiles-booked {
    .booking-item__info-wrap {
      width: 123px;
    }
  }

  &--calendar-two-days {
    gap: 15px;

    .booking-item__client {
      width: 70px;
    }
  }

  &--calendar-timeline {
    .booking-item__name {
      width: 70px;
    }

    .booking-item__client {
      width: 235px;
    }
  }

  &__hour-marker {
    position: absolute;
    left: -10px;
    top: 0;
    transform: translateX(-100%);
    font-size: 14px;
    color: $color-bali-hai;
  }


  &__date-marker {
    position: absolute;
    transform: translate(-9px, -40px);
    font-size: 14px;
    color: $color-bali-hai;
  }

  &__color-line {
    position: absolute;
    height: 100%;
    left: 0;
    width: 3px;
    border-radius: 6px;
  }

  &__column {
    display: flex;
    flex-direction: column;

    &--grow {
      flex-grow: 1;
    }

    &--is-prebooking {
      cursor: pointer;
    }
  }

  &__name {
    width: 50px;
    & > span {
      cursor: pointer;
    }

    &--duo {
      cursor: pointer;
      margin-top: 3px;
    }

    &--is-prebooking {
      width: 70px;
      cursor: pointer;
      &-escort {
        width: 100%;
      }
    }
  }

  &__operator {
    width: 70px;
  }

  &__name, &__operator {
    overflow: hidden;
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: $color-azure-radiance;
  }

  &__badge {
    display: flex;
    align-items: center;
    font-weight: bold;
    width: 25px;

    &--duo {
      color: $color-razzle-dazzle-rose;
    }
  }

  &__price {
    display: flex;
    color: $color-black;
    width: 135px;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    &--is-prebooking {
      width: 170px;
    }
  }

  &__requirements-indicator-box {
    display: flex;
    justify-content: center;
    width: 40px;
  }

  &__requirements-indicator {
    width: 2.5px;
    height: 7.5px;
    background-color: $color-neon-carrot;

    &:not(:last-child) {
      margin-right: 3.5px;
    }
  }

  &__type {
    text-transform: capitalize;
  }

  &__date {
    text-align: left;
    text-transform: lowercase;
    color: $color-bali-hai;
    width: 111px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__client-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
  }

  &__client {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 140px;

    .lazy-load__container {
      width: 32px;
      height: 32px;
    }

    &-avatar {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      margin-right: 5px;
      border-radius: 50%;
    }

    &-name {
      flex-grow: 1;
      font-size: 12px;
      text-align: left;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: $color-dodger-blue;
    }
  }

  &__info-wrap {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: flex-end;
    width: 167px;

    &--booking-modal {
      width: 100%;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
    }
  }

  &__statuses-wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__text-status {
    flex-grow: 1;
    text-align: start;
    text-transform: capitalize;
    color: $color-azure-radiance;
    font-style: italic;

    &--finished {
      color: $color-japanese-laurel;
    }

    &--in-progress {
      color: $color-blaze-orange;
    }

    &--cancelled {
      color: $color-red;
    }
  }

  &__icons-list {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-grow: 1;
    height: 18px;
    margin-right: 10px;

    &-item {
      width: 15px;
      height: 15px;

      &--prebooking {
        color: $color-persian-pink;
      }
    }
  }

  &__status {
    display: flex;
    align-items: center;
    padding: 1px 10px;
    border-style: solid;
    border-width: 1px;
    border-radius: 6px;
    margin-left: 10px;
    letter-spacing: 1px;
    text-transform: capitalize;
    cursor: pointer;

    &-dot {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      margin-left: 5px;
    }
  }

  &__additional-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-right: 4px;
  }

  &__date {
    width: auto;
    
    &--client {
      color: $color-dodger-blue;
    }
  }

  &__operator {
    text-align: right;
  }

  &__requirements-indicator {
    width: 2.5px;
    height: 7.5px;
    background-color: $color-neon-carrot;
    
    &:not(:last-child) {
      margin-right: 3.5px;
    }
  }

  &__operator {
    text-align: right;
  }
}
