@import 'assets/scss/functions.scss';
@import 'assets/scss/variables';

.mention-list {
  @include ModalWindow(
    308px,
    96%,
    column,
    null,
    null,
    'top',
  );

  position: absolute;
  bottom: 100%;
  left: 0;
  left: 50%;
  transform: translateX(-50%);
  visibility: hidden;
  box-shadow: 0 -10px 10px 2px rgba(0, 0, 0, 0.12);
  z-index: 3;

  &--visible {
    visibility: visible;
  }

  &__list-container {
    height: 100%;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 2px;
    padding: 12px;
  }

  &__item {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 4px;
    cursor: pointer;
    color: #858181;
    font-size: 12px;
    background-color: $color-white;
    margin-bottom: 5px;
    border-radius: 5px;
    width: 100%;
    border: 1px solid transparent;

    &--active {
      border: 1px solid #00a6ad;
      background-color: rgba(#88d6d9, 0.44);
    }

    &:hover {
      border: 1px solid $color-bondi-blue;
    }
  }

  &__ava-wrap {
    width: 32px;
    min-width: 32px;
    height: 32px;
    position: relative;
    background-color: #ffffff;
    border-radius: 50%;
  }

  &__ava {
    border-radius: 50%;
  }

  &__status {
    width: 8px;
    height: 8px;
    position: absolute;
    border-radius: 50%;
    top: -3px;
    left: -4px;
    background-color: $color-red;
    z-index: 2;
    border: 1px solid rgb(230, 235, 241);

    &--online {
      background-color: $color-green-haze;
    }

    &--away {
      background-color: $color-orange;
    }

    &--busy {
      background-color: $color-froly;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    margin-left: 5px;
    gap: 2px;

    & > * {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__phone {
    font-style: italic;
  }
}
