.gallery-list {
  grid-area: list;

  &--centered {
    display: grid;
    justify-content: center;
    align-content: center;
  }
}

.gallery-list__no-profiles {
  font-size: 26px;
  font-weight: 600;
  color: rgba(136, 214, 217, 0.6);
}

.gallery-list__profile-list {
  padding-right: 15px;
  display: flex;
  flex-wrap: wrap;
  gap: 15px 20px;
  justify-content: space-evenly;

  // Cards in last row will be on the left side
  // display: grid;
  // grid-template-columns: repeat(auto-fill, 160px);
}
