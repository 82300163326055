@import 'assets/scss/variables';

.calendar-table-bookings {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $color-bali-hai;
    font-size: 14px;
    margin-bottom: 12px;
    z-index: 1;
  }

  &__export-btn {
    height: 28px;
    color: $color-black;
    background: $color-white;
    border: 2px solid $color-bondi-blue;
    padding: 0 20px;

    svg {
      margin-left: 15px;
      width: 17px;
      height: 17px;
    }
  }

  &__sort-icon {
    width: 12px;
    height: 12px;
    transform: rotate(-90deg);

    &--asc {
      transform: rotate(90deg);
    }

    &--active {
      color: $color-bondi-blue;
    }
  }

  &__new-booking {
    display: flex;
    color: $color-bondi-blue;
  }

  &__table {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;

    &-header {
      display: flex;
      align-items: center;
      font-size: 14px;
      box-shadow: 0 12px 12px 0 rgba(0,0,0,0.12);
      // z-index: 1;

      .calendar-table-bookings__table-col {
        height: 40px;
        justify-content: space-between;
        color: $color-gray;
        white-space: pre;

        &:nth-child(even) {
          background: #f7f9fa;
        }

        &--status {
          justify-content: center;
        }
      }
    }

    &-col {
      border: 0.5px solid #f4f7f9;
      position: relative;
      display: flex;
      align-items: center;
      height: 100%;
      font-size: 12px;
      color: $color-text-primary;
      padding: 0 10px;
      gap: 12px;
      cursor: pointer;

      &-text {
        width: 100%;
        overflow: hidden;
        text-align: left;
        white-space: nowrap;
        text-overflow: ellipsis;

        &--date {
          white-space: normal;
        }
      }

      &:nth-child(even) {
        background: rgba(230, 235, 241, 0.3);
      }

      &--checkbox {
        width: 70px;
        min-width: 70px;
      }

      &--date {
        width: 10%;
        min-width: 10%;
      }

      &--escort, &--client, &--details {
        width: 14.5%;
        min-width: 14.5%;
      }

      &--status {
        width: 4.3%;
        min-width: 4.3%;
      }

      &--requirements {
        width: 70px;
        min-width: 70px;
      }

      &--markers {
        width: 10.9%;
        min-width: 10.9%;
      }

      &--operator {
        width: 10.5%;
        min-width: 10.5%;
      }

      &--notes {
        width: calc(100% - 70px - (14.5%) * 3 - 4.3% - 70px - 10.9% - 10.5%);
      }
    }
  }

  &__list {
    & .calendar-table-bookings__table-col {
      &--status {
        justify-content: center;
      }
    }
  }

  &__checkbox-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  &__checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15px;
    height: 15px;
    background-color: $color-silver;

    &--active {
      background-color: transparent;
    }

    &-check-icon {
      color: $color-bondi-blue;
    }
  }

  &__list-container {
    height: 99%;
    width: 100%;
  }

  &__spinner-wrap, &__no-items  {
    position: absolute;
    width: fit-content;
    top: 40%;
    left: 50%;
    transform: translateX(-50%);
  }

  &__no-items {
    font-size: 14px;
    color: $color-bali-hai;
  }

  .scrollbars-track {
    width: 5px;
    right: 5px;
  }

  .scrollbars-thumb {
    background-color: $color-gray;
  }
}

.calendar-table-booking {
  display: flex;
  align-items: center;

  &__status {
    position: absolute;
    left: 0;
    width: 100%;
    height: calc(100% - 1px);
  }

  &__icons-list {
    display: flex;
    align-items: center;
    flex-grow: 1;
    height: 18px;

    &-item {
      width: 15px;
      height: 15px;

      &--prebooking {
        color: $color-persian-pink;
      }
    }
  }

  &__requirements-indicator-box {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 7.5px;
  }

  &__requirements-indicator {
    width: 2.5px;
    height: 7.5px;
    background-color: $color-neon-carrot;

    &:not(:last-child) {
      margin-right: 3.5px;
    }
  }
}
