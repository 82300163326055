.modal-container {
  position: fixed;
  display: table;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  transform: scale(0);
  z-index: 120;
}
.modal-container.open {
  transform: scale(1);
}
.modal-container.open .modal-background {
  text-align: center;
  background: rgba(0, 0, 0, 0);
  --final-background: rgba(0, 0, 0, 0.5);

  &--gallery, &--girls-map, &--profile-buffer {
    --final-background: rgba(64, 64, 64, 0.95);
  }

  &--girls-map {
    &:has(.girls-map--selection-mode) {
      --final-background: rgba(37, 124, 125, 0.95);
    }
  }

  animation: fadeIn 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}
.modal-container.open .modal-background .modal-content {
  opacity: 0;
  animation: scaleUp 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;

  &--girls-map {
    height: 94vh;
    max-height: 94vh;
  }
}
.modal-container.open .modal-background--transferred-or-assistance-session {
  background-color: rgba(255,255,255, 0.7);
  --final-background: rgba(255, 255, 255, 0.7);
}
.modal-container.open.out {
  animation: quickScaleDown 0s 0.5s linear forwards;

  // To prevent user interaction with modal
  // during close timeout
  &::after {
    content: '';
    position: absolute;
    inset: 0;
    z-index: 1000;   // not necessary
  }
}
.modal-container.open.out .modal-background {
  animation: fadeOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}
.modal-container.open.out .modal-background .modal-content {
  animation: scaleDown 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}
.modal-container .modal-background {
  display: table-cell;
  vertical-align: middle;
}
.modal-container .modal-background .modal-content {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.modal-content--pending {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.3);
  cursor: wait;
}

// .modal-content {
//   position: relative;

//   & > *:last-child:not([class*="done"]) {
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//   }

//   & > *:only-child {
//     position: static !important;
//     top: 0 !important;
//     left: 0 !important;
//   }
// }

.modal-content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}


// PHOTO GALLERY ANIMATION

.modal-content:has(.modal--photo-gallery, .modal--profile-carousel) {
  & .modal--enter.modal--profile-carousel {
    transform: scale(0.7);
    filter: blur(2px);
  }

  & .modal--enter-active.modal--profile-carousel {
    transform: scale(1);
    filter: blur(0);
  }

  & .modal--exit.modal--profile-carousel {
    transform: scale(1);
    filter: blur(0);
  }

  & .modal--exit-active.modal--profile-carousel {
    transform: scale(0.7);
    filter: blur(2px);
  }

  & .modal--exit.modal--photo-gallery {
    transform: scale(1);
    filter: blur(2px);
  }

  & .modal--exit-active.modal--photo-gallery {
    transform: scale(1.3);
    filter: blur(0);
  }

  & .modal--enter.modal--photo-gallery {
    transform: scale(1.3);
    filter: blur(2px);
  }

  & .modal--enter-active.modal--photo-gallery {
    transform: scale(1);
    filter: blur(0);
  }
}


// DEFAULT OPEN CHILD MODAL ANIMATION

.modal--enter {
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
  pointer-events: none;
}

.modal--enter-active {
  opacity: 1;
  pointer-events: none;
  transition: opacity 300ms, transform 300ms;
}

.modal--exit {
  opacity: 1;
  position: absolute;
  transition: opacity 300ms, transform 300ms;
}

.modal--exit-active {
  opacity: 0;
  position: absolute;
  transition: opacity 300ms, transform 300ms;
}

.modal--exit-done {
  display: none !important;
}

@keyframes unfoldIn {
  0% {
    transform: scaleY(0.005) scaleX(0);
  }
  50% {
    transform: scaleY(0.005) scaleX(1);
  }
  100% {
    transform: scaleY(1) scaleX(1);
  }
}
@keyframes unfoldOut {
  0% {
    transform: scaleY(1) scaleX(1);
  }
  50% {
    transform: scaleY(0.005) scaleX(1);
  }
  100% {
    transform: scaleY(0.005) scaleX(0);
  }
}
@keyframes zoomIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes zoomOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes fadeIn {
  0% {
    background: rgba(0, 0, 0, 0);
  }
  100% {
    background: rgba(0, 0, 0, 0.5);
    background: var(--final-background);
  }
}
@keyframes fadeOut {
  0% {
    background: rgba(0, 0, 0, 0.5);
  }
  100% {
    background: rgba(0, 0, 0, 0);
  }
}
@keyframes scaleUp {
  0% {
    transform: scale(0.8) translateY(1000px);
    opacity: 0;
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
  }
}
@keyframes scaleDown {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 1;
  }
  100% {
    transform: scale(0.8) translateY(1000px);
    opacity: 0;
  }
}
@keyframes quickScaleDown {
  0% {
    transform: scale(1);
  }
  99.9% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
