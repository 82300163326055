@import 'assets/scss/variables';

.conflicting-bookings-modal {
  width: 600px;
  height: 395px;
  padding: 16px 16px 50px 16px;
  background-color: $color-white;
  border-radius: 6px;
  border-bottom: 2px solid $color-bondi-blue;
  box-shadow: 0 24px 24px 0 rgba(0, 0, 0, 0.06);
}

.conflicting-bookings-modal-list {
  &__list-container {
    width: 100%;
    height: 100%;
  }
}
