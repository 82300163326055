@import 'assets/scss/variables.scss';

.contact-notes-tab {
  padding: 16px;

  &__text-wrapper {
    border-radius: 2px;
    color: $color-gray;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0;
    background-color: $color-white;
  }

  &__text-content {
    cursor: text;
  }

  &__input {
    width: 100%;
    padding: 0;
    border: none;
    outline: none;
    color: #0C201F;
    resize: none;
    padding: 7px;

    @extend %font-main-text;
  }

  &__btn-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  &__btn-close {
    @extend %adr-book-btn-warn;
    &:disabled {
      opacity: 0.5;
    }
  }

  &__close-icon {
    color: $color-white;
    width: 6px;
  }

  .clipboard-btn {
    margin-right: 8px;
    @extend %adr-book-btn;

    &__icon {
      position: absolute;
      inset: 0;
      margin: auto;
      width: 11px;
      height: 11px;
      color: $color-white;
    }
  }
}