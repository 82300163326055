@import 'assets/scss/variables';

.sidebar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  padding: 14px 0;
  color: rgba($tertiary-background-color, $dimmed-text-opacity);
}
