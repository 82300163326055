@import 'assets/scss/variables.scss';

.date-time-changer {
  display: flex;
  align-items: center;
  height: 100%;
  gap: 8px;

  &__wrapper {
    position: relative;
  }

  &__container {
    width: 100%;
    margin-right: 8px;
  }

  &__calendar-icon {
    width: 14px;
    height: 14px;
    margin: 0 8px;
  }

  &__prev-btn svg {
    transform: rotate(90deg);
  }

  &__next-btn svg {
    transform: rotate(-90deg);
  }

  &__tooltip {
    z-index: 4;
  }
}
