@import 'assets/scss/variables';

.sales-sidebar {
  display: flex;
  flex-direction: column;
  padding: 12px 15px 0;
  overflow-y: auto;

  &__sessions {
    display: flex;
    flex-direction: column;
    flex-grow: 5;
  }

  &__completed-sessions {
    display: flex;
    flex-direction: column;
    flex-grow: 3;
  }

  &__operators {
    flex-grow: 3;
    min-height: 200px;
  }
}
