.booking-status {
  display: flex;
  font-size: 12px;
  height: 18px;
  align-items: center;
  padding: 1px 10px;
  border-style: solid;
  border-width: 1px;
  border-radius: 6px;
  letter-spacing: 1px;
  text-transform: capitalize;
  cursor: pointer;

  &-dot {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-left: 5px;
  }
}
