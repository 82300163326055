@import 'assets/scss/variables.scss';

.booking-special-request {
  position: relative;
  width: 100%;

  &__tooltip {
    display: flex;
    z-index: 2;
    flex-direction: column;
    width: 100%;
    max-width: 571px;
    height: 588px;
    border-radius: 6px;
    background-color: $color-mystic;
    box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.08);
  }

  &__main {
    flex-grow: 1;
    padding: 29px 14px 10px;
    border-bottom: 2px solid $color-bondi-blue;
    border-radius: 6px;
    background-color: $color-white;
    display: flex;
    flex-direction: column;
    &-header {
      display: flex;
      justify-content: space-between;
      padding: 0 26px;
      margin-bottom: 5px;
    }
  }

  &__request {
    position: relative;
    margin-bottom: 15px;
    padding: 22px 12px;
    border-radius: 6px;
    color: $color-tundora-light;
    font-style: italic;
    font-size: 14px;
    line-height: 19px;
    text-align: left;
    background-color: $color-athens-gray;

    &-wrap {
      flex-grow: 1;
      height: 377px;
      padding: 23px 17px;
    }
  }

  &__title {
    color: $color-gray;
    font-size: 14px;
    letter-spacing: 0;
  }

  &__subtitle {
    color: $color-gray;
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 16px;
  }

  &__btns-wrapper {
    display: flex;
    align-items: center;
  }

  &__btn {
    font-size: 12px;
    padding: 0;
    border: none;
    &-icon {
      width: 20px;
      &--check {
        margin-left: 9px;
      }

      &--add {
        color: $color-bondi-blue;
        margin-right: 6px;
      }

      &--request {
        position: absolute;
        top: 1px;
        left: 2px;
        width: 15px;
        color: $color-gray;
      }

      &--delete {
        width: 6px;
        height: 6px;
      }

      &--add-from {
        color: $color-bondi-blue;
        width: 16px;
        margin-right: 9px;
      }
    }
    &--save {
      display: flex;
      align-items: flex-end;
      margin-left: 25px;
      padding: 0;
      color: $color-bondi-blue;
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    &--cancel {
      color: $color-froly;
    }

    &--add-from {
      display: flex;
      align-items: center;
      color: $color-gray;
      font-size: 14px;
      margin-right: 15px;
      &:disabled {
        opacity: .5;
        cursor: not-allowed;
      }
    }

    &--delete {
      display: flex;
      position: absolute;
      top: 2px;
      right: 2px;
      justify-content: center;
      align-items: center;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      color: $color-white;
      background-color: $color-froly;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding: 19px 31px;
    align-items: center;
  }

  &__label {
    margin-bottom: 5px;
    width: max-content;
    display: block;
    font-size: 12px;
    letter-spacing: 1px;
  }

  &__textarea-wrap {
    padding: 0 17px 23px;
    border-bottom: 1px solid $color-silver;
    height: 96px;

    textarea {
      width: 100%;
      height: 100%;
      padding: 12px 10px;
      border: 1px solid $color-silver;
      border-radius: 6px;
      outline: none;
      color: $color-tundora;
      font-size: 14px;
      line-height: 1.35;
      font-family: Roboto;
      resize: none;

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $color-gray;
        border-radius: 3px;
      }
    }
  }

  &__list-wrap {
    flex-grow: 1;
    margin-right: -12px;
    margin-top: 23px;
  }
  
  &__list {
    padding: 0px 29px 0px 17px;

    &-container {
      height: 100%;
    }

    &-item {
      position: relative;
      margin-bottom: 12px;
      background-color: $color-athens-gray;
      padding: 22px 15px 12px;

      &-body {
        font-size: 14px;
        line-height: 19px;
        color: $color-tundora-light;
        font-style: italic;
        text-align: left;
      }
    }
  }
}
