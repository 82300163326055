@import 'assets/scss/variables';

.booked-zone {
  display: flex;
  flex-direction: column;
  width: 660px;
  height: 540px;
  border-radius: 6px;
  background-color: $color-mystic;

  &__list-container {
    height: 100%;
    padding: 24px 15px 0 15px;
    border-radius: 6px;
    background-color: $color-white;
    border-bottom: 2px solid #00A6AD;
    box-shadow: $shadow-primary;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding: 19px 30px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    background-color: $color-mystic;
  }

  &__text {
    font-size: 14px;
    color: $color-gray;
  }

  &__btn-esc {
    display: flex;
    font-size: 12px;
    color: $color-gray;
  }

  &__icon-close {
    width: 13px;
    height: 13px;
    margin-left: 10px;
    fill: $color-gray;
  }

  .booked-editor {
    position: relative;
    box-shadow: none;
    margin-bottom: 0px;
    padding: 0;
  }

  .booked-editor-select {
    &__list {
      max-height: 200px;
      margin-bottom: 10px;
    }
  }
}

//.booked-zone-item {
//  display: flex;
//  align-items: center;
//  justify-content: space-between;
//  min-height: 41px;
//  background-color: $color-white;
//  border-top: 1px solid $color-mystic;
//  padding: 3px 13px 2px 8px;
//  font-size: 12px;
//
//  &__name {
//    max-width: 75px;
//    overflow: hidden;
//    white-space: nowrap;
//    text-overflow: ellipsis;
//    color: $color-azure-radiance;
//  }
//
//  &__content {
//    display: flex;
//  }
//
//  &__info {
//    margin-right: 30px;
//    letter-spacing: 0;
//    color: $color-black;
//  }
//
//  &__type {
//    text-transform: capitalize;
//  }
//
//  &__date {
//    text-transform: lowercase;
//    color: $color-bali-hai;
//  }
//}
