@import "../../assets/scss/variables.scss";

.modal-img {
  width: unset;
  min-width: 100px;
  max-width: 90vw;
  height: unset;
  min-height: 100px;
  max-height: 90vh;

  &--round {
    width: 50vh;
    height: 50vh;
    border-radius: 50%;
    background-color: $color-white;
  }
}
