@import 'assets/scss/variables';

.sidebar-contacts-list {
  &--empty {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 13px;
    color: #bfbfbf;
  }

  &__contact-item {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    &--marked {
      &::before {
        content: '';
        position: absolute;
        bottom: 1px;
        left: 1px;
        width: 11px;
        height: 11px;
        background-color: rgb(var(--red-status-color));
        border-bottom-left-radius: 5px;
        clip-path: polygon(100% 100%, 0% 0%, 0% 100%);
      }
    }

    &::after {
      content: "";
      position: absolute;
      bottom: -6px;
      height: 1px;
      width: 100%;
      background-color: rgba(var(--tertiary-color), var(--light-highlighted-opacity));
    }

    &--divided {
      &::after {
        background-color: rgba(var(--secondary-color), 0.7);
      }
    }
  }

  &__contact-item--pinned &__contact-item-avatar {
    &::before {
      content: '';
      position: absolute;
      top: -1px;
      left: 33px;
      width: 8px;
      height: 8px;
      mask-image: url("../../../../assets/icons/pin-new.svg");
      mask-size: contain;
      background-color: rgb(var(--darker-tertiary-color));
    }
  }


  &__operator-info {
    height: 16px;
    bottom: 3px;
    gap: 4px;
    font-style: italic;

    & button {
      margin-left: 6px;
    }
  }

  &__last-interaction {
    bottom: 1px;
    font-size: $font-size-m;

    & svg {
      height: 11px;
      aspect-ratio: 1;
      margin-right: 2px;
    }
  }

  &__contact-time {
    bottom: 3px;
  }

  &__contact-ex {
    color: rgb(var(--status-color));
    font-weight: bold;
  }

  &__booking-icon {
    position: absolute;
    bottom: -8px;
    right: -1px;
    height: 12px;
    fill: rgb(var(--tertiary-color));
  }

  &__unread-msgs-counter {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(50%, -50%);
  }

  &__contact-item-wrapper {
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  &__add-contact-button {
    height: 12px;
    aspect-ratio: 1;
  }
}
