@import 'assets/scss/variables.scss';
@import 'assets/scss/functions.scss';

.grouped-buttons {
  $theme-dim-background-color: rgba($tertiary-background-color,
      $strong-highlighted-opacity );
  $theme-dim-selected-button-color: rgba($secondary-background-color,
      $selected-opacity );
  $theme-transparent-button-border-color: rgba($tertiary-background-color,
      $light-highlighted-opacity + $difference-opacity );
  $theme-transparent-button-background-color: rgba($tertiary-background-color,
      $light-highlighted-opacity );

  display: flex;
  position: relative;
  flex-direction: row;
  transition: all $default-animation;
  clip-path: inset(0 -200px -200px -200px);
  height: 100%;

  // @include underliedBackground();

  &--theme-light {
    background-color: $primary-background-color;
    border-radius: 0 0 $radius-m $radius-m;
  }

  :is(&--theme-dim, &--theme-dim-animated) {
    border-radius: $radius-m;

    // @include underliedBackground($radius-m);

    &>.dropdown-button> :where(a.active, button.active) {
      border-radius: $radius-m;
    }
  }

  :is(&--theme-dim, &--theme-dim-animated) :is(&__button, &__dropdown-button) {
    background-color: $theme-dim-background-color;

    &.active {
      background-color: $theme-dim-selected-button-color;
    }

    &:first-child {
      border-top-left-radius: $radius-m;
      border-bottom-left-radius: $radius-m;
    }

    &:last-child {
      border-top-right-radius: $radius-m;
      border-bottom-right-radius: $radius-m;
    }

    &.disabled {
      pointer-events: none;
    }
  }

  &--theme-dim-animated &__button {
    transition: background-color $default-animation, border-color $default-animation;

    &:where(:hover, &.active) {
      background-color: $theme-transparent-button-background-color;
      border: 2px solid $theme-transparent-button-border-color;
    }

    &:first-child {
      border-top-left-radius: $radius-m;
      border-bottom-left-radius: $radius-m;
    }

    &:last-child {
      border-top-right-radius: $radius-m;
      border-bottom-right-radius: $radius-m;
    }
  }

  &__null-box {
    height: 100%;
    width: 100%;
    aspect-ratio: 1;
  }

  &>.dropdown-button {
    position: relative;
    z-index: 2;
    background-color: $color-morning-glory;

    & svg {
      width: 20px;
      height: 20px;
      max-width: 20px;
      max-height: 20px;
    }
  }

  &>.grouped-menu {
    height: auto;
    width: 100%;
    position: absolute;
    z-index: 1;
    flex-direction: column;
    opacity: 0;
    visibility: hidden;
    top: 45px;
    transform: translateY(-100%);
    transition: all $default-animation;
    background-color: $color-morning-glory;
    color: $color-white;

    & svg {
      width: 20px;
      height: 20px;
      max-width: 20px;
      max-height: 20px;
    }
    
    &.visible {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
      box-shadow: $default-floating-box-shadow;
    }

  }
  
  &::before > .dropdown-button {
    content: "";
    position: static;
  }
}
