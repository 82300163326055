.carousel-arrow-icon {
  width: 26px;
  height: 26px;

  &--prev {
    transform: rotate(90deg);
  }
  
  &--next {
    transform: rotate(-90deg);
  }
}