@import 'assets/scss/variables.scss';

.booking-week-item {
  list-style: none;
  border-radius: 6px;
  border-top: 2px solid;
  border-right: 1px solid;
  border-left: 1px solid;
  background-color: $color-wild-sand;
  font-size: 12px;
  line-height: 16px;
  width: 100%;
  cursor: pointer;

  // &:not(:last-child) {
  //   margin-bottom: 15px;
  // }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 15px;

    &--deleted {
      opacity: 0.5;
    }
  }

  & &__hidden-details {
    overflow: visible;
  }

  &__header,
  &__info {
    display: flex;
    height: 20px;
    align-items: center;
    padding: 0 6px;
    justify-content: space-between;
  }

  &__header {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    background-color: $color-white;
  }

  &__outer-header {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin: 7px 15px;
    overflow: hidden;
    text-overflow: ellipsis;

    &--hidden {
      flex-direction: row;
    }
  }

  &__hidden-part {
    flex-direction: row;
    gap: 6px;
  }

  &__details {
    color: $color-bali-hai;
    white-space: pre;
  }

  &__markers {
    & svg {
      height: 14px;
      width: 14px;
    }
  }

  &__client {
    white-space: pre;
    overflow: hidden;
  }

  & &__escort {
    overflow: visible;
  }
}
