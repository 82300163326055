@import 'assets/scss/variables';

.menu-new {
  --menu-item-height: 48px;
  --menu-item-width: 264px;
  --padding-x: 16px;
  --padding-y: 24px;
  --max-items-without-scroll: 5;

  font-family: $font-family-main;;
  font-size: $font-size-l;
  background-color: $primary-background-color;
  padding: var(--padding-y) var(--padding-x);
  border-radius: $radius-xl;
  box-shadow: $default-floating-box-shadow;
  position: absolute;
  z-index: $tooltips-z-index;
  cursor: pointer;
  
  &::-webkit-scrollbar {
    display: none;
  }

  &__list {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    max-height: calc(var(--menu-item-height) * var(--max-items-without-scroll));
  }

  &__item {
    width: var(--menu-item-width);
    height: var(--menu-item-height);
    min-height: var(--menu-item-height);
    color: rgba($tertiary-background-color, 1);
    border-radius: $radius-m;
    transition: background-color $default-animation;
    text-transform: capitalize;

    &:hover {
      color: var(--selected-text-color);
      background-color: rgba($tertiary-background-color, $highlighted-opacity);
    }
  }

  &__button {
    width: 100%;
  }

  &__icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 16px;
  }
}

.dropdown-menu-new {  
  visibility: hidden;
  opacity: 0;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  transition: all $default-animation;
  width: fit-content;

  &--active {
    opacity: 1;
    visibility: visible;
    transform: translate(-50%, 10px);
  }
}

.floating-menu-new {
  opacity: 0;

  &.enter-done {
    opacity: 1;
    transition: opacity 0.2s;
  }

  &.exit {
    opacity: 1;
  }

  &.exit-active {
    opacity: 0;
    transition: opacity 0.2s;
  }
}

.select__menu {
  left: 50%;
  transform: translate(-50%, 10px);
  animation: dropdownFadeIn $default-animation;

  &--close {
    animation: dropdownFadeOut $default-animation;
  }
}

@keyframes dropdownFadeIn {
  from {
    opacity: 0;
    transform: translateX(-50%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 10px);
  }
}

@keyframes dropdownFadeOut {
  from {
    opacity: 1;
    transform: translate(-50%, 10px);
  }
  to {
    opacity: 0;
    transform: translateX(-50%);
  }
  
}
