.sidebar-contact-wrapper {
  &__unfinished-bookings-count {
    align-items: center;
    border: 1px solid #000;
    border-radius: 3px;
    color: #000;
    display: inline-flex;
    font-size: 12px;
    font-weight: 600;
    height: 16px;
    justify-content: center;
    margin-right: 5px;
    width: 16px;
  }
}
