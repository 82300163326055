@import 'assets/scss/variables.scss';
@import 'assets/scss/functions.scss';

.search-list-diva-item {
  display: flex;
  border: 1px solid transparent;
  border-radius: 6px;
  column-gap: 15px;
  cursor: pointer;
  background-color: #F6F9FC;

  &:hover {
    border: 1px solid #29F18E;
  }

  &--selected {
    border-color: $color-seance;
    cursor: not-allowed;
    background-color: $color-melanie;
    &:hover {
      border: 1px solid $color-seance;
    }
  }

  &__desc {
    color: $color-black;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0;
  }

  &__avatar-thumb {
    flex-shrink: 0;
    width: 64px;
    height: 70px;
    border-radius: 6px;
    overflow: hidden;
    background-color: $color-white;

    img {
      display: block;
    }
  }

  &__info {
    padding: 9px 9px 0 0;
    flex-grow: 1;
  }

  &__name {
    position: relative;
    margin-bottom: 10px;
    padding-left: 23px;
    color: #0092F2;
    font-size: 12px;
    @include webkit-text-overflow;

    &::before {
      content: '';
      position: absolute;
      left: 9px;
      top: 50%;
      transform: translateY(-50%);

      width: 4px;
      height: 4px;

      border-radius: 50%;
      background-color: #000;
    }

    &--available-now::before {
      background-color: #29F18E;
    }

    &--off-today::before {
      background-color: #F8737F;
    }
  }

  &__short-info {
    color: #000;
    font-size: 12px;

    span {
      @include webkit-text-overflow;
    }
  }
}
