
@import 'assets/scss/variables';

.booking-session-item {
  position: relative;
  display: flex;
  align-items: center;
  height: 41px;
  background-color: $color-white;
  border-top: 1px solid $color-mystic;
  font-size: 12px;
  width: 100%;
  cursor: pointer;

  &--deleted {
    opacity: 0.5;

    .booking-status1 {
      color: $color-red;
    }
  }

  &__color-line {
    height: 100%;
    width: 3px;
    border-radius: 6px;
    position: absolute;
    left: 0;
  }

  &__column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    &--escort {
      width: 16%;
      margin-left: 8px;
    }

    &--duo {
      align-items: center;
      width: 9%;
    }

    &--details {
      width: 23%;
    }

    &--requirements {
      width: 9%;
    }

    &--status {
      width: 22%;
    }

    &--rest {
      width: 23%;
      align-items: end;
    }
  }

  &__client {
    & img {
      height: 32px;
      width: 32px;
    }
  }

  &__date {
    color: $color-bali-hai;
  }

  &__column-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }
}
