@import 'assets/scss/variables';
@import 'assets/scss/functions.scss';

.form-textarea {
  &--dark-background {
    @include MessageContainer;
  }

  &--light-background {
    @include MessageContainer('light-background')
  }

  flex-grow: 1;
  display: flex;
  position: relative;
  justify-content: space-between;

  cursor: text;

  &__input-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }
  
  &__input {
    font-size: 14px;
    width: 100%;
    position: relative;
    flex-grow: 1;
    outline: none;
    border: none;
    background: none;
    color: $color-tundora-light;
    resize: none;
    line-height: 1.4;
    border-radius: 5px;
    background-color: transparent;
    letter-spacing: 0.88px;
    transition: background-color linear 1s;
    font-family: inherit;
    @include scrollBars;

    &--hidden {
      display: none;
    }
  }

  &--disabled {
    background-color: hsl(0, 0%, 95%);
    cursor: not-allowed;
  }

  // & svg {
  //   color: $color-gray;
  // }

  &__extra-content {
  	// position: absolute;
  }

  //& textarea {
  //  pointer-events: none;
  //}
}
