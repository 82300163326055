@import 'assets/scss/variables.scss';

.computer-modal {
  background-color: $color-white;
  padding: 96px 36px;
  border-radius: 6px;
  box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.12);

  &__title {
    font-size: 23px;
    color: $color-gray;
    font-weight: bold;
  }

  &__btns {
    margin-top: 15px;
    display: flex;
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 160px;
    min-height: 50px;
    margin: 65px 10px 0 10px;
    padding: 15px 20px;
    border-radius: 6px;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
    color: $color-white;
    background: $color-bondi-blue;
    box-shadow: 0 12px 12px 0 rgba(0,0,0,0.12);
  }
}
