.mail-pagination {
  min-width: 150px;
  margin-left: auto;
  display: flex;
  align-items: center;

  &--5-pages {
    min-width: 170px;
  }

  &--10-pages {
    min-width: 190px;
  }

  &--100-pages {
    min-width: 210px;
  }
}

.mail-pagination__arrow-btn {
  width: 32px;
  height: 32px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  color: #00a6ad;

  &:disabled {
    cursor: default;
    color: #bfbfbf;
  }

  svg {
    width: 14px;
    height: 14px;
  }

  &--left {
    transform: rotate(90deg);
  }

  &--right {
    transform: rotate(270deg);
  }
}

.mail-pagination__page-btn {
  height: 32px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 2px;

  color: #808080;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1px;

  &:disabled {
    cursor: default;
  }

  &--active {
    color: #414141;
    font-size: 14px;
    font-weight: 900;
    letter-spacing: 0;
  }
}

.mail-pagination__divider {
  cursor: default;

  color: #808080;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: -0.5px;
}
