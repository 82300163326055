.modal-video {
    position: relative;
    width: unset;
    height: unset;
    max-height: 90vh;
    min-width: 100px;
    max-width: 90vw;
    min-height: 100px;

    &__spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 5;
    }

    &__player-wrap {
      display: block;
      width: 100%;
      height: 100%;

      &--pending {
        display: none;
      }

      .video {
        width: 100%;
        height: 100%;
        overflow: hidden;
      }
    }
  }
