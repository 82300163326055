@import 'assets/scss/variables';

.sash-horizontal {
  background-size: contain;
  background-repeat: repeat;
  background-color: rgba($secondary-background-color, $selected-opacity);
  height: 2px !important;
  transform: translateY(2px);

  &::before {
    display: none;
  }
}

.sash-button {
  position: absolute;
  height: 32px;
  left: 50%;
  bottom: 0px;
  transform: translate(-50%, -2px);

  & .content {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
