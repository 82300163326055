@import 'assets/scss/variables';

.username-label {
  display: flex;
  position: relative;
  background-color: $color-gray;
  align-items: center;
  color: $color-white;
  height: min-content;
  padding: 2px 4px;
  border-radius: 6px;
  gap: 8px;
  visibility: visible;
  height: 20px;
  letter-spacing: 0.88px;
  padding-right: 22px;
  overflow: hidden;

  &--group {
    background-color: #F6F9FC;
    color: $color-black;
  }

  &--success svg,
  &--failed svg,
  &--pending svg {
    position: absolute;
    background-color: $color-silver; 
    right: 6px;
    width: 10px;
    height: 10px;
  }

  &--success svg {
    color: $color-japanese-laurel;
  }

  &--failed svg {
    color: $color-froly;
    width: 8px;
    height: 8px;
  }

  & [role="button"] {
    display: flex;
    position: absolute;
    padding: 0;
    border-radius: 50%;
    right: 6px;
    cursor: pointer;

    & svg {
      height: 8px;
      width: 8px;
    }
  }

  &__text {
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
