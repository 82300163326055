@import "assets/scss/variables.scss";

.chat-creator-form {
  height: 34px;
  width: 100%;
  border-radius: 6px;
  border: solid 1px $color-bondi-blue;
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-top: 19px;
  margin-bottom: 25px;

  &:focus-within &__phone-code {
    display: none;
  }

  &:focus-within &__input {
    padding-left: 16px;
  }

  &__phone-code {
    background: transparent;
    font-size: 13.333px;
    padding-left: 16px;
    margin-bottom: 1px;
    color: lightgray;
  }

  &__wrap {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }

  &__input {
    flex-grow: 1;
    outline: none;
    border: none;
    height: 20px;
    line-height: 1;
    padding: 0px 16px 0px 0px;
    width: 100%;
    
    &::placeholder {
      color: #bdbdbd;
    }
  }

  &__btn {
    &--keyboard {
      cursor: pointer;
      margin-left: 5px;
    }

    &--submit {
      display: flex;
      padding: 0;
      margin-left: auto;
      cursor: pointer;
      margin-right: 10px;
      color: $color-bondi-blue;
    }
  }
}
