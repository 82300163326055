@import 'assets/scss/variables';
@import 'assets/scss/functions.scss';

.duo-booking-form {
  &__main {
    display: flex;
    z-index: 999;
    position: fixed;
    top: 50%;
    left: 50%;
    flex-direction: column;
    width: 100%;
    max-width: 571px;
    // height: 95vh;
    border-radius: 6px;
    overflow-y: scroll;
    background-color: $color-mystic;
    box-shadow: 0 16px 16px 0 rgb(0 0 0 / 12%);
    transform: translate(-50%, -50%);

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  &__btns-wrapper {
    display: flex;
    align-items: center;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    &-info {
      display: flex;
    }
  }

  &__total {
    color: $color-gray;
    font-size: 14px;
    font-family: Roboto;
    margin-left: 15px;
    span {
      margin-left: 5px;
      color: $color-black;
    }
  }

  &__title {
    color: $color-razzle-dazzle-rose;
    font-weight: bold;
    font-size: 16px;
  }

  &__diva-name {
    margin-bottom: 10px;
    color: $color-seance;
    font-size: 12px;
    font-family: Roboto;
    text-align: left;
    letter-spacing: 0;
  }

  &__fields {
    flex-grow: 1;
    border-bottom: 2px solid $color-bondi-blue;
    border-radius: 6px;
    text-align: left;
    background-color: $color-white;
  }

  &__taxi-note {
    max-width: 92px;
    resize: none;
    border: none;
    outline: none;
    height: 100%;
    font-size: 12px;
    letter-spacing: 1px;
    @include scrollBars;
  }

  &__fields-part {
    width: 214px;
    margin-right: 10px;
    &--time {
      font-size: 12px;
      width: 106px;
      .booking-form__field--type {
        margin-left: 15px;
      }

      .booking-form__booking-type {
        margin-bottom: 5px;
      }
    }
    &--rate, &--discount, &--taxi {
      width: auto;
      margin-right: 15px;
      .select-form-field {
        width: 90px;
      }
    }
  }

  &__field-item:last-child > &__fields-wrapper {
    &:last-child {
      border: none;
    }
  }

  &__fields-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0 10px;
    padding: 18px;
    border-bottom: 1px solid $color-gray;

    &--diva-info {
      padding: 18px 5px;
    }

    &--services {
      flex-direction: column;
      font-size: 12px;
      text-align: left;
      padding: 0;
    }

    .calendar-form-field__label {
      letter-spacing: 0;
    }
  }
}
