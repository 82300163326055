@import 'assets/scss/variables';

.booking-form-item {
  height: 60px;
  width: 274.5px;
  background-color: $color-white;
  border-radius: 6px;
  font-size: 12px;
  flex-shrink: 0;
  overflow: hidden;
  padding-right: 10px;

  &--deleted {
    opacity: 0.5;

    .booking-status1 {
      color: $color-red;
    }
  }

  &-escort__content {
    height: 100%;
  }

  &-escort__container {
    height: 100%;

    & img {
      height: 60px;
      width: 60px;
      min-width: 60px;
      border-radius: 0px;
    }
  }

  &-escort__avatars-container {
    height: 100%;
    margin-right: 10px;
    border-radius: 6px;
    overflow: hidden;
  }

  &__details {
    margin-top: 12px;
  }
}
