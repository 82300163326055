@import 'assets/scss/variables';

.mailing-log {
  &__message-header {
    font-size: 12px;
    display: flex;
    flex-direction: row;
    padding: 8px 20px;
    gap: 18px;
    width: 100%;
  }

  &__username-labels {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 6px;
    flex-grow: 1;
    max-width: 608px;
  }

  &__redirect-btn {
    display: flex;
    align-items: center;
    color: $color-gray;

    &--active {
      color: $color-bondi-blue;
    }

    & svg {
      width: 20px;
      height: 15px;
    }
  }
}
