@import 'assets/scss/variables.scss';
@import 'assets/scss/functions.scss';

$ava-size: 64px;

.adr-book-contact-form {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &-wrap {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    padding: 24px 106px 0 36px;
    background-color: $color-iceberg-light;
  }

  &__tab {
    height: 100%;

    & .contact-bookings-list__list-container {
      height: 100%;
    }
  }

  &__select-tel-label {
    position: absolute !important;
    box-sizing: border-box;
    left: 1px;
    top: 1px;

    & .select__single-value {
      display: flex;
      align-items: center;
    }
  }

  &__item-wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  &__checkbox-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 4px;
    align-items: center;
  }

  &__avatar-wrapper {
    display: flex;
    position: relative;
    margin-right: 12px;
    height: 64px;

    .clipboard-btn {
      position: absolute;
      top: calc(50% - 6px);
      left: -22px;
      &__icon {
        color: $color-gun-powder;
      }
    }
  }

  &__rendered-girl-fn {
    position: absolute;
    left: 0;
    top: -20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 1029px;
    @extend %font-main-text;
  }

  &__actions {
    display: flex;
    justify-content: center;
    margin: 20px 0;
    text-align: center;
  }

  &__mail {
    position: relative;
  }

  &__input--error {
    border: 1px solid $color-red !important;
  }

  &__tel {
    position: relative;
  }

  &__telegram-nickname {
    position: relative;
    margin-bottom: 22px;
  }

  &__input-field-btn {
    position: absolute;
    top: 50%;
    padding: 0;
    transform: translateY(-50%);

    &--field-action {
      display: flex;
      right: 48px;
      color: $color-bondi-blue;
      svg {
        width: 20px;
        height: 20px;
      }
    }

    &--chat {
      right: 75px;
    }

    &--del {
      display: flex;
      z-index: 1;
      right: 8px;
      justify-content: center;
      align-items: center;
      padding: 5px;
      color: $color-persimmon;
      svg {
        width: 8px;
        height: 8px;
      }
    }

    &--action { //TODO remove
      position: relative;
      display: flex;
      margin: 10px 20px;
      font-size: 14px;
      font: 14px Roboto;
      padding: 10px 20px;
      background-color: $color-bondi-blue;
      border-radius: 6px;
      letter-spacing: 0.88px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.12);
      color: #fff;

      &:disabled {
        cursor: not-allowed;
        
        svg {
          color: rgba(0, 0, 0, 0.25);
        }
      }
    }
  }

  &__fields-wrapper {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__actions { //TODO remove
    display: flex;
    justify-content: center;
    margin: 20px 0;
    text-align: center;
  }

  &__add-field-btn {
    position: absolute;
    padding: 0;
    
    &--tel-add, &--mail-add, &--contact-add, &--telegram-add, &--not-for {
      display: flex;
      position: relative;
      align-items: center;
      color: $color-gray;
      gap: 6px;
    }
  }

  &__btn {
    position: absolute;
    padding: 0;

    &-wrapper {
      flex-basis: 100%;
    }

    &-add-icon {
      width: 12px;
      height: 12px;
      color: $color-white;
      position: absolute;
      margin: auto;
      inset: 0;
      &-wrap {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 18px;
        height: 18px;
        border-radius: 6px;
        background-color: $color-bondi-blue;
        border-bottom: 1px solid $color-neptune;
      }
    }

    &-clear {
      display: flex;
      z-index: 2;
      position: absolute;
      top: 7px;
      right: 5px;
      padding: 6px;

      &--description {
        top: auto;
        bottom: 12px;
      }

      &-icon {
        width: 5px;
        height: 5px;
      }
    }
  }

  &__phone-numbers, &__mails, &__telegram-nicknames {
    margin-top: 14px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__not-for-item {
    display: flex;
    align-items: center;
    margin: 0 2px;
    margin-bottom: 5px;
    padding: 8px;
    border-radius: 8px;
    background-color: $color-alabaster;
    gap: 6px;
    
    & > span {
      @extend %font-main-text;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 130px;
      width: 100%;
    }
    
    &-btn {
      display: flex;
      margin-left: 3px;
      padding: 3px;
      color: $color-tundora;
    }

    &-icon {
      width: 8px;
      height: 8px;
      color: map-get($status-colors, "light-red");
    }

    & .lazy-load__container {
      width: 14px;
      height: 14px;
      flex-shrink: 0;
      & > img {
        border-radius: 50%;
      }
    }
  }

  &__select-tel-label {
    min-width: 81px;
    width: fit-content;

    & menu {
      width: 200px;
    }

    & .react-select {
      &__control {
        display: flex;
        flex-direction: row-reverse;
        width: 105px;
        z-index: 1;
        min-height: 30px;
        border-radius: 8px;
        cursor: pointer;
        border: 1px solid transparent;
        box-shadow: none;
        &:hover {
          border: 1px solid transparent;
          & .react-select__indicators svg {
            animation: bottomArrowBounceBottom 0.3s infinite alternate;
          }
        }
      }

      &__menu {
        width: 200px;
        position: relative;
        z-index: 2;
      }

      &__indicator {
        padding: 0;
      }

      &__indicators {
        padding: 0 15px;
        & > svg {
          flex-shrink: 0;
          width: 8px;
        }
      }

      &__single-value {
        color: $color-manatee;
        @extend %font-main-text;
      }
    }
  }

  &__select-tel-icon {
    height: 6px;
  }

  &__tel-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $color-white;
    border-radius: 8px;

    &:focus-visible {
      // no
      border-color: $color-border-focus;
    }
  }

  &__input-wrapper {
    display: flex;
    position: relative;
    flex-grow: 1;
    height: 32px;
    &--email, &--telegram-nickname {
      margin: 0;
    }
  }

  & .form-error-text__wrap {
    position: absolute;
    right: 0;
    bottom: -15px;
    left: 0;
    margin: auto;
    color: $color-red;
    font-weight: 100;
    font-size: 11px;
    text-align: center;
  }

  &__field-title {
    display: flex;
    align-items: center;
    padding: 0 8px;
    color: $color-manatee;
    gap: 6px;

    @extend %font-main-text;

    &--uid {
      min-width: 83px;
    }

    &--tags {
      padding: 0;
    }
  }

  &__error-msg {
    position: absolute;
    right: 0;
    bottom: 0;
    font-size: 14px;
    color: #FF5E57;
  }

  &__header {
    display: flex;
    flex-direction: column;
    padding-bottom: 13px;
    // height: 104px;

    &-wrapper {
      display: flex;
      padding-bottom: 13px;
    }

    &-form-block {
      display: flex;
      gap: 15px;
    }

    &-content {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      &-wrapper {
        display: flex;
        position: relative;
        z-index: 4;
        gap: 10px;

        .react-select {
          flex-grow: 1;
          margin-left: 5px;
          font-size: 14px;
          $block: '.react-select';
      
          #{$block}__control {
            padding: 0;
            cursor: pointer;
          }

          #{$block}__dropdown-indicator {
            padding: 0 3px;
            color: $color-gray;
          }
      
          #{$block}__single-value {
            color: $color-dodger-blue;
            font-weight: normal;
          }
      
          #{$block}__indicator-separator {
            display: none;
          }
      
          #{$block}__option {
            overflow: hidden;
            font-size: 12px;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }

  &__contact-tag {
    background-color: $color-dark-background-tag;
  }

  &__phone-numbers {
    gap: 12px;
    display: flex;
    flex-direction: column;
  }

  &__field {
    position: relative;

    &-via-wrap {
      display: flex;
      gap: 18px;
    }

    &--phone-numbers, &--email, &--telegram-nickname {
      margin: 26px 0 0;
    }

    &--tags .tags-field {
      margin-top: 5px;

      &-container {
        margin-top: 5px;
      }

      &__not-for-popup {
        @include ModalWindow(fit-content, 370px, column);

        position: absolute;
        transform: translateY(2px);
      }
    }

    &--via {
      display: flex;
      flex-direction: column;
      margin-top: 16px;
      gap: 8px;
    }

    &--not-for {
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;
      gap: 12px;
    }

    &--contact-type, &--agent, &--category, &--type {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      width: 204px;
      margin-bottom: 0 !important;
      padding: 0 5px 0 9px;
      background-color: $color-white;
      border-radius: 6px;
      color: $color-manatee;
      transition: all 0.15s ease-in-out;
      @extend %font-main-text;

      &:focus-within {
        border-color: $color-border-focus;
      }

      & .react-select__single-value {
        font-size: 10px;
        color: $color-bondi-blue;
      }

      & .react-select__dropdown-indicator {
        padding: 0;
        color: $color-gray;
      }

      & .react-select__single-value {
        font-size: 12px;
      }
    }

    &--type {
      width: 138px;
    }

    &--subtype {
      width: 179px;

      .react-select {
        $block: '.react-select';

        #{$block}__menu {
          left: -56px;
        }
      }
    }

    &--disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &--is-trusted {
      display: flex;
      align-items: center;
      width: 100px;
      height: 40px;
      padding: 4px 5px 4px 9px;
      border: 1px solid $color-apple;
      border-radius: 6px;
      color: $color-gray;
      font-size: 13px;
      transition: all 0.15s ease-in-out;
    }

    &--category {
      width: 55px;

      .react-select {
        margin-left: 0;
        $block: '.react-select';

        #{$block}__menu {
        left: -5px;
        width: 40px;
      }

        #{$block}__single-value {
          color: $color-gray;
        }

        #{$block}__dropdown-indicator {
          padding: 0;

          svg {
            width: 16px;
            height: 16px;
          }
        }
      }
    }

    &--checkbox-wrapper {
      display: flex;
      align-items: center;
      gap: 6px;
      margin-left: 16px;
    }

    &--agent {
      & .react-select__menu {
        left: -50px;
        width: 200px;
      }
    }

    &--phone-numbers {
      & .tags-field:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    &--uid {
      display: flex;
      align-items: center;
      background-color: $color-alabaster;
      padding: 4px 12px;
      border-radius: 8px;
      gap: 8px;
      @extend %font-main-text;
    }

    &-content {
      align-self: center;
      margin-left: 5px;
      font-size: 12px;
      &--uid {
        display: flex;
        overflow: hidden;
        color: $color-black;
        text-overflow: ellipsis;
        white-space: nowrap;

        .clipboard-btn__icon {
          width: 14px;
          height: 14px;
          margin-left: 19px;
          color: $color-bondi-blue;
        }
      }
    }
  }

  &__field-type-error {
    position: absolute;
    top: -19px;
    font-size: 14px;
    color: #FF5E57;
  }

  &__fn-wrapper {
    position: relative;
    width: 100%;
    &--emoji {
      width: 110px;
      min-width: 110px;
      // z-index: 3;
    }
    &--prices {
      width: 200px;
      z-index: 3;
    }
  }

  &__input-wrap {
    display: flex;
    position: relative;
    gap: 12px;

    &--description {
      width: 100%;
    }

    &--name, &--description {
      margin: 0;
    }

    &--is-contact-girl {
      gap: 12px;
    }
    
    &--address {
      margin-top: 1px;
      padding: 25px 17px;
    }
  }

  &__input {
    width: 100%;
    padding: 0 72px 0 10px;
    border: 1px solid transparent;
    border-radius: 8px;
    outline: none;
    font-size: 14px;
    transition: all 0.15s ease-in-out;
    font-weight: 500;
    @extend %font-main-text;

    &-id {
      & > input::-webkit-outer-spin-button,
      & > input::-webkit-inner-spin-button {
        appearance: none;
        margin: 0;
      }
    }

    &--phone-number {
      padding: 5px 100px 5px 105px;
      color: #494656;
      font-size: 14px;
      @extend %font-main-text;
    }

    &--description {
      width: 100%;
    }

    &--name {
      width: 332px;
    }

    &--name, &--description {
      display: flex;
      position: relative;
      align-items: center;
      padding: 0;
      border: none;

      .input {
        z-index: 2;
        padding: 7px 20px 7px 48px;
        border: 1px solid transparent;
        font-size: 14px;
        &:focus-visible {
          border-color: $color-border-focus;
        }
      }
    }

    &--description {
      .input {
        padding: 7px 20px 7px 100px;
        text-overflow: ellipsis;
      }
    }

    &--fn, &--short_name, &--prices, &--emoji, &--fb-letters, &--location, &--nationality {
      padding: 0;
      border: none;

      .input {
        border: 1px solid transparent;
        color: $color-gray;
        color: $color-tundora;
        font-size: 14px;
        font-weight: 500;
        @extend %font-main-text;

        &:focus-visible {
          border-color: $color-border-focus;
        }
      }
    }

    &--fb-letters {
      .input {
        padding-left: 82px;
      }
    }

    &--short_name {
      .input {
        padding-left: 54px;
      }
    }

    &--emoji {
      .input {
        padding-left: 53px;
      }
    }

    &--emoji-mistress {
      .input {
        padding: 3px 10px;
      }
    }

    &--prices {
      .input {
        padding: 3px 10px;
        padding-left: 56px;
      }
    }

    &--location {
      .input {
        padding-left: 76px;
      }
    }

    &--nationality {
      .input {
        padding-left: 91px;
      }
    }

    &-title {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 8px;
      height: fit-content;
      margin: auto;
      z-index: 3;
      color: $color-manatee;
      @extend %font-main-text;
    }

    &--id {
      display: flex;
      position: relative;
      align-items: center;
      max-width: 105px;
      margin-right: 12px;
      padding: 0;
      border: transparent;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      transition: none;

      .input {
        padding: 4px 20px 4px 29px;
        border: none;
        background: $color-white;
        font-size: 14px;

        &:focus-visible {
          border-color: $color-border-focus;
        }
      }
    }

    &--is-contact-girl {
      margin-right: 0;
    }

    &--id-mistress {
      max-width: 130px;
      & .input {
        padding: 8px 20px 8px 45px;
      }
    }

    &--address {
      padding: 5px 35px 5px 5px;
      border: 1px solid $color-silver;
      border-radius: 6px;
      background-color: $color-white;

      & textarea {
        display: block;
        max-height: 166px;
        padding: 10px 5px 10px 10px;
        border: none;
        color: $color-tundora;
        font-size: 14px;
        line-height: 19px;
        font-family: Roboto;
        letter-spacing: 0.88px;
        resize: none;
        @include scrollBars;
      }
    }

    &:focus-visible {
      border-color: $color-border-focus;
    }
  }

  &__date-status {
    flex-grow: 1;
    color: $color-gun-powder;
    font-size: 10px;
    line-height: 12px;
    text-align: right;
    min-width: 103px;
    font-family: $font-family-main;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
  }

  &__form {
    width: 100%;
    max-width: 475px;
    padding: 0 13px 25px 0;
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 4px; 
    }

    &::-webkit-scrollbar-thumb {
      margin: 5px;
      border-radius: 3px;
      background: $color-silver;
    }
  }

  &__info {
    width: 100%;

    .react-tabs {
      display: flex;
      flex-direction: column;
      height: 100%;
      background-color: $color-white;

      &__tab {
        margin: 0 3px;
        padding: 6px 10px 8px;
        border-radius: 6px 6px 0 0;
        cursor: pointer;
        background-color: $color-mystic;
        transition: all linear 0.1s;
        font-weight: 600;
        @extend %font-main-text;

        &-list {
          display: flex;
          padding-bottom: 1px;
          background-color: $color-iceberg-light;
        }

        &-panel--selected {
          display: flex;
          flex-direction: column;
          height: 100%;
          overflow-y: auto;
          border-top: 1px solid $color-morning-glory;
          @include scrollBars;
          
          &::-webkit-scrollbar {
            width: 4px;
          }

          &::-webkit-scrollbar-thumb {
            background: $color-silver;
          }
        }

        &--selected {
          color: $color-white;
          font-weight: 500;
          background-color: $color-bermuda-dark;
        }
      }
    }
  }

  &__feedbacks-tab {
    height: calc(100% - 40px);
    padding: 12px 0 0 16px;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
  }

  &__content {
    display: flex;
    height: calc(100% - 104px);
    flex-direction: row;
    gap: 40px;
    // overflow-y: hidden;
  }

  .tags-field {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
    padding: 8px 4px;
    border: 1px solid $color-mystic;
    border-radius: 6px;
  
    &__input {
      display: block;
      flex-grow: 1;
      padding: 0 5px;
      border: none;
      outline: none;
      color: $color-tundora;
      font-size: 14px;
      font-family: Roboto;
      letter-spacing: 0.88px;
    }
  
    &__btn {
      display: flex;
      align-items: center;
      padding: 3px 6px;
      color: $color-gray;
    }
  }

  &__group {
    &--tels, &--mails  {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }

  &-lock {
    &__group {
      display: flex;
      align-items: center;
      margin: 20px 8px;
      line-height: 1.5;
      text-align: left;
      font-size: 12px;

      @extend %font-main-text;

      & .checkbox__indicator {
        width: 16px;
        height: 16px;
      }

      & .checkbox {
        align-self: center;
      }
  
      & .lock-form {
        &__name {
          max-width: 72px;
          margin-left: 6px;
          overflow: hidden;
          font-weight: normal;
          white-space: nowrap;
        }
  
        &__block-send-media-toggle-label {
          display: flex;
          cursor: pointer;
        }
  
        &__block-send-media-toggle-span {
          width: auto;
          margin-left: 8px;
          color: $color-black;
          font-size: 12px;
          text-transform: capitalize;
        }
      }
    }
  
    &__label {
      letter-spacing: 0;
    }
  }

  &__map-icon-wrap {
    position: absolute;
    top: calc(50% + 5px);
    right: 30px;
    color: $color-gray;
  }

  &__icon-action {
    color: $color-bondi-blue;
  }

  .adr-contact-ava {
    &__img-container {
      display: flex;
      border-radius: 50%;

      &:hover .adr-contact-ava__control-icons {
        background-color: rgba($color-black, 0.8);
      }

      &:hover .adr-contact-ava__icon {
        opacity: 1;
      }
    }

    &__img-wrap {
      width: $ava-size;
      height: $ava-size;
      border-radius: 50%;
    }

    &__img {
      width: $ava-size;
      height: $ava-size;
      border-radius: 50%;
      background-color: $color-white;
    }

    &__control-icons {
      display: flex;
      position: absolute;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
      width: $ava-size;
      height: $ava-size;
      border-radius: 50%;
      background-color: rgba($color-black, 0);
      transition: opacity 0.2s linear, background-color 0.2s linear;
      z-index: 5;
    }

    &__icon-wrapper {
      &--upload {
        padding: 3.5px;
      }

      &--delete {
        padding: 2.5px;
      }

      &--edit {
        padding: 5.5px;
      }
    }

    &__icon {
      opacity: 0;
      color: $color-white;
      transition: opacity 0.2s linear, background-color 0.2s linear;
      cursor: pointer;

      &--upload {
        font-size: 20px;
      }

      &--delete {
        width: 22px;
        font-size: 22px;
      }

      &--edit {
        font-size: 16px;
      }
    }

    &__image-input {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      outline: none;
      cursor: pointer;
    }

    .adr-book-img-cropper {
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 999;

      .bg-dark {
        animation: fadeIn 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
      }

      &__ava-cropper-wrap {
        position: relative;
        top: -60px;
        background-color: rgba(209, 209, 209, 1);
        animation: translateUp 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
      }

      &.close {
        .bg-dark {
          animation: fadeOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
        }

        .adr-book-img-cropper__ava-cropper-wrap {
          animation: translateDown 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
        }
      }

      &__ava-cropper {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 400px;
        height: 400px;
      }

      &__ava-cropper img {
        width: inherit;
        height: inherit;
        object-fit: unset;
        min-width: unset;
      }

      &__ava-cropper-section {
        position: relative;
        width: 100%;
        height: 100%;
      }

      &__ava-cropper-navigation-bar {
        width: 100%;
        background-color: $color-mine-shaft;
        height: 50px;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .ava-cropper-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 120px;
        height: 28px;
        margin: 0 5px;
        padding: 0;
        border-radius: 6px;
        color: $color-white;
        font-weight: bold;
        font-size: 14px;
        cursor: pointer;
        transition: 0.14s all;

        &:disabled {
          background-color: $color-alto;
          color: $color-silver;
          cursor: not-allowed;
        }

        &:hover {
          background-color: $color-silver;
        }
      }

      &__ava-cropper-btn-input-file {
        display: block;
        opacity: 0;
        visibility: hidden;
        width: 0;
        height: 0;
      }

      &__ava-cropper-btn-file-add {
        background-color: $color-azure-radiance;
      }

      &__ava-cropper-btn-file-crop {
        background-color: $color-persimmon;
      }

      &__ava-cropper-btn-file-submit {
        background-color: $color-caribian-green;
      }

      &__ava-cropper-options-bar {
        display: flex;
        position: absolute;
        bottom: 60px;
        align-items: center;
        width: calc(100% - 40px);
        margin: 0 20px;

        .toggle__wrap {
          margin: 0 0 0 10px;
          padding: 4px 10px;
          border-radius: 6px;
          background-color: rgba(0, 0, 0, 0.4);

          .toggle__title {
            color: $color-white;
          }
        }
      }

      @keyframes translateUp {
        0% {
          opacity: 0;
          transform: translateY(1000px);
        }

        100% {
          opacity: 1;
          transform: translateY(0px);
        }
      }

      @keyframes translateDown {
        0% {
          opacity: 1;
          transform: translateY(0px);
        }

        100% {
          opacity: 0;
          transform: translateY(1000px);
        }
      }
    }
  }

  &__checkbox-btn {
    display: flex;
    align-items: center;
    padding: 0;
  }

  &__checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    margin-right: 7px;
    background-color: white;
    border: 2px solid $color-silver;
    border-radius: 4px;

    &--active {
      background-color: $color-bondi-blue;
      border-color: $color-bondi-blue;
    }

  }

  &__ex-icon {
    color: $color-white;
  }

  &__select-wrapper {
    position: relative;
  }

  &__ex {
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 1px;
    color: $color-neon-carrot;
  }

  &__popup-spinner {
    display: flex;
    position: absolute;
    top: 0;
    right: 25px;
    bottom: 0;
    align-items: center;
  }
}

.adr-book-img-cropper {
  display: flex;
  z-index: 999;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  animation: fadeIn 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;

  &.close {
    animation: fadeOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }

  &__toggle-title {
    display: block;
    text-align: center;
  }
}

.adr-book-popup-wrapper { // this style is the same like in tooltip or dropdown, should be refactored later
  z-index: 120;
  position: relative;
  bottom: 0;
  left: 0;
  width: 370px;

  background-color: $color-white;
  color: $color-gray;
  box-shadow: $shadow-primary;
  padding: 10px;
  border-radius: 6px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0px 2px 0 0 $color-bondi-blue;
    border-radius: 6px;
  }
}


@keyframes fadeIn {
  0% {
    background: rgba(0, 0, 0, 0);
  }
  100% {
    background: rgba(0, 0, 0, 0.7);
  }
}
@keyframes fadeOut {
  0% {
    background: rgba(0, 0, 0, 0.7);
  }
  100% {
    background: rgba(0, 0, 0, 0);
  }
}
