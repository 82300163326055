.calendar-two-days {
  display: flex;
  gap: 33px;
  width: 100%;

  .calendar-timeline {
    width: 50%;
    min-width: 650px;
    flex-grow: 1;

    &__header {
      padding: 0;
    }

    &__main-filters {
      flex-wrap: wrap;

      .booking-filters-list,
      .calendar-timeline__agent-select {
        margin-top: 25px;
      }
    }

    &__counter-box {
      top: auto;
      bottom: 7px;
      transform: none;
    }
  }
}
