@import 'assets/scss/variables.scss';

$border-radius: 6px;

.booking-g-tab-item {
  display: flex;
  flex-direction: column;
  border-radius: $border-radius;
  height: 94px;
  font-size: 12px;
  transition: box-shadow .5s;

  &--deleted {
    opacity: 0.5;

    .booking-status1 {
      color: $color-red;
    }
  }

  &--chat-opened {
    box-shadow: 0px 0px 0px 6px $color-bermuda;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    background-color: $color-white;
    margin: 2px;
    border-radius: $border-radius;
    height: 20px;
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 8px;
    background-color: $color-white;
    border-radius: $border-radius;
    margin: 0 1px 0;
    height: 100%;
    padding: 6px 16px;
  }

  &__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    padding: 0 6px;
  }

  &__details {
    color: $color-bali-hai;
  }

  &__client, &__escort {
    white-space: pre;
  }
}
