@import 'assets/scss/variables';

.operators-chat-rooms {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  padding: 0 1px;

  &__btn {
    display: flex;
    padding: 5px;
    transition: opacity linear .05s;

    &:disabled {
      opacity: .4;
      cursor: default;
    }

    &--hidden {
      visibility: hidden;
    }
  }

  &__icon {
    width: 10px;
    height: 10px;
    color: $color-bondi-blue;

    &--left {
      transform: rotate(90deg);
    }

    &--right {
      transform: rotate(-90deg);
    }
  }


  &__list {
    gap: 10px;
    position: relative;
    display: flex;
    align-items: center;
    overflow-x: scroll;
    margin: -8px 3px 0;
    padding: 8px 0 0;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__tab {
    position: relative;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    padding: 2px 10px;
    border-radius: 6px;
    background-color: #fff;
    color: $color-tundora;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;

    &--active {
      background-color: $color-bondi-blue;
      color: #fff;
    }

    &[data-unread]:first-child {
      margin-left: 7px;
    }
  }

  &__unread-count {
    pointer-events: none;
    position: absolute;
    z-index: 10;
    top: -8px;
    left: -7px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: $color-froly;
    color: #fff;
  }
}