@import 'assets/scss/variables.scss';

.contact {
  --avatar-width: 64px;

  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: $color-mystic;
  border-radius: 5px;
  overflow: hidden;

  &--is-profile {
    cursor: pointer;
  }

  &__avatar-container {
    height: 100%;
    width: var(--avatar-width);
    border-radius: 5px;
    overflow: hidden;
    flex-shrink: 0;
  }

  &__info-container {
    width: calc(100% - var(--avatar-width));
    flex-grow: 1;
    padding: 7px 7px 7px 15px;
    height: 100%;
  }

  &__name-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 12px;
    color: $color-azure-radiance;
    width: 100%;
  }
  
  &__name {
    width: 90%;
    height: 18px;
    overflow: hidden;
    white-space: pre;
    text-overflow: ellipsis;
    padding-top: 2px;
  }

  &__services {
    font-size: 12px;
    line-height: 16px;
    height: 32px;
    color: $color-black;
    margin-top: 7px;
    
    // &::-webkit-scrollbar {
    //   width: 4px;
    // }
  
    // &::-webkit-scrollbar-track {
    //   background: transparent;
    // }
    
    // &::-webkit-scrollbar-thumb {
    //   background: $color-black;
    //   border-radius: 3px;
    //   margin: 5px;
    // }
  }

  &__ex {
    font-size: 13px;
    background: transparent;
    margin-right: 2px;
    color: #f27e3f;
    font-weight: bold;
  }

  &__remove {
    display: flex;
    height: 15px;
    width: 15px;
    background-color: $color-persimmon;
    color: $color-white;
    border-radius: 50%;

    & > svg {
      margin: auto;
      width: 50%;
      height: 50%;
    }
  }
}
