@import 'assets/scss/variables.scss';

.confirm-logout-modal {
  background-color: $color-white;
  padding: 41px;
  border-radius: 6px;
  box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.12);

  &__title {
    font-size: 23px;
    color: $color-gray;
    font-weight: bold;
    text-align: start;
  }

  &__subtitle {
    font-size: 17px;
    color: $color-gray;
    text-align: start;
    margin-top: 17px;
  }

  &__btns {
    margin-top: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 40px;
  }

  &__btn {
    &--logout {
        min-width: 160px;
        min-height: 50px;
        padding: 15px 20px;
        border-radius: 6px;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 1px;
        color: $color-white;
        background: $color-bondi-blue;
        box-shadow: 0 12px 12px 0 rgba(0,0,0,0.12);
    }

    &--cancel {
        color: $color-white;
        font-weight: 600;
        background-color: $color-silver;
        padding: 5px 21px;;
        border-radius: 4px;
        box-shadow: 0 12px 12px 0 rgba(0,0,0,0.12);
    }
  }
}