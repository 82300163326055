/* roboto-100 - latin_cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: local('Roboto Thin'), local('Roboto-Thin'),
       url('../fonts/Roboto/roboto-v29-latin_cyrillic-100.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/Roboto/roboto-v29-latin_cyrillic-100.woff') format('woff'), /* Modern Browsers */
       url('../fonts/Roboto/roboto-v29-latin_cyrillic-100.ttf') format('truetype'); /* Safari, Android, iOS */
}
/* roboto-300 - latin_cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'), local('Roboto-Light'),
       url('../fonts/Roboto/roboto-v29-latin_cyrillic-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/Roboto/roboto-v29-latin_cyrillic-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/Roboto/roboto-v29-latin_cyrillic-300.ttf') format('truetype'); /* Safari, Android, iOS */
}
/* roboto-regular - latin_cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'),
       url('../fonts/Roboto/roboto-v29-latin_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/Roboto/roboto-v29-latin_cyrillic-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/Roboto/roboto-v29-latin_cyrillic-regular.ttf') format('truetype'); /* Safari, Android, iOS */
}
/* roboto-italic - latin_cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: local('Roboto Italic'), local('Roboto-Italic'),
       url('../fonts/Roboto/roboto-v29-latin_cyrillic-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/Roboto/roboto-v29-latin_cyrillic-italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/Roboto/roboto-v29-latin_cyrillic-italic.ttf') format('truetype'); /* Safari, Android, iOS */
}
/* roboto-500 - latin_cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'),
       url('../fonts/Roboto/roboto-v29-latin_cyrillic-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/Roboto/roboto-v29-latin_cyrillic-500.woff') format('woff'), /* Modern Browsers */
       url('../fonts/Roboto/roboto-v29-latin_cyrillic-500.ttf') format('truetype'); /* Safari, Android, iOS */
}
/* roboto-500italic - latin_cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  src: local('Roboto Medium Italic'), local('Roboto-MediumItalic'),
       url('../fonts/Roboto/roboto-v29-latin_cyrillic-500italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/Roboto/roboto-v29-latin_cyrillic-500italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/Roboto/roboto-v29-latin_cyrillic-500italic.ttf') format('truetype'); /* Safari, Android, iOS */
}
/* roboto-700 - latin_cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Bold'), local('Roboto-Bold'),
       url('../fonts/Roboto/roboto-v29-latin_cyrillic-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/Roboto/roboto-v29-latin_cyrillic-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/Roboto/roboto-v29-latin_cyrillic-700.ttf') format('truetype'); /* Safari, Android, iOS */
}

// New fonts for new design, old fonts needs to be deleted after redisign

@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 100;
  src: url('../fonts/Manrope/Manrope-Thin.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/Manrope/Manrope-Thin.woff') format('woff'), /* Modern Browsers */
       url('../fonts/Manrope/Manrope-Thin.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/Manrope/Manrope-Light.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/Manrope/Manrope-Light.woff') format('woff'), /* Modern Browsers */
      url('../fonts/Manrope/Manrope-Light.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Manrope/Manrope-Medium.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/Manrope/Manrope-Medium.woff') format('woff'), /* Modern Browsers */
      url('../fonts/Manrope/Manrope-Medium.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/Manrope/Manrope-Semibold.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/Manrope/Manrope-Semibold.woff') format('woff'), /* Modern Browsers */
      url('../fonts/Manrope/Manrope-Semibold.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/Manrope/Manrope-Bold.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/Manrope/Manrope-Bold.woff') format('woff'), /* Modern Browsers */
      url('../fonts/Manrope/Manrope-Bold.ttf') format('truetype'); /* Safari, Android, iOS */
}
