%position-right {
  position: absolute;
  right: 26px;
  top: 50%;
  transform: translate(50%, -50%);
}

.gallery-search {
  position: relative;
  grid-row: row-begin / row-end;
  grid-column: col-center / col-end;
  align-self: center;
  display: block;
}

.gallery-search__text-input {
  width: 100%;
  padding: 19px 48px 18px 15px;

  border: 1px solid transparent;
  border-radius: 6px;
  outline: none;

  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0.875px;
  color: #404040;

  &:focus {
    border-color: #00A6AD;
  }

  &::placeholder {
    opacity: 1;
    color: #BFBFBF;
  }
}

.gallery-search__clear-button {
  @extend %position-right;

  padding: 5px;
  display: inline-grid;
  color: #FF5E57;

  svg {
    width: 13px;
    height: 13px;
  }
}

.gallery-search__search-icon {
  @extend %position-right;

  width: 13px;
  height: 13px;
  pointer-events: none;
  color: #00A6AD;
}
