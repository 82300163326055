@import 'assets/scss/variables';

.dropdown-menu {
  font-family: $font-family-main;
  font-size: $font-size-l;
  background-color: $primary-background-color;
  padding: 24px 14px;
  border-radius: $radius-xl;
  box-shadow: $default-floating-box-shadow;

  &__item {
    display: flex;
    flex-wrap: wrap;
    width: 264px;
    height: 48px;
    color: rgba($darker-tertiary-background-color, 1);
    align-items: center;
    border-radius: $radius-m;
    text-align: left;
    transition: background-color $default-animation;

    &:hover {
      color: var(--selected-text-color);
      background-color: var(--selected-color);
    }
  }

  &__icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 16px;
  }
}
