@import 'assets/scss/variables';

.booking-girl-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
	height: 78px;
  background-color: $color-athens-gray;
  border-radius: 6px;
  padding: 10px 13px 6px;
  font-size: 12px;

  &--clickable {
    cursor: pointer;
  }

  &--deleted {
    opacity: 0.5;

    .booking-status1 {
      color: $color-red;
    }
  }

  &__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 40px;
  }

  &__client {
    flex-shrink: 1;
    width: 200px;
    max-height: 100%;

    & .lazy-load__container {
      width: 32px;
    }

    & img {
      height: 32px;
      width: 32px;
    }
    
    & p {
      display: flex;
      max-height: 75%;
    }
  }

  &__graphs {
    display: flex;
    flex-direction: column;
    height: 40px;
    width: 100px;
    gap: 6px;
  }

  &__graphs-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 8px;
    align-items: center;
  }

  &__date {
    color: $color-bali-hai
  }

  &__markers {
    justify-content: flex-end;

    & div {
      scale: 0.85;
    }
  }
}
