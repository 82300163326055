@import 'assets/scss/variables';

.calendar-split {
  --header-gap: 15px;

  display: flex;
  justify-content: center;
  gap: 30px;
  width: 100%;

  &__header {
    margin-left: 60px;
    gap: var(--header-gap);
    margin-bottom: 29px;
  }

  &__filters {
    gap: var(--header-gap);
  }

  &__select-filter {
    width: 180px;
  }

  &__calendar-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__calendar {
    display: flex;
    flex-direction: column;
    width: 490px;

    &-controller {
      display: flex;
      align-items: center;
      align-self: flex-end;
      justify-content: space-between;
      width: 200px;
      margin-bottom: 30px;
      font-size: 18px;
      color: $color-bondi-blue;

      &-icon {
        width: 13px;
        height: 13px;
        color: $color-silver;
        cursor: pointer;

        &--next {
          transform: rotate(180deg);
        }
      }
    }

    &-weekdays {
      display: flex;
      align-items: center;
      width: 490px;
      height: 16px;
      font-size: 12px;
      color: $color-bali-hai;

      &-item {
        display: flex;
        width: 70px;
        padding-left: 2px;
        text-transform: uppercase;
      }
    }

    &-days-list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 12px;
      width: 490px;
      height: 500px;
    }

    &-day {
      display: flex;
      flex-direction: column;
      width: 70px;
      height: 100px;
      border-radius: 6px;
      border: 1px solid $color-mystic;
      cursor: pointer;

      &--active {
        .calendar-split__calendar-day-number {
          background-color: $color-bondi-blue;
          font-weight: bold;
          color: $color-white;
        }
      }

      &--hidden {
        .calendar-split__calendar-day-counters {
          opacity: 0.3;
        }

        .calendar-split__calendar-day-number {
          opacity: 0.6;
        }
      }

      &-number {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        border-radius: 6px;
        font-size: 12px;
        color: $color-tundora;
        background-color: $color-mystic;
      }

      &-counters {
        display: flex;
        flex-direction: column;
        margin-top: 5px;
      }

      &-counter {
        display: flex;
        align-items: center;
        padding: 0 6px;
        font-size: 10px;
        font-style: italic;
        letter-spacing: 0.92px;
      }

      &-dot {
        width: 6px;
        height: 6px;
        margin-right: 5px;
        border-radius: 50%;
      }
    }
  }

  &__counters-wrap {
    display: flex;
    justify-content: center;
    width: 491px;
  }

  &__counters {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 370px;
    margin-top: 65px;
  }

  &__counter {
    position: relative;
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 10px;
    border: 1px solid;
    border-radius: 6px;
    font-size: 12px;
    letter-spacing: 1px;
    color: $color-tundora;

    &--main {
      width: 370px;
      margin-bottom: 9px;
    }

    &--additional {
      width: 180px;
    }

    &-dot {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      width: 6px;
      height: 6px;
      border-radius: 50%;
    }
  }

  &__timeline {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    &-header {
      display: flex;
      padding: 0 22px;
      margin-bottom: 18px;
    }
  }

  &__tooltip-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px;
    padding-top: 3px;
    line-height: 16px;

    & > svg {
      height: 20px;
      width: 20px;
      margin-bottom: 6px;
    }
  }
}
