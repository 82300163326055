@import 'assets/scss/variables';

.sidebar-contacts-filters {
  position: relative;

  &__main-filters-wrap {
    display: flex;
    flex-direction: column;
    gap: 19px;
    margin-bottom: 24px;
  }

  &__filters-wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 8px;
    height: 24px;
  }

  &__item {
    position: relative;
    width: 32px;
    height: 100%;

    &>button {
      width: 100%;
    }
  }

  &__input-wrap {
    position: relative;
    width: 100%;
    height: 32px;
  }

  &__input {
    width: 100%;
    height: 100%;
    font-family: $font-family-main;
    ;
  }

  &__left-counter,
  &__right-counter {
    position: absolute;
    z-index: $underlying-elements-z-index;
  }

  &__left-counter {
    left: 0;
    bottom: -12px;
  }

  &__right-counter {
    right: 0;
    bottom: -12px;
  }

  &__select-wrapper {
    height: 30px;
    margin: 10px 0;
  }
}

.sidebar-filter-select {
  height: 100%;
  width: 100%;
}
