@import 'assets/scss/variables';

.sending-message-form {
  padding: 0 10px;
  min-height: 80px;
  align-items: center;

  &--error {
    border-color: $color-froly;

    & textarea::placeholder {
      color: $color-froly;
    }
  }

  &--disabled {
    filter: blur(1px);
    cursor: not-allowed;
  }

  &__input {
    max-height: 118px;
    margin: 15px 0;
  }
  

  &::placeholder {
    line-height: 50px;
    font: 14px sans-serif;
    color: $color-silver;
    opacity: 1;
    letter-spacing: 0.88px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  
  &__send-btn {
    display: flex;
    padding: 2px;
    color: $color-gray;

    & svg {
      width: 12px;
      height: 12p x;
    }
  }
}
