@import 'assets/scss/variables';
@import 'assets/scss/functions.scss';

.select-control {
  @include ModalWindow(
    fit-content,
    330px,
    column,
    (
      header: (
        padding: 0 26px
      ),
      main-container: (
        padding: 26px,
      )
    )
  );

  position: absolute;
  max-height: 540px;
  z-index: 150;
  box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.12);

  &__header {
    height: 54px;
    min-height: 54px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
  }

  &__btn--cancel {
    color: $color-persimmon;
  }

  &__btn--submit {
    display: flex;
    align-items: center;
    margin-left: 22px;
    color: $color-bondi-blue;

    &::after {
      content: "";
      display: block;
      mask-image: url(../../assets/icons/check.svg);
      mask-size: 20px;
      background-color: $color-bondi-blue;
      width: 20px;
      height: 20px;
      margin-left: 8px;
    }
  }
  
  &__add-option-btn {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 0;
    width: fit-content;
    margin-top: 32px;
    
    &-icon {
      color: $color-bondi-blue;
    }
  }
  
  &__main-container {
    @include scrollBars();
    overflow-y: auto;
    padding-top: 26px;
  }
  
  &__option-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__input {
    padding: 11px 10px;
    border: 1px solid $color-wild-sand;
    border-radius: 6px;
    color: $color-black;
    font-weight: 500;

    &:disabled {
      background-color: inherit;
    }
  }

}
