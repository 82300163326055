.lazy-load {
  &__container {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    user-select: none;

    & img {
      transition: filter 0.5s ease-in-out;
    }

    &--collapsing:not(&--selected) {
      &::after {
        content: '';
        fill: green;
        position: absolute;
        height: 32px;
        width: 32px;
        mask-image: url('/assets/icons/check-circle.svg');
        mask-size: cover;
        background-color: green;
        bottom: 32px;
      }
    }

    &-spinner {
      position: absolute;
    }
  }

  &__image {
    display: block;

    &--hidden {
      display: none;
    }
  }

  &__spinner {
    width: 100%;
    height: 100%;
  }
}
