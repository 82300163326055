@import 'assets/scss/variables.scss';

.contact-form-via-item {
  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    
    &-checkbox {
      display: block;
      width: 15px;
      height: 15px;
      border: 2px solid $color-manatee;
      background-color: $color-white;
      border-radius: 6px;

      &--disabled {
        opacity: 0.5;
      }
    }

    &-title {
      display: flex;
      padding-left: 6px;
      &--checked {
        font-weight: bold;
      }
    }
  }

  &__popup {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    width: 160px;
    padding: 12px 15px;
    background-color: $color-white;
    border-radius: 6px;
    box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.12);
    z-index: 1;
    gap: 5px;

    &-list-item {
      display: flex;
      gap: 5px;
      cursor: pointer;
    }
  }

  &__icon {
    &-check {
      width: 15px;
      height: 15px;
      color: $color-caribbean-green;
      background-color: transparent;
    }

    &-chevron {
      width: 15px;
      height: 15px;
      color: $color-bondi-blue;
      transform: rotate(180deg);
    }
  }

  &__option-label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: auto;
    &--email {
      width: 100%;
      text-align: center;
    }
  }

  &--is-mistress {
    z-index: 3;
  }
  
  .react-select {
    &__menu {
      left: 2px;
      width: 100%;
      min-width: 192px;
      border-radius: 6px;
    }

    &__control {
      min-height: auto;
    }

    &__menu-list {
      border-bottom: 2px solid $color-bondi-blue;
      border-radius: 6px;
    }

    &__dropdown-indicator {
      padding: 0;
    }

    &__option {
      display: flex;
      justify-content: center;
      color: $color-gray;
      &--is-selected {
        color: $color-azure-radiance;
      }
    }
  }
}
