@import 'assets/scss/variables';

.booking-item-new {
  cursor: pointer;
  
  &--to-sort {
    --status-color: #B96904;
  }

  &--to-do {
    --status-color: #F03D3D;
  }

  &--confirmed {
    --status-color: #058F43;
  }

  &--done {
    --status-color: #0092F2;
  }

  &--canceled {
    --status-color: #0C201F;
  }
}

[data-isanimate="true"] {
  animation: shakeMe 1s linear;
}

@keyframes shakeMe {
  0%,
  20%,
  40%,
  60%,
  80% {
    transform: translateX(-1px);
  }

  30%,
  50%,
  70%,
  10%,
  90% {
    transform: translateX(1px);
  }

  100% {
    transform: translateX(0px);
  }
}
