.booking-confirmation-status {
  text-transform: capitalize;
  color: var(--status-color);
  cursor: pointer;

  &-dot {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
}

.booking-table-status {
  height: 50px;
  width: 50px;
}
