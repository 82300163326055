@import "assets/scss/variables";

.mail-attachment {
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  &:not(:last-child) {
    margin-right: 15px;
  }

  & .lazy-load__container {
    width: 32px;
  }
  & svg {
    width: 32px;
    height: 32px;
  }

  &__info {
    position: relative;
    overflow: hidden;
    display: flex;
    background-color: $color-black-squeeze-light;
    border-radius: 6px;
    align-items: center;
    padding: 9px 10px 9px 5px;

    .mail-attachment--large & {
      padding: 10px;

      &::after {
        top: 10px;
        bottom: 10px;
      }
    }
  }

  &__preview {
    display: flex;
    width: 32px;
    height: 32px;
    border-radius: 6px;
  }

  &__text {
    margin-left: 8px;
    overflow: hidden;
    display: flex;

    .mail-attachment--exist & {
      display: block;
    }
  }

  &__name {
    font-size: 12px;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__size {
    flex-shrink: 0;
    margin: 0 0 0 8px;
    font-size: 14px;
    opacity: .6;

    .mail-attachment--exist & {
      margin-left: 0;
      font-size: 11px;
    }
  }

  &__spinner {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
  }

  &__remove {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    padding-left: 10px;
    color: $color-tundora;
    
    svg {
      width: 12px;
      height: 12px;
      padding: 2px;
    }

    &:hover {
      color: $color-silver;
    }

    &:disabled {
      opacity: .5;
      cursor: default;
      color: $color-silver;
    }
  }

  &__download {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    font-size: 15px;
    color: #404040;

    .mail-attachment--large & {
      width: 50px;
      font-size: 20px;
    }
  }
}
