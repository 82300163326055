@import 'assets/scss/variables';

.widget-btn {
  position: fixed;
  bottom: 70px;
  right: 85px;
  width: 60px;
  height: 60px;
  z-index: $floating-buttons-z-index;
  
  & .content {
    background-color: rgba($secondary-background-color, $selected-opacity);
  }

  :where(&__top-counter, &__bottom-counter) {
    position: absolute;
  }

  &__top-counter {
    top: 4px;
    right: 4px;
  }

  &__bottom-counter {
    bottom: 4px;
    right: 4px;
  }
}
