@import 'assets/scss/variables';

.booking-lined-item {
  position: relative;
  display: flex;
  align-items: center;
  height: 41px;
  background-color: $color-white;
  border-top: 1px solid $color-mystic;
  font-size: 12px;
  cursor: pointer;
  width: 100%;

  &--deleted {
    opacity: 0.5;

    .booking-status1 {
      color: $color-red;
    }
  }

  &__color-line {
    height: 100%;
    width: 3px;
    border-radius: 6px;
    position: absolute;
    left: 0;
  }

  &__column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding-right: 2px;
    gap: 3px;

    &--escort {
      width: 10%;
      margin-left: 8px;

      & > div > div {
        gap: 3px;
      }
    }

    &--duo {
      width: 5%;
    }

    &--details {
      width: 14%;
    }

    &--requirements {
      width: 7%;
    }

    &--status {
      width: 20%;
    }

    &--client {
      width: 24%;
    }

    &--rest {
      width: 20%;
      align-items: end;
    }
  }

  &__client {
		height: 100%;

    & p {
      max-height: 70%;
    }

		& .lazy-load__container {
      width: 32px;
    };

    & img {
      height: 32px;
      width: 32px;
    }
  }

  &__escort {
    width: 100%;
  }

  &__date {
    color: $color-bali-hai;
  }

  &__column-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }

  &__requirements {
    justify-content: center;
    width: 100%;
  }
}
