@import 'assets/scss/variables';

.mail-chat {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 10px;
  border-radius: 5px;
  background-color: $color-white;

  &--centered {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-silver;
  }

  &--not-found {
    color: $color-silver;

    .mail-chat__main {
      justify-content: center;
      align-items: center;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__sort {
    display: flex;
    align-items: center;
  }

  &__return-btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 7px;
    color: $color-gray;
    font-size: 12px;
    font-weight: 700;

    &-icon {
      margin-right: 7px;
      width: 18px;
      height: 17px;
      transform: rotate(-90deg);
      fill: $color-bondi-blue;
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 12px 45px 15px 0;
  }

  &__item {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    padding-right: 15px;

    &:first-child {
      margin-top: 0;
    }
  }
}