@import 'assets/scss/variables';
@import 'assets/scss/functions.scss';

.pinned-items {
  width: 100%;
  max-height: 100%;
  user-select: none;

  &__bookings-wrapper {
    display: flex;
    flex-direction: column;
    padding: 0;
    background-color: $color-carousel-pink;
    border-radius: 6px;
    border: 1px solid $color-sunset-orange;
  }

  &__bookings {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    //align-items: center;
    background-color: $color-carousel-pink;
    border-radius: 6px;

    &--open {
      //padding: 10px 5px;
      //
      //svg {
      //  transform: rotate(180deg)
      //}

      .pinned-booking__text {
        margin: 10px 5px;

        &::after {
          transform: rotate(180deg);
        }
      }

      .pinned-items__list-container {
        display: block;
      }
    }
  }

  &__list-container {
    display: none;
    height: 100%;
  }
}

.pinned-msgs {
  @include Pinned($color-iceberg, $color-bondi-blue);

  &--open {
    flex-direction: column;
    padding: 0 0 12px 0;

    & .pinned-msgs__content {
      flex-direction: column;
      align-items: baseline;
    }

    & .pinned-msgs__text {
      display: block;
      padding-right: 20px;
      padding-bottom: 6px;
    }

    & .pinned-msgs__text--voice {
      flex-direction: row;
    }

    & .pinned-msgs-attachments__image-wrapper {
      min-width: 48px;
      width: 48px;
      height: 48px;
    }
  }

  &__rolled-up-item {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
  }

  &__message {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }

  &__avatar-wrapper {
    margin: 2px 5px 2px 0;
    min-width: 36px;
    height: 36px;
  }

  &__avatar {
    border-radius: 50%;
    min-width: 36px;
    width: 36px;
    height: 36px;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;

    &--voice-msg {
      flex-direction: column;
      align-items: baseline;

      & .pinned-msgs__text {
        -webkit-line-clamp: 1;
      }
    }
  }

  &__attachments {
    height: 38px;
    width: 64px;
  }

  &__text {
    font-size: 12px;
    color: $color-text-primary;
    line-height: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &__text-wrapper {
    display: flex;
    align-items: center;
  }

  &__btn-container {
    display: flex;
    justify-content: center;
    margin-left: 15px;

    &--closed {
      align-items: center;
    }
  }

  &__btn-delete {
    display: flex;
    padding: 6px;
    position: absolute;
    top: 12px;
    right: 8px;
  }

  &__btn-icon {
    display: flex;
    justify-content: center;
    padding: 6px 6px;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }

  &__icon-unpin {
    width: 12px;
    height: 12px;
    fill: $color-persimmon;
    transform: rotate(45deg);
  }

  &__icon-pin {
    width: 9px;
    height: 15px;
    color: $color-bondi-blue;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    height: 36px;
  }

  &__title {
    color: $color-gray;
    font-size: 14px;
    letter-spacing: normal;
  }

  &__btn-close {
    padding: 0;
    color: $color-azure-radiance;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: normal;
    text-decoration: underline;
  }

  &__item {
    position: relative;
    display: flex;
    justify-content: space-between;
    background-color: rgba($color-white, 0.3);
    margin-bottom: 12px;
    padding: 5px 10px;
  }

  &__list {
    overflow-y: scroll;
    @include scrollBars;
  }

  &__rolled-up {
    display: flex;
  }

  &__indicator {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 5px;

    &-round {
      width: 6px;
      height: 6px;
      background-color: $color-white;
      border-radius: 50%;

      &:not(:last-child) {
        margin-bottom: 5px;
      }

      &--active {
        background-color: $color-bondi-blue;
      }
    }
  }

  & .interaction__link {
    cursor: default;
    display: inline-flex;
    font-size: 12px;
    line-height: 16px;
    text-decoration: none;
    margin: 0;
  }
}

.pinned-booking {
  @include Pinned($color-misty-rose, $color-sunset-orange);

  &--prebooking {
    background-color: $color-carousel-pink;
    border-color: #f77fbe;
  }


  &--prebooking &__icon {
    color: #f77fbe;
  }

  &__content-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__diva-inform {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__content {
    height: min-content;
  }

  &__girl-name {
    font-weight: bold;
    color: $color-crystal-blue;
  }

  &__details {
    text-transform: capitalize;
  }

  &__icon-container {
    margin-right: 10px;
  }

  &__text {
    position: relative;
    font-size: 14px;
    color: $color-gray;
    margin: 5px;
    list-style: none;

    &::after {
      content: "";
      display: block;
      position: absolute;
      right: 2px;
      top: 0;
      height: 16px;
      width: 16px;
      mask-image: url("../../../../../assets/icons/chevron.svg");
      background-color: $color-bondi-blue;
    }
  }

  //[open] &__text {
  //  margin: 10px 5px;
  //
  //  &::after {
  //    transform: rotate(180deg);
  //  }
  //}
}

.pinned-msgs-attachments {
  display: grid;
  grid-template-columns: 36px;
  grid-template-rows: 36px;

  &--open {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 5px;
  }

  &--modopen1 {
    grid-template-columns: 1fr;
  }

  & .video-thumbnail {
    border-radius: 6px;
  }

  & .video-thumbnail__icon {
    border-color: $color-bermuda;
    width: 20px;
    height: 20px;
  }

  & .video-thumbnail__icon svg {
    fill: $color-bermuda;
    width: 10px;
    height: 10px;
  }

  & .video-thumbnail__duration {
    visibility: hidden;
  }

  &--mod2 {
    grid-template-columns: 24px 24px;
    grid-template-rows: 24px;
    gap: 5px;
  }

  &--mod4 {
    grid-template-columns: 18px 18px;
    grid-template-rows: 18px 18px;
    gap: 2px 5px;
  }

  &--mod6 {
    grid-template-columns: 18px 18px 18px;
    grid-template-rows: 18px 18px;
    gap: 2px 5px;

    & .video-thumbnail__icon {
      width: 15px;
      height: 15px;
    }
  }

  &__voicemail {
    display: flex;
  }

  &__voicemail-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $color-white;
    border-radius: 6px;
    padding: 3px 6px;
  }

  &__voicemail-text {
    display: flex;
    text-transform: capitalize;
    font-size: 11px;
    color: $color-black;
    margin-right: 11px;
  }

  &__voicemail-time {
    padding: 0 2px;
  }

  &__voicemail-icon {
    fill: $color-neon-carrot;
    width: 16px;
    height: 14px;
  }

  &__item {
    display: flex;
    align-items: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
  }

  &__image-wrapper {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    overflow: hidden;
  }

  &__image {
    border-radius: 6px;
    overflow: hidden;
  }

  &__image-name {
    margin-left: 15px;
    color: $color-azure-radiance;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
