.contact-form {
  border: 1px solid #eee;
  box-shadow: 0 2px 2px #ccc;
  background-color: #fff;
  padding: 10px;
  width: 700px;
  text-align: left;
  max-height: 90vh;
  overflow-y: scroll;

  &__lock {
    height: 33px;
    width: 33px;
    position: relative;
    overflow: hidden;
    margin-left: 100px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__title {
    margin: 10px 0;
  }
  &__img {
    width: 150px;
    height: 150px;
    margin-top: 10px;
    overflow: hidden;
  }
  &__label {
    font-size: 12px;
    white-space: nowrap;
    flex-basis: 80px;
  }
  &__group {
    margin-bottom: 14px;
    position: relative;
    display: flex;
    align-items: center;

    &--unflex {
    }
  }
  &__group-wrap {
    padding-left: 80px;
  }
  &__input {
    // width: 100%;
    flex-grow: 1;
    padding: 7px 12px;
    font-size: 12px;
    color: #555;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  }
  &__btn {
    color: #fff;
    background-color: #5bc0de;
    border-color: #46b8da;
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    border: 1px solid transparent;
    border-radius: 4px;
    cursor: pointer;
    outline: none;

    &--pin {
      margin: 0 10px;
    }

    &--lg {
      width: 100%;
      padding: 10px 12px;
    }
    &--block {
      display: block;
      margin-left: 80px;
      margin-bottom: 10px;
    }
    &--del {
      -position: relative;
      background: none;
      border: none;
      cursor: pointer;
      padding: 10px;

      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 8px;
        height: 15px;
        width: 2px;
        background-color: red;
      }
      &::before {
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(-45deg);
      }
    }
    &:disabled {
      cursor: not-allowed;
    }
  }
}

.full-mode {
  &__wrap {
    height: calc(100vh - 100px);
    display: flex;
  }

  &__card-wrap {
    width: 400px;
  }

  &__chat-wrap {
    display: flex;
    flex-direction: column;
    margin-left: 40px;
    height: 100%;

    .chat {
      flex-grow: 1;
      min-height: 300px;
      width: 652px;

      .spinner-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }

      .chat__wrapper {
        height: calc(100% - 44px);
        flex-grow: 1;
      }
    }
  }
}

.agent-select {
  flex-grow: 1;
  font-size: 12px;

  &__menu {
    max-height: 180px;
    overflow: hidden;
  }
  &__menu-list {
    height: 100%;
  }
}
