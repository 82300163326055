@import 'assets/scss/variables';
@import 'assets/scss/functions';

.photo-gallery {
  display: flex;
  flex-direction: column;
  width: calc(100vw - 260px);
  max-width: 1370px;
  height: 836px;

  &__header {
    position: relative;
    display: flex;
    height: 46px;
    align-items: center;
    color: $color-white;
    margin-bottom: 40px;
    font-size: 14px;
    letter-spacing: 0.88px;
    justify-content: center;
  }

  &__photos-count {
    position: absolute;
    left: 0;
  }

  &__esc-btn {
    position: absolute;
    right: 0;
  }

  &__btn-sections-wrapper {
    display: flex;
    align-items: center;
    max-width: 1050px;
    width: 100%;
    justify-content: space-between;
  }

  &__btn-section {
    display: flex;
    position: relative;

    &--left {
      margin-left: 45px;
      align-items: flex-end;
    }

    &--right {
      gap: 20px;
    }

    &-substrate {
      position: absolute;
      bottom: -2px;
      width: 151%;
      height: 1px;
      transform: translateX(-18%);
      background: linear-gradient(to right, rgba(255, 255, 255, 30%), rgb(255, 255, 255), rgba(255, 255, 255, 30%));
    }
  }

  &__btn {
    height: 50px;

    & svg {
      transform: rotate(90deg);
      width: 14px;
      height: 14px;
    }

    &--select {
      width: 231px;
    }

    &--send {
      width: 313px;
    }

    &--cancel {
      width: 130px;

      & svg {
        color: $color-persimmon;
      }
    }
  }

  &__toggle-btn {
    @include Tab;

    width: 76px;
    color: $color-gray;

    &--selected {
      padding-bottom: 9px;
    }

    & svg {
      transform: translateX(5px);
    }
  }

  &__esc-btn {
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    padding: 14px 0 14px 14px;
    color: $color-white;
    font-size: 14px;
    letter-spacing: 0.88px;

    &-icon {
      width: 13px;
      height: 13px;
      margin-right: 10px;
    }
  }

  &__content-wrapper {
    display: flex;
    position: relative;
    justify-content: center;
    color: $color-white;
  }

  &:has(&__image) {
    &::after {
      content: '';
      display: block;
      fill: green;
      position: absolute;
      height: 32px;
      width: 32px;
      mask-image: url('/assets/icons/check-circle.svg');
      mask-size: cover;
      background-color: green;
      bottom: 32px;
      opacity: 0;
      transition: opacity 0.5s ease-in-out;
    }

  }

  &__no-items,
  &__pending {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-white;
  }

  &__media-container {
    display: flex;
    align-items: center;
    overflow: hidden;
  }

  &__carousel {
    overflow: hidden;
    height: 750px;
    width: 1050px;
    animation: fade 0.3s;

    &-inner {
      display: flex;
      white-space: nowrap;
      gap: 30px;
      transition: transform 0.3s;
      height: 100%;
    }

    &-item {
      display: grid;
      grid-template-columns: repeat(4, 240px);
      grid-template-rows: repeat(2, 360px);
      gap: 30px;
      height: 100%;

      & .lazy-load__container {
        &::after {
          content: '';
          fill: green;
          position: absolute;
          height: 32px;
          width: 32px;
          mask-image: url('/assets/icons/check-circle.svg');
          mask-size: cover;
          background-color: green;
          bottom: 32px;
          opacity: 0;
          transition: opacity 0.5s ease-in-out;
        }

        &--selected {
          &::after {
            opacity: 1;
          }

          & img {
            filter: brightness(0.4);
          }
        }
      }
    }

    &-controller {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 50%;
      transform: translateY(-10%);
      padding: 25px;
      color: $color-white;
      cursor: pointer;

      &--prev {
        left: 25px;
      }

      &--next {
        right: 25px;
      }

      &:disabled {
        opacity: 0.5;
        cursor: default;
      }
    }

    &-controller-icon {
      width: 53px;
      height: 53px;

      &--prev {
        transform: rotate(90deg);
      }

      &--next {
        transform: rotate(-90deg);
      }
    }
  }
}

@keyframes fade {
  0% {
    opacity: 0.3;
  }

  100% {
    opacity: 1;
  }
}
