@import 'assets/scss/variables';

.prebookings-list {
  display: flex;
  flex-direction: column;
  width: 660px;
  height: 540px;
  border-radius: 6px;
  background-color: $color-mystic;

  &__booking-details {
    &-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 5px;
    }

    &-title {
      margin-right: 8px;
      color: #8C97B2;
      font-size: 12px;
      line-height: 27px;
      letter-spacing: 0;
      max-width: 345px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__toggle-wrap {
    display: flex;
    align-items: center;
    color: #8C97B2;
    font-size: 12px;
    letter-spacing: 0;
    gap: 5px;

    &  .toggle-switch {
        width: 48px;
        height: 20px;

        &__slider {
          background-color: #808080;
          transition: none;
        }

        &__slider-inner {
          right: 29px;
          width: 16px;
          height: 16px;
          background-color: #01DF85;
        }

        &__checkbox:checked {
          & + .toggle-switch__slider {
            background-color: #808080;
            & > .toggle-switch__slider-inner {
              right: 1px;
            } 
          }
        }
      }
  }

  &__no-items {
    justify-content: center;
  }

  &__spinner-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    margin-right: 5px;
  }

  &__list-container {
    width: 100%;
    height: 150px;
    padding: 0;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding: 19px 30px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    background-color: $color-mystic;
  }

  &__text {
    font-size: 14px;
    color: $color-gray;
  }

  &__btn-esc {
    display: flex;
    font-size: 12px;
    color: $color-gray;
  }

  &__icon-close {
    width: 13px;
    height: 13px;
    margin-left: 10px;
    fill: $color-gray;
  }
}
