@import 'assets/scss/variables';

.users-count {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1px;

  &--hidden {
    visibility: hidden;
  }

  &__number {
    color: $color-black;
  }

  & svg {
    width: 20px;
    height: 12px;
  }
}
