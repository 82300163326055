@import 'assets/scss/variables.scss';

.quick-booking-item {
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin-right: 1px;
  height: 100px;

  &--no-diva-default-id {
    cursor: not-allowed;
  }

  &--horizontal-line {
    &::after {
      content: "";
      display: block;
      width: calc(100% - 35px);
      height: 1px;
      background-color: $color-silver;
      margin: auto;
      margin-top: 8px;
    }
  }

  &__icon-wrapper {
    display: flex;
    color: $color-froly;
    & svg {
      width: 13px;
      height: 13px;
    }
  }

  &--is-pending {
    opacity: .5;
  }

  &__spinner {
    z-index: 2;
    position: absolute;
    margin: auto;
    inset: 0;
  }

  &__content {
    position: relative;
    display: flex;
    align-items: center;
    flex-grow: 1;
    background-color: $color-black-squeeze-light;
    border: 1px solid $color-athens-gray;
    border-radius: $radius-s;
    gap: 4px;
    color: $color-gray;
    overflow: hidden;

    &--active-contact {
      background-color: $color-white;
      border-color: $color-spring-green;
    }

    &--agent {
      border-color: $color-seance;
      background-color: rgba($color: $color-seance, $alpha: 0.8);
    }
  }

  .booked-editor--editing {
    color: $color-black;
  }

  &__info {
    width: 100%;
    min-width: 0;
  }

  &__status-wrapper {
    padding: 0 2px;
  }

  &__status-point {
    display: block;
    background-color: $color-black;
    width: 4px;
    height: 4px;
    border-radius: 50%;
  }

  &__info-top {
    display: flex;
    align-items: center;
  }

  &__girl-info {
    display: flex;
    margin-top: 7px;
    font-size: 12px;
    line-height: 16px;
    color: $color-black;
    
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  
  &__img-wrap {
    padding: 4px;
    min-width: 60px;
    height: 100%;
    overflow: hidden;
  }

  &__name {
    margin-left: 5px;
    padding-right: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 13px;
    color: $color-azure-radiance;
  }
}
