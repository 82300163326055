@import 'assets/scss/variables.scss';

.comm-module-modal {
  background-color: $color-white;
  padding: 96px 36px;
  border-radius: 6px;
  box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.12);

  &__title {
    font-size: 23px;
    color: $color-gray;
    font-weight: bold;
  }

  &__button {
    margin: 20px 0;
    padding: 20px 10px;
    font-size: 16px;
    color: $color-gray;
    font-weight: bold;

    &:hover {
      opacity: 90%;
      box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.12);
    }
  }
}
