@import "assets/scss/variables.scss";

.mail-contact-item {
  display: flex;
  align-items: center;
  padding: 0 5px;
  background-color: $color-black-squeeze-light;
  border-radius: 6px;
  cursor: pointer;

  &__img-wrap {
    width: 36px;
    height: 36px;
    margin-right: 10px;
    min-width: 36px;
    border: 2px solid darken(#e6ebf1, 5);
    border-radius: 50%;
    overflow: hidden;
    background-color: #fff;

    &--client {
      border-color: $color-dodger-blue;
    }

    &--escort {
      border-color: $color-azure-radiance;
    }

    &--agent {
      border-color: $color-seance;
    }
  }

  &__info {
    display: flex;
    flex: 1;
    min-width: 0;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: #808080;
    font-size: 12px;
    line-height: 16px;
  }

  &__name, &__email {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
    max-width: 100%;
  }

  &__name {
    margin-bottom: 5px;

    &--client {
      color: $color-dodger-blue;
    }

    &--escort {
      color: $color-azure-radiance;
    }

    &--agent {
      color: $color-seance;
    }
  }

  &__ex {
    color: #f27e3f;
    font-weight: bold;
  }

  &__no-emails {
    text-align: center;
    font-size: 14px;
    color: $color-gray;
  }
}
