@import 'assets/scss/variables';

.new-tasks-or-reminders-modal {
  width: 600px;
  background-color: $color-white;
  border-radius: 6px;
  border-bottom: 2px solid $color-bondi-blue;
  box-shadow: 0 24px 24px 0 rgba(0, 0, 0, 0.06);

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    margin-bottom: 24px;
    background-color: $color-athens-gray;
    height: 54px;

    &-title {
      font-size: 14px;
      color: $color-gray;
    }
  }

  &__btns {
    display: flex;
    align-items: center;
    font-size: 12px;
  }

  &__btn-cancel {
    margin-right: 30px;
    color: $color-froly;
  }

  &__btn-done {
    display: flex;
    align-items: center;
    color: $color-bondi-blue;
    &:disabled {
      color: $color-gray;
      cursor: not-allowed;
    }

    & svg {
      margin-left: 7px;
    }
  }

  &__btn-add {
    width: 148px;
    height: 40px;
  }

  &__form {
    padding: 0 45px 35px 25px;
  }

  &__form-header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 14px;
    margin-bottom: 12px;
    font-size: 12px;
    color: $color-tundora;

    &:after {
      content: "";
      background-color: $color-mystic;
      display: block;
      height: 1px;
      width: calc(100% - 20px);
      position: absolute;
      bottom: 0;
      left: 20px;
    }
  }

  &__btn-set {
    padding: 5px 14px;
    border-radius: 6px;
    background-color: $color-bondi-blue;
    color: $color-white;
    font-size: 12px;
  }

  &__icon-bell {
    width: 20px;
    height: 18px;
    margin-right: 7px;
    fill: $color-persimmon;
  }

  &__reminders-wrapper {
    display: flex;
    align-items: center;
  }

  &__reminders-text {
    margin-right: 30px;
    font-size: 12px;
    color: $color-tundora;

    &--date {
      font-weight: bold;
      font-style: italic;
    }
  }

  &__date-btns {
    & #close {
      width: 13px;
      height: 13px;
      margin-right: 25px;
      fill: $color-persimmon;
      cursor: pointer;
    }

    & #edit {
      fill: $color-bondi-blue;
      cursor: pointer;
    }
  }

  &__box {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: $color-tundora;
  }

  // .react-select {
  //   &__control {
  //     width: 130px;
  //     margin-left: 5px;
  //     padding: 9px;
  //     cursor: pointer;
  //     border: 1px solid $color-mystic;
  //     border-radius: 6px;

  //     &:hover, &:focus {
  //       border-color: $color-bondi-blue;
  //     }
  //   }

  //   &__single-value {
  //     color: $color-dodger-blue;
  //   }

  //   &__dropdown-indicator {
  //     padding: 0;

  //     & svg {
  //       width: 16px;
  //       height: 16px;
  //       color: $color-gray;
  //     }
  //   }
  // }

  &__input {
    width: 100%;
    padding: 12px 10px;
    margin-top: 8px;
    border: 1px solid $color-mystic;
    border-radius: 6px;
    outline: none;
    color: $color-tundora;
    font-size: 14px;
    transition: all 0.15s ease-in-out;

    &:focus {
      border: 1px solid $color-bondi-blue;
    }
  }

  &__description {
    width: 100%;
    margin-top: 8px;
    padding: 12px 10px;
    outline: none;
    border: 1px solid $color-mystic;
    border-radius: 6px;
    min-height: 144px;
    color: $color-tundora;
    font-size: 14px;
    resize: none;
    transition: all 0.15s ease-in-out;

    &:focus {
      border: 1px solid $color-bondi-blue;
    }

    &-wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      padding-bottom: 17px;
      padding-left: 20px;
      text-align: start;

      &:after {
        content: "";
        background-color: $color-mystic;
        display: block;
        height: 1px;
        width: calc(100% - 20px);
        position: absolute;
        bottom: 0;
        left: 20px;
      }
    }

    & textarea {
      max-height: 118px;
    }
  }

  &__label {
    display: flex;
    flex-direction: column;
    text-align: start;
    
    &-text {
      font-size: 14px;
      margin-left: 10px;
      color: $color-gray;
    }
  }
  
  &__label-wrapper {
    position: relative;
    padding-bottom: 17px;
    padding-left: 20px;
    margin-bottom: 12px;

    &:after {
      content: "";
      background-color: $color-mystic;
      display: block;
      height: 1px;
      width: calc(100% - 20px);
      position: absolute;
      bottom: 0;
      left: 20px;
    }
  }

  &__icon-adrBook {
    margin-left: 20px;
    fill: $color-bondi-blue;
  }

  &__btn-add {
    display: flex;
    align-items: center;
    padding: 10px 22px;
    margin-left: 30px;
    border-radius: 6px;
    background-color: $color-athens-gray;
    font-size: 14px;
    color: $color-black;
    text-transform: capitalize;
  }

  &__client-info {
    position: relative;
    display: flex;
    height: 70px;
    align-items: center;
    padding-left: 28px;
    // padding-bottom: 6px;
    margin-bottom: 12px;

    &:after {
      content: "";
      background-color: $color-mystic;
      display: block;
      height: 1px;
      width: calc(100% - 20px);
      position: absolute;
      bottom: 0;
      left: 20px;
    }
  }

  &__client {
    display: flex;
    width: 100%;
    padding: 8px 5px;
    font-size: 12px;

    & .lazy-load__container {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      overflow: hidden;
    }

    &--selected {
      position: relative;
      background-color: $color-athens-gray;
      border: 1px solid $color-mystic;
      border-radius: 6px;

      & #close {
        position: absolute;
        top: 20px;
        left: -20px;
        width: 13px;
        height: 13px;
        margin-right: 25px;
        fill: $color-persimmon;
        cursor: pointer;
      }
    }

    &-text-content {
      display: flex;
      flex-direction: column;
      max-width: 210px;
      margin-left: 10px;
    }

    &-name {
      max-width: 200px;
      overflow: hidden;
      color: $color-dodger-blue;
      text-overflow: ellipsis;
      letter-spacing: 0;
      white-space: nowrap;
    }

    &-contact {
      margin-top: 10px;
      text-align: start;
      overflow: hidden;
      color: $color-gray;
      text-overflow: ellipsis;
      letter-spacing: 0;
      white-space: nowrap;
    }
  }

  &__date-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 20px;
    font-size: 12px;
    color: $color-bali-hai;

    &:not(:last-child) {
      margin-bottom: 5px;
    }

    & span {
    }
  }

  &__date {
    font-size: 12px;
    color: $color-bali-hai;

    & span {
      margin-left: 5px;
      color: $color-azure-radiance;
    }
  }

  &__field {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    width: 140px;
    margin-bottom: 0 !important;
    border: 1px solid $color-mystic;
    padding: 4px 5px 4px 9px;
    background-color: $color-white;
    border-radius: 6px;
    color: $color-gray;
    font-size: 13px;
    transition: all 0.15s ease-in-out;
    margin-left: 6px;

    &:focus-within {
      border-color: $color-border-focus;
    }

    & .react-select__single-value {
      font-size: 10px;
      color: $color-dodger-blue;
    }

    & .react-select__dropdown-indicator {
      padding: 0;
      color: $color-gray;
    }

    & .react-select__single-value {
      font-size: 12px;
      text-align: left;
      width: 100px;
    }
  }

  &__assigned-message {
    margin: 0 10px;
  }
}

.assigned-message {
  cursor: auto;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 6px;
    font-size: 12px;
    color: $color-dodger-blue;
  }
  
  &__content {
    background-color: $color-bermuda-opacity;
    line-height: 1.5;
    padding: 6px 25px;
    border-radius: 6px;
    overflow-wrap: anywhere;
    max-height: 157px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-button {
      height: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.2);
      border-radius: 3px;
      margin: 5px;
    }
  }

  &__caller {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
  }

  &__icon {
    height: 18px;
    width: 18px;

    &--telegram, &--robot {
      color: $color-azure-radiance;
    }

    &--whatsapp {
      color: $color-jade;
    }

    &--apple {
      color: $color-gray;
    }
  }

  &__delete-btn {
    font-size: 10px;
    transition: all 0.15s ease-in-out;
    padding: 0 8px;
    color: $color-gray;

    & svg {
      width: 10px;
      height: 10px;
    }

    &:hover {
      color: $color-persimmon;
    }
  }

  &:after {
    content: "";
    background-color: $color-mystic;
    display: block;
    height: 1px;
    width: calc(100% - 32px);
    margin: auto;
    margin-top: 10px;
  }

  &__attachments {
    // margin-top: 6px;

    &  .pinned-msgs-attachments__image-wrapper {
      width: 48px;
      height: 48px;
      min-width: 48px;
    }

    * + & {
      margin-top: 4px;
    }
  }
}
