@import "assets/scss/variables.scss";

.mail-contact-list-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 370px;
  height: 690px;
  border-radius: 6px;
  box-shadow: 0 3px 0 0 #00A9AF;
  background-color: $color-mystic;
  overflow: hidden;

  &__header {
    display: flex;
    justify-content: center;
    padding: 36px 20px 25px 20px;
  }

  &__label-input-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  &__search-input-wrap {
    width: 100%;
  }

  &__search-label {
    display: inline-flex;
    margin-bottom: 5px;
    color: #000001;
    font-size: 12px;
    letter-spacing: 1px;
  }

  &__search-input {
    width: 100%;
    padding: 11px 55px 11px 9px;
    border-radius: 6px;
    border: solid 1px $color-silver;
    outline: none;
    font-family: Roboto;
    color: $color-tundora;

    &::placeholder {
      font: 14px sans-serif;
      color: #bfbfbf;
    }

    &:focus {
      border-color: $color-bondi-blue;
    }
  }

  .search-input__icon--clear {
    width: 9px;
    height: 9px;
    cursor: pointer;
  }

  &__list {
    position: relative;
    flex-grow: 1;
    background-color: #FFFFFF;
    padding: 13px 5px 13px 12px;
    border-radius: 6px 6px 0 0;
  }
}
