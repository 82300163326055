@import 'assets/scss/variables';
@import 'assets/scss/functions';

.date-time-picker {
  @include ModalWindow(auto, 390px, column);
  box-shadow: 0 6px 21px 0 rgba(0, 0, 0, 0.15);
  overflow: visible !important;

  &__header {
    margin: auto;
  }

  .default-calendar {
    margin: 0 auto 5px;
  }

  .time-select {
    display: flex;
    gap: 10px;
    margin: 0 10px 18px;

    .react-select {
      flex-grow: 1;
      &:first-child {
        flex-grow: 8;
      }

      &--is-disabled {
        .react-select__control {
          background-color: $color-silver;
        }
      }
    }

    .react-select__control {
      width: 100%;
    }
  }

  &__header {
    height: 96px;
    padding: 24px 30px 0;
    flex-direction: column;

    & menu {
      display: flex;
      flex-direction: row;
      gap: 15px;
      margin-bottom: 12px;
    }

    & p {
      font-size: 12px;
      text-align: center;
    }
  }

  &__main-container {
    padding-top: 16px;
    padding-bottom: 24px;
  }

  &__actions{
    text-align: center;
  }

  &__cancel, &__submit, &__range {
    padding: 6px;
    min-width: 100px;
    padding: 6px;
    border-radius: 6px;
    color: $color-white;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 1px;
  }

  &__cancel{
    padding: 6px 11px;
    background-color: $color-silver;
  }

  &__range {
    padding: 6px 11px;
    margin-left: 30px;
    background-color: #bfbfbf;

    &--active {
      padding: 11px;
      background-color: #00A6AD;
      box-shadow: 0 12px 12px 0 rgba(0,0,0,0.12);
    }
  }

  &__submit{
    padding: 11px;
    margin-left: 30px;
    background-color: $color-bondi-blue;
    box-shadow: 0 12px 12px 0 rgba(0,0,0,0.12);

    &:disabled {
      background-color: $color-silver;
    }
  }

  &__period {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 32px;
    text-decoration: none;
    border-radius: 6px;
    border: 1px solid #fff;
    background-color: transparent;
    font-size: 12px;
    color: #000;
    transition: all 250ms;
    cursor: pointer;
    text-transform: capitalize;

    &--active {
      background-color: $color-white;
      color: $color-bondi-blue;
    }
  }
}

.calendar-error-msg {
  display: flex;
  position: fixed;
  flex-direction: column;
  align-items: center;
  width: 308px;
  height: 106px;
  margin: auto;
  padding: 10px 25px;
  border-radius: 6px;
  color: $color-gray;
  font-weight: 300;
  font-size: 12px;
  text-align: center;
  background-color: $color-black-squeeze-light;
  box-shadow: 0 -4px 12px 2px rgb(0 0 0 / 18%);
  inset: 0;

  &__msg {
    display: flex;
    flex-grow: 1;
    align-items: center;
  }
}
