@import 'assets/scss/variables.scss';

.operators-list {
  display: flex;
  flex-direction: column;
  background-color: $primary-background-color;
  height: 100%;
  width: 100%;

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    
    &-icon {
      margin-right: 6px;
      width: 15px;
      height: 15px;
      color: $color-gray;
      transform: translateY(-1px);
    }
  }

  &__title {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    color: $color-gray;
  }

  &__list-container {
    flex-grow: 1;
    margin-right: -10px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  &__list {
    padding-right: 10px;
  }
  &__no-items {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    color: #808080;
  }

  &__item:not(:last-child) {
    margin-bottom: 8px;
  }

  &__sip {
    display: inline-block;
    width: 40px;
  }

  &__operator-mode {
    text-transform: uppercase;
    font-weight: 800;

    &--off {
      color: map-get($status-colors, "gray");
    }

    &--full {
      color: map-get($status-colors, "green");
    }

    &--sms {
      color: map-get($status-colors, "orange");
    }

    &--phone {
      color: map-get($status-colors, "blue");
    }
  }
}
