@import 'assets/scss/variables.scss';
@import 'assets/scss/functions.scss';


.see-notes-popup {
  width: 300px;   
  padding: 8px;
  background-color: $color-black-squeeze;

  &__wrapper {
    box-shadow: 0 12px 12px 0 rgb(0 0 0 / 12%);
    z-index: 999;
    border-radius: 8px;
    overflow: hidden;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin: 0 5px;
  }

  &__title {
    color: #b0b7c3;
    font-size: 14px;
    font-weight: 600;
  }

  &__main-container {
    display: flex;
    flex-direction: column;
    padding: 5px 5px;
  }

  &__notes {
    padding: 10px 0px 2px 0px;
  }

  &__value {
    flex-grow: 1;
    font-size: 13px;
    text-align: left;
    color: $color-black;
    font-size: 12px;
    max-height: 200px;
    overflow-y: auto;
    padding-right: 2px;
    @include scrollBars; 

    &--prewrap {
      white-space: pre-wrap;
      word-break: break-all;
    }
  }

  &__copy {
    .clipboard-btn__icon {
      width: 15px;
      height: 15px;
      color: $color-gray;
    }
  }

  &__spinner-wrapper {
    display: flex;
    justify-content: center;
    padding: 10px 0;
  }
}