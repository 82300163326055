@import 'assets/scss/variables';

.form-text-content {
  position: relative;

  &__input:focus-visible + &__ex {
    display: none;
  }

  &__ex {
    display: block;
    pointer-events: none;
    position: absolute;
    top: 48%;
    transform: translateY(-50%);
    padding: 8px 4px;
    left: 7px;
    background-color: white;
    letter-spacing: 1px;
    font-weight: bold;
    color: #f27e3f;
  }

  &__error {
    position: absolute;
    bottom: -15px;
    left: 0;
    color: $color-red;
    font-size: 11px;
    letter-spacing: 1px;

    &--relative {
      position: relative;
      bottom: 0;
      margin-top: 12px;
    }
  }
}
