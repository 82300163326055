@import 'assets/scss/variables.scss';

.bookings-list-item {
  border-radius: 6px;
  border-top: 2px solid $color-persimmon;
  border-right: 1px solid $color-persimmon;
  border-left: 1px solid $color-persimmon;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
  box-sizing: content-box;
  overflow: hidden;
  
  
  &--g-tab {
    background-color: $color-persimmon;

    & .bookings-list-item__content {
      box-sizing: border-box;
      height: 72px;
      border-top: 2px solid $color-persimmon;
      background-color: $color-white;
      border-radius: 6px;
    }

    & .bookings-list-item__header {
      border-radius: 6px;
      background-color: $color-white;
      border-right: 1px solid $color-persimmon;
      border-left: 1px solid $color-persimmon;
    }
  }

  &--calendar {
    height: 114px;
    
    & .bookings-list-item__header-info {
      background-color: $color-white;
    }

    & .bookings-list-item__content {
      background-color: $color-wild-sand;
    }

    & .bookings-list-item__time {
      background-color: $color-wild-sand;
    }
  }

  &--selected {
    box-shadow: 0px 0px 0px 6px $color-bermuda;
    transition: box-shadow .5s;
  }

  &__header {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
  }

  &__time {
    display: flex;
    justify-content: space-between;
    min-width: 130px;
    height: 20px;
    align-items: center;
    flex-grow: 1;
    padding: 0 6px;
  }

  &__operator-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: $color-azure-radiance;
  }

  &__content {
    padding: 7px 15px 11px 15px;
    height: 100%;
  }

  &__info,
  &__girl-name,
  &__client-name {
    overflow: hidden;
    white-space: pre;
    text-overflow: ellipsis;
  }

  &__info {
    color: $color-bali-hai;
  }

  &__timeline {
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 1;
  }

  &__girl-name {
    display: flex;
    align-items: center;
    position: relative;
    margin: 5px 0;
    color: $color-azure-radiance;
    max-width: 100%;
  }

  &__girl-message-counter {
    position: absolute;
    left: -10px;
  }

  &__client-name {
    display: flex;
    align-items: center;
    width: 100%;
    white-space: nowrap;
  }

  &__type {
    text-transform: capitalize;
  }

  &__icons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
  }

  &__requirements-list {
    text-align: left;
  }

  &__requirements-marks {
    display: flex;
    align-items: center;
    gap: 4px;
    width: fit-content;
    height: 100%;

    &-wrapper {
      display: flex;
      flex-direction: row-reverse;
      height: 100%;
    }
  }

  &__requirement-mark {
    display: block;
    width: 3px;
    height: 65%;
    border-radius: 2px;
  }

  &__icon-pre-booking {
    width: 14px;
    height: 14px;
    min-width: 14px;
    fill: $color-persian-pink;
  }

  &__header-info {
    display: flex;
    overflow: hidden;
    min-width: 76px;
    height: 20px;
    align-items: center;
    flex-grow: 1;
    padding: 0 6px;

    & > * {
      width: 50%;
      // height: 100%;
    }
  }

  &__info-icons {
    display: flex;
    align-items: center;
    width: 50%;
  }

  &__requirements {
    width: 50%;
    height: 80%;
  }
}

.bookings-list .scrollbars-track {
  right: 3px;
}

.bookings-list .search-input__icon {
  fill: $color-gray;
}
