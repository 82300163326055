@import 'assets/scss/variables.scss';

.user-notifications {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: $color-mystic;

  &__header {
    padding: 13px 0 11px 12px;
    color: $color-gray;
    font-size: 12px;
    font-weight: 500;
  }

  &__list-container {
    display: flex;
    flex-grow: 1;
    padding-top: 15px;
    padding-bottom: 10px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin: 0 15px;
  }

  &__load-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .scrollbars-track {
    right: 5px;
  }
}
