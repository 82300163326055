@import 'assets/scss/variables';

.overflowed-tags {
  $block: &;

  display: flex;
  flex-direction: column;

  &__overflowed-tag {
    background-color: $color-dark-background-tag;
  }

  &__tags-tooltip {
    display: flex;
    flex-flow: row wrap;
    gap: 4px;
    max-width: 276px;
    margin: 8px 12px;
  }

  &__row {
    display: flex;
    position: relative;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__tag-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__tag-wrapper {
    display: flex;
    margin-right: 6px;
    border-radius: 6px;
    overflow: hidden;
  }

  &__popup {
    gap: 6px;
  }

  &__popup &__tag-wrapper {
    margin: 0;
  }

  &__popup &__tag {
    background: $color-athens-gray;
  }
  
  &__hidden-count {
    display: flex;
    justify-content: center;
    width: 40px;
    border-radius: 6px;
    background-color: $color-bermuda;
    overflow: visible;
    cursor: pointer;
  }

  &__tag {
    &--hidden {
      margin: 0;
      background: darken(#f6f6f8, 2);
      width: min-content;
    }
  }

  &__hidden-tags {
    display: flex;
    flex-wrap: wrap;
    padding: 12px 15px;
    max-width: 350px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 2px 0 0 $color-bondi-blue;
    gap: 5px;
  }
}
