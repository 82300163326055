@import "../../assets/scss/variables.scss";

.buffer-search {
  display: flex;
  flex-direction: column;
  width: 252px;
  margin-top: 30px;

  &__input {
    width: 230px;
    padding: 11px 50px 10px 10px;
    border: none;
    border-radius: 6px;
    outline: none;
    color: $color-silver;
    font-size: 14px;
    line-height: 19px;
    font-family: Roboto;
    letter-spacing: 0.88px;
  }

  &__buffer-icon {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    color: $color-white;

    &--active {
      color: $color-bermuda;
    }
  }

  &__contacts-list {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    width: 274px;
    overflow-y: scroll;
    gap: 10px;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      width: 4px;
      border-radius: 2px;
      background-color: $color-mystic;
    }
  }

  &__contacts-item {
    position: relative;
    margin-left: -19px;
  }

  & .contact-item {
    position: relative;
    width: 252px;
    margin: 0;
    padding-right: 25px;
  }

  & .contact-item__last-msg {
    font-style: italic;
  }
}

.buffer-chats {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 1500px;
  height: 700px;
  text-align: left;

  & .chat {
    border-radius: 6px;
  }

  & .chat__wrapper {
    overflow: hidden;
  }

  & .chat-header {
    background-color: $color-mystic;
    border-radius: 0;

    &__header {
      background-color: inherit;
    }

    &__contact-groups-title-list {
      background-color: $color-mystic;
    }
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    gap: 56px;
  }

  &__chat-wrapper {
    width: 550px;
    // height: 700px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 70px;
  }

  &__btns-wrapper {
    display: flex;
    gap: 16px;
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 40px;
    padding: 11px 24px;
    border-radius: 6px;
    color: $color-white;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
    background-color: $color-bondi-blue;

    &--booking-details {
      color: $color-black;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.88px;
      background-color: $color-white;
      transition: .3s;
      gap: 21px;

      & svg {
        color: $color-bondi-blue;
      }

      &:disabled {
        opacity: .5;
        cursor: not-allowed;
      }
    }

    &--esc {
      display: flex;
      align-items: center;
      margin-left: 60px;
      color: $color-white;
      font-weight: normal;
      font-size: 14px;
      letter-spacing: 0.88px;
      background: transparent;
      padding: 11px 0px;

      & svg {
        width: 13px;
        height: 13px;
        margin-right: 10px;
      }
    }
  }

  & .title__content {
    color: $color-white;
  }

  & .chat {
    max-width: 550px;
    width: 100%;

    &:first-child .chat__wrapper {
      border-radius: 6px;
    } 
  }
}

.chat-buffer-tabs {
  display: flex;
  flex-grow: 1;
  max-width: 375px;
  overflow-x: scroll;
  color: $color-white;
  gap: 6px;

  &-wrapper {
    display: flex;
    z-index: 99;
    position: relative;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    margin-bottom: 6px;
    padding: 0;
    border-radius: 6px;
    color: $color-bondi-blue;
    background-color: $color-white;

    svg {
      width: 10px;
      height: 10px;
    }

    &--right {
      transform: rotate(180deg);
    }
  }

  &__remove-img {
    display: none;
    position: absolute;
    top: 7px;
    right: 6px;
    color: $color-tundora;
    font-size: 12px;
    cursor: pointer;
  }

  &__item {
    position: relative;
    min-width: 82px;
    max-width: 100px;
    padding: 6px;
    padding-right: 15px;
    border: 1px solid;
    border-bottom-color: rgba(0, 0, 0, 0.5);
    border-radius: 6px 6px 0 0;
    overflow: hidden;
    color: $color-tundora-light;
    font-size: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    background-color: $color-mystic;
    
    &--active {
      border: 1px solid $color-bondi-blue;
      color: $color-white;
      font-weight: 500;
      background: $color-bondi-blue;

      &:hover .chat-buffer-tabs__remove-img {
        color: $color-white;
      }
    }

    &:hover .chat-buffer-tabs__remove-img {
      display: inline;
    }
  }

  &::-webkit-scrollbar {
    height: 0;
  }
}
