.time-select{
  border-radius: 6px;

  .react-select {
    .react-select__single-value {
      color: #404040;
      font-size: 14px;
      letter-spacing: 0.88px;
      font-weight: 400;
    }

    .react-select__control {
      background-color: #E6EBF1;
      border-radius: 6px;
      padding-left: 10px;

      min-height: 40px;
    }

    .react-select__dropdown-indicator svg {
        width: 16px;
        height: 16px;
        color: #000;
    }

    .react-select__menu-list {
      border-radius: 6px;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background-color:#bfbfbf;
        border-radius: 3px;
      }

      &::-webkit-scrollbar-button {
        height: 5px;
      }
    }
  }
}
