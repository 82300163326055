@import 'assets/scss/variables.scss';

.booking-location-editor {
  position: relative;
  width: 100%;

  &__tooltip {
    display: flex;
    z-index: 2;
    position: fixed;
    flex-direction: column;
    width: 100%;
    max-width: 571px;
    height: fit-content;
    min-height: 530px;
    margin: auto;
    border-radius: 6px;
    background-color: $color-mystic;
    box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.08);
    inset: 0;
  }

  &__main {
    flex-grow: 1;
    padding: 29px 15px 38px;
    border-bottom: 2px solid $color-bondi-blue;
    border-radius: 6px;
    background-color: $color-white;
    display: flex;
    flex-direction: column;
    &-header {
      display: flex;
      justify-content: space-between;
      padding: 0 17px;
      margin-bottom: 3px;
    }
  }
  
  &__title {
    color: $color-gray;
    font-size: 14px;
    text-transform: capitalize;
    letter-spacing: 0;
  }

  &__subtitle {
    color: $color-black;
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 16px;
  }

  &__btns-wrapper {
    display: flex;
    align-items: center;
  }

  &__btn {
    font-size: 12px;
    padding: 0;
    border: none;
    &-icon {
      width: 20px;
      &--check {
        margin-left: 9px;
      }

      &--add {
        color: $color-bondi-blue;
        margin-right: 6px;
      }

      &--request {
        position: absolute;
        top: 0;
        left: 0;
        width: 16px;
        color: $color-gray;
      }

      &--delete {
        width: 5px;
        height: 5px;
      }
    }
    &--save {
      display: flex;
      align-items: flex-end;
      margin-left: 25px;
      padding: 0;
      color: $color-bondi-blue;
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    &--cancel {
      color: $color-froly;
    }

    &--add-from {
      display: flex;
      align-items: center;
      color: $color-gray;
      font-size: 14px;
      &:disabled {
        opacity: .5;
        cursor: not-allowed;
      }
    }

    &--delete {
      display: flex;
      position: absolute;
      top: 1px;
      right: 2px;
      justify-content: center;
      align-items: center;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      color: $color-white;
      background-color: $color-froly;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding: 19px 31px;
    align-items: center;
  }

  &__fields-wrapper {
    padding: 0 15px 21px;
    border-bottom: 1px solid $color-gray;
  }

  &__map {
    position: relative;
    flex-grow: 1;
    margin-top: 20px;
    .spinner {
      position: absolute;
      margin: auto;
      inset: 0;
    }
  }
}
