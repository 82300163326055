@import "assets/scss/variables.scss";

.gallery-profile-card {
  width: 160px;
  min-height: 285px;

  background-color: $color-black-squeeze-light;
  border-radius: 6px;
  border-top: 2px solid transparent;

  &--hidden {
    display: none;
  }

  &__profile-rates-wrapper {
    position: absolute;
    z-index: 100;
    box-shadow: $shadow-primary;
  }

  &__flag-img {
    width: 22px;
    height: 14px;
    border-radius: 2px;
    object-fit: contain;

    &-wrap {
      position: absolute;
      top: 3px;
      left: 3px;
      display: flex;
      gap: 5px;
    }
  }
}

.gallery-profile-card__top-wrap {
  position: relative;
  padding: 5px 18px 5px 8px;
  display: flex;
  align-items: center;

  &--available-now::before {
    @extend %available-mark;

    background-color: $color-caribbean-green;
  }

  &--off-today::before {
    @extend %available-mark;

    background-color: $color-red-orange;
  }
}

.gallery-profile-card__buffer-button {
  position: relative;

  width: 13px;
  height: 13px;
  margin-right: 5px;
  padding: 0;
  flex-shrink: 0;

  &::after {
    @extend %vertical-hover;

    left: -5px;
    right: 0;
  }

  &--add {
    color: $color-bondi-blue;
  }

  &--remove {
    color: $color-froly;
  }

  svg {
    width: 100%;
    height: 100%;
  }
}

.gallery-profile-card__name {
  margin-right: auto;

  overflow: hidden;

  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 1px;
  color: $color-tundora;
}

.gallery-profile-card__rating-button {
  position: relative;
  margin-left: 5px;
  padding: 0;
  flex-shrink: 0;

  font-size: 12px;
  color: $color-azure-radiance;

  &::after {
    @extend %vertical-hover;

    left: 0;
    right: 0;
  }
}

.gallery-profile-card__rates-button {
  @extend %right-absolute-button;

  top: 2px;
  padding: 0;
  color: $color-bondi-blue;
}

.gallery-profile-card__divaescort-link {
  @extend %right-absolute-button;

  bottom: 2px;
  color: $color-gray;
}

.gallery-profile-card__image-thumb {
  position: relative;
  height: 210px;
  cursor: pointer;
}

.gallery-profile-card__prev-booked-count {
  position: absolute;
  top: 6px;
  right: 8px;

  padding: 2px 5px 2px 10px;
  display: flex;
  align-items: center;

  background-color: $color-white;
  border-radius: 6px;
  font-size: 12px;

  svg {
    margin-left: 5px;
    width: 15px;
    height: 15px;

    color: $color-red-orange;
  }
}

.gallery-profile-card__image-tags-wrap {
  position: absolute;
  inset: 0;

  display: flex;
  flex-direction: column;
}

.gallery-profile-card__labels-or-book-wrap {
  position: relative;
  flex-grow: 1;
}

.gallery-profile-card__book-button {
  position: absolute;
  bottom: 5px;
  right: 5px;

  padding: 5px 18px;

  opacity: 0;
  pointer-events: none;
  transition: opacity 150ms;
  background-color: $color-bondi-blue;
  border-radius: 6px;

  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 1px;
  color: $color-white;

  .gallery-profile-card__image-tags-wrap--session-with-client:hover & {
    opacity: 1;
    pointer-events: auto;
  }
}

.gallery-profile-card__open-map-button {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 36px;
  width: 36px;
  display: flex;
  background-color: $color-white;
  border-radius: 6px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 150ms;

  & > svg {
    height: 22px;
    width: 22px;
    margin: auto;
    color: $color-bondi-blue;
  }
  
  .gallery-profile-card__image-tags-wrap:hover & {
    opacity: 1;
    pointer-events: auto;
  }
}

.gallery-profile-card__not-available {
  padding: 2px 10px;
  background-color: rgba(235, 99, 0, 0.7);

  font-size: 13px;
  line-height: 1.2;
  color: $color-white;
}

.gallery-profile-card__agent-name {
  padding: 2px 10px;
  background-color: rgba(255,255,255,0.8);

  font-style: italic;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 1px;
  color: $color-lucky-point;
}

.gallery-profile-card__short-info {
  padding: 4px 10px 3px 10px;
  font-size: 12px;
}

%available-mark {
  content: "";
  position: absolute;
  top: 1px;
  left: 2px;

  width: 7px;
  height: 7px;

  border-radius: 50%;
}

%vertical-hover {
  content: '';
  position: absolute;
  top: -5px;
  bottom: -5px;
}

%right-absolute-button {
  content: '';
  position: absolute;
  right: 8px;
  transform: translateX(50%);

  width: 9px;
  height: 9px;

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }
}
