@import 'assets/scss/variables';

.user-menu {
  &__img-wrap {
    height: 48px;
    aspect-ratio: 1;
    border-radius: 50%;
    overflow: hidden;
  }

  &__info {
    display: flex;
    flex-direction: column;
    margin: 10px;
    max-width: 100px;
  }

  &__content {
    margin: 0 8px;
  }

  &__name {
    text-align: left;
    font: $font-l;
  }

  &__clock {
    font: $font-s;
    color: rgba($secondary-background-color, 1);
    font-weight: bold;
    padding: 0;
    text-transform: uppercase;
    text-align: left;
    height: 18px;

    & * {
      position: absolute; // for not affecting conteiner width when time is changing
    }
  }

  &__city {
    font: $font-s;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  &__status {
    position: absolute;
    top: -4px;
    left: -4px;
  }
}
