@import './variables.scss';

.input {
  padding: 7px 10px;
  width: 100%;
  outline: none;
  border: 1px solid transparent;
  border-radius: 8px;
  background-color: $color-white;
  line-height: 1;
  letter-spacing: 1px;
  color: $color-gun-powder;
  font-weight: 400;
  transition: all 0.15s ease-in-out;
  @extend %font-main-text;

  &:focus-visible {
    border-color: $color-border-focus;
  }

  &--error {
    border: 1px solid $color-border-error !important;
  }

  &:disabled::placeholder {
    color: transparent;
  }
}
