@import 'assets/scss/variables';

.booking-filters-list-new {
  display: flex;
  position: relative;
  flex-flow: row wrap;
  align-items: center;

  &__counter-box {
    position: absolute;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    &--center {
      top: 50%;
      transform: translateY(-50%);
    }

    &--top {
      top: 9px;
    }

    &--bottom {
      bottom: 0;
    }
  }

  &__counter-box:has(&__clear-btn) {
    cursor: pointer;
  }

  &__counter {
    font-size: $font-size-s;
    color: rgba($tertiary-background-color, $dimmed-text-opacity);
  }

  &__clear-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 14px;
    height: 14px;
    cursor: pointer;
    background-color: $color-persimmon;
    border-radius: 4px;
    margin-left: 8px;

    &-icon {
      color: $color-white;
      width: 8px;
      height: 8px;
    }
  }

  // & .booking-status-filters-new {
  //   background-color: $color-mystic;
    
  //   .booking-status-filters-new__item--active {
  //     background-color: $color-white;
  //   }
  // }
}
