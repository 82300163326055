@import 'assets/scss/variables.scss';
@import 'assets/scss/functions.scss';

.bookings-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: $color-mystic;

  &__filters {
    gap: 15px;
    margin-bottom: 12px;
  }

  &__search-input-wrap {
    height: 40px;
    width: 100%;
  }

  &__search-input {
    height: 100%;
    width: 100%;
  }

  &__no-items {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 24px;
    color: $color-gray;
    width: 100%;
    height: 100%;
  }

  &__header {
    display: flex;
    flex-direction: column;
    margin: 14px 5px 12px 5px;
  }

  &__list {
    position: relative;
    margin: 0 15px;
    display: flex;
    flex-direction: column;

    & > li {
      margin-top: 12px;
    }
    // gap: 12px;
  }

  &__list-container {
    height: 100%;

    &>div>div:first-child {
      bottom: -17px;
    }
  }

  &__ava-wrap {
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    border-radius: 50%;
    cursor: pointer;

    &--active {
      box-shadow: 0px 0 0px 2px rgba(#88D6D9, 0.44);
    }
  }
}
