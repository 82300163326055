@import 'assets/scss/variables';

.transferred-or-assistance-session-modal {
  display: flex;
  width: 470px;
  height: 264px;
  background-color: $color-black-squeeze-light;
  box-shadow: 0 2px 0 0 #00A6AD;

  &__wrapper {
    width: 100%;
    box-shadow: 0 16px 16px 0 rgba(0,0,0,0.08);
  }

  &__header {
    display: flex;
    padding: 23px 17px;
    margin-bottom: 24px;
    background-color: $color-mystic;
    font-size: 18px;
    line-height: 24px;
    color: $color-gray;
    border-radius: 6px;
    box-shadow: 0 16px 16px 0 rgba(0,0,0,0.08);
  }

  &__icons {
    margin-right: 12px;
  }

  &__icon {
    width: 20px;
    height: 18px;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
    margin: 24px 30px 36px;
  }

  &__contact-info {
    display: flex;
    padding: 5px;
    width: 196px;
    background-color: $color-white;
    border-radius: 6px;
  }

  &__image {
    width: 36px;
    height: 36px;
    min-width: 36px;
  }

  &__box {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    align-items: flex-start;
    overflow: hidden;
  }

  &__name {
    width: 100%;
    margin-bottom: 5px;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
    color: $color-dodger-blue;
    text-align: start;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    
    &--operator {
      color: $color-seance;
    }
  }

  &__text {
    width: 100%;
    color: $color-gray;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
    text-align: start;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__btn {
    border-radius: 6px;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 21px;
    color: $color-white;

    &-decline {
      margin-right: 40px;
      padding: 4px 20px;
      background-color: $color-silver;
    }

    &-accept {
      padding: 14px 52px;
      background-color: $color-bondi-blue;
    }
  }
}

