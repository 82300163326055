@import 'assets/scss/variables';

.remind-msg {
  position: relative;
  margin-top: 10px;
  padding: 3px 0;
  border-radius: 5px;
  background-color: $color-white;

  &__header {
    display: flex;
    justify-content: flex-end;
    padding: 0 8px;
    padding-bottom: 3px;
    color: $color-black;
    font-size: 11px;
    line-height: 15px;
    text-align: right;
    letter-spacing: 1px;

    &:after {
      display: block;
      width: 15px;
      height: 15px;
      margin-left: 7px;
      border: 1px solid $color-mystic;
      border-radius: 2px;
      content: '';
    }
  }

  &__name {
    margin-left: 6px;
    color: $color-azure-radiance;
    font-style: italic;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    font-family: Roboto;
    letter-spacing: 0;
  }

  &__close {
    display: none;
    z-index: 1;
    position: absolute;
    top: 3px;
    right: 8px;
    justify-content: center;
    align-items: center;
    width: 15px;
    height: 15px;
    border: none;
    outline: none;
    color: $color-persimmon;
    cursor: pointer;

    svg {
      width: 12px;
      height: 12px;
    }
  }

  .interaction__menu.i-menu {
    display: none;
  }

  .interaction__attachments {
    padding: 8px;
  }

  &__msg-time {
    flex-grow: 1;
    color: $color-gray;
    font-size: 10px;
    line-height: 13px;
    text-align: end;
    letter-spacing: 0;
  }

  &__time {
    margin-left: 5px;
    color: $color-azure-radiance;
  }

  &__caller-info {
    display: flex;
    align-items: center;
    margin-top: 13px;

    &-icon {
      width: 11px;
      height: 11px;
      margin: 0 7px;
      color: $color-silver;
    }

    & .lazy-load__container {
      width: fit-content;
      &::after {
        content: none;
      }
    }
  }

  &__caller-name {
    width: 100%;
    padding-right: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__img {
    width: 22px;
    height: 22px;
    border-radius: 50%;
  }

  &__footer {
    padding: 0 8px;
    padding-left: 5px;
    color: $color-gray;
    font-style: italic;
    font-size: 12px;
    line-height: 16px;
    font-family: Roboto;
    letter-spacing: 0;
  }
  &__content {
    padding: 10px 8px 17px;
    color: $color-tundora-light;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0;
    cursor: pointer;
    background-color: $color-athens-gray;

    &-main {
      display: flex;
      svg {
        flex-shrink: 0;
        width: 10px;
        height: 10px;
        margin-right: 5px;
      }
    }

    &--is-link {
      padding: 0;
      color: $color-azure-radiance;
      font-size: 10px;
    }
  }

  &:hover .remind-msg__close {
    display: flex;
  }
}
