@import 'assets/scss/variables';

.session {
  font-family: Roboto;
  background-color: #f6f6f8;
  border-radius: 6px;
  border: 1px solid #f6f6f8;
  padding: 3px 7px 3px 5px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  
  &--active {
    background-color: #b3e4e6;
    border: 1px solid $color-bondi-blue;
  }
  &--unread {
    //  animation: blink 1s linear infinite;
    position: relative;
    
    &::after {
      content: "";
      box-sizing: border-box;
      display: inline-block;
      width: 10px;
      height: 10px;
      position: absolute;
      border-radius: 50%;
      top: 2px;
      right: 2px;
      border: 2px solid rgb(230, 235, 241);
      background-color: rgb(245, 48, 74);
      animation: unread-pulse 1s infinite;
      transition: all 0.2s ease-in;
    }
  }
  
  &:hover:not(.session--active) {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .12);
  }
  
  &__content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  &__avatar-wrap {
    width: 32px;
    height: 32px;
    min-width: 32px;
    margin-right: 5px;
  }
  &__avatar {
    border-radius: 100%;
  }
  &__info {
    color: $color-gray;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    overflow: hidden;

    &--fullwidth {
      max-width: none;
    }
  }
  &__info-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__right {
    margin: 0 0 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: right;
    font-size: 12px;

    .call-item__timer {
      color: $color-tundora-light;
      font-size: 11px;
      font-style: italic;
      margin: 0;
    }

    & > span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &__name {
    font-size: 12px;
    font-style: italic;
    color: $color-azure-radiance;
  }
  &__menu-btn-wrap {
    display: flex;
    align-items: center;
    margin-left: 12px;
    padding: 3px;
    // margin-right: 4px;

    .dropdown-menu.dropdown-menu--fixed-right {
      left: 80%;
    }
  }
  &__create-btn-wrap {
    display: flex;
    align-items: center;
  }
  &__create-btn {
    padding: 5px 0;
  }
  &__request {
    position: absolute;
    left: -1px;
    top: -1px;
    right: -1px;
    bottom: -1px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 2px 0;
    z-index: 2;
    cursor: default;
    border-radius: 6px;

    &--assistance {
      background-color: rgba(1, 223, 133, 0.4);
    }
    &--transfer {
      background-color: rgba(0, 146, 242, 0.4);
    }
  }
  &__request-title {
    color: #0092f2;
    letter-spacing: 1px;
    font-weight: 600;
    font-size: 12px;
    background-color: #fff;
    border-radius: 6px;
    padding: 1px 5px;
  }
  &__request-actions {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
  &__request-btn {
    color: #2e2e30;
    font-size: 12px;
    padding: 0;
    margin: 0;
    padding: 4px 14px;
    border-radius: 6px;

    &--cancel {
      background-color: #f8737f;
    }
    &--accept {
      background-color: #01df85;
    }
  }

  &__operator-name {
    font-family: Roboto;
    font-size: 12px;
  }

  &__dates {
    display: flex;
    bottom: 0;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 8px;
    gap: 14px;
  }

  &__dates-start {
    line-height: 10px;
    flex-shrink: 0;
  }
  
  &__duration {
    color: $color-gray;
    font-size: 9px;
    font-weight: 600;
  }

  &__operator {
    white-space: pre;
    text-overflow: ellipsis;
    overflow: hidden;
    color: $color-malibu;
  }
}

@keyframes unread-pulse {
  0% {
    box-shadow: 0 0 0 0px rgb(245, 48, 74);
  }
  100% {
    box-shadow: 0 0 0 15px rgba(4, 241, 83, 0);
  }
}
