
@import 'assets/scss/variables';
@import 'assets/scss/functions.scss';


.girls-map {
  --btn-gap: 14px;

  display: flex;
  flex-direction: column;
  width: calc(100vw - 260px);
  max-width: 1370px;
  height: 100%;
  max-height: 1200px;

  &__street-view {
    height: 100%;
    width: 100%;

    &--fallback {
      position: absolute !important;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      background-color: $color-athens-gray;
      z-index: 2;
    }
  }

  // &__header-wrapper {
  //   display: flex;
  //   align-items: center;
  //   justify-content: space-between;
  //   min-height: 38px;
  //   height: 38px;
  //   margin-bottom: 18px;
  //   gap: var(--btn-gap);
  // }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 38px;
    height: 38px;
    margin-bottom: 18px;
    width: 100%;
    gap: var(--btn-gap);
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    margin-top: 36px;
    min-height: 38px;
    height: 38px;
    gap: var(--btn-gap);
  }
  
  &__footer-side {
    display: flex;
    flex-direction: row;
    gap: var(--btn-gap);
    align-items: center;
    white-space: nowrap;
  }

  &__content {
    flex-grow: 1;
    display: grid;
    position: relative;
    min-height: 0;
    grid-template-columns: 30% 70%;
    grid-template-rows: 35% 65%;

    & > :only-child {
      grid-area: 1 / 1 / -1 / -1;
    }

    &-preview {
      grid-area: 1 / 1 / 1 / -1;
      background-color: $color-white;
      display: flex;
      position: relative;
      
      & > div {
        position: relative;
        height: 100%;
        width: 100%;

        & > div:first-child {
          position: absolute;
        }
      }
    }

    &-contact {
      grid-area: 2 / 1 / -1 / 2;
      overflow: scroll;
      background-color: $color-white;
      display: flex;
      @include scrollBars;

      & .tag {
        background-color: $color-athens-gray;
      }

      & .contact-info {
        width: 100%;
      }
    }

    &-map {
      grid-area: 2 / 2 / -1 / -1;
      position: relative;
    }
  }

  &__description {
    font-size: 14px;
    color: $color-white;
  }

  &__filters {
    display: flex;
    gap: var(--btn-gap);
  }

  &__filter-label {
    position: absolute;
    top: -20px;
    color: $color-white;
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: capitalize;
    text-align: left;
  }

  &__filter-wrap {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
  }

  &__filter {
    width: 140px;
    height: 38px;
  }

  &__buttons-wrap {
    display: flex;
    height: 100%;
    gap: var(--btn-gap);
  }

  &__toggle-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $color-white;
    min-width: 170px;
    height: 38px;
    border-radius: 6px;
    padding: 0 22px;
    // margin-left: 10px;
    font-size: 14px;
    letter-spacing: 0.88px;
    color: $color-black;
    white-space: pre;
    transition: all 0.15s ease-in-out;

    &:disabled {
      filter: brightness(0.6);
      cursor: default;
    }

    &--disabled {
      opacity: 0.8;
      cursor: default;
    }

    &--active {
      color: $color-white;
      background: $color-bondi-blue;

      svg {
        color: $color-white;
      }
    }

    &--inner-map {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      min-width: 40px;
      border-radius: 2px;
      padding: 0;
      font-size: 18px;
      z-index: 50;
      box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.15);
      letter-spacing: normal;

      & > svg {
        height: 26px;
        width: 26px;
        margin: 0;
      }
    }

    &--subway {
      top: 10px;
      left: 186px;
    }

    &--recenter {
      right: 10px;
      bottom: 209px;
    }

    &--send {
      // width: 185px;
      display: flex;
      justify-content: space-between;

      & > svg {
        margin-left: 14px;
        min-width: 16px;
      }
    }

    &--selected {
      & > svg {
        color: $color-black;
      }
    }

    &--copy {
      min-width: 142px;
    }

    &-icon {

      margin-left: 10px;
      color: $color-bondi-blue;
      transition: all 0.15s ease-in-out;
    }
  }

  &__similar-button {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    width: 134px;
    font-size: 13px;
    height: 24px;
    letter-spacing: 0.88px;
    background-color: $color-azure-radiance;
    color: $color-white;
    border-radius: 6px;
    z-index: 2;
    left: 50%;
    transform: translateX(-50%);
    top: 18px;
    transition: all 0.3s ease-in-out;

    &--active {
      background-color: $color-bondi-blue;
    }
  }

  &__bestmatch-label {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    transform: translateX(-50%);
    height: 22px;
    font-size: 13px;
    letter-spacing: 0.88px;
    background-color: $color-azure-radiance;
    color: $color-white;
    border-radius: 6px;
    z-index: 2;
    padding: 5px;
    transform: translateY(-50%);

    &--low {
      background-color: $color-radical-red;
    }

    &--medium {
      background-color: $color-selective-yellow;
    }

    &--high {
      background-color: $color-fun-green;
    }
  }

  &__back-btn {
    display: flex;
    align-items: center;
    color: $color-white;
    gap: 16px;
    margin-right: 10px;
    white-space: pre;

    & > svg {
      height: 20px;
      width: 20px;
      transform: rotate(-90deg);
    }
  }

  &__esc-btn {
    display: flex;
    position: absolute;
    align-items: center;
    height: 36px;
    color: $color-white;
    font-size: 14px;
    letter-spacing: 0.88px;
    padding: 0;
    right: 0;
    transform: translateX(calc(100% + 40px));

    &-icon {
      width: 13px;
      height: 13px;
      margin-right: 10px;
    }
  }

  &__spinner-wrap {
    position: absolute;
    top: 9px;
    left: 230px;
    z-index: 500;
  }

  &__radius-select {
    height: 36px;

    & .select__control {
      height: 36px !important;
      min-height: 36px !important;
      width: 120px;
    }

    & .select__menu {
      top: revert !important;
      bottom: 43px;
    }
  }

  &__client-location-input-wrap {
    height: 100%;
    width: 330px;
  }

  &__client-location-input {
    height: 100%;
    min-height: 36px;
    height: 36px;
  }

  &__tooltip {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 150px;
  }

  &__tooltip-header {
    height: 14px;
    margin-bottom: 14px;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & button {
      color: $color-bondi-blue;
    }
  }

  &__tooltip-content {
    width: 150px;
    text-align: left;
    font-size: 13px;

    & svg {
      margin: auto;
    }
  }

  &__tooltip-buttons {
    display: flex;
    flex-direction: row;
    gap: 12px;
    margin-right: 5px;
  }
}
