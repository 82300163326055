@import 'assets/scss/variables';
@import 'assets/scss/functions.scss';

.booking-status-filters {
  display: flex;
  align-items: center;
  width: fit-content;
  height: 100%;
  gap: 8px;
}

