@import 'assets/scss/variables';

.booking-horizontal-item {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 56px;
  background-color: rgba($tertiary-background-color, $light-highlighted-opacity);
  border-radius: $radius-xs;
  overflow: hidden;
  container-type: inline-size;
  container-name: booking-horizontal-item;

  &__color-line {
    height: 100%;
    width: 4px;
    background-color: var(--status-color);
  }

  &__body {
    display: flex;
    flex-direction: row;
    padding: 0 10px;
    width: calc(100% - 4px);
  }

  &__column {
    display: flex;
    flex-direction: column;

    &:first-child {
      width: 82%;

      &>* {
        height: calc(100% / 3);
      }
    }

    &:last-child {
      width: 18%;
      text-align: right;
      align-items: flex-end;
      justify-content: space-between;
    }
  }

  &__row {
    display: flex;
    flex-direction: row;
    min-height: 20px;
    gap: 8px;
    width: 100%;

    &:first-child {
      justify-content: space-between;
      color: var(--status-color);
    }

    &:nth-child(2) {
      & > :nth-child(1), & > :nth-child(3) {
        width: 30%;
      }

      & > :nth-child(2) {
        width: 40%;
      }
    }
  }

  &__status {
    @container booking-horizontal-item (max-width: 500px) {
      display: none;
    }
  }
}
