@import 'assets/scss/variables';

.booking-tooltip-add-from {
    &__btn {
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 8px;
        
        & svg {
            color: $color-bondi-blue;
        }
    }
    
    &__list-wrap {
        background-color: $color-white;
        border-radius: 6px;
        box-sizing: content-box;
        height: 447px;
        width: 327px;
        box-shadow: 0 12px 15px 0 rgba(0,0,0,0.09);
        padding: 16px 12px 24px 12px;
        z-index: 150;
    }

    &__list {
        padding-right: 14px;

        &-container {
            height: 100%;
        }
    }

    &__item {
        margin-bottom: 12px;
        cursor: pointer;

        &:hover {
            background-color: $color-athens-gray;
        }

        &-header {
            display: flex;
            align-items: center;
            padding: 6px 10px 6px 5px;
        }

        &-name {
            font-size: 12px;
            line-height: 16px;
            color: $color-dodger-blue;
            text-align: left;
        }

        &-time {
            margin-left: auto;
            font-size: 10px;
            line-height: 13px;
            color: $color-gray;
            transform: translateY(3px);
        }

        &-body {
            padding: 6px 25px;
            text-align: left;
            font-size: 12px;
        }
    }

    &__icon {
        height: 18px;
        width: 18px;
        margin-right: 5px;

        &--telegram {
        color: $color-azure-radiance;
        }
        &--whatsapp {
        color: $color-jade;
        }
    }
        
    &__no-items {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
