@import 'assets/scss/variables';

.tasks-and-reminders {
  display: flex;
  flex-direction: column;
  width: clamp(490px, 29vw, 900px);
  height: 70vh;
  border-radius: 6px;
  font-family: $font-family-main;
  background-color: $primary-background-color;
  box-shadow: $default-modal-shadow;
  border-radius: $radius-xl;
  overflow: hidden;

  &__header {
    display: flex;
    align-items: center;
    height: 70px;
    padding: 16px;
    background-color: rgba($secondary-background-color, $highlighted-opacity);
    gap: 24px;
  }

  &__header-block {
    display: flex;
    align-items: center;
    height: 100%;
    gap: 8px;
  }

  &__list-container {
    width: 100%;
    height: 100%;
  }

  &__bookings-list-wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: 16px;

    .scrollbars-thumb {
      background-color: $color-mystic;
    }
  }

  // &__bookings-list-title {
  //   font-size: 14px;
  //   padding-left: 50px;
  //   color: $color-bali-hai;
  //   margin-bottom: 13px;
  // }

  
  &__spinner-wrap,
  &__no-items {
    position: absolute;
    width: fit-content;
    top: 40%;
    left: 50%;
    transform: translateX(-50%);
  }

  &__no-items {
    font-size: 14px;
    color: $color-bali-hai;
  }

  &__counter {
    position: absolute;
    right: 16px;
    font-size: $font-size-s;
    color: rgba($tertiary-background-color, $dimmed-text-opacity);
  }
}
