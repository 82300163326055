
@import 'assets/scss/variables';
@import 'assets/scss/functions.scss';

.bookings-select-filter {
  display: flex;
  position: relative;
  align-items: center;
  border: 1px solid $color-silver;
  border-radius: 6px;
  align-self: stretch;
  padding-left: 6px;
  background-color: $color-white;

  &:hover {
    border: 1px solid $color-bondi-blue;
  }

  &:has(.react-select--menu-is-open) {
    border: 1px solid $color-bondi-blue;
  }

  &:has(.react-select--is-disabled) {
    opacity: 0.8;
    cursor: default;
  }

  & > .lazy-load__container {
    width: 13px;
    height: 13px;
    min-width: 13px;
  }

  &__icon {
    color: $color-dodger-blue;
    width: 15px;
    height: 13px;
    min-width: 13px;
  }

  .react-select {
    width: 100%;
    position: static;

    &__single-value {
      margin-left: 2px;
    }
    
    &__placeholder {
      color: hsl(0, 0%, 50%);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      letter-spacing: 1px;
    }
    
    &__control {
      display: flex;
      border-radius: 6px;
      justify-content: space-between;
      width: 100%;
      padding: 4px 5px;
      background-color: $color-white;
      font-size: 14px;
      transition: all 0.15s ease-in-out;
      color: $color-tundora;
      min-height: 30px;
      cursor: pointer;
    }
    
    &__indicator {
      color: $color-gray;
      padding: 0;
      
      &:nth-child(2) {
        display: none;
      }

      svg {
        width: 14px;
        height: 14px;
      }
    }
    
    &__menu {
      margin-top: -4px;
      width: 100%;
      left: 0;
      overflow: hidden;
    }

    &__menu-list {
      position: relative;
      @include scrollBars;
      width: 100%;

      & > div:hover {
        background-color: $color-bermuda;
      }
    }
    
    &__option {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      & .lazy-load__container {
        width: 13px;
        height: 13px;
        min-width: 13px;
        margin-left: -9px;
        margin-right: 9px;
        pointer-events: none;
      }
      
      &-text {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &:has(img) {
        display: flex;
        flex-direction: row;
        overflow: visible;
        justify-content: flex-start;
      }
    }
    
    &__single-value {
      position: relative;
      font-size: 14px;
      font-weight: normal;
      z-index: 2;
    }
    
    &--is-disabled {
      .agent-select__dropdown-indicator {
        display: none;
      }
    }

    &__placeholder {
      margin-left: 5px;
    }
  }
}
