@import 'assets/scss/variables';
@import 'assets/scss/functions.scss';

.chat-input {
  position: relative;
  padding-top: 2px;
  
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 12px;
    height: 42px;
  }

  &__indicator {
    display: flex;
    font-size: 13px;
    align-items: flex-end;
    width: 50%;
  }

  &__modal-wrap {
    z-index: 5 !important;
  }

  &__dispatch-types {
    display: flex;
    flex-wrap: nowrap;
    height: 100%;
    width: 60%;
  }
  &__dispatch-type-btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 52px;
    border-top: 2px solid transparent;
    color: rgba(0, 0, 0, 0.5);

    &--record-active {
      border-color: $color-red !important;
      color: $color-red !important;
    }
    
    &--active {
      background-color: #fff;
      border-color: $color-bondi-blue;
      box-shadow: 0 1px 2px 0px #88D6D9;
      color: $color-bondi-blue;

      &:after {
        content: '';
        position: absolute;
        bottom: -1px;
        width: 100%;
        height: 1px;
        background-color: #fff;
        z-index: 1;
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 50%;
    padding-left: 5px;

    &--disabled {
      pointer-events: none;
    }
  }
  &__btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    margin: 0 5px;
    padding: 0;

    &--is-hidden {
      visibility: hidden;
      width: 0;
      height: 0;
      position: absolute;
      bottom: 0;
    }

    &--template {
      &:before {
        content: '';
        position: absolute;
        bottom: 100%;
        width: 100%;
        height: 10px;
        // background-color: red;
      }
    }

    &--emoji {
      &:hover svg > g {
        fill: #0092f2;
      }
    }

    &-icon {
      width: 14px;
      height: 14px;
      color: $color-silver;

      &--selected {
        color: #00a1a9,
      }

      &--voice-record {
        width: 15px;
        height: 15px;
      }

      &--active {
        color: $color-bondi-blue;
      }
    }
  }

  &__template-list-wrap {
    position: absolute;
    z-index: 10;
    bottom: calc(100% + 5px);
    // height: 358px;

    &:before {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 5px;
    }
  }
  &__template-list {
    box-shadow: $shadow-primary;
    background-color: $color-white;
    color: $color-gray;
    padding: 0;
    overflow: auto;
    width: 240px;
    height: 100%;
    border-radius: 4px;

    &:empty {
      display: flex;
      min-height: 70px;

      &::before {
        content: 'No templates';
        display: block;
        text-align: center;
        color: $color-silver;
        margin: auto;
      }
    }
  }

  &__template-list-item {
    text-align: left;
    font-size: 12px;
    letter-spacing: 1px;
    padding: 11px 15px;

    &:nth-child(odd) {
      background-color: $color-bermuda;
    }

    &:nth-child(even) {
      background-color: rgba($color-bermuda, 0.3);
    }
  }

  &__comm-warning {
    @include MessageContainer;

    position: relative;
    min-height: 80px;
    padding: 15px 10px;
    color: $color-red;
    font-size: 14px;
    line-height: 19px;
  }

  &__form-container {
    position: relative;
    margin: 0 10px 10px;
    
    // &:has(.chat-input__record-container:not(:empty)) {
    //   border: 1px solid $color-border-focus;
    // }
  }
  
  &__form {
    display: flex;
    align-items: center;
    position: relative;
    padding: 0 10px;
    border: 1px solid $color-mystic;
    border-radius: 6px;
    background: $color-white;

    &:focus-within {
      border: 1px solid $color-border-focus;
    }
  }

  &__input {
    position: relative;
    flex-grow: 1;
    margin: 15px 5px 15px 0;
    padding: 0px 5px;
    outline: none;
    border: none;
    font-size: 14px;
    background: none;
    color: $color-tundora-light;
    resize: none;
    max-height: 250px;
    min-height: 50px;
    line-height: 1.2;
    border-radius: 5px;
    background-color: transparent;
    transition: background-color linear 1s;
    font-family: inherit;

    &--changed {
      background-color: #cce9fd;
      transition: background-color linear .05s;
    }

    &:disabled {
      filter: blur(1px);
      cursor: not-allowed;
    }

    &:focus > .chat-input__btn svg {
      fill: #01df85;
    }
    &::placeholder {
      line-height: 50px;
      font: 14px sans-serif;
      color: $color-silver;
      opacity: 1;
      letter-spacing: 0.88px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__send-btn {
    display: flex;
    padding: 2px;
    color: $color-gray;

    &-icon {
      width: 12px;
      height: 12px;
    }
  }

  &__audio-record {
    display: flex;
    padding: 2px;
    color: $color-gray;

    &-icon {
      color: red;
      width: 12px;
      height: 12px;
    }
  }

  &__shortcut {
    position: absolute;
    cursor: pointer;
    font-size: 13px;
    display: inline-block;
    padding: 2px 5px;
    background-color: #0092f2;
    color: #fff;
    border-radius: 3px;
    z-index: 3;
    overflow: hidden;
    width: 120px;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:hover {
      color: #0092f2;
      background-color: #fff;
      box-shadow: 0px 2px 10px #999999;
    }
  }

  &__indicator {
    display: flex;
    font-size: 13px;
    align-items: flex-end;
    padding-left: 4px;
  }

  &__indicator-wrap {
    display: flex;
    align-items: center;
    color: #bdbdbd;
    font-size: 11px;
    white-space: nowrap;
    gap: 6px;
  }

  &__record-container {
    @include MessageContainer('active');

    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    outline: none;
    font-size: 14px;
    color: $color-tundora-light;
    resize: none;
    max-height: 250px;
    min-height: 80px;
    line-height: 1.2;
    transition: background-color linear 1s;
    font-family: inherit;
    width: 100%;
    padding: 0 10px;
  }
  
  &__record-container:empty {
    display: none;
  }
  
  &__record-container:not(:empty) ~ * {
    display: none;
  }

  &__btn-record {
    padding: 2px;
  }

  &__btn-record-icon {
    display: block;
    color: red;
    width: 16px;
    height: 16px;

    &--active {
      color: #0092f2;
    }
  }


  &__voice-slider {
    display: flex;
  }

  &__slider-container {
    display: flex;
  }

  &__placeholder {
    color: $color-silver;
    letter-spacing: 1px;
    user-select: none;
  }
}

.message-forbid {
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 10px;
  font-family: Roboto;

  & ~ .chat-input__form,
  & ~ .chat-input__header {
    display: none;
    // position: fixed;
    // bottom: -50vh;
  }

  &__input {
    cursor: pointer;
    background-color: $color-white;
    border: 1px solid $color-red;
    border-radius: 6px;
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 16px;
    color: $color-red;
    margin: 0 10px 9px;
    width: calc(100% - 20px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    min-height: 80px;
  }

  &__text {
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.88px;
    color: $color-red;
  }
}

.emoji-mart {
  position: absolute;
  bottom: 29px;
  right: -12px;
  width: 250px !important;
  z-index: 6;
}
.emoji-mart-preview {
  display: none;
}

.voice-message {
  display: flex;
  position: relative;
  align-items: center;
  justify-items: center;
  gap: 16px;
  width: 70%;
  max-width: 300px;
  background-color: #fff;
  top: 50%;
  left: 0px;
  font-size: 14px;
  z-index: 1;

  &__record-button-close {
    display: flex;
    position: absolute;
    border-radius: 50%;
    background-color: $color-red;
    padding: 4px;
    right: -28px;
  }

  &__record-button-close-icon {
    height: 6px;
    width: 6px;
    color: $color-white;
    cursor: pointer;
  }

  &__record {
    width: 28px;
    height: 25px;
    display: flex;

    &:disabled {
      opacity: .4;
      cursor: default;
    }

    svg {
      z-index: 2;
    }
  }
  
  &__control {
    border: 2px solid $color-bondi-blue;
    border-radius: 50%;
    cursor: pointer;
  }

  &__control-icon {
    display: block;
    color: $color-bondi-blue;
    width: 10px;
    height: 10px;
    padding: 1.5px;
    
    &--play {
      transform: translateX(1px);
    }
  }

  &__slider {
    position: relative;
    height: 100%;
    width: 100%;

    &-path {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
    }

    &-left-path {
      background: $color-bondi-blue;
      opacity: 0.5;
      height: 100%;
      mix-blend-mode: color-dodge;
    }
  }

  &__thumb {
    position: absolute;
    top: 0;
    height: 100%;
    width: 2px;
    background-color: $color-bondi-blue;
    box-shadow: #0092f2;
  }

  &__record-bg {
    position: absolute;
    width: 26px;
    height: 25px;
    background-color: #0092f2;
    border-radius: 100%;
    z-index: 2;
    left: 1px;
    top: 0px;
    animation: startRecord 4s infinite, initRecord 0.5s;
  }
  &__time {
    display: block;
    font-size: 12px;
    font-family: Roboto;
    color: $color-gray;
    user-select: none;
  }
  &__indicator {
    width: 4px;
    height: 4px;
    border-radius: 100%;
    background-color: #dc3545;
    animation: indicator 1s infinite;
    margin: 4px 5px;
    flex-shrink: 0;
  }
  &__waves-container {
    height: 14px;
    overflow: hidden;
  }
  &__waves-wrapper {
    display: flex;
    gap: 2px;
    align-items: center;
    height: 100%;
    overflow: hidden;
  }
  &__waves-wrapper div {
    width: 2px;
    background-color: lighten($color-gray, 10%);
    flex-shrink: 0;
  }
  
  &__send {
    padding: 0;
    margin: 0;
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &::before {
      content: "";
      display: none;
      position: absolute;
      width: 22px;
      height: 22px;
      top: -2;
      left: -4px;
      border-radius: 100%;
      background: rgba(0, 146, 242, 0.2);
    }

    &:hover::before {
      display: block;
    }
  }
  &__stop {
    color: white;
    background-color: #dc3545;
    width: 12px;
    height: 12px;
    margin-left: 10px;
    position: relative;

    &::before {
      content: "";
      display: none;
      position: absolute;
      width: 22px;
      height: 22px;
      top: -5px;
      left: -5px;
      border-radius: 100%;
      background: rgba(220, 53, 69, 0.2);
    }

    &:hover::before {
      display: block;
    }
  }
}

.voice-message-slider {
  display: flex;
  width: 100%;
  gap: 16px;

  &__control {
    height: 14px;
    border: 2px solid $color-bondi-blue;
    border-radius: 50%;
    cursor: pointer;

    &--volume {
      display: none;
    }
  }

  &__control-icon {
    display: block;
    color: $color-bondi-blue;
    width: 10px;
    height: 10px;
    padding: 1.5px;
    
    &--play {
      transform: translateX(1px);
    }
  }

  &__slider {
    position: relative;
    height: 100%;
    width: calc(100% - 74px);

    &-path {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
    }

    &-left-path {
      background: $color-azure-radiance;
      opacity: 0.5;
      height: 100%;
      mix-blend-mode: color-dodge;
    }
  }

  &__thumb {
    position: absolute;
    top: 0;
    height: 100%;
    width: 1px;
    background-color: $color-bondi-blue;
  }

  &__time {
    display: block;
    font-size: 12px;
    font-family: Roboto;
    color: $color-gray;
    user-select: none;
  }

  &__control-panel {
    display: flex;
    flex-direction: row;
    gap: 14px;
    width: 100%;
  }
}

@keyframes startRecord {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1.5, 1.5);
  }
  50% {
    transform: scale(1, 1);
  }
  75% {
    transform: scale(1.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes initRecord {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes indicator {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes initRecordInfo {
  0% {
    opacity: 0;
    width: 0px;
  }
  99% {
    width: 119px;
  }
  100% {
    width: auto;
    opacity: 1;
  }
}

@keyframes prop {
  0% {
    left: 0;
  }
  100% {
    left: 100%;
  }
}

.custom-scroll {
  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-button {
    height: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    margin: 5px;
  }
}


