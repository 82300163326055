.feedback-form {
  width: 800px;
  border-radius: 6px;
  overflow: hidden;
  text-align: left;
  background-color: #fff;
  box-shadow: 0 16px 16px 0 rgba(0,0,0,0.08);

  &__title {
    padding: 13px 30px;
    color: #808080;
    font-size: 18px;
    background-color: #E6EBF1;
  }

  &__main {
    padding: 28px 31px 36px;
  }

  &__fields-group {
    margin-bottom: 93px;
    display: flex;
    flex-wrap: wrap;
    gap: 36px 28px;
  }

  &__submit-group {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 30px;
    gap: 30px;
  }

  &__cancel-button {
    padding: 6px 22px;
    border-radius: 6px;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 1px;
    background-color: #BFBFBF;
  }

  &__publish-button {
    padding: 11px 45px;
    border-radius: 6px;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 1px;
    background-color: #00A6AD;
    box-shadow: 0 12px 12px 0 rgba(0,0,0,0.12);

    &--validation-errors {
      opacity: 0.3;
    }
  }

  // Used in child components
  &__field-error {
    position: absolute;
    bottom: -3px;
    left: 11px;
    width: 100%;
    color: #FF5E57;
    font-size: 12px;
    line-height: 13px;
    letter-spacing: 1px;
    transform: translateY(100%);
  }
}
