@import 'assets/scss/variables';
@import 'assets/scss/functions.scss';

.sales-sessions-requests {
  padding: 12px 0;

  &__list-wrap {
    margin-right: -10px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-right: 10px;
  }
}

.session-request {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 3px 10px 6px;
  border: 1px solid $color-ripe-lemon;
  border-radius: 6px;

  &__main {
    display: flex;
    align-items: center;
  }

  &__img-wrap {
    width: 32px;
    height: 32px;
    min-width: 32px;
    margin-right: 5px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    font-weight: 500;
    font-size: 12px;
    color: $color-gray;
    gap: 2px;
  }

  &__avatar {
    border-radius: 100%;
    // background-color: red;
  }

  &__name {
    @include webkit-text-overflow;
  }

  &__title {
    margin-top: 5px;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 1px;
  }
  &__actions {
    display: flex;
    justify-content: space-evenly;
    margin-top: 5px;
  }

  &__btn {
    padding: 0 9px;
    height: 20px;
    border-radius: 6px;
    background-color: $color-black-squeeze-light;
    font-size: 12px;
    color: $color-bondi-blue;

    &--decline {
      color: $color-red-orange;
    }
  }
}
