@import "assets/scss/variables.scss";

.not-for-contact-modal {
  display: flex;
  flex-direction: column;
  height: 690px;
  border-bottom: 2px solid $color-persian-green;
  border-radius: 6px;
  overflow: hidden;
  background-color: $color-mystic;

  &__title {
    margin-bottom: 6px;
    padding: 0 20px;
    font-size: 12px;
    text-align: left;
    letter-spacing: 1px;
  }

  &__list-container {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    color: $color-gray;
    background-color: $color-white;
  }

  &__search-input {
    width: 330px;
    padding: 10px 40px 10px 10px;
  }

  &__spinner-wrap {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
  }

  &__wrap {
    padding: 34px 0 24px 0;
    background-color: $color-mystic;
  }

  &__list {
    padding: 13px 17px;
  }

  &__item {
    display: flex;
    margin-bottom: 11px;
    padding: 4px 7px;
    border-radius: 6px;
    font-size: 12px;
    cursor: pointer;
    background-color: $color-black-squeeze-light;

    &-img {
      overflow: hidden;
      background: transparent;
    }

    &-info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: left;

      & span {
        width: 100%;
        max-width: 270px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &:first-child {
          color: $color-dodger-blue;
        }
      }
    }

    .lazy-load__container {
      width: 35px;
      height: 35px;
      margin-right: 11px;
      overflow: hidden;
    }
  }

  .search-input {
    margin: 0 20px;
    &__icon {
      right: 11px;
      &--clear {
        width: 9px;
        height: 9px;
      }
    }
  }
}