@import 'assets/scss/variables';

.dialpad {
  --outer-background-color: rgb(var(--primary-color));

  display: flex;
  flex-direction: column;
  z-index: $modals-z-index;
  box-shadow: $default-modal-shadow;
  background-color: var(--outer-background-color);
  border-radius: $radius-xl;
  width: 508px;
  overflow: hidden;
  font-family: $font-family-main;

  &__header {
    position: relative;
    display: flex;
    align-items: center;
    height: 64px;
    padding: 16px;
    background-color: #E5F6F7;
    justify-content: space-between;
  }

  &__header-left {
    display: flex;
    flex-direction: row;
    height: 32px;
    width: 280px;
  }

  &__tabs {
    display: flex;
    flex-direction: row;
    gap: 10px;
    font-size: $font-size-m;
  }

  &__input-wrap {
    margin-right: 17px;
    width: 410px;
  }

  &__input {
    line-height: 19px;
    padding: 10px;
    border: none;
    border-radius: 6px;
    outline: none;
    border: solid 1px $color-white;
    letter-spacing: 0.88px;
  }

  &__btn-remove-all {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    position: absolute;
    cursor: pointer;
    padding: 15px;
    top: 14px;
    right: 310px;
    visibility: hidden;

    &--show {
      visibility: visible;
    }
  }

  &__icon-remove-all {
    fill: $color-gray;
    width: 12px;
    height: 12px;
  }

  &__btn-plus {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    border-radius: 50%;
    opacity: 1;
    margin-right: 18px;

    &:disabled {
      opacity: 0.3;
    }
  }

  &__icon-plus {
    width: 20px;
    height: 20px;
    fill: $color-bondi-blue;
  }

  &__tab {
    padding: 7px 9px;
    background-color: inherit;
    border-radius: 6px;
    border: solid 1px $color-white;
    color: $color-black;

    &--active {
      background-color: $color-white;
      color: $color-bondi-blue;
    }

    &--not-used {
      opacity: 0.3;
    }
  }

  &__content {
    display: flex;
    background-color: $color-white;
  }

  &__btns {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    padding: 16px;
    background-color: $color-white;
    flex-basis: 264px;
    gap: 8px;
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-text-primary-darkest;
    background-color: #E6EBF1;
    width: 72px;
    aspect-ratio: 1;
    border-radius: $radius-m;
    border: 1px solid $color-mecury;
    transition: all $default-animation;

    &:hover {
      opacity: 0.3;
    }

    &:focus {
      opacity: 1;
      background-color: $color-bondi-blue;
    }

    &--disabled {
      cursor: default;
      opacity: 0.6;

      &:focus {
        background-color: $color-bermuda;
      }
    }

    &--phone {
      background-color: #80d3d6;
    }
  }

  &__icon-phone {
    width: 24px;
    height: 24px;
    outline: none;
    fill: $color-white;
  }

  &__icon-arrow {
    transform: rotate(-90deg);
    fill: $color-froly;
  }

  &__list-wrapper {
    padding: 16px;
    padding-left: 0;
    flex-grow: 1;
  }

  &__tabs-wrapper {
    display: flex;
    justify-content: space-between;
    height: 24px;
  }
  &__list-missed-header {
    display: flex;
    justify-content: center;
  }
  &__clear-all-btn {
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 1px;
    background-color: #fff;
    border-radius: 6px;
    white-space: nowrap;
    padding: 5px 13px;
    display: flex;
    align-items: center;
    height: 20px;
    margin-bottom: 9px;

    &:hover {
      color: $color-white;
      background-color: $color-bondi-blue;
    }
  }
}



.dialpad-list {
  &__list {
    text-align: start;

    & > *:not(:last-child) {
      margin-bottom: 4px;
    }
  }

  &__list-container {
    height: 100%;
  }

  &__load-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  &__item {
    cursor: pointer;
    margin-bottom: 6px;
    padding: 4px 10px;
    background-color: $color-white;
    border-radius: 6px;

    &--resent-calls {
      display: flex;
      border: solid 0.5px $color-border-primary;
      margin-bottom: 3px;
      padding: 5px;
    }

    &--search {
      display: flex;
      border: solid 0.5px $color-border-primary;
      margin-bottom: 3px;
      padding: 5px;
    }
  }

  &__text-box {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__icon-telegram {
    position: absolute;
    right: 0;
    bottom: -3px;
    width: 16px;
    height: 16px;
    border: 1px solid $color-white;
    border-radius: 50%;
    background: $color-white;
    color: $color-azure-radiance;
    overflow: hidden;
  }

  &__date-box {
    display: flex;
    padding: 3px 2px;
    color: #4A4A4A;
    line-height: 1.3;
  }

  &__icon-incoming {
    margin-right: 7px;
    width: 12px;
    height: 12px;
    fill: $color-gray;
  }

  &__info {
    display: flex;
    justify-content: space-between;
  }

  &__contact-name {
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 3px;

    &--client {
      color: $color-dodger-blue;
    }

    &--girl {
      color: $color-azure-radiance;
    }

    &--agent {
      color: $color-seance;
    }

    &--recent {
      color: $color-dodger-blue;
    }
  }

  &__operator-name {
    text-transform: capitalize;
    max-width: 125px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    letter-spacing: 0;
    line-height: 15px;

    &--online {
      color: $color-azure-radiance;
    }

    &--bisy {
      color: $color-green-haze;
    }

    &--away {
      color: $color-selective-yellow;
    }

    &--offline {
      color: $color-red-orange;
    }
  }

  &__avatar-box {
    position: relative;
    margin-right: 5px;
    min-width: 32px;
    height: 32px;
  }

  &__avatar {
    border-radius: 50%;
    width: 32px;
    height: 32px;
  }

  &__tel {
    color: $color-gray;
  }

  &__no-items {
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
