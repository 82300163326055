@import 'assets/scss/variables';

.video{
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
  
  &__play{
    padding: 0;
    margin: 0;
    color: $color-persian-green;
    font-size: 18px;
    height: 19px;
    width: 16px;
  }
  &__controls{
    display: flex;
    height: 36px;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    transition: bottom .5s;
    background-color: $color-outer-space;
    align-items: center;
    justify-content: space-around;
    gap: 15px;
    padding: 0 15px;
    color: #fff; // FIXME:
  }
  &__volume{
    width: 100px;
    display: flex;
    align-items: center;
  }
  &__volume-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    color: #fff;
    transform: scale(1.2)
  }
  &__toggle-fullscreen{
    padding: 0;
    margin: 0;
    color: #fff;
  }
  &__time {
    user-select: none;
    font-size: 12px;
  }
  
  video {
    display: flex;
    align-items: center;
    background-color: #000;
    transform: scale(1.5);
    // width: 100%;
  }
}
