@import "assets/scss/variables.scss";

.contact-info {
  position: relative;
  margin-top: 16px;

  &__booking-content {
    overflow: hidden;
  }

  &__name {
    font-size: 12px;
    color: $color-azure-radiance;
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__booking-info {
    display: flex;
    font-size: 12px;
    color: $color-gray;
  }

  &__booking-data {
    color: $color-black;
  }

  &__booking-count {
    margin-right: 4px;
  }

  &__via {
    display: flex;
    align-items: center;
    margin: 0 10px 5px 0px;
    padding: 0;
    color: $color-black;
    font-weight: bold;
    font-size: 14px;

    &-label {
      max-width: 75px;
      margin-left: 3px;
      padding: 1px 9px;
      border-radius: 6px;
      overflow: hidden;
      color: $color-white;
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
      text-overflow: ellipsis;
      letter-spacing: 1px;
      white-space: nowrap;
      background-color: $color-black;
    }

    &-btn {
      &-icon {
        margin-right: 5px;
        color: $color-persimmon;

        &--telegram {
          position: absolute;
          top: 0;
          right: 0;
          margin: 0;
          margin: 0;
          color: $color-azure-radiance;
        }
      }
    }
  }

  &__header {
    padding: 3px 15px 28px;
    &-links {
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;
      .clipboard-btn__icon {
        width: 14px;
        height: 14px;
        color: $color-bondi-blue;
      }
    }
  }

  &__header-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    padding: 0 5px;
  }

  &__card {
    position: absolute;
    bottom: -2px;
    left: -2px;
    height: 10px;
    width: 14px;
  }

  &__img-wrap {
    cursor: pointer;
    position: relative;
    margin-right: 14px;

    .lazy-load__container {
      width: 90px;
      height: 90px;
      overflow: hidden;
    }

    img.lazy-load__image, 
    img.lazy-load__error {
      width: 90px;
      height: 90px;
    }

    &--booking {
      margin-right: 10px;

      & > .lazy-load__container {
        height: 32px;
        width: 32px;
      }
    }
  }

  &__links-row {
    display: flex;
    margin-right: 5px;
    align-items: flex-start;
  }
  
  &__contact-info {
    flex-direction: column;
    width: 225px;
    padding-top: 6px;

    &--age {
      display: block;
      margin-top: 10px;
      color: $color-tundora-light;
      font-size: 10px;
    }

    &--edit-link {
      display: flex;
      align-items: center;
      color: $color-azure-radiance;
      font-size: 12px;
      line-height: 16px;
      text-decoration: underline;
      letter-spacing: 0;
      flex-shrink: 0;
      cursor: pointer;
      svg {
        margin-right: 7px;
      }
    }

    &--agent {
      display: flex;
      align-items: flex-start;
      color: $color-seance;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0;
      margin-left: 15px;
      text-align: left;
      svg {
        margin-right: 7px;
        min-width: 12px;
        transform: translateY(2px);
      }
    }

    &--header {
      margin-bottom: 10px;
      text-align: left;
      color: $color-tundora-light;
      font-size: 10px;
      line-height: 13px;
      letter-spacing: 0.7px;
    }

    
    &--footer {
      width: 225px;
      display: flex;
      align-items: flex-start;
      color: $color-tundora-light;
      font-size: 14px;
      line-height: 19px;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__group {
    align-items: center;
    padding: 15px;
    line-height: 1.5;
    text-align: left;
    border-top: 1px solid $color-gray;

    &--icon-btn {
      margin-left: 14px;
    }

    &--bookings {
      text-align: center;
    }

    &-title {
      color: $color-gray;
      font-size: 14px;
      letter-spacing: 0;
      &--note {
        color: $color-black;
        font-size: 12px;
      }
      &--via {
        margin-right: 10px;
      }
    }

    &--via {
      display: flex;
      position: relative;
      flex-wrap: wrap;
      margin-bottom: 15px;
      padding-right: 25px;
    }

    &--phone {
      justify-content: space-between;
    }

    &--item {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      padding: 0 5px;
      color: $color-black;
      font-size: 12px;
      line-height: 16px;

      .clipboard-btn {
        margin: 0 8px 0 14px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &--phone,
    &--email {
      .contact-info__value {
        &-label {
          display: inline-block;
          min-width: 35px;
          margin-right: 4px;
        }

        span {
          vertical-align: top;
        }
      }
    }

    &--not-for {
      margin-top: 8px;
      padding: 0 15px;
      color: $color-dodger-blue;

      &-empty-field {
        margin-top: 8px;
        padding: 0 15px;
      }
    }

    &--note {
      margin-top: 5px;
      padding: 14px 6px 14px 14px;
      border: 1px solid $color-silver;
      border-radius: 6px;
      color: $color-gray;
      font-size: 12px;
      background-color: $color-white;
      & .contact-info__value--prewrap {
        flex-grow: 1;
      }
    }

    &--uid {
      display: flex;

      .contact-info__group--item {
        margin: 0 0 0 10px;
        padding: 0;
      }

      .contact-info__value {
        display: block;
        max-width: 208px;
        overflow: hidden;
        font-size: 12px;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .clipboard-btn__icon {
      width: 16px;
      height: 16px;
      color: $color-bondi-blue;
    }

    &--list {
      font-size: 13px;
      
      & li {
        display: flex;
        align-items: center;
        line-height: 2.5;
        max-width: 100%;
        
        & p {
          white-space: pre;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

  }

  &__girl {
    position: relative;
    padding-left: 10px;
    margin-left: -5px;

    :where(&--available, &--off-today, &--off)::before {
      content: "";
      position: absolute;
      top: 3px;
      left: 1px;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: black;
    }

    :where(&--available)::before {
      background-color: $color-caribian-green;
    }

    :where(&--off-today, &--off)::before {
      background-color: $color-persimmon;
    }
  }

  // &__tags {
  //   display: flex;
  //   flex-wrap: wrap;
  //   max-width: 100%;
  //   margin: 0;
  //   padding: 0;
  //   padding: 0 5px;
  //   list-style: none;
    
  //   .tag {
  //     padding: 2px 8px 3px;
  //     border: none;
  //     border-radius: 6px;
  //     color: $color-tundora-light;
  //     font-size: 11px;
  //     line-height: 15px;
  //     letter-spacing: 0;
  //     background-color: $color-athens-gray;
  //     margin-top: 5px;
      
  //     &:not(:last-child) {
  //       margin-right: 5px;
  //     }
  //     & .tag__title--bold {
  //       font-size: 12px;
  //       font-weight: bold;
  //     }
  //   }
  // }
  
  &__value {
    display: flex;
    align-items: center;
    font-size: 13px;
    text-align: left;

    &-btn {
      color: $color-bondi-blue;
    }

    &--link {
      word-break: break-all;
      svg {
        font-size: 13px;
      }
    }

    &--prewrap {
      white-space: pre-wrap;
      word-break: break-all;
    }

    &--pre {
      white-space: pre-wrap;
    }

    &--trim {
      display: flex;
      align-items: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &--label {
      margin-left: 4px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    svg {
      margin-right: 7px;
      color: $color-gray;
      flex-shrink: 0;
    }
  }

  &-lock-form {
    &__label {
      color: $color-gray;
      font-size: 14px;
      letter-spacing: 0;
    }

    &__group {
      display: flex;
      align-items: center;
      padding: 15px;
      border-top: 1px solid $color-gray;
      line-height: 1.5;
      text-align: left;

      .lock-form {
        &__name {
          max-width: 80px;
          margin: 0 5px;
          overflow: hidden;
          white-space: nowrap;
        }

        &__block-send-media-toggle {
          &-label {
            display: flex;
            cursor: pointer;

            .checkbox {
              align-self: center;
            }

            .checkbox__indicator {
              width: 15px;
              height: 15px;
              background-color: $color-white;
            }
          }

          &-span {
            width: auto;
            margin-left: 8px;
            color: $color-black;
          }
        }
      }
    }
  }

  &__link-label {
    margin-right: 10px;
    text-transform: capitalize;
  }

  &__link {
    color: $color-azure-radiance;
    text-decoration: underline;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
  }
}
