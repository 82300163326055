@import 'assets/scss/variables';

.gallery-header {
  grid-area: header;

  &__main {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 10px;
  }

  &__best-matching-count {
    color: $color-gray;
    font-size: 14px;
  }

  &__show-filters-btn {
    position: absolute;
    left: 50%;
    display: flex;
    align-items: center;
    transform: translateX(-50%);
    padding: 5px 9px;
    border-radius: 6px;
    background-color: #F6F9FC;
    font-size: 12px;
  
    &--filters-opened {
      background-color: #88D6D9;
    }

    &-icon {
      margin-right: 6px; 
      width: 12px;
      height: 12px;
      color: $color-bondi-blue;
    }
  }

  &__sort-select {
    margin-left: auto;
  }

  .react-select {
    &__control {
      padding-left: 0;
    }
  }
}

.gallery-header-sort-select .react-select {
  &__control {
    flex-direction: row-reverse;
  }

  &__indicator {
    padding: 0 3px;
  }

  &__dropdown-indicator svg {
    transform: translateY(-2px);
    width: 21px;
    height: 21px;
  }

  &__single-value,
  &__placeholder {
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 1px;
  }

  &__menu {
    right: 0;
  }
}