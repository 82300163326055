@import 'assets/scss/functions.scss';

.edit-contact-form {
  &-img-cropper {
    z-index: 999;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    // width: 100%;
    height: 100%;
    // background: rgba(0, 0, 0, 0);
    // animation: fadeIn 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;

    background: $color-white;
    width: 470px;
    border-radius: 6px;
    overflow: hidden;

    &.close {
      animation: translateDown 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      background-color: $color-mystic;
      padding: 23px 21px 23px 15px;
      color: $color-gray;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 24px;
      box-shadow: 0 16px 16px 0 rgba(0,0,0,0.08);

      &-btn {
        color: $color-gray;

        & svg {
          width: 12px;
          height: 12px;
        }
      }
    }

    &__toggle-wrap {
      color: $color-black;
      font-size: 16px;
    }

    &__ava-editor-wrap {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px;
      background-color: $color-white;
    }

    &__ava-cropper {
      display: flex;
      position: relative;
      flex-direction: column;
      height: 180px;
      width: 180px;

      & > div > img {
        width: inherit;
        min-width: unset;
        height: inherit;
        object-fit: unset;
      }

      &-wrap {
        position: relative;
        width: 470px;
        border-radius: 6px;
        overflow: hidden;
        display: flex;
        height: 776px;
        flex-direction: column;
        background-color: $color-alto;
        animation: translateUp 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
      }

      &-avatars {
        flex-grow: 1;
        background-color: $color-black-squeeze-light;
        padding: 30px 10px 20px 15px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        &-list {
          position: relative;
          display: flex;
          flex-wrap: wrap;
          gap: 15px;
          max-height: 402px;
          overflow-y: scroll;
          @include scrollBars;

          & .spinner {
            position: absolute;
            inset: 0;
            margin: auto;
          }

          &-item {
            width: 96px;
            height: 96px;
            cursor: pointer;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-color: $color-gallery;
          }
        }
        
        &-title {
          color: $color-gray;
          font-family: Roboto;
          font-size: 18px;
          letter-spacing: 0;
          line-height: 24px;
          text-align: left;
        }
      }

      &-options-bar {
        // display: flex;
        position: absolute;
        bottom: 60px;
        align-items: center;
        width: 250px;
        height: 26px;
        // margin: 0 20px;
        transform: rotate(90deg);
        right: 0;
        bottom: 44%;
        left: 146px;
        width: 184px;
      }

      &-navigation-bar {
        display: flex;
        gap: 12px;
        z-index: 2;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
      }

      &-btn-file {
        &-cancel, &-upload, &-save {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 40px;
          width: 170px;
          margin: 0 5px;
          padding: 0;
          border-radius: 6px;
          color: $color-white;
          font-weight: bold;
          cursor: pointer;
          transition: 0.14s all;
          font-family: Roboto;
          font-size: 16px;
          letter-spacing: 1px;
          line-height: 21px;
          box-shadow: 0 12px 12px 0 rgba(0,0,0,0.12);
        }

        &-cancel {
          background-color: $color-silver;
        }

        &-upload {
          background-color: $color-azure-radiance;
        }

        &-save {
          background-color: $color-bondi-blue;
        }
      }
    }
  }
}