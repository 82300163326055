@import '../../assets/scss/variables.scss';

.lock-form {
  width: 100%;
  padding: 10px;
  border: 1px solid $color-gallery;
  text-align: left;
  background-color: $color-white;
  box-shadow: 0 2px 2px $color-silver;

  &__block-send-media-toggle {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    align-self: center;
    color: $color-gray;
    font-size: 14px;
    &-span {
      width: 130px;
    }
  }

  &__name {
    width: 160px;
    color: $color-azure-radiance;
    font-weight: bold;
    font-size: 12px;
    text-align: left;
    text-overflow: ellipsis;
  }

  &__label {
    flex-basis: 80px;
    font-size: 12px;
    white-space: nowrap;
    &--hard {
      position: absolute;
      right: 170px;
    }
  }
  &__group {
    display: flex;
    position: relative;
    align-items: center;
    height: 30px;
    margin: 14px 0;
  }
}