@import 'assets/scss/variables';
@import 'assets/scss/functions.scss';

.filters-editor {
  @include ModalWindow(786px, 952px, column);
  @include ModalWindowHeaderButtons;

  &__input {
    height: 40px;
    width: 100%;
    
  }
  
  &__btn {
    display: flex;
    align-items: center;
    font-size: 12px;
    padding: 0;

    &--cancel {
      color: $color-persimmon;
    }

    &--submit {
      margin-left: 22px;
      color: $color-bondi-blue;

      &::after {
        content: "";
        mask-image: url(../../../../assets/icons/check.svg);
        mask-size: 20px;
        background-color: $color-bondi-blue;
        width: 20px;
        height: 20px;
        margin-left: 8px;
      }

      &:disabled {
        color: $color-gray;
        cursor: default;

        &::after {
          background-color: $color-gray;
        }
      }
    }
  }
  
  &__header {
    height: 54px;
    min-height: 54px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    padding: 0 26px,
  }

  &__main-container {
    padding: 14px 26px 26px,
  }

  &__lists-zone {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 32px;
    height: 100%;
  }

  &__zone {
    position: relative;

    &--services {
      &::after {
        content: '';
        position: absolute;
        height: 100%;
        width: 1px;
        top: 0;
        right: -16px;
        background-color: gray;
      }
    }
  }

  &__zone-title {
    padding: 0 12px;
    margin-bottom: 28px;
    text-transform: capitalize;

    &--services {
      text-align: right;
    }
  }

  &__zone-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    border-top: 1px solid $color-gray;
    padding: 16px 6px;
  }

  &__list-container {
    display: flex;
    height: 100%;
  }

  &__services {
    height: 100%;
  }

  &__list-wrap {
    height: 100%;
    width: 100%;
  }

  &__list {
    height: 100%;
    list-style: none;
  }

  &__lists-zone {
    overflow: hidden;
  }

  &__search-input {
    width: 189px;
    height: 40px;
    margin-bottom: 16px;
  }

  &__list-item-wrap {
    display: flex;
    border: 2px solid black;
    border-radius: 6px;
    height: 30px;
    justify-content: space-between;
    align-items: center;
    padding: 0 22px;
    color: $color-black;
    letter-spacing: 1px;
    top: auto !important;
    left: auto !important;
    margin-bottom: 4px;

    &--filters {
      border-color: #0996F2;
    }

    &--services {
      border-color: #FFBF35;
    }

    &--filters-choosen {
      background-color: #B2DEFB;
      border-color: #B2DEFB;
    }

    &--services-choosen {
      background-color: #FBEFB6;
      border-color: #FBEFB6;
    }
  }

  &__list-item {
    width: 80%;
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__add-list-item {
    height: 100%;
    width: 26px;
    position: relative;
    cursor: pointer;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 2px;
      width: 12px;
      background-color: #3BC864;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 12px;
      width: 2px;
      background-color: #3BC864;
    }
  }

  &__close-list-item {
    height: 100%;
    width: 26px;
    position: relative;
    cursor: pointer;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      height: 12px;
      width: 2px;
      background-color: #FF584F;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
      height: 12px;
      width: 2px;
      background-color: #FF584F;
    }
  }
}
