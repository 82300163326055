@import 'assets/scss/variables';

.booking-attachment {
  position: relative;
  align-items: center;
  gap: 8px;
  height: auto;
  padding: 7px 5px;
  background: #ffffff;
  border-radius: 6px;
  font-size: 12px;
  cursor: pointer;
  height: 45px;
  align-items: flex-end;

  &--deleted {
    opacity: 0.5;

    .booking-status1 {
      color: $color-red;
    }
  }

  &-escort__avatars-container {
    gap: 4px;
  }

  &-escort__container {
    font-weight: 500;
    font-style: italic;
    color: $color-black;
    letter-spacing: 1px;
    flex-grow: 1;

    & :is(img, .lazy-load__container) {
      width: 32px;
      height: 32px;
      min-width: 32px;
      border-radius: 50%;
    }

    &>div>div {
      gap: 4px;
    }
  }

  &__details {
    font-weight: normal;
    font-style: normal;
    letter-spacing: normal;
  }

  &__operator {
    position: absolute;
    right: 11px;
    bottom: 5px;
    flex-shrink: 0;
    font-weight: 500;
    font-style: italic;
    letter-spacing: 1px;
    z-index: 2;
  }

  &__status {
    color: $color-red;
    font-weight: 500;
    text-transform: capitalize;
    letter-spacing: 1px;
    position: absolute;
    right: 11px;
    top: 5px;
    z-index: 2;
  }
}
