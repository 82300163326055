@import 'assets/scss/variables';

.mailing-log {
  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    line-height: 1.4;
    letter-spacing: 0.88px;
    
    &--redirected {
      & .mailing-log__redirect-btn {
        color: $color-bondi-blue;
      }

      & .mailing-log__message {
        background-color: $color-aqua-island;
      }
    }
  }
  
  &__time {
    font-size: 12px;
    position: relative;
    font-style: italic;
    color: black;
    margin-bottom: 4px;

    &--delivered::before {
      content: '';
      position: absolute;
      mask-image: url('../../../../../../assets/icons/check.svg');
      mask-size: cover;
      width: 12px;
      height: 12px;
      background-color: $color-japanese-laurel;
      left: -16px;
      top: 2px;
    }
  }

  &__load-wrap {
    display: flex;

    & svg {
      margin: auto;
    }
  }

  &__message {
    display: flex;
    align-items: center;
    position: relative;
    padding: 10px;
    border: 1px solid $color-mystic;
    border-radius: 6px;
    background: $color-white;
    min-height: 80px;
    width: 100%;
    overflow-wrap: anywhere;

    &--loading {
      position: relative;

      opacity: 0.7;
      animation-duration: 1.8s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-name: placeHolderShimmer;
      animation-timing-function: linear;
      background: #f6f7f8;
      background: linear-gradient(to right, #fafafa 8%, #f4f4f4 38%, #fafafa 54%);
      background-size: 1000px 640px;
    }

    &--forwarded {
      background-color: $color-aqua-island;
    }
  }

  &__list .username-label {
    background-color: $color-silver; 
  }
}

@keyframes placeHolderShimmer{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}
