@import 'assets/scss/variables';
@import 'assets/scss/functions';

.button {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 22px;
  border-radius: 6px;
  font-size: 14px;
  letter-spacing: 0.875px;
  white-space: pre;
  text-align: left;

  & svg {
    min-width: 16px;
    width: 16px;
    flex-shrink: 0;
  }

  &--primary {
    background-color: $color-athens-gray;
    color: $color-tundora-light;

    & svg {
      color: $color-bondi-blue;
    }
  }

  &--secondary {
    border: 1px dashed $color-tundora-light;

    & svg {
      color: $color-tundora-light;
    }
  }

  &--inversion {
    background-color: $color-bondi-blue;
    color: $color-athens-gray;
  }

  &--inversion-light {
    background-color: $color-bermuda;
    color: $color-black;

    & svg {
      color: $color-bondi-blue;
    }
  }

  &--modal {
    background-color: $color-white;

    & svg {
      color: $color-bondi-blue;
    }
  }

  &--dim {
    background-color: $color-silver;
    color: $color-white
  }

  &--light {
    background-color: $color-white;
    color: $color-tundora-light;

    & svg {
      color: $color-bondi-blue;
    }
  }

  &--none {
    padding: 0;
    color: $color-text-primary-light;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  & .typing-indicator {
    zoom: 2.5;
    
    & > span {
      background-color: $color-white;
    }
  }
}
