@import "assets/scss/variables.scss";

.chat-header {
  --header-height: 48px;
  --content-padding: calc(var(--header-height) / 5);

  display: flex;
  flex-direction: row;
  position: relative;
  color: $color-black;
  position: relative;
  background-color: rgba($secondary-background-color, 0.07);
  height: var(--header-height);
  border-radius: $radius-s $radius-s 0 0;

  &--with-agent {
    background-color: rgba(253, 5, 232, 0.15);

    .chat-header__name {
      color: rgb(151, 151, 151);
    }
  }

  &:hover .booked-dropdown {
    display: flex;
    align-items: center;
    padding: 0 7px;
    opacity: 1;
    height: 30px;
    box-shadow: -2px 2px 4px -1px rgba(0, 0, 0, 0.2);
  }

  &::after {
    box-sizing: border-box;
    display: inline-block;
    width: 7px;
    height: 7px;
    position: absolute;
    border-radius: 50%;
    top: 3px;
    left: 8px;
    transition: all 0.2s ease-in;
  }

  &--available::after {
    content: "";
    background-color: rgb(1, 223, 133);
  }

  &--off::after {
    content: "";
    background-color: #f8737f;
  }

  &__floating-container {
    position: absolute;
    inset: 0;
    z-index: $floating-buttons-z-index;
    background-color: $primary-background-color;
  }

  &__floating-content {
    display: flex;
    flex-direction: column;
    gap: 4px;
    background-color: rgba($secondary-background-color, 0.07);
    height: 100%;
    padding: 8px 16px;
  }

  &__floating-header {
    display: flex;
    align-items: center;
    gap: 8px;
    color: rgba($tertiary-background-color, 0.4);
    align-items: flex-start;
    margin-bottom: 8px;
    height: 28px;
    justify-content: space-between;
  }

  &__floating-title {
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: center;
    line-height: 10px;

    &>svg {
      height: 14px;
      aspect-ratio: 1;
    }
  }

  &__row {
    margin-bottom: 6px;
  }

  &__additional-info {
    background-color: rgba($tertiary-background-color, 0.07);
  }

  &__fn-tooltip-wrapper {
    position: absolute;
    display: flex;
    align-items: center;
    z-index: 100;
    box-shadow: $shadow-primary;
    justify-content: center;
    text-align: center;
    background: $color-white;
    border-radius: 6px;
    font-size: 13px;
    max-width: 240px;
    width: 100%;
    min-height: 38px;
    color: $color-gray;
    padding: 10px;

    &>span {
      position: absolute;
      z-index: 2;
      width: 5px;
      height: 5px;
      top: -3px;
      left: 50%;

      &::before {
        content: '';
        position: absolute;
        transform: translateX(-50%) rotate(45deg);
        width: 8px;
        height: 8px;
        background: inherit;
        background-color: #fff;
      }
    }
  }

  &__ava-wrap {
    position: relative;
    height: 100%;
    aspect-ratio: 1;
    padding: 4px;
    margin: 0 8px;
    flex-shrink: 0;
    cursor: pointer;
  }

  &__ava {
    border-radius: 50%;
  }

  &__info {
    position: relative;
    display: flex;
    flex-grow: 1;
  }

  &__contact-details {
    position: absolute;
    width: 67%;
    left: 0;
    top: 0;
    bottom: 0;
    min-width: 0;
    gap: 6px;
    padding: var(--content-padding) 0;
    line-height: 17px;
    cursor: pointer;
  }

  &__id {
    font-size: $font-size-s;
    line-height: $font-size-s;
    color: var(--dimmed-font-color);
    min-width: 50px;
  }

  &__card {
    width: 14px;
    height: 10px;
  }

  &__ex {
    margin-right: 2px;
    line-height: 1.4;
    color: #f27e3f;
    font-weight: bold;
  }

  &__name {
    overflow: hidden;
    min-width: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $color-black;
    font-size: 12px;
    cursor: pointer;

    & > svg {
      margin-left: 4px;
      height: 10px;
      aspect-ratio: 1;
    }
  }

  &__details {
    margin-right: 10px;
  }

  &__rating {
    font-size: $font-size-m;
    line-height: $font-size-m;
    width: 17px;
  }

  &__reviews {
    color: rgb(var(--blue-status-color));
    font-size: $font-size-m;
    line-height: $font-size-m;
    cursor: pointer;
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 16px;
    flex-grow: 0;

    &>* {
      height: 50%;
    }

    &--girl {
      margin-left: 40%;
    }
  }

  &__btn {
    display: flex;
    min-width: 13px;
    height: 13px;
    margin: 0 4px;
    padding: 0;
    color: $color-gray;
    cursor: pointer;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &:disabled {
      cursor: not-allowed;
    }

    &--pin {
      margin: 0 2px;
    }

    &--disabled {
      color: $color-froly;
    }
  }

  &__bookings-block {
    display: flex;
    align-items: center;
    margin-right: 7px;
  }

  &__booking-icon-eye {
    width: 15px;
    height: 10px;
    fill: $color-red-orange;
  }

  &__bookings-count {
    margin-right: 6px;
    color: $color-tundora;
  }

  &__icon {
    width: 13px;
    height: 13px;

    &--taken {
      color: $color-bondi-blue;
    }
  }

  &__btn {
    &--info {
      position: absolute;
      right: 0px;
      top: 4px;
      padding: 3px 10px;
      cursor: pointer;
      border: none;
      outline: none;
      background: transparent;
    }

    &--agent {
      color: #0092f2;
      display: flex;
      align-items: center;
      text-decoration: underline;
      text-align: left;

      &:hover {
        color: #000;
        text-decoration: none;
      }

      &:hover svg {
        fill: #000;
      }

      svg {
        margin-right: 10px;
      }
    }
  }

  &__available-switcher {
    position: absolute;
    top: 12px;
    right: 52px;
  }

  &__text-line {
    margin: 0;
  }

  &__text {
    white-space: pre-line;
    font-size: 13px;
    border: none;
    outline: none;
    box-shadow: none;
    resize: none;
    text-align: center;
    width: 100%;
    padding: 0;

    &--link {
      color: blue;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__actual-bookings {
    font-size: 15px;
    padding: 0px 3px !important;
  }

  &__middle {
    display: flex;
    flex-direction: row;
    // padding: 2px 1px 2px 3px;
  }

  &__tags {
    flex-grow: 1;
    padding: 0 6px;
    margin-bottom: 6px;
  }

  &__row {
    padding: 0 6px;
  }

  &__tag {
    background-color: $color-white;
  }

  &__text-line {
    display: flex;
    align-items: center;
    text-align: left;
    margin: 2px 0;
  }

  &__btn--share {
    position: absolute;
    background-color: #fff;
    padding: 2px 20px;
    left: calc(50% - 28px);
    cursor: pointer;
    border: none;
    outline: none;
    top: 8px;

    &:active {
      top: 6px;
    }
  }

  &__footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 12px;
    position: relative;
    padding-bottom: 6px;
  }

  &__booked-dropdown {
    position: absolute;
    bottom: -10px;
    right: -10px;
    width: 80px;
  }

  &__img {
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }

  &--hidden {
    height: 74px;
    background-color: #fff;
  }

  &--hidden>&__header,
  &--hidden>&__footer {
    display: none;
  }

  &__block-info {
    border: 1px solid rgba(27, 31, 35, 0.15);
    position: absolute;
    background: $color-white;
    border-radius: 5px;
    font-size: 14px;
    padding: 5px;
    z-index: 2;
    box-shadow: 0 3px 12px rgba(27, 31, 35, 0.15);

    span {
      font-weight: bold;
      color: $color-bondi-blue-dark;
    }
  }

  &__exclusive {
    // margin-left: 4px;
    font-size: 13px;
    color: $color-ripe-lemon;
  }

  &__contact {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-grow: 1;
    font-size: 12px;
  }

  &__contact-tels,
  &__contact-emails {
    display: flex;
    justify-content: flex-start;
  }

  &__contact-tels {
    // margin-left: 4px;

    & .react-select__control {
      width: max-content;
    }

    & .react-select__value-container {
      overflow: visible;
      width: min-content;
    }

    & .react-select__single-value {
      width: max-content;
    }
  }

  &__select-wrap {
    min-width: 0;
    justify-content: flex-start;
  }

  &__contact-emails {
    min-width: 0;

    & .react-select__menu {
      right: 0;
    }
  }

  &__contact-groups {
    width: 100%;

    &-title-list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      border-bottom: 1px solid $color-bondi-blue;
    }

    &-title {
      margin-right: 15px;
      padding: 3px 5px;
      background-color: $color-mystic;
      color: $color-gray;
      border-radius: 6px 6px 0 0;
      font-size: 12px;
      text-transform: capitalize;
      cursor: pointer;

      &:first-child {
        margin-left: 10px;
      }

      &--active {
        background-color: $color-bondi-blue;
        color: $color-white;

        .chat-header__contact-groups-title-btn-icon {
          color: $color-white;
        }
      }
    }

    &-title-btn {
      width: 18px;
      height: 18px;
      padding-left: 5px;
      transform: translateY(2px);

      &-icon {
        transform: rotate(-90deg);
        color: $color-bondi-blue;
      }

      &--active {
        .chat-header__contact-groups-title-btn-icon {
          transform: rotate(90deg);
        }
      }
    }

    &-name-list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 0 3px;
    }

    &-name {
      padding: 3px 10px;
      border-radius: 6px;
      background-color: $color-bermuda;
      color: $color-black;
      font-size: 11px;
      letter-spacing: 0.5px;
      margin: 5px 3px;
    }
  }

  &__select-wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-width: 0;
    height: 20px;

    .react-select {
      &__option {
        &:last-child {
          color: $color-azure-radiance;
          border-radius: 0 0 6px 6px;
          background-color: inherit !important;
        }
      }

      &__control {
        padding-left: 26px;
      }
    }
  }

  &__select-btn {
    position: absolute;
    top: 50%;
    left: 6px;
    z-index: 1;
    transform: translateY(-50%);
    display: flex;
    padding: 0;

    &--email {
      cursor: default;
    }
  }

  &__select-icon {
    width: 12px;
    height: 12px;
    color: $color-silver;

    &--comm-available {
      color: $color-red;
    }
  }

  &__add-new-btn {
    padding: 0;
    color: $color-bondi-blue;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__action-menu {
    margin: -5px -7px 0 0;
  }

  &__rates {
    $indent: 5px;

    position: absolute;
    z-index: $tooltips-z-index;
    top: calc(100% + #{$indent});

    &:before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 100%;
      width: 100%;
      height: #{$indent};
      background-color: transparent;
    }
  }
}

.chat-header__contact-tels {
  .icon-menu {
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 4px;

    width: 26px;

    border-radius: 6px 6px 0 0;
    color: #808080;

    &.menu-open {
      color: #00a6ad;
      background-color: #fff;
    }

    svg {
      width: 12px;
      height: 12px;
    }
  }

  .icon-menu-popup {
    min-width: 240px;
    border-radius: 0 0 6px 6px;
  }

  .popup-menu {
    overflow: hidden;
    border-radius: 0 0 6px 6px;
  }

  .popup-menu__item {

    &:first-child,
    &:last-child {
      border-radius: 0;
    }
  }

  .foreign-num {

    .react-select__single-value {
      color: #F8737F !important;
    }
  }
}

.attachment-contact__btn+.call-btn__dropdown {
  top: 75%;
}

.contact-bookings {
  &__open {
    position: absolute;
    right: 0px;
    top: 5px;
    cursor: pointer;
    font-size: 17px;
  }
}

.textDimensionCalculation {
  position: absolute;
  visibility: hidden;
  height: auto;
  width: auto;
  white-space: nowrap;
}
