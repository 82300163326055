@import "assets/scss/variables.scss";

.title {
  // color: rgba($tertiary-background-color, $dimmed-text-opacity);
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: color $default-animation;
  position: relative;
  border-radius: 9999px;
  padding: 0 8px;
  gap: 8px;

  &--clickable:hover {
    cursor: pointer;
    background-color: rgba($tertiary-background-color, $highlighted-opacity);
    box-shadow: $default-control-elements-hover-shadow;
    color: rgba($darker-tertiary-background-color, 1);
  }

  &__text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__icon {
    height: 100%;

    &--overflowed {
      position: absolute;
      height: 10px;
      width: 10px;
      right: 12px;
    }
  }
    
  &__icon-wrapper {
    height: 16px;
    aspect-ratio: 1;
  }

  &__count {
    display: block;
    margin-left: 4px;
  }
}
