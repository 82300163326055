@import 'assets/scss/variables';

.profile-carousel {
  display: flex;
  flex-direction: column;
  height: 836px;
  width: calc(100vw - 260px);
  max-width: 1370px;
  position: relative;

  &__holder {
    position: relative;
    width: 100%;
  }

  &__header {
    position: relative;
    display: flex;
    height: 46px;
    align-items: center;
    color: $color-white;
    margin-bottom: 40px;
    font-size: 14px;
    letter-spacing: 0.88px;
    // text-transform: capitalize;
  }

  &__esc-btn {
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    padding: 14px 0 14px 14px;
    color: $color-white;
    font-size: 14px;
    letter-spacing: 0.88px;

    &-icon {
      width: 13px;
      height: 13px;
      margin-right: 10px;
    }
  }

  &__back-btn {
    position: absolute;
    display: flex;
    align-items: center;
    top: 70px;
    padding: 14px 14px 14px 0;
    color: $color-white;
    font-size: 14px;
    z-index: 1;

    &-icon {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }
  }

  &__controller {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 50%;
    transform: translateY(90%);
    padding: 25px;
    color: $color-white;
    cursor: pointer;

    &--prev {
      left: 25px;
    }

    &--next {
      right: 25px;
    }

    &:disabled {
      opacity: 0.5;
      cursor: default;
    }
  }

  &__controller-icon {
    width: 53px;
    height: 53px;

    &--prev {
      transform: rotate(90deg);
    }

    &--next {
      transform: rotate(-90deg);
    }
  }
  
  &__image-inner {
    position: absolute;
    height: 750px;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: none;
    transition: opacity 0.5s;
    opacity: 0;
    overflow: hidden;

    &--active{
      pointer-events: all;
      opacity: 1;
    }
  }
  &__image, .lazy-load__error{
    width: max-content;
    transition: opacity .5s;
    object-fit: contain;
    margin: auto auto 0px;
  }
  &__prev-slide, &__next-slide{
    user-select: none;
    position: absolute;
    z-index: 2;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 225px;
    height: 150px;
    cursor: pointer;
    
    &:hover{
      background-color: rgba(236, 226, 226, 0.3);
    }
  }
  &__prev-slide{
    left: calc(50% - 225px);
  }
  &__next-slide{
    left: 50%;
  }
}
