@import 'assets/scss/variables';

.booking-badges {
  &__item {
    font-weight: bold;

    &--duo {
      color: $color-razzle-dazzle-rose;
    }
  }
}
