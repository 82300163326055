@import 'assets/scss/variables';

// .checkbox {
//   $transition-duration: 0.05s;

//   position: relative;
//   display: inline-flex;
//   cursor: pointer;

//   &__input {
//     position: relative;
//     cursor: inherit;
//     position: absolute;
//     opacity: 0;
//     width: 100%;
//     height: 100%;
//     top: 0;
//     left: 0;
//     margin: 0;
//     padding: 0;
//     z-index: 1;

//     &:checked + .checkbox__indicator {
//       &:before {
//         width: calc(100% - 6px);
//         height: calc(100% - 6px);
//       }
//     }

//     &:focus-visible + .checkbox__indicator:after {
//       box-shadow: $shadow-focus;
//     }
//   }

//   &__indicator {
//     position: relative;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     width: 16px;
//     height: 16px;
//     cursor: pointer;

//     &::after {
//       content: "";
//       position: absolute;
//       width: inherit;
//       height: inherit;
//       border: solid 2px $color-border-primary;
//       border-radius: $radius-xs;
//       background-color: $primary-background-color;
//       transition: border-color linear $transition-duration;
//     }

//     // &::before {
//     //   content: '';
//     //   position: absolute;
//     //   width: 0;
//     //   height: 0;
//     // }
//   }

//   // &--rectangled {
//   //   & .checkbox__indicator::before{
//   //     border-radius: 2px;
//   //     background-color: $color-bondi-blue;
//   //     transition: all linear $transition-duration;
//   //   }

//   //   & .checkbox__input:checked + .checkbox__indicator::after {
//   //     border-color: $color-bondi-blue;
//   //   }

//   //   & .checkbox__input:focus-visible + .checkbox__indicator::after {
//   //     border-color: $color-bondi-blue;
//   //   }
//   // }

//   & .checkbox__indicator::before{
//     mask-image: url(../../assets/icons/check.svg);
//     mask-size: 95%;
//     background-color: $color-screaming-green;
//   }

//   & .checkbox__input:checked + .checkbox__indicator {
//     &::after {
//       border-color: $color-screaming-green;
//     }
//   }

//   & .checkbox__input:focus-visible + .checkbox__indicator::after {
//     border-color: $color-screaming-green;
//   }
// }
$main: black; //#008cff;
$grey: #8d9aa9;
$bezier: cubic-bezier(0.65, 0.25, 0.56, 0.96);

.mcui-checkbox,
.mcui-radio {
  display: inline-flex;
  align-items: center;
  user-select: none;
  box-sizing: border-box;

  input[type="checkbox"],
  input[type="radio"] {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
  }

  input[type="checkbox"] + div,
  input[type="radio"] + div {
    display: flex;
    border: 2px solid rgba($tertiary-background-color, $focused-border-opacity);
    height: 16px;
    width: 16px;
    box-sizing: border-box;
    border-radius: 6px;
    background-color: $primary-background-color;
    position: relative;
    transition: border-color $default-animation;

    &:hover {
      border-color: rgba($secondary-background-color, $focused-border-opacity);
    }

    & > svg {
      width: 80%;
      aspect-ratio: 1;
      margin: auto;
      color: $primary-background-color;
    }
  }

  input[type="radio"] + div {
    border-radius: 50%;

    &::after {
      content: "";
      position: absolute;
      left: 25%;
      top: 25%;
      width: 50%;
      height: 50%;
      border-radius: 50%;
      transform: scale(0.5);
      opacity: 0;
      background: $main;
      transition: all 107ms $bezier;
    }
  }

  :where(
    input[type="checkbox"]:focus,
    input[type="radio"]:focus,
    input[type="checkbox"]:active,
    input[type="radio"]:active
  ) {
    & + div {
      box-shadow: 0 0 0 2px rgba($tertiary-background-color, 0.3);
    }

    &:checked + div {
      box-shadow: 0 0 0 2px rgba($secondary-background-color, 0.3);
    }
  }

  input[type="checkbox"]:checked + div {
    border-color: rgba($secondary-background-color, $focused-border-opacity - 0.7);
    background-color: rgba($secondary-background-color, $focused-border-opacity);
    transition: border-color 107ms $bezier;

    .mcui-check {
      opacity: 1;
      transition: opacity 107ms $bezier;

      polyline {
        animation: dash-check 107ms $bezier forwards;
      }
    }
  }

  input[type="radio"]:checked + div {
    border-color: $main;

    &::after {
      opacity: 1;
      transform: scale(1);
    }
  }

  input[type="checkbox"]:indeterminate + div::after {
    content: "";
    height: 4px;
    width: 60%;
    left: 20%;
    top: calc(50% - 2px);
    position: absolute;
    background: $grey;
    border-radius: 1px;
  }

  input[type="checkbox"]:disabled ~ div,
  input[type="radio"]:disabled ~ div {
    color: $grey;
    cursor: not-allowed;
  }

  input[type="checkbox"]:enabled ~ div,
  input[type="radio"]:enabled ~ div {
    cursor: pointer;
  }
}

.mcui-check {
  height: 100%;
  width: 100%;
  transform: scale(1);
  color: $main;
  opacity: 0;

  polyline {
    fill: none;
    transform-origin: 50% 50%;
    stroke-width: 5px;
    stroke-dasharray: 22.771367900227325;
    stroke: currentcolor;
  }
}

@supports (display: grid) {
  .mcui-check {
    polyline {
      stroke-dashoffset: 22.771367900227325;
    }
  }
}

@keyframes dash-check {
  to {
    stroke-dashoffset: 0;
  }
}
