@import '../../../../assets/scss/variables.scss';

.adr-book-list-sort-select {
  position: relative;

  &__input {
    display: none;
  }

  &__input-wrap {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: center;
    padding: 0 14px;
    cursor: pointer;

    &--focused {
      .adr-book-list-sort-select__indicator {
        transform: rotateX(180deg);
      }
    }
  }

  &__input-value {
    margin-left: 10px;
    @extend %font-main-text;
  }

  &__indicator {
    width: 13px;
    height: 9px;
    color: $color-bermuda;
  }

  &__list {
    z-index: 2;
    position: absolute;
    top: 24px;
    right: 0;
    width: 200px;
    padding: 15px 0 0;
    border-radius: 0 0 6px 6px;
    font-weight: normal;
    background-color: $color-white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.12);
  }

  &__item {
    &:nth-child(even) > div {
      background-color: $color-athens-gray;
    }

    &:last-child > div {
      border-radius: 0 0 6px 6px;
    }
    & > div:hover {
      background-color: $color-bermuda;
    }
  }

  &__option {
    width: 100%;
    padding: 10px 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    @extend %font-main-text;
    &--active, &--active:hover {
      color: $color-bondi-blue;
    }
  }
}