@import 'assets/scss/variables';

.message-link {
  display: inline-block;
  color: $color-azure-radiance;
  vertical-align: middle;
  font-style: italic;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  cursor: pointer;
}
