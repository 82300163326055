@import '../../assets/scss/variables.scss';

.adr-book-tags {
  display: flex;
  flex-direction: column;
  width: 234px;
  min-width: 234px;
  color: $color-gray;
  font-size: 14px;
  margin-top: 16px;

  &__list {
    flex-grow: 1;
    height: 1px;
    margin-top: 15px;
    padding-right: 5px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-button {
      height: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: $color-silver;
      border-radius: 3px;
      margin: 5px;
    }
  }

  &__list-item {
    margin: 5px 0;
  }

  &__list-item--temporary {
    &::before {
      display: block;
      height: 1px;
      margin: 10px 0;
      border-radius: 8px;
      content: '';
      background-color: #ccc;
    }
  }

  &__list-item-title {
    position: relative;
    display: flex;
    align-items: center;
    margin: 13px 0;
    @extend %font-main-text;

    span {
      padding: 0 10px;
      display: flex;
      gap: 6px;
      align-items: center;
    }

    // &::before,
    // &::after {
    //   content: '';
    //   flex-grow: 1;
    //   height: 2px;
    //   background-color: #ccc;
    // }
  }

  // select all but the first adr-book-tags__list-item child of .list
  &__list>.adr-book-tags__list-item--client~.adr-book-tags__list-item--client,
  &__list>.adr-book-tags__list-item--girl~.adr-book-tags__list-item--girl {
    .adr-book-tags__list-item-title {
      display: none;
    }
  }

  &__spinner-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 245px;
    height: calc(100% - 16px);
  }

  &__title {
    @extend %font-main-text;
  }

  &__tag {
    @extend %font-main-text;
    display: flex;
    padding: 4px 10px;
    border-radius: 12px;
    color: $color-black;
    border: 1px solid $color-athens-gray;
    box-shadow: $default-control-elements-shadow;
    transition: $default-animation;
    max-width: 100%;

    &:hover {
      box-shadow: $default-control-elements-hover-shadow;
    }

    &-title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &--active {
      color: $color-white;
      background-color: $color-bermuda-dark;
      font-family: $font-family-main;
      border-bottom: 1px solid $color-neptune;
    }
  }

  &__ex {
    color: #f27e3f;
    font-weight: bold;
    background-color: transparent;
  }
}
