@import 'assets/scss/variables';

/* Reset */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
button,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
textarea {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  text-decoration: none;
  background-color: transparent;
}

:root {
  --separator-border: transparent !important;
}

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

*,
*:after,
*:before {
  box-sizing: inherit;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-button {
    height: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    margin: 5px;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: subpixel-antialiased;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-button {
    height: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    margin: 5px;
  }

  &:has(.modal-background--girls-map) .pac-container {
    // style for dropdown of Google Maps API in girls-map modal
    top: revert !important;
    bottom: 70px;
  }
}

div[style*="background-color: transparent"]:has(.girls-map__girl-marker) {
  // style for changing stacking context of layers with geo markers
  z-index: 2;
}

code {
  font-family: $font-family-main;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

button {
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
}

a {
  all: unset;
  cursor: pointer;
}

// styles for chat tabs
.rc-overflow-item {
  &[aria-hidden="true"]:not(&-rest) {
    transform: translate(-9999px);
  }

  &:not(&-rest):not(:nth-last-child(2))>* {
    margin-right: 5px;
  }

  &:has(.chat__contact-item) {
    cursor: grab;
  }
}

.container {
  margin: 0 auto;
  padding: 0 80px;
}

.popup {
  display: flex;
  flex-flow: row wrap;
  position: relative;
  box-shadow: $shadow-primary;
  background-color: $color-white;
  overflow-wrap: anywhere;
  color: $color-gray;
  border-radius: 6px;
  font-size: 12px;
  letter-spacing: 1px;
  max-width: 310px;
  padding: 10px;
  text-align: center;
  transform: translateY(-5px);
  z-index: 120;

  &--main {
    padding: 18px 22px;
    box-shadow: $shadow-primary;
    box-shadow: 0 2px 0 0 $color-bondi-blue;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-shadow: $shadow-primary;
    }
  }

  &--large {
    padding: 30px 10px;
  }

  &__icon {
    display: block;
    position: absolute;
    left: 50%;
    bottom: 8px;
    transform: scale(1.3) translateX(-50%);
  }

  &__arrow {
    position: relative;
    z-index: 2;
    width: 5px;
    height: 5px;
    bottom: 0;

    &--main::before {
      box-shadow: 2px 2px 0 0 $color-bondi-blue;
    }

    &::before {
      content: '';
      position: absolute;
      transform: translateX(-50%) rotate(45deg);
      width: 8px;
      height: 8px;
      background: inherit;
      background-color: #fff;
    }
  }

  &::after {
    content: "";
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-top: 5px solid $color-white;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    left: 50%;
    bottom: -4px;
    transform: translateX(-53%);
  }
}

.popup-menu {
  box-shadow: $shadow-primary;
  background-color: $color-white;
  color: $color-gray;
  border-radius: 6px !important;
  padding: 0 !important;
  border-bottom: 2px solid $color-bondi-blue;

  & :where(&__item, div) {
    padding: 11px 15px;
    font-family: Roboto;
    font-size: 12px;
    letter-spacing: 1px;
    cursor: pointer;

    &:nth-child(even) {
      background-color: $color-athens-gray;
    }

    &:first-child {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }

    &:last-child {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    &:hover {
      background-color: $color-bermuda;
      cursor: pointer;
    }
  }

  &--dark {
    & :where(.popup-menu__item, div) {
      background-color: rgba($color-bermuda, 0.54);
      ;

      &:nth-child(even) {
        background-color: $color-bermuda-opacity;

        &:hover {
          background-color: $color-bermuda;
          cursor: pointer;
        }
      }
    }
  }
}

.split-view {
  overflow: visible !important;

  &-view {
    overflow: visible !important;
  }
}

.round-img {
  border-radius: 50%;
  background: $color-white;
}

.hidden {
  display: none;
}

.react-select {
  color: $color-gray;
  $block: &; // For increase specificity

  #{$block}__control {
    padding-left: 0;
    min-height: 24px;
    border: none;
    box-shadow: none;
    border-radius: 6px 6px 0 0;
    background-color: transparent;

    &--menu-is-open {
      #{$block}__dropdown-indicator svg {
        transform: rotate(180deg);
        color: $color-bondi-blue;
      }
    }
  }

  #{$block}__clear-indicator {
    padding: 0;
  }

  #{$block}__dropdown-indicator {
    color: $color-bondi-blue;

    &:hover {
      color: $color-bondi-blue;
    }
  }

  #{$block}__value-container {
    padding: 0;
  }

  #{$block}__menu {
    z-index: 50;
    margin: 0;
    padding: 0;
    width: max-content;
    min-width: 100%;
    box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.12);
    border-radius: 0 0 6px 6px;

    &-list {
      padding: 0;
    }
  }

  #{$block}__option {
    padding: 11px 15px;
    letter-spacing: 1px;
    font-size: 12px;
    background-color: $color-white;
    cursor: pointer;

    &:active {
      background-color: inherit;
    }

    &:hover {
      background-color: $color-bermuda !important;
    }

    &:nth-child(even) {
      background-color: $color-athens-gray;
    }

    &:last-child {
      border-radius: 0 0 6px 6px;
    }

    &--is-focused {
      color: inherit;
      background-color: inherit;
    }

    &--is-selected {
      background-color: inherit;
      color: $color-bondi-blue;
    }
  }

  #{$block}__single-value {
    color: $color-gray;
    font-family: $font-family-main;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__loading-indicator {
    display: flex;
    padding: 11px;
  }

  #{$block}__indicator-separator {
    display: none;
  }
}

.bookings-select-filter {
  border: 1px solid $color-silver;
  border-radius: 6px;

  &:hover {
    border: 1px solid $color-bondi-blue;
  }

  &:has(.react-select--menu-is-open) {
    border: 1px solid $color-bondi-blue;
  }

  &:has(.react-select--is-disabled) {
    opacity: 0.8;
    cursor: default;
  }

  .react-select {
    &__control {
      display: flex;
      border-radius: 6px;
      justify-content: space-between;
      width: 130px;
      padding: 4px 5px 4px 9px;
      background-color: $color-white;
      font-size: 14px;
      transition: all 0.15s ease-in-out;
      color: $color-tundora;
      min-height: 30px;
      cursor: pointer;
    }

    &__indicator {
      color: $color-gray;
      padding: 0;

      &:nth-child(2) {
        display: none;
      }

      svg {
        width: 14px;
        height: 14px;
      }
    }

    &__menu {
      margin-top: -4px;
      width: 100%;
      left: 0;
    }

    &__menu-list {
      position: relative;
      @include scrollBars;
      width: 100%;
    }

    &__option {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &__single-value {
      font-size: 14px;
      font-weight: normal;
    }

    &--is-disabled {
      .agent-select__dropdown-indicator {
        display: none;
      }
    }
  }
}

.scrollbar {
  &--loading {
    pointer-events: none;
  }
}

.scrollbar-thumb {
  position: absolute;
  top: 0;
  right: 0;
  width: 5px;
  background: lightgrey;
  border-radius: 4px;
  z-index: 3;
  opacity: 0.7;
  margin: 0 4px;
  transition: opacity 0.3s;

  &--hidden {
    opacity: 0;
  }
}

/* Visually Hidden */
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;

  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

/* Animations */
.animated-init {
  animation: slideInRight 0.5s;
}

.animated-init-left {
  animation: slideInLeft 0.5s;
}

.animated-init-bottom {
  animation: slideInBottom 0.5s;
}

.checkbox-container {
  position: relative;
  height: 15px;
  width: 15px;
  min-width: 15px;

  & input {
    position: absolute;
    opacity: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    cursor: pointer;

    &~.checkmark {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 2px solid $color-athens-gray !important;

      & svg {
        display: none;
      }

      &--completed {
        border-width: 0 !important;
        color: $color-caribian-green;

        & svg {
          display: block;
          width: 13px;
          height: 13px;
        }
      }
    }

    &:checked~.checkmark {
      border: initial;
      color: $color-caribian-green;

      & svg {
        width: 13px;
        height: 13px;
      }
    }
  }
}

.flexrow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flexcolumn {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reveal-button {
  height: 24px;
  width: 24px;
  color: $color-tundora;

  & svg {
    height: 13px;
    width: 13px;
  }
}

.reveal-button--closed {
  transform: rotate(180deg);
}

.reveal-button--operators {
  position: absolute;
  right: 19px;
  top: 35px;
}

.reveal-button--sessions {
  position: absolute;
  top: 10px;
  left: 19px;
}

.avatar {
  border-radius: 50%;
}

.flex-break {
  flex-basis: 100%;
  height: 0;
}

.not-scrolled-container {
  & .scrollbars-track {
    opacity: 0;
    transition: opacity 0.3s;
  }
}

.scrolled-container {
  & .scrollbars-track {
    opacity: 1;
  }
}

.client-marker {
  --color: #3565FD;
  --border-color: blue;
  --content-color: white;
  --text-color: #3565FD;
}

.girl-marker {
  --color: #F8737F;
  --content-color: white;

  &--available {
    --color: #01df85;
    ;
  }
}

.cluster-marker {
  --color: #62aff8;
  --content-color: #62aff8;
  --text-color: black;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.custom-menu {
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 300ms, transform 300ms;
}

.custom-menu.react-select__menu--is-open {
  opacity: 1;
  transform: translateY(0);
}

.dropdown-enter {
  opacity: 0;
  transform: translateY(-10px);
}

/* Entering state */
.dropdown-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}

/* Exiting state */
.dropdown-exit {
  opacity: 1;
  transform: translateY(0);
}

/* Exiting state */
.dropdown-exit-active {
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 300ms, transform 300ms;
}

@keyframes slideInRight {
  from {
    transform: translate3d(100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInLeft {
  from {
    transform: translate3d(-100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInBottom {
  from {
    opacity: 0;
    transform: translate3d(0, 40%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
