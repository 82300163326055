@import 'assets/scss/variables';

.public-chat-girls-item {
  display: flex;
  align-items: center;
  margin-top: 3px;

  &.available-girls-list-item--active {
    border: 1px solid $color-bondi-blue;
    background-color: rgba(#88D6D9, 0.44);
  }

  // &:hover {
  //   box-shadow: 0 3px 6px 0 rgba(0,0,0,0.12);
  //   cursor: pointer;
  //   border-color: transparent;
  // }

  // &:hover &__btn--room-call {
  //   display: block;
  // }

  &--pending {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #cacaca;
  }

  &__img-wrap {
    position: relative;
    margin-left: 5px;
    width: 24px;
    height: 24px;
    min-width: 24px;
  }

  &__telegram-icon {
    position: absolute;
    right: 0;
    bottom: -3px;
    width: 12px;
    height: 12px;
    border: 1px solid $color-white;
    border-radius: 50%;
    background: $color-white;
    color: $color-azure-radiance;
  }

  &__status {
    position: absolute;
    z-index: 2;
    top: -1px;
    left: -4px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: $color-spring-green;

    &::after {
      position: absolute;
      z-index: 9999;
      left: calc(100% - 14px);
      top: -6px;
      content: "";
      width: 20px;
      height: 20px;
    }
    
    &--off-today {
      background-color: $color-froly;
    }
  }

  &__img {
    border-radius: 50%;
  }

  &__name {
    flex-grow: 1;
    overflow: hidden;
    margin-left: 6px;
    color: $color-gray;
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
  }

  &__box {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    min-width: 0;
  }

  &__icon-info {
    margin: 0 6px;
    width: 11px; 
    height: 11px;
    min-width: 11px;
    fill: $color-silver;
  }

  &__ex {
    font-weight: bold;
    color: #f27e3f;
  }

  &__time {
    display: flex;
    align-items: flex-end;
    flex-grow: 1;
    overflow: hidden;
    margin-left: 5px;
    margin-right: 30px;
    max-width: 73px;
    min-width: 73px;
    text-overflow: ellipsis;
    font-size: 10px;
    white-space: nowrap;
    color: $color-tundora-light;
  }

  &__msg-wrapper {
    display: flex;
    margin-top: 6px;
    padding: 7px 10px 7px 14px;
    border-radius: 6px;
    background-color: $color-white;
  }

  &__icon-undo {
    width: 10px;
    height: 10px;
    min-width: 10px;
    fill: $color-bondi-blue;
  }

  &__last-msg {
    flex-grow: 1;
    margin-right: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    white-space: nowrap;

    .interaction__text {
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: unset;
      line-height: unset;
      white-space: nowrap;
      color: $color-gray;
    }

    &::-moz-selection {
      background: transparent;
    }
    &::selection {
      background: transparent;
    }
  }
}