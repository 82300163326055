@import 'assets/scss/variables.scss';

// [class*='list-container'] > div > div {
//   margin-right: 0 !important;
//   margin-bottom: 0 !important;

//   &::-webkit-scrollbar {
//     width: 0;
//     height: 0;
//   }
// }

[class*='list-container'] {
  position: relative;
}

[class*='no-items'] {
  display: flex;
  margin: auto;
}

[class*='load-wrap'] {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

[class*='list'] {
  scrollbar-width: none;
}
