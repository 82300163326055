@import 'assets/scss/variables';

.bookings-list-modal {
  display: flex;
  flex-direction: column;
  min-width: 750px;
  height: 825px;
  border-radius: 6px;
  text-align: initial;
  background-color: $color-white;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 15px;
    background-color: $color-mystic;
  }

  &__main {
    position: relative;
    flex-grow: 1;
    padding: 24px 15px;
    border-bottom: 2px solid $color-bondi-blue;
    border-radius: 6px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      width: 4px;
      border-radius: 2px;
      background-color: $color-gray;
    }
  }

  &__btn {
    width: 140px;
    height: 40px;
    border-radius: 6px;
    color: $color-white;
    font-weight: 600;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    font-family: Roboto;
    text-align: center;
    letter-spacing: 1px;
    background-color: $color-bondi-blue;
  }

  &__prebookings-counter {
    color: $color-gray;
    font-size: 14px;
    line-height: 24px;
    font-family: Roboto;
    letter-spacing: 0;
  }

  &__spinner {
    position: absolute;
    margin: auto;
    inset: 0;
  }
}